/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import GenericModal from "../../Modal/GenericModal";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import ModeIcon from "@mui/icons-material/Mode";

import { isMobile } from "../../Utils/utils";
import { useEffect, useState } from "react";
import {
  AutocompleteModelForDrivers,
  GenericAutocompleteModalModelForDrivers,
  ShippingData,
} from "../../Models/Models";
import { urlEnum } from "../../Utils/urlEnum";
import { getData } from "../../Services/getData";
import GenericAutocompleteModal from "../../Modal/GenericAutocompleteModal";
import { AutocompleteSchemaforDrivers } from "../../Validators/vehicleValidators";
import { postData } from "../../Services/postData";
import { updateData } from "../../Services/updateData";

type StatusProps = {
  open: boolean;
  title: string;
  editedShipping: ShippingData | null;
  onClose: (shouldRefetch?: boolean) => void;
};

const initialState: ShippingData = {
  name: "",
  address: "",
  phoneNumber: "",
  drivers: [],
  nrVehicul: "[]",
  nrRemorca: "[]",
};

const initialGenericModalState = new GenericAutocompleteModalModelForDrivers();

export default function ShippingModal(props: StatusProps) {
  const { open, title, onClose, editedShipping } = props;
  const [data, setData] = useState<ShippingData>(initialState);
  const [nrInmatriculare, setNrInmatriculare] = useState({
    nrVehicul: "",
    nrRemorca: "",
  });
  const [genericAutocompleteModel, setGenericAutocompleteModel] = useState(
    initialGenericModalState
  );

  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    getDrivers();
    if (editedShipping) {
      setData(editedShipping);
    }
  }, [editedShipping]);

  /**
   *
   * @param data
   */
  function localClose() {
    onClose(false);
    setData(initialState);
  }

  /**
   *
   * @param data
   */
  function saveTransport() {
    const newIds = [];
    const formatedDriversArray = Object.assign({}, data);
    for (const idx in data.drivers) {
      newIds.push(data.drivers[idx].id);
    }
    formatedDriversArray.drivers = newIds;
    formatedDriversArray.nrVehicul = nrInmatriculare.nrVehicul.toUpperCase();
    formatedDriversArray.nrRemorca = nrInmatriculare.nrRemorca.toUpperCase();
    if (editedShipping) {
      const url = `${urlEnum.shipping}/${editedShipping.id}`;

      updateData(url, formatedDriversArray)
        .then(() => {
          setData(initialState);
          onClose(true);
        })
        .catch(() => {
          setData(initialState);
          onClose(false);
        });
    } else {
      const url = `${urlEnum.shipping}`;
      postData(url, formatedDriversArray)
        .then(() => {
          setData(initialState);
          onClose(true);
        })
        .catch(() => {
          setData(initialState);
          onClose(false);
        });
    }
  }

  /**
   *
   * @param event
   */
  function handleChangeTextFields(event: any) {
    if (
      event.target.name === "nrVehicul" ||
      event.target.name === "nrRemorca"
    ) {
      setNrInmatriculare({
        ...nrInmatriculare,
        [event.target.name]: event.target.value,
      });
    } else setData({ ...data, [event.target.name]: event.target.value });
  }

  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeType(event: any, newValue: any) {
    const newIds = [];
    const newData = Object.assign({}, data);
    for (const idx in newValue) {
      newIds.push(newValue[idx]);
    }
    newData.drivers = newIds;
    setData(newData);
  }

  /**
   *
   * @param param0
   * @returns
   */
  const DropDownButton = ({ children, ...other }: any) => (
    <Paper {...other}>
      <IconButton
        onMouseDown={(event) => {
          event.preventDefault();
          children[1] === "null" || children[1] === null
            ? handleClickEditDrop(
                children[2]?.props?.ownerState?.options,
                children[2]?.props?.ownerState?.label,
                children[2].props?.ownerState?.id
              )
            : handleClickEditDrop(
                children[1]?.props?.ownerState?.options,
                children[1]?.props?.ownerState?.label,
                children[1].props?.ownerState?.id
              );
        }}
        style={{
          position: "absolute",
          top: -40,
          right: -36,
          margin: 5,
          display: "flex",
          justifyContent: "end",
          backgroundColor: "#FF6633",
          color: "#fff",
          borderRadius: 7,
          zIndex: 100,
        }}
        size="small"
        aria-label="editDropdown"
      >
        <ModeIcon fontSize="inherit" />
      </IconButton>
      {children}
    </Paper>
  );

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Nume",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "phoneNumber",
      label: "Numar telefon",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  /**
   *
   * @param data
   * @param title
   * @param id
   */
  const handleClickEditDrop = (data: any, title: any, id: any) => {
    setGenericAutocompleteModel({
      ...genericAutocompleteModel,
      open: true,
      title: title,
      data: data,
      id: id,
      columns: columns,
      validationSchema: AutocompleteSchemaforDrivers,
      hiddenFieldsArray: [
        "tip_cost_adm",
        "comision",
        "created_at",
        "id",
        "pret_recomandat",
        "tip_cost_adm",
        "updated_at",
      ],
      modelForFields: new AutocompleteModelForDrivers(),
    });
  };

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  function getDrivers() {
    const url = `/${urlEnum.drivers}`;

    getData(url)
      .then((res: any) => {
        setDrivers(res.data.drivers);
      })
      .catch((err) => {
        //
      });
  }

  return (
    <GenericModal open={open} onClose={() => localClose()} title={title}>
      <ValidatorForm onSubmit={saveTransport}>
        <Grid container spacing={isMobile() ? 1 : 4} style={{ marginTop: 5 }}>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="name"
              label={Vocabulary.name}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.name}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="phoneNumber"
              label={Vocabulary.phone}
              fullWidth
              //validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.phoneNumber}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="nrVehicul"
              label={Vocabulary.ntVehicul}
              fullWidth
              //validators={["required"]}
              defaultValue={""}
              errorMessages={[Vocabulary.requiredField]}
              value={nrInmatriculare.nrVehicul}
              onChange={handleChangeTextFields}
            />
            {JSON.parse(data.nrVehicul)?.map((value: any) => (
              <Chip
                key={value.id}
                label={value}
                style={{
                  margin: 5,
                  backgroundColor: "#4442",
                }}
              />
            ))}
          </Grid>
          <Grid item md={6} xs={12}>
            <Autocomplete
              id="drivers"
              freeSolo={false}
              multiple
              onChange={(event: any, newValue: any) =>
                handleChangeType(event, newValue)
              }
              value={data?.drivers ? data?.drivers : []}
              PaperComponent={DropDownButton}
              getOptionLabel={(option: any) => option.name}
              options={drivers ? drivers : []}
              // getOptionDisabled={(option: any) =>
              //   !!drivers.find(
              //     (element: any) => element.name === option.name
              //   )
              // }
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="drivers"
                  label={Vocabulary.drivers}
                  //   validators={["required"]}
                  //   errorMessages={[Vocabulary.requiredField]}
                  variant="outlined"
                  value={""}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="nrRemorca"
              label={Vocabulary.nrRemorca}
              fullWidth
              //validators={["required"]}
              defaultValue={""}
              errorMessages={[Vocabulary.requiredField]}
              value={nrInmatriculare.nrRemorca}
              onChange={handleChangeTextFields}
            />
            {JSON.parse(data.nrRemorca)?.map((value: any) => (
              <Chip
                key={value.id}
                label={value}
                style={{
                  margin: 5,
                  backgroundColor: "#4442",
                }}
              />
            ))}
          </Grid>

          <Grid
            container
            spacing={isMobile() ? 1 : 4}
            style={{ margin: 10, marginTop: 10 }}
          >
            <Grid item md={6} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => localClose()}
                fullWidth
              >
                {Vocabulary.cancel}
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                style={{ color: "white" }}
              >
                {Vocabulary.save}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
      {genericAutocompleteModel.open && (
        <GenericAutocompleteModal
          open={genericAutocompleteModel.open}
          onClose={() =>
            setGenericAutocompleteModel({
              ...genericAutocompleteModel,
              open: false,
              modelForFields: new AutocompleteModelForDrivers(),
            })
          }
          title={genericAutocompleteModel.title}
          data={genericAutocompleteModel.data}
          id={genericAutocompleteModel.id}
          columns={genericAutocompleteModel.columns}
          validationSchema={genericAutocompleteModel.validationSchema}
          hiddenFieldsArray={genericAutocompleteModel.hiddenFieldsArray}
          modelForFields={genericAutocompleteModel.modelForFields}
          onEdit={(value, tableMeta: any) => {
            setGenericAutocompleteModel({
              ...genericAutocompleteModel,
              modelForFields: tableMeta.tableData[tableMeta.rowIndex],
            });
          }}
          onSucces={getDrivers}
        >
          <></>
        </GenericAutocompleteModal>
      )}
    </GenericModal>
  );
}
