/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */

import { Paper } from "@mui/material";

export type ControlPanelCardProps = {
  name?: string;
  children?: any;
};

export default function ControlPanelCard(props: ControlPanelCardProps) {
  return (
    <Paper style={{ margin: 10,minHeight:200}}>
      <div>{props.children}</div>
    </Paper>
  );
}
