import {
  Autocomplete,
  Chip,
  Divider,
  //Divider,
  Grid,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { urlEnum } from "../../Utils/urlEnum";
import { getData } from "../../Services/getData";
import { useLocation, useNavigate } from "react-router-dom";
import { CacheContext } from "../../Context/cacheContext";
import styles from "../../Styles/salaries.module.css";

export default function UserSalaries() {
  const months = Array.from({ length: 12 }, (e, i) =>
    new Date(0, i).toLocaleString("ro", { month: "long" })
  );
  const currentDate = new Date();
  const [state, setState] = useState({
    users: [],
    usersNumber: 0,
    page: 0,
    perPage: 20,
    year: currentDate.getFullYear(),
    month: currentDate.getMonth() + 1,
    monthlyProfit: 0,
    totalUserSalaries: 0,
  });
  const navigate = useNavigate();
  const cacheData = useContext(CacheContext);
  const desktop = useMediaQuery("(min-width:1320px)");
  const years = cacheData.cache?.years;
  const location = useLocation();
  const numberFormat = new Intl.NumberFormat();

  /**
   *
   */
  useEffect(() => {
    getUsers(state.page, state.perPage, null);
  }, [state.month, state.year]);

  /**
   *
   */
  const getUsers = (
    page = state.page,
    perPage = state.perPage,
    search: string | null
  ) => {
    const url = `${urlEnum.usersForSalaries}/${page}/${perPage}/${search}/${state.month}/${state.year}`;
    const queryParams = new URLSearchParams(location.search);
    getData(url)
      .then((res) => {
        setState({
          ...state,
          users: res.data.users,
          usersNumber: res?.data?.usersNumber,
          page: page,
          perPage: perPage,
          year: queryParams.get("year")
            ? parseInt(queryParams.get("year") ?? "")
            : state.year,
          month: queryParams.get("month")
            ? parseInt(queryParams.get("month") ?? "")
            : state.month,
          monthlyProfit: res?.data?.monthlyProfit?.grossIncome,
          totalUserSalaries: res?.data?.totalUserSalaries,
        });
      })
      .catch((err) => {
        //
      });
  };

  /**
   *
   */
  const columns = [
    {
      name: "id",
      label: "",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "name",
      label: Vocabulary.name,
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "email",
      label: Vocabulary.email,
    },
    {
      name: "base_net_salary",
      label: Vocabulary.baseNetSalary,
      options: {
        customBodyRender: (salary: any) => {
          return (
            <Typography>{` ${numberFormat.format(salary)} ${
              Vocabulary.lei
            }`}</Typography>
          );
        },
      },
    },
    {
      name: "net_salary",
      label: Vocabulary.netSalary,
      options: {
        customBodyRender: (salary: any) => {
          return (
            <Typography>{` ${numberFormat.format(salary)} ${
              Vocabulary.lei
            }`}</Typography>
          );
        },
      },
    },
    {
      name: "gross_salary",
      label: Vocabulary.grossSalary,
      options: {
        customBodyRender: (salary: any) => {
          return (
            <Typography>{` ${numberFormat.format(salary)} ${
              Vocabulary.lei
            }`}</Typography>
          );
        },
      },
    },
    {
      name: "roles",
      label: Vocabulary.roles,
      options: {
        customBodyRender: (dataIndex: any, rowIndex: any) => {
          return (
            <>
              {dataIndex?.map((role: any, index: number) => {
                return <Chip size="small" key={index} label={role.name} />;
              })}
            </>
          );
        },
      },
    },
  ];

  /**
   *
   */
  const options = {
    filter: false,
    responsive: "standard" as any,
    download: false,
    viewColumns: false,
    rowsPerPageOptions: [
      10, 20, 50,
      //state?.vehiclesNumber > 100 ? state?.vehiclesNumber : 1000,
    ],
    sort: false,
    textLabels: {
      viewColumns: {
        title: Vocabulary.viewColumns,
      },
      toolbar: {
        downloadCsv: Vocabulary.downloadExcel,
        viewColumns: Vocabulary.selectColumns,
      },
      pagination: Vocabulary.pagination,
    },
    fixedHeader: false,
    fixedSelectColumn: false,
    selectableRows: "none" as any,
    print: false,
    page: state.page,
    serverSide: true,
    rowsPerPage: state.perPage,
    search: true,
    count: state?.usersNumber,
    rowHover: true,
    onRowClick: (rowData: any, rowState: any) => {
      navigate(`/salaries/user/${rowData[0]}/${state.month}/${state.year}`);
    },
    onChangePage: (page: number) => {
      getUsers(page, state.perPage, null);
      window.scrollTo(0, 0);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      getUsers(state.page, numberOfRows, null);
      window.scrollTo(0, 0);
    },
    searchProps: {
      onBlur: (e: any) => {
        getUsers(0, state.perPage, e.target.value);
        window.scrollTo(0, 0);
      },
      onKeyUp: (e: any) => {
        //if (e.target?.value == "") getUsers(0, state.perPage, null);
        if (e.key == "Enter") getUsers(0, state.perPage, e.target.value);
      },
    },
    onSearchClose: () => {
      getUsers(0, state.perPage, null);
      window.scrollTo(0, 0);
    },
    setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
      return {
        style: { cursor: "pointer" },
      };
    },
  };

  const renderFinancialSummary = () => {
    return (
      <div>
        <Typography>{`${Vocabulary.monthlyProfit}: ${numberFormat.format(
          state.monthlyProfit
        )} ${Vocabulary.lei}`}</Typography>
        <Typography>{`${Vocabulary.totalUserSalaries}: ${numberFormat.format(
          state.totalUserSalaries
        )} ${Vocabulary.lei}`}</Typography>
        <Divider className={styles.divider} />
        <Typography>{`${Vocabulary.profit}: ${numberFormat.format(
          state.monthlyProfit - state.totalUserSalaries
        )} ${Vocabulary.lei}`}</Typography>
      </div>
    );
  };
  return (
    <>
      <Paper className={styles.paper} elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={2} lg={4}>
            <Typography>{Vocabulary.yearName}</Typography>
            {desktop ? (
              <ToggleButtonGroup
                color="primary"
                value={state.year}
                exclusive
                onChange={(event, newValue) => {
                  navigate(`/salaries?year=${newValue}&month=${state.month}`);
                  setState({ ...state, year: newValue });
                }}
                aria-label="Platform"
              >
                {years?.map((year: string, index: number) => {
                  return (
                    <ToggleButton key={index} value={year}>
                      {year}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            ) : (
              <Autocomplete
                freeSolo={false}
                fullWidth
                disablePortal
                value={state.year}
                isOptionEqualToValue={(option, value) => option == value}
                getOptionLabel={(option: any) => option.toString()}
                options={years}
                onChange={(event: any, newValue: any) => {
                  navigate(`/salaries?year=${newValue}&month=${state.month}`);
                  setState({ ...state, year: newValue });
                }}
                sx={{ maxWidth: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
              />
            )}
          </Grid>
          <Grid item xs={6} md={10} lg={8}>
            <Typography>{Vocabulary.month}</Typography>
            {desktop ? (
              <ToggleButtonGroup
                color="primary"
                value={state.month}
                exclusive
                onChange={(event, newValue) => {
                  setState({ ...state, month: newValue });
                  navigate(`/salaries?year=${state.year}&month=${newValue}`);
                }}
                aria-label="Platform"
              >
                {months?.map((month, index) => {
                  return (
                    <ToggleButton key={index} value={index + 1}>
                      {month}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            ) : (
              <Autocomplete
                freeSolo={false}
                fullWidth
                disablePortal
                //getOptionLabel={(option: any) => option.nume}
                //  size="s"
                value={months[state.month - 1]}
                isOptionEqualToValue={(option, value) =>
                  months.indexOf(option) === months.indexOf(value)
                }
                options={months}
                onChange={(event: any, newValue: any) => {
                  const index = months.indexOf(newValue);
                  if (index >= 0) setState({ ...state, month: index + 1 });
                  navigate(`/salaries?year=${state.year}&month=${index + 1}`);
                }}
                sx={{ maxWidth: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {renderFinancialSummary()}
          </Grid>
          <Grid item xs={12}>
            <MUIDataTable
              title={Vocabulary.usersName}
              data={state.users}
              columns={columns}
              options={options}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
