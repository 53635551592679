/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */

import ControlPanelCard from "./ControlPanelCard";
import NewsFeedCardContent from "./NewsFeedCardContent";

export type ControlPanelProps = {
  name?: string;

};

export default function ControlPanel(props: ControlPanelProps) {
  return (
    <div style={{ margin: 10 }}>
      <div>
        <ControlPanelCard>
          
        </ControlPanelCard>
        <ControlPanelCard>
          <NewsFeedCardContent></NewsFeedCardContent>
        </ControlPanelCard>
        
      </div>
    </div>
  );
}
