import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ConstructionIcon from "@mui/icons-material/Construction";

import { Vocabulary } from "../Vocabulary";

export const ROUTER_INTERVENTII_SERVICE = {
  routes: [
    "/",
    "/vehiclesDashboard",
    "/vehiclesDashboard/vehicleService/:id?",
    "*",
  ],
};

export const MAIN_SLIDER_INTERVENTII_SERVICE = [{
  name: Vocabulary.vehicles,
  path: "/vehiclesDashboard",
  icon: <DirectionsCarIcon />,
}];

export const VEHICLE_OPTIONS_INTERVENTII_SERVICE = [
  {
    name: Vocabulary.service,
    path: "/vehiclesDashboard/vehicleService/",
    icon: <ConstructionIcon />,
    id: "vehicleService",
    slug: "interventii_service",
    orderNumber: 3,
  },
];

export const DINAMIC_TABS_INTERVENTII_SERVICE = {
  addVehicles: true,
};

