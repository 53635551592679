/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vocabulary } from "../../../Utils/Vocabulary";
import { useContext } from "react";
import { VehicleContext } from "../../../Context/vehicleContext";

import GenericModal from "../../../Modal/GenericModal";

import { urlEnum } from "../../../Utils/urlEnum";
import { Button, Grid } from "@mui/material";
import { deleteData } from "../../../Services/deleteData";
import { updateData } from "../../../Services/updateData";
import { ContractInformationModel } from "../../../Models/Models";
import moment from "moment";

type SubProgressInformation5_1DeleteProps = {
  handleModalReserve: any; //TODO
  open: boolean;
};

export default function SubProgressInformation5_1Delete(
  props: SubProgressInformation5_1DeleteProps
) {
  const vehicleContext: any = useContext(VehicleContext);

  /**
   * unreserve vehicle
   */
  async function unReserveTransportVehicle() {
    const dataUpdate = {
      ...vehicleContext.state.vehicle,
      client_id: null,
      data_rezervare: null,
      status_id: vehicleContext?.state.vehicle?.status_id,
      isLeasing: false,
      contractData: new ContractInformationModel(),
      indexes: vehicleContext.updateIndex(null, ["0.1", "0.2"]),
      modifyBy: vehicleContext.updateModifyBy([{index: "0.1",userId: localStorage.getItem("userName"),date: moment().format("DD-MM-YYYY HH:mm:ss")}]),
    };

    // unreserve vehicle
    const urlUpdate = `${urlEnum.reserveVehicle}/${vehicleContext?.state?.vehicle?.id}`;

    const responseUpdate = await updateData(urlUpdate, dataUpdate);
    // unreserve vehicle
    const url = `${urlEnum.deleteReserveForShipping}/${vehicleContext?.state?.vehicle?.vehicle_shipping_r[0]?.id}`;

    await deleteData(url);
    // update documents
    vehicleContext.updateVehicle(responseUpdate?.data?.vehicle);

    // console.log(response); //PUS SA VAD CE VINE IN RESPONSE
    props.handleModalReserve();
  }

  return (
    <div>
      <GenericModal
        open={props.open}
        onClose={props.handleModalReserve}
        title={Vocabulary.unreserveShippingVehicleMessage}
      >
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={6}>
            <Button
              size="large"
              onClick={props.handleModalReserve}
              variant="contained"
              color="secondary"
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={unReserveTransportVehicle}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
    </div>
  );
}
