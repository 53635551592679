import {
  Box,
  Chip,
  Divider,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../Utils/Vocabulary";
import moment from "moment";
//import { FIRST_SEEN_USER_ID_NOT_ALLOCATED } from "../Utils/Constants";

type HistoryComponentProps = {
  logs: any;
  firstAllocMessage?: string;
  firstTimeStatusSetMessage?: string;
  statusChangedMessage?: string;
  createdMessage?: string;
  type?: string;
};

export default function HistoryComponent(props: HistoryComponentProps) {
  
  /**
   * Renders a physical status change message based on the provided log.
   * If both old and new physical status names are present, it returns a message indicating the physical status change.
   * If only a new physical status name is present, it returns a message indicating the first-time physical status set.
   * If neither are present, it returns a default status changed message.
   *
   */
  const renderPhysicalStatusMessage = (log: any) => {
    if (log.new_values?.physical_status_name) {
      if (log.old_values?.physical_status_name) {
        return (
          <>
            {props.statusChangedMessage}
            <strong>{log.old_values.physical_status_name}</strong>
            {Vocabulary.in}
            <strong>{log.new_values.physical_status_name}</strong>
          </>
        );
      }
      return (
        <>
          {props.firstTimeStatusSetMessage}{" "}
          <strong>{log.new_values.physical_status_name}</strong>
        </>
      );
    }
    return Vocabulary.statusChangedDefault;
  };

  /**
   * Renders a status change message based on the provided log.
   * If both old and new status names are present, it returns a message indicating the status change.
   * If only a new status name is present, it returns a message indicating the first-time status set.
   * If neither are present, it returns a default status changed message.
   */
  const renderStatusMessage = (log: any) => {
    if (log.new_values?.status_name) {
      if (log.old_values?.status_name) {
        return (
          <>
            {props.statusChangedMessage}
            <strong>{log.old_values.status_name}</strong>
            {Vocabulary.in}
            <strong>{log.new_values.status_name}</strong>
          </>
        );
      }
      return (
        <>
          {props.firstTimeStatusSetMessage}{" "}
          <strong>{log.new_values.status_name}</strong>
        </>
      );
    }
    return Vocabulary.statusChangedDefault;
  };

  return (
    <div>
      {props?.type && (
        <>
          <Divider style={{ margin: "40px 0 " }} />
          <Typography
            style={{
              display: "flex",
              justifyContent: "center ",
              fontSize: "1rem",
            }}
          >
            <strong>{Vocabulary.history + props.type}</strong>
          </Typography>
        </>
      )}
      <Stepper orientation="vertical" style={{ padding: 10 }}>
        {props.logs.length
          ? props.logs.map((log: any, index: number) => {
              return (
                <Step active={true}>
                  <StepLabel
                    StepIconComponent={() => (
                      <div
                        style={{
                          display: "flex !important",
                          alignItems: "center !important",
                          width: "100% !important",
                          justifyContent: "space-between !important",
                        }}
                      >
                        <Chip
                          style={{
                            backgroundColor: "#4444 !important",
                            color: "#000 !important",
                            fontSize: "17px !important",
                            borderRadius: "5px !important",
                          }}
                          label={moment(log.created_at).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                        />
                      </div>
                    )}
                  />
                  <StepContent>
                    <Box
                      style={{
                        padding: "20px !important",
                        borderRadius: "7px",
                        backgroundColor: "#fff !important",
                        boxShadow:
                          "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                        position: "relative",
                        marginTop: "10px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={12}
                          style={{ paddingTop: 0 }}
                        >
                          <Typography
                            style={{
                              padding: "10px",
                              fontSize: "0.8rem",
                              fontStyle: "ITALIC",
                            }}
                          >
                            {log["user_id"]
                              ? Vocabulary.updatedBy + log.user.name
                              : Vocabulary.systemAssociated}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={12}
                          style={{ paddingTop: 0 }}
                        >
                          <Typography
                            style={{ padding: "10px", fontSize: "1rem" }}
                          >
                            {/* {JSON.stringify(log.old_values) ==
                        FIRST_SEEN_USER_ID_NOT_ALLOCATED
                          ? props.firstAllocMessage + log.user.name
                          : log.old_values?.status_name
                          ? props.statusChangedMessage +
                            log.old_values?.status_name +
                            " in " +
                            log.new_values?.status_name
                          : props.firstTimeStatusSetMessage +
                            log.new_values?.status_name} */}
                            {log.event == "created" ? (
                              <>
                                <div>
                                  {log.new_values["first_seen_user_id"]
                                    ? Vocabulary.setUser +
                                      log.new_values["first_seen_user_name"]
                                    : null}
                                </div>
                                <div>{props.createdMessage}</div>{" "}
                              </>
                            ) : (
                              Object.keys(log.new_values).map((value: any) => {
                                switch (value) {
                                  case "brand_id":
                                    return (
                                      <div>
                                        {log.new_values["brand_name"]
                                          ? log.old_values[value]
                                            ? Vocabulary.brandChanged +
                                              log.old_values["brand_name"] +
                                              Vocabulary.in +
                                              log.new_values["brand_name"]
                                            : Vocabulary.setAssociatedBrand +
                                              log.new_values["brand_name"]
                                          : Vocabulary.brandChangedDefault}
                                      </div>
                                    );
                                  case "status_id":
                                    return renderStatusMessage(log);
                                  case "physical_status_id":
                                    return renderPhysicalStatusMessage(log);
                                  case "buget":
                                    return (
                                      <div>
                                        {Vocabulary.budgetChanged +
                                          log.old_values["buget"] +
                                          Vocabulary.in +
                                          log.new_values["buget"]}
                                      </div>
                                    );
                                  case "detalii_extra":
                                    return (
                                      <div>
                                        {Vocabulary.detailsChanged +
                                          log.old_values[value] +
                                          Vocabulary.in +
                                          log.new_values[value]}
                                      </div>
                                    );
                                  case "email":
                                    return (
                                      <div>
                                        {Vocabulary.emailChanged +
                                          log.old_values[value] +
                                          Vocabulary.in +
                                          log.new_values[value]}
                                      </div>
                                    );
                                  case "nume_complet":
                                    return (
                                      <div>
                                        {Vocabulary.nameChanged +
                                          log.old_values[value] +
                                          Vocabulary.in +
                                          log.new_values[value]}
                                      </div>
                                    );
                                  case "localitate":
                                    return (
                                      <div>
                                        {Vocabulary.cityChanged +
                                          log.old_values[value] +
                                          Vocabulary.in +
                                          log.new_values[value]}
                                      </div>
                                    );
                                  case "model_id":
                                    return (
                                      <div>
                                        {log.new_values["model_name"]
                                          ? log.old_values[value]
                                            ? Vocabulary.modelChanged +
                                              log.old_values["model_name"] +
                                              Vocabulary.in +
                                              log.new_values["model_name"]
                                            : Vocabulary.setAssociatedModel +
                                              log.new_values["model_name"]
                                          : Vocabulary.modelChangedDefault}
                                      </div>
                                    );
                                  case "sursa_comanda":
                                    return (
                                      <div>
                                        {Vocabulary.sourceChanged +
                                          log.old_values[value] +
                                          Vocabulary.in +
                                          log.new_values[value]}
                                      </div>
                                    );
                                  case "telefon":
                                    return (
                                      <div>
                                        {Vocabulary.phoneChanged +
                                          log.old_values[value] +
                                          Vocabulary.in +
                                          log.new_values[value]}
                                      </div>
                                    );
                                  case "user_id":
                                    return (
                                      <div>
                                        {log.new_values["user_name"]
                                          ? Vocabulary.setAssociatedUser +
                                            log.new_values["user_name"]
                                          : log.new_values[value]
                                          ? Vocabulary.userChangedDefault
                                          : Vocabulary.associatedUserRemoved}
                                      </div>
                                    );
                                  case "vehicul_id":
                                    return (
                                      <div>
                                        {log.new_values["vin_vehicle"]
                                          ? Vocabulary.setAssociatedVehicle +
                                            log.new_values["vin_vehicle"]
                                          : Vocabulary.vehicleChangedDefault}
                                      </div>
                                    );
                                  case "first_seen_user_id":
                                    return (
                                      <div>
                                        {log.new_values["first_seen_user_name"]
                                          ? Vocabulary.setUser +
                                            log.new_values[
                                              "first_seen_user_name"
                                            ]
                                          : log.new_values[value]
                                          ? Vocabulary.userChangedDefault
                                          : Vocabulary.associatedUserRemoved}
                                      </div>
                                    );
                                }
                              })
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </StepContent>
                </Step>
              );
            })
          : null}
      </Stepper>
    </div>
  );
}
