import { forwardRef } from "react";
import { NumericFormat } from "react-number-format";

export const NumericFormatCustom = forwardRef(function NumericFormatCustom(
  props: any,
  ref
) {
  const { onChange, ...other }: any = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=" "
      valueIsNumericString
      decimalSeparator=","
    />
  );
});

export const EuropeanNumberFormatCustom = forwardRef(function NumericFormatCustom(
  props: any,
  ref
) {
  const { onChange, ...other }: any = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      valueIsNumericString
    />
  );
});