/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import GenericModal from "../../Modal/GenericModal";
import { Autocomplete, Button, Grid } from "@mui/material";
import { isMobile } from "../../Utils/utils";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { urlEnum } from "../../Utils/urlEnum";
import { postData } from "../../Services/postData";
import { TemplatesType } from "../../Utils/Constants";

export default function Template(props: any) {
  const notifyError = (message: string) => toast.error(message);
  const { open, title, onClose } = props;
  const [data, setData] = useState<any>({
    type: null,
    documentName: "",
    documentNumber: "",
    files: [],
  });

  /**
   *   Save template
   */
  function saveTemplate() {
    if (data.files.length === 0 || data.type === null) {
      notifyError(Vocabulary.requiredField);
      return;
    }
    const name = data.documentName.replaceAll(" ", "_");
    const formData = new FormData();
    formData.append("name", name);
    formData.append("type", data.type);
    formData.append("number", data.documentNumber);
    formData.append("file_id", data.files[0]);
    const url = `${urlEnum.templates}`;
    postData(url, formData).then((res) => {
      handleClose(true);
    });
  }
  /**
   *
   * @param files
   */
  function handleChangeDropFile(files: any) {
    if (files !== null) {
      setData({ ...data, files: files });
    } else {
      setData({ ...data, files: [] });
    }
  }

  /**
   *
   * @param shouldRefetch
   */
  function handleClose(shouldRefetch: boolean) {
    onClose(shouldRefetch);
    setData({
      name: "",
      files: [],
    });
  }
  return (
    <>
      <ToastContainer />
      <GenericModal
        open={open}
        onClose={() => handleClose(false)}
        title={title}
      >
        <ValidatorForm onSubmit={saveTemplate}>
          <Grid container spacing={isMobile() ? 1 : 4} style={{ marginTop: 5 }}>
            <Grid item lg={6} md={6} xs={6}>
              <Autocomplete
                id="type"
                freeSolo={false}
                disablePortal
                getOptionLabel={(option: any) => option.name}
                size="small"
                value={TemplatesType.find((item) => {
                  if (item.name === data.type) return item;
                  return null;
                })}
                options={TemplatesType}
                onChange={(event: any, newValue: any) => {
                  if (newValue) {
                    setData({ ...data, type: newValue.name });
                  } else {
                    setData({ ...data, type: null });
                  }
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="type"
                    label={Vocabulary.documentType}
                    variant="outlined"
                    size="large"
                    value={TemplatesType.find((item) => {
                      if (item.name === data.type) return item;
                      return null;
                    })}
                    fullWidth
                    validators={["required"]}
                    errorMessages={[Vocabulary.requiredField]}
                  />
                )}
              />
            </Grid>
            <Grid item lg={6} md={6} xs={6}>
              <TextValidator
                name="documentName"
                label={Vocabulary.documentName}
                fullWidth
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                value={data.documentName}
                onChange={(e: any) =>
                  setData({ ...data, documentName: e.target.value })
                }
              />
            </Grid>
            <Grid item lg={6} md={6} xs={6}>
              <TextValidator
                name="documentNumber"
                type="number"
                label={Vocabulary.startNumberDocument}
                fullWidth
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                value={data.documentNumber}
                onChange={(e: any) =>
                  setData({ ...data, documentNumber: e.target.value })
                }
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <DropzoneArea
                acceptedFiles={[".doc", ".docx"]}
                maxFileSize={30000000} //30 MB
                filesLimit={1}
                onDelete={(file: any) => {
                  handleChangeDropFile(null);
                }}
                onChange={(file: any) => {
                  handleChangeDropFile(file);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              style={{ marginTop: 20 }}
            >
              {Vocabulary.save}
            </Button>
          </Grid>
        </ValidatorForm>
      </GenericModal>
    </>
  );
}
