import { useEffect, useState, useRef } from "react";

/**
 * This component returns true when the user is inactive, false otherwise.
 * @param props
 * @returns
 */
export default function useNoActivity() {
  const [hasActivity, setHasActivity] = useState(true);
  const inactivityTimeout = 20 * 60 * 1000; //20min
  const timeout: any = useRef();
  const updateLastActivityTime = () => {
    localStorage.setItem("lastActivityTime", Date.now().toString());
  };

  const checkInactivity = () => {
    const lastActivityTime = parseInt(
      localStorage.getItem("lastActivityTime") || "0",
      10
    );
    if (Date.now() - lastActivityTime > inactivityTimeout) {
      setHasActivity(false);
    } else {
      setHasActivity(true);
    }
  };
  
  const onActivity = () => {
    updateLastActivityTime();
    clearTimeout(timeout.current);
    if (!hasActivity) {
      setHasActivity(true);
    }
    timeout.current = setTimeout(() => {
      setHasActivity(false);
    }, inactivityTimeout);
  };

  useEffect(() => {
    // Check inactivity on tab focus or page load
    checkInactivity();
    window.removeEventListener("mousemove", onActivity);
    window.removeEventListener("keyup", onActivity);
    window.removeEventListener("scroll", onActivity);
    window.removeEventListener("touchstart", onActivity);

    window.addEventListener("mousemove", onActivity);
    window.addEventListener("keyup", onActivity);
    window.addEventListener("scroll", onActivity);
    window.addEventListener("touchstart", onActivity);

    // Listen for localStorage changes to synchronize inactivity across tabs
    const handleStorageChange = () => {
      checkInactivity();
    };
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("mousemove", onActivity);
      window.removeEventListener("keyup", onActivity);
      window.removeEventListener("scroll", onActivity);
      window.removeEventListener("touchstart", onActivity);
      clearTimeout(timeout.current);
    };
  }, [hasActivity]);

  return hasActivity;
}
