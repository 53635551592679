import { Vocabulary } from "../Vocabulary";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

export const ROUTER_VEHICULE_GESTIONARE = {
  routes: [
    "/",
    "/vehiclesDashboard",
    "/vehiclesDashboard/vehicleGuarantees/:id?",
    "/vehiclesDashboard/vehicleDetails/:id?",
    "*",
  ],
};

export const MAIN_SLIDER_VEHICULE_GESTIONARE = [{
  name: Vocabulary.logsName,
  path: "/logs",
  icon: <AssignmentIndIcon />,
}];

export const VEHICLE_OPTIONS_VEHICULE_GESTIONARE = [
];

export const DINAMIC_TABS_VEHICULE_GESTIONARE = null;

export const MAIN_TABLE_VEHICULE_GESTIONARE = null;
