/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */

import { useContext, useEffect, useMemo, useState } from "react";
import style from "../../Styles/filters.module.css";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import TaxiAlertIcon from "@mui/icons-material/TaxiAlert";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PieChartIcon from "@mui/icons-material/PieChart";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import TuneIcon from "@mui/icons-material/Tune";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { CacheContext } from "../../Context/cacheContext";
import { isMobile } from "../../Utils/utils";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/ro"; // Import Romanian locale
import { Vocabulary } from "../../Utils/Vocabulary";
import { europeanDate, shortEuropeanDate } from "../../Utils/formatDate";
import { ReportsTab } from "../../Utils/autocompleteEnum";
import { StatusModel } from "../../Models/Models";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

type FiltersProps = {
  onChangeTextField: (event: any, newValue: any, name: string) => void;
  handleAccordion: (value: boolean) => void;
  handleChangeStartDate: (event: any) => void;
  handleChangeEndDate: (event: any) => void;
  handleChangePeriods: (
    event: any,
    index: number | null,
    deleteMode?: boolean
  ) => void;
  exportExcel: () => void;
  data: any;
  handleToggleModal: () => void;
  periods: string[];
  setPeriods: (periods: (string | Date)[]) => void;
  currentDate: string | Date;
};
moment.locale("ro"); // Set moment to use Romanian locale
export default function Filters(props: FiltersProps) {
  const {
    onChangeTextField,
    handleAccordion,
    handleChangeStartDate,
    handleChangeEndDate,
    data,
    handleToggleModal,
    periods,
    setPeriods,
    currentDate,
    handleChangePeriods,
  } = props;
  const cacheContext: any = useContext(CacheContext);
  const [hideFilters, setHideFilters] = useState(true);
  const [displayPeriods, setDisplayPeriods] = useState<any>(null);

  //Get selected data from cache
  //user
  const localUser = useMemo(() => {
    return (
      cacheContext.cache.length !== 0 &&
      cacheContext?.cache?.users?.find((user: any) => {
        if (+user.id === +data?.users) return user;
        return null;
      })
    );
  }, [cacheContext.cache, data?.users]);

  //status
  const localStatus = useMemo(() => {
    const statusesArray: StatusModel[] = [];
    if (data?.vehicleStatus)
      if (JSON.parse(data?.vehicleStatus)?.length) {
        const ids = JSON.parse(data.vehicleStatus);
        if (ids?.length) {
          ids.forEach((id: number) => {
            const status: StatusModel = cacheContext?.cache?.vehicleStatus.find(
              (status: StatusModel) => {
                return status.id == id;
              }
            );
            if (status) statusesArray.push(status);
          });
        }
      }
    return statusesArray;
    // return (
    //   cacheContext.cache.length !== 0 &&
    //   cacheContext?.cache?.vehicleStatus?.find((status: any) => {
    //     if (+status.id === +data?.vehicleStatus) return status;
    //     return null;
    //   })
    // );
  }, [cacheContext.cache, data?.vehicleStatus]);

  //company
  const localCompany = useMemo(() => {
    return (
      cacheContext.cache.length !== 0 &&
      cacheContext?.cache?.company?.find((company: any) => {
        if (+company.id === +data?.company) return company;
        return null;
      })
    );
  }, [cacheContext.cache, data?.company]);

  //location
  const localLocation = useMemo(() => {
    return (
      cacheContext.cache.length !== 0 &&
      cacheContext?.cache?.locations?.find((location: any) => {
        if (+location.id === +data?.locations) return location;
        return null;
      })
    );
  }, [cacheContext.cache, data?.locations]);

  //purchaseType
  const localPurchaseType = useMemo(() => {
    return (
      cacheContext.cache.length !== 0 &&
      cacheContext?.cache?.purchaseType?.find((purchaseType: any) => {
        if (+purchaseType.id === +data?.purchaseType) return purchaseType;
        return null;
      })
    );
  }, [cacheContext.cache, data?.purchaseType]);

  //tva
  const localTva = useMemo(() => {
    return (
      cacheContext.cache.length !== 0 &&
      cacheContext?.cache?.tvaType?.find((tva: any) => {
        if (+tva.id === +data?.tva) return tva;
        return null;
      })
    );
  }, [cacheContext.cache, data?.tva]);

  /**
   * Deletes a period from the periods array and calls the handleChangePeriods function
   */
  function handleDeletePeriod(
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ): void {
    handleChangePeriods(event, index, true);
  }

  /**
   *
   */
  //compareComponent
  const compareComponent = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {periods?.length &&
          periods?.map((period: any, index: number) => {
            return (
              <div
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 10,
                  padding: 7,
                  marginBottom: 10,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* <CalendarMonthIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} /> */}
                  <DesktopDatePicker
                    label={Vocabulary.month}
                    value={moment(period)}
                    onChange={(event: any) => handleChangePeriods(event, index)}
                    //format="MM/YYYY"
                    views={["month", "year"]}
                    slotProps={{
                      textField: { size: "small", fullWidth: true },
                    }}
                    disableFuture
                  />
                  <IconButton aria-label="delete">
                    <DeleteIcon
                      onClick={(event: any) => handleDeletePeriod(event, index)}
                    />
                  </IconButton>
                </Box>
              </div>
            );
          })}
      </LocalizationProvider>
    );
  };

  /**
   *
   * @returns
   */
  const dateFilterComponent = () => {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: 10,
          padding: 7,
          marginBottom: 10,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CalendarMonthIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label={Vocabulary.startDate}
              value={moment(data?.startDate)}
              maxDate={moment(data?.endDate)}
              onChange={handleChangeStartDate}
              format={europeanDate}
              slotProps={{ textField: { size: "small", fullWidth: true } }}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CalendarMonthIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label={Vocabulary.endDate}
              value={moment(data?.endDate)}
              minDate={moment(data?.startDate)}
              onChange={handleChangeEndDate}
              format={europeanDate}
              slotProps={{ textField: { size: "small", fullWidth: true } }}
            />
          </LocalizationProvider>
        </Box>
      </div>
    );
  };

  return (
    <div className={hideFilters ? style.container : style.containerHide}>
      <div className={hideFilters ? style.filters : style.filtersHide}>
        <>{periods?.length ? compareComponent() : dateFilterComponent()}</>
        <div>
          {periods && periods?.length ? (
            <Button
              variant="contained"
              size="small"
              color="secondary"
              style={{ margin: 3, height: 35, fontSize: 12, width: "100%" }}
              onClick={(event) => {
                setPeriods([...periods, currentDate]);
                handleChangePeriods(event, periods.length);
              }}
            >
              {Vocabulary.addPeriod}
            </Button>
          ) : null}
          <Button
            variant="contained"
            onClick={(event) => {
              if (displayPeriods)
                handleChangeStartDate({ _d: data?.startDate });
              else handleChangePeriods(event, periods.length);
              setDisplayPeriods(!displayPeriods);
            }}
            style={{ margin: 3, height: 35, fontSize: 12, width: "100%" }}
          >
            {periods && periods?.length
              ? Vocabulary.compareByDate
              : Vocabulary.comparePeriod}
          </Button>
        </div>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PeopleAltIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <Autocomplete
            id="users"
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) => option.nume}
            size="small"
            options={cacheContext.cache.users}
            value={localUser ? localUser : null}
            onChange={(event: any, newValue: any) => {
              onChangeTextField(event, newValue, "users");
              handleAccordion(false);
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.usersName}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TaxiAlertIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <Autocomplete
            id="company"
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) => option.nume_firma}
            size="small"
            options={cacheContext.cache.company}
            value={localCompany ? localCompany : null}
            onChange={(event: any, newValue: any) => {
              onChangeTextField(event, newValue, "company");
              handleAccordion(false);
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.companies}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LocationCityIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <Autocomplete
            id="locations"
            disablePortal
            freeSolo={false}
            getOptionLabel={(option: any) => option.nume}
            size="small"
            value={localLocation ? localLocation : null}
            options={cacheContext.cache.locations}
            onChange={(event: any, newValue: any) => {
              onChangeTextField(event, newValue, "locations");
              handleAccordion(false);
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.administrativeLocation}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PieChartIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <Autocomplete
            id="purchaseType"
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) => option.nume}
            size="small"
            options={cacheContext.cache.purchaseType}
            value={localPurchaseType ? localPurchaseType : null}
            onChange={(event: any, newValue: any) => {
              onChangeTextField(event, newValue, "purchaseType");
              handleAccordion(false);
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.purchaseType}
                variant="outlined"
                size="small"
              />
            )}
          ></Autocomplete>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PriceCheckIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <Autocomplete
            id="tva"
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) => option.nume}
            size="small"
            options={cacheContext.cache.tvaType}
            value={localTva ? localTva : null}
            onChange={(event: any, newValue: any) => {
              onChangeTextField(event, newValue, "tva");
              handleAccordion(false);
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.tipTVA}
                variant="outlined"
                size="small"
              />
            )}
          ></Autocomplete>
        </Box>

        {data?.reports === ReportsTab.vehicles ? (
          <Button
            variant="outlined"
            style={{ color: "#ff6633", borderColor: "#ff6633", marginTop: 5 }}
            onClick={handleToggleModal}
          >
            <CloudDownloadIcon style={{ marginRight: 10 }} />
            {Vocabulary.downloadExcel}
          </Button>
        ) : null}
      </div>
      <div style={{ position: "absolute", bottom: 10, left: 8 }}>
        {isMobile() ? null : hideFilters ? (
          <Tooltip title={Vocabulary.hideFilters}>
            <IconButton
              onClick={() => setHideFilters(!hideFilters)}
              style={{ backgroundColor: "#ff6633", color: "#fff" }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filtre">
            <IconButton
              onClick={() => setHideFilters(!hideFilters)}
              style={{ backgroundColor: "#4448", color: "#fff" }}
            >
              <TuneIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
