/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect } from "react";
import { componentNames } from "../../Utils/Constants";
import VehicleOptions from "../VehicleOptions";
import { VehicleContext } from "../../Context/vehicleContext";
import { useLocation, useNavigate } from "react-router-dom";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/urlEnum";
import { VehicleModel } from "../../Models/VehicleModel";
import { getPermissions, takeEachRoute } from "../LoginPage";
import { isEmpty } from "../../Utils/utils";

type VehicleOptionSliderProps = {
  title: string;
  component: any;
};

export default function VehicleOptionSlider(props: VehicleOptionSliderProps) {
  const { component } = props;
  const vehicleContext: any = useContext(VehicleContext);

  const navigate = useNavigate();

  const location = useLocation();

  const test = location.pathname;
  const words = test.split("/");
  const id = words[words.length - 1];

  async function getVehicleForChecking(id: number | string, contextValue: any) {
    const pathname = window.location.pathname;
    if (pathname.includes("vehicleDashboard")) {
      const url = `${urlEnum.vehicles}/${id}`;
      const res = (await getData(url)) as any;
      if (!res || res?.error) {
        const data: any = window.localStorage.getItem("permissions");
        const formatedData = data.split(",");
        navigate(getPermissions(takeEachRoute(formatedData)));
        return;
      }
      if (
        !areObjectsEqualShallow(
          res?.data?.vehicles[0],
          vehicleContext.state.vehicle
        )
      ) {
        const vehicle = res?.data?.vehicles[0];
        if (
          isEmpty(vehicle.descriere) &&
          !isEmpty(vehicleContext.state.vehicle.descriere)
        )
          vehicle.descriere = vehicleContext.state.vehicle.descriere;
        vehicleContext.updateVehicle(vehicle);
        (window as any).vehicleIdForDeletionFromVehiclesEditing =
          res?.data?.vehicles[0]?.id;
        vehicleContext.updateVehicleInvoices({
          clientInvoice: res.data.clientInvoice,
          providerInvoice: res.data.providerInvoice,
          OPInvoice: res.data.OPInvoice ? res.data.OPInvoice : [],
          reservedDocuments: res.data.reservedDocuments
            ? res.data.reservedDocuments
            : [],
        });
        vehicleContext.updateVehicleDocuments(res?.data?.documents);
        vehicleContext.updateVehicleImages(res?.data?.images);
      }
    }
  }

  function areObjectsEqualShallow(obj1: any, obj2: any) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  function handleFocus(e: any) {
    if (
      e.type === "focus" &&
      words[words.length - 1] !== "newVehicle" &&
      document.getElementsByClassName("MuiDialog-container").length === 0
    )
      getVehicleForChecking(id, vehicleContext.state.vehicle);
  }

  useEffect(() => {
    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [vehicleContext.state?.vehicle?.id]);

  useEffect(() => {
    getVehicles(id);
  }, [id]);

  /**
   *
   * @param id
   * @returns
   */
  async function getVehicles(id: number | string) {
    if (id === "newVehicle") {
      vehicleContext.updateVehicle(new VehicleModel());
      return;
    }
    const url = `${urlEnum.vehicles}/${id}`;
    const res = (await getData(url)) as any;
    if (!res || res?.error) {
      const data: any = window.localStorage.getItem("permissions");
      const formatedData = data.split(",");
      navigate(getPermissions(takeEachRoute(formatedData)));
      return;
    }
    const vehicle = res?.data?.vehicles[0];
    if (
      isEmpty(vehicle?.descriere) &&
      !isEmpty(vehicleContext?.state?.vehicle?.descriere)
    )
      vehicle.descriere = vehicleContext.state?.vehicle?.descriere;
    vehicleContext.updateVehicle(vehicle);
    (window as any).vehicleIdForDeletionFromVehiclesEditing =
      res?.data?.vehicles[0]?.id;
    vehicleContext.updateVehicleInvoices({
      clientInvoice: res.data.clientInvoice,
      providerInvoice: res.data.providerInvoice,
      OPInvoice: res.data.OPInvoice ? res.data.OPInvoice : [],
      reservedDocuments: res.data.reservedDocuments
        ? res.data.reservedDocuments
        : [],
    });
    vehicleContext.updateVehicleDocuments(res?.data?.documents);
    vehicleContext.updateVehicleImages(res?.data?.images);
  }
  return (
    <div
      style={{ display: "flex", backgroundColor: "#F7F8FA", height: "100%" }}
    >
      <VehicleOptions name={componentNames.VehicleOptions} />
      <div
        style={{ width: "100%", backgroundColor: "#FFF", overflowY: "auto" }}
      >
        {component}
      </div>
    </div>
  );
}
