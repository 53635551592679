import ProgressActionWrapper from "../ProgressActionComponents/ProgressActionWrapper";
import ProgressAction from "../ProgressActionComponents/ProgressAction";
import { Vocabulary } from "../../Utils/Vocabulary";
import UploadElement from "../ProgressActionComponents/UploadElement";
import ProgressInformation1 from "./ProgressInformation1";
import { useContext, useState } from "react";
import { VehicleContext } from "../../Context/vehicleContext";
import { VehicleFileType, tipFisier } from "../../Utils/autocompleteEnum";
import { disableStep } from "../../Utils/utils";

export default function ProgressAction1() {
  const vehicleContext: any = useContext(VehicleContext);

  const [open, setOpen] = useState(false);

  const handleOpenCloseModal = () => {
    setOpen(!open);
  };
  

  /**
   * saves the vehicle in the context
   */
  async function saveVehicle() {
    try {
      const done = await vehicleContext.saveWithFile(
        "providerInvoice",
        tipFisier[0].nume,
        VehicleFileType.providerInvoice,
        () => {
          return (
            vehicleContext?.state?.invoices?.providerInvoice[0]?.name?.length >
              0 &&
            (vehicleContext?.state?.vehicle?.pret_achizitie_euro > 0 ||
              vehicleContext?.state?.vehicle?.pret_achizitie_ron > 0) &&
            vehicleContext?.state?.vehicle?.data_achizitie
          );
        },
        ["1.1"]
      );
      if (done) handleOpenCloseModal();
    } catch (e) {
      return false;
    }
  }

  const modifyByArray = [];
  const tempModifyBy = vehicleContext?.state?.vehicle?.modifyBy
    ? vehicleContext?.state?.vehicle?.modifyBy
    : [];
  for (const modification of tempModifyBy) {
    if (modification?.index === "1.1") modifyByArray.push(modification);
  }

  return (
    <ProgressActionWrapper
      status={disableStep("1.1", vehicleContext?.state?.vehicle?.indexes)}
      title={Vocabulary.invoiceProvider}
      modifyBy={modifyByArray}
    >
      <ProgressAction index="1.1">
        <div>
          <ProgressInformation1 disabled></ProgressInformation1>
          <UploadElement
            openModal={open}
            handleModal={handleOpenCloseModal}
            handleSave={saveVehicle}
            handleEditInfo={(event: any) => {
              vehicleContext.updateVehicleInvoiceType({
                name: "providerInvoice",
                value: event,
              });
            }}
            fileName={
              vehicleContext?.state?.invoices?.providerInvoice.length
                ? vehicleContext?.state?.invoices?.providerInvoice[0]?.name
                : ""
            }
            file={
              vehicleContext?.state?.invoices?.providerInvoice.length
                ? vehicleContext?.state?.invoices?.providerInvoice[0]?.file
                : null
            }
            title={
              vehicleContext?.state?.invoices?.providerInvoice?.length
                ? Vocabulary.change
                : Vocabulary.addInvoice
            }
            fileIsRequired={true}
          >
            <ProgressInformation1></ProgressInformation1>
          </UploadElement>
        </div>
      </ProgressAction>
    </ProgressActionWrapper>
  );
}
