import * as React from "react";
import {
  Autocomplete,
  Avatar,
  Chip,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import style from "../../Styles/vehicleInfo.module.css";

import Grid from "@mui/material/Grid";

import { urlEnum } from "../../Utils/urlEnum";

import { isMobile } from "../../Utils/utils";
import { Vocabulary } from "../../Utils/Vocabulary";
import { getData } from "../../Services/getData";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { updateData } from "../../Services/updateData";
import { CacheContext } from "../../Context/cacheContext";
import {
  DEFAULT_MAX_NUMBER_OF_MESSAGES,
  tipStatusVehicule,
} from "../../Utils/Constants";
import { clientMessagesType } from "../../Utils/autocompleteEnum";

// import { getBnr } from "../../Services/getBnr";

export default function PlatformSettings() {
  const cacheContext = React.useContext(CacheContext);

  const [state, setState] = React.useState({
    telefonContactAnunturiPDF: "",
    ProcentDobanda: "",
    MaxNotificariDrawer: "",
    DeadlineRaportari: "",
    IDStatusVandut: "",
    procentTVA: 19,
    automatedClientMessages: "0",
    maxNumberForMessages: DEFAULT_MAX_NUMBER_OF_MESSAGES,
    usersForClientMessages: [],
    usersForFinancingRequests: [],
    usersForOnDemandsCars: [],
  });
  const cacheData = cacheContext.cache;

  /**
   * Get status
   */
  React.useEffect(() => {
    getSettings();
  }, []);

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  async function getSettings() {
    let url = `${urlEnum.settings}`;
    const settings = await getData(url);
    setState({
      ...state,
      telefonContactAnunturiPDF: settings.data.data.telefonContactAnunturiPDF,
      ProcentDobanda: settings.data.data.ProcentDobanda,
      MaxNotificariDrawer: settings.data.data.MaxNotificariDrawer,
      DeadlineRaportari: settings.data.data.DeadlineRaportari,
      IDStatusVandut: settings.data.data.IDStatusVandut,
      procentTVA: settings.data.data.procentTVA,
      automatedClientMessages: settings.data.data.automatedClientMessages,
      usersForClientMessages: settings.data.data.usersForClientMessages,
      usersForFinancingRequests: settings.data.data.usersForFinancingRequests,
      usersForOnDemandsCars: settings.data.data.usersForOnDemandsCars,
      maxNumberForMessages: settings.data.data.maxNumberForMessages,
    });
  }

  /*FUNCTION THAT FETCH DATA ABOUT UNREAD MEASSAGES BASED ON IDS */
  /**
   *
   * @param {*} index => userId
   * @param {*} type => type of client message
   * @returns
   */
  async function getUnreadMessages(index, type) {
    let url = `${urlEnum.unreadMessages}/${index}/${type}`;
    const settings = await getData(url);
    return settings?.data?.unreadMessagesCount;
  }

  /**
   * refreshes the cache data with the new values
   * @param data
   */
  const refreshCache = (data) => {
    const id = Object.keys(data.data);
    const tempLocalStorage = Object.assign({}, cacheData);
    id.forEach((element) => {
      if (cacheData[element]) tempLocalStorage[element] = data.data[element];
    });
    cacheContext.setCache(tempLocalStorage);
  };

  /**
   *
   * @returns
   */
  async function updateSettings() {
    let url = `${urlEnum.settings}`;

    const idsForUsersMessages = Object.assign({}, state);
    idsForUsersMessages.usersForClientMessages =
      state.usersForClientMessages?.map((item) => item.id);

    idsForUsersMessages.usersForFinancingRequests =
      state.usersForFinancingRequests?.map((item) => item.id);

    idsForUsersMessages.usersForOnDemandsCars =
      state?.usersForOnDemandsCars?.map((item) => item.id);

    const res = await updateData(url, idsForUsersMessages);
    if (!res || res?.error) {
      return;
    }
    setState({
      ...state,
      data: res.data.data,
    });
    refreshCache(res);
    getSettings();
  }

  /**
   *
   * @param {*} event
   */
  function handleChangeTextFields(event) {
    const newObj = Object.assign({}, state);
    newObj[event.target.name] = event.target.value;
    setState(newObj);
  }

  /**
   *
   * @param {*} event
   */
  function handleChangeSwitch(event) {
    const newObj = Object.assign({}, state);
    newObj.automatedClientMessages = event.target.checked ? 1 : 0;
    setState(newObj);
  }

  return (
    <Paper
      style={{
        padding: 15,
        marginBottom: 35,
        borderRadius: "0px 0px 15px 15px ",
      }}
    >
      <ValidatorForm
        method="get"
        onSubmit={updateSettings}
        id="settingsPlatform"
        style={{ marginTop: 10 }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: isMobile() ? "column" : "row",
          }}
        >
          <Grid
            container
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            xl={12}
            //md={12}
            sx={12}
          >
            <Grid item={4} xl={4} xs={12} gap={2}>
              <TextValidator
                name="telefonContactAnunturiPDF"
                InputLabelProps={{
                  shrink: true,
                }}
                className={style.textValidator}
                label={Vocabulary.nrTelefonPdf}
                fullWidth
                errorMessages={[Vocabulary.requiredField]}
                value={state?.telefonContactAnunturiPDF}
                onChange={handleChangeTextFields}
              />
              <TextValidator
                type="number"
                name="ProcentDobanda"
                InputLabelProps={{
                  shrink: true,
                }}
                className={style.textValidator}
                label={Vocabulary.procentDobanda}
                fullWidth
                errorMessages={[Vocabulary.requiredField]}
                value={state?.ProcentDobanda}
                onChange={handleChangeTextFields}
              />
            </Grid>
            <Grid item={4} xl={4} xs={12} gap={2}>
              <TextValidator
                name="IDStatusVandut"
                className={style.textValidator}
                InputLabelProps={{
                  shrink: true,
                }}
                label={Vocabulary.idStatusVandut}
                fullWidth
                errorMessages={[Vocabulary.requiredField]}
                value={state?.IDStatusVandut}
                onChange={handleChangeTextFields}
              />
              <TextValidator
                type="number"
                name="DeadlineRaportari"
                className={style.textValidator}
                InputLabelProps={{
                  shrink: true,
                }}
                label={Vocabulary.deadlineRaportari}
                fullWidth
                errorMessages={[Vocabulary.requiredField]}
                value={state?.DeadlineRaportari}
                onChange={handleChangeTextFields}
              />
            </Grid>
            <Grid item={4} xl={4} xs={12} gap={2}>
              <TextValidator
                type="number"
                name="MaxNotificariDrawer"
                className={style.textValidator}
                InputLabelProps={{
                  shrink: true,
                }}
                label={Vocabulary.nrMaximNotificari}
                fullWidth
                errorMessages={[Vocabulary.requiredField]}
                value={state?.MaxNotificariDrawer}
                onChange={handleChangeTextFields}
              />
              <div>
                <TextValidator
                  type="number"
                  name="procentTVA"
                  className={style.textValidator}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={Vocabulary.VATPercent}
                  fullWidth
                  value={state.procentTVA}
                  errorMessages={[Vocabulary.requiredField]}
                  onChange={handleChangeTextFields}
                />
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    width: "100%",
                  }}
                >
                  <Typography
                    style={{ margin: 10, marginLeft: 17, fontSize: 15 }}
                  >
                    {Vocabulary.automatedClientMessage}
                  </Typography>

                  <Switch
                    checked={state.automatedClientMessages === 1 ? true : false}
                    name="clientMessagesSettings"
                    id="clientMessagesSettings"
                    onChange={handleChangeSwitch}
                  />
                </div>
                <Autocomplete
                  freeSolo={false}
                  multiple
                  id="usersForClientMessages"
                  disablePortal
                  disableClearable
                  className={style.textValidator}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  disabled={state.automatedClientMessages === 1 ? false : true}
                  value={
                    state?.usersForClientMessages?.length
                      ? state?.usersForClientMessages
                      : []
                  }
                  fullWidth
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option.nume}
                        avatar={
                          <Avatar
                            style={{
                              backgroundColor:
                                state.maxNumberForMessages <=
                                option.unreadMessagesCount
                                  ? "#F0B3B3"
                                  : "#CAE8B7",
                            }}
                          >
                            {option.unreadMessagesCount
                              ? option.unreadMessagesCount
                              : 0}
                          </Avatar>
                        }
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  onChange={async (event, value) => {
                    if (value?.length)
                      value[value.length - 1].unreadMessagesCount =
                        await getUnreadMessages(
                          value[value.length - 1]?.id,
                          clientMessagesType.contactMessage
                        ); //TO DO : SCHIMBA DIN ENUM

                    setState({ ...state, usersForClientMessages: value });
                  }}
                  getOptionLabel={(option) => option?.nume}
                  options={cacheContext.cache.users}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ marginTop: 10 }}
                      id="usersForClientMessages"
                      label={Vocabulary.usersForClientMessages}
                      name="usersForClientMessages"
                    />
                  )}
                />
                <Autocomplete
                  freeSolo={false}
                  multiple
                  id="usersForFinancingRequests"
                  fullWidth
                  disableClearable
                  disabled={state.automatedClientMessages === 1 ? false : true}
                  className={style.textValidator}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  onChange={async (event, value) => {
                    if (value?.length)
                      value[value.length - 1].unreadMessagesCount =
                        await getUnreadMessages(
                          value[value.length - 1]?.id,
                          clientMessagesType.financingRequest
                        ); //TO DO : SCHIMBA DIN ENUM

                    setState({ ...state, usersForFinancingRequests: value });
                  }}
                  value={
                    state?.usersForFinancingRequests?.length
                      ? state?.usersForFinancingRequests
                      : []
                  }
                  getOptionLabel={(option) => option?.nume}
                  options={cacheContext.cache.users}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option.nume}
                        avatar={
                          <Avatar
                            style={{
                              backgroundColor:
                                state.maxNumberForMessages <=
                                option.unreadMessagesCount
                                  ? "#F0B3B3"
                                  : "#CAE8B7",
                            }}
                          >
                            {option.unreadMessagesCount
                              ? option.unreadMessagesCount
                              : 0}
                          </Avatar>
                        }
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ marginTop: 10 }}
                      id="usersForFinancingRequests"
                      label={Vocabulary.usersForFinancingRequests}
                      name="usersForFinancingRequests"
                    />
                  )}
                />
                <Autocomplete
                  freeSolo={false}
                  multiple
                  id="usersForOnDemandsCars"
                  className={style.textValidator}
                  disableClearable
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  disabled={state.automatedClientMessages === 1 ? false : true}
                  onChange={async (event, value) => {
                    if (value?.length)
                      value[value.length - 1].unreadMessagesCount =
                        await getUnreadMessages(
                          value[value.length - 1]?.id,
                          tipStatusVehicule.CarOrder
                        ); //TO DO : SCHIMBA DIN ENUM

                    setState({ ...state, usersForOnDemandsCars: value });
                  }}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option.nume}
                        avatar={
                          <Avatar
                            style={{
                              backgroundColor:
                                state.maxNumberForMessages <=
                                option.unreadMessagesCount
                                  ? "#F0B3B3"
                                  : "#CAE8B7",
                            }}
                          >
                            {option.unreadMessagesCount}
                          </Avatar>
                        }
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  value={
                    state?.usersForOnDemandsCars?.length
                      ? state?.usersForOnDemandsCars
                      : []
                  }
                  getOptionLabel={(option) => option?.nume}
                  options={cacheContext.cache.users}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ marginTop: 10 }}
                      id="usersForOnDemandsCars"
                      label={Vocabulary.usersForOnDemandsCars}
                      name="usersForOnDemandsCars"
                    />
                  )}
                />
              </div>
              <TextValidator
                type="number"
                name="maxNumberForMessages"
                className={style.textValidator}
                InputLabelProps={{
                  shrink: true,
                }}
                label={Vocabulary.maxNumberForMessages}
                fullWidth
                errorMessages={[Vocabulary.requiredField]}
                value={state?.maxNumberForMessages}
                onChange={handleChangeTextFields}
              />
            </Grid>
          </Grid>
        </div>
      </ValidatorForm>
    </Paper>
  );
}
