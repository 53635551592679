import { Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../../Utils/Vocabulary";
import { ChangeEvent } from "react";

export type FileDataProforma = {
  pretFaraTVA: number;
};
type ProformaFileProps = {
  fileData: FileDataProforma;
  onChangeFileData: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export default function ProformaFile(props: ProformaFileProps) {
  const { fileData, onChangeFileData } = props;
  return (
    <>
      <Grid item xs={12}>
        <TextField
          label={Vocabulary.netAmountInLei}
          variant="outlined"
          name="pretFaraTVA"
          size="small"
          type="number"
          value={fileData.pretFaraTVA}
          style={{ width: "100%" }}
          onChange={(event) =>
            onChangeFileData(event)
          }
        />
      </Grid>
    </>
  );
}
