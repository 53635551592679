/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Delete, Edit } from "@mui/icons-material";
// import AssignmentIcon from "@mui/icons-material/Assignment";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/urlEnum";
import { useLocation, useNavigate } from "react-router-dom";
import Client from "./Client";
import GenericModal from "../../Modal/GenericModal";
import { multipleDelete } from "../../Services/multipleDelete";
import { ThemeProvider } from "@emotion/react";
import { getMuiTheme } from "../../Utils/globalStyleTables";
import style from "../../Styles/tableFilter.module.css";
import DinamicTabs from "../DinamicTabs";
import { componentNames, tableLayoutOnMobile } from "../../Utils/Constants";
import PreviewPDF from "../Generic/PDFViewer";
import { postData } from "../../Services/postData";
import {
  download,
  exportTable,
  getFileExtensionByFileType,
  isMobile,
} from "../../Utils/utils";
import PreviewImage from "../Generic/PreviewImage";
import { toast } from "react-toastify";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

type State = {
  clients: Array<ClientData>;
  clientsNumber: number;
  page: number;
  perPage: number;
  search: string | null;
  order: string;
  filter: string | null;
};

export type ClientData = {
  id?: number | null;
  filePath?: string | undefined | null;
  isSignature?: boolean | undefined | null;
  companyName: string;
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  CNP: string;
  series: string;
  number: string;
  CUI: string;
  nrRegCom: string;
  street: string;
  city: string | null;
  country: string | null;
  isLegalPerson: boolean;
};

/**
 * download pdf
 */
export const downloadPDF = (url: string, fileName: string) => {
  const token = localStorage.getItem("access_token");
  if (url)
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((response) => {
        download(response, fileName, "application/pdf");
      });
};

export default function Clients() {
  const navigate = useNavigate();
  const location = useLocation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedIds, setDeletedIds] = useState<Array<number>>([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openGDPRModal, setOpenGDPRModal] = useState(false);
  const [editedClient, setEditedClient] = useState<ClientData | null>(null);
  const [clientFilePathForGDPR, setClientFilePathForGDPR] = useState<any>(null);
  const [docId, setDocId] = useState<any>(null);
  const [firmaId, setFirmaId]: any = useState<string | null>();
  const [state, setState] = useState<State>({
    clients: new Array<ClientData>(),
    clientsNumber: 0,
    page: 0,
    perPage: 10,
    search: null,
    order: "asc",
    filter: null,
  });

  /**
   * Get clients
   */
  useEffect(() => {
    const search = location.search.split("?");
    const newState = {
      clients: new Array<ClientData>(),
      clientsNumber: 0,
      page: 0,
      perPage: 10,
      search: null,
      order: "asc",
      filter: null,
    } as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1] - 1;
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    getClients(newState.page, newState.perPage, newState.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  function getClients(page: number, perPage: number, search?: string | null) {
    let url = `${urlEnum.clients}/${page}/${perPage}`;
    if (search) {
      url = `${urlEnum.clients}/${page}/${perPage}/${search}`;
    }
    getData(url)
      .then((res) => {
        setState({
          ...state,
          clients: res.data.clients,
          clientsNumber: res.data.clientsNumber,
          page: page,
          perPage: perPage,
          search: search ? search : null,
        });
      })
      .catch((err) => {
        //
      });
  }

  function handleFirmaId(firmaId: any) {
    setFirmaId(firmaId);
  }

  /**
   *
   * @param client
   */
  async function handleOpenGDPR(client: any, firmaId: any) {
    if (!firmaId) {
      const fileName = client?.name.split("/").pop() as string;
      const file = `${urlEnum.templates}/getDoc/${fileName}`;
      setClientFilePathForGDPR({
        id: client.client_id,
        file: file,
        fileName: fileName,
        email: client.email,
        filePath: client?.name,
        isSignature: client.isSignature,
      });
      toast.success(fileName + " " + Vocabulary.succesSign);

      setOpenGDPRModal(true);
    } else {
      const res = await postData(
        `${urlEnum.templates}/generateGDPR/${client.id}/${firmaId}`
      );
      const fileName = res.data.client?.name.split("/").pop() as string;
      setDocId(res.data.client?.id);
      toast.success(Vocabulary.docGenerated + " " + fileName);

      const file = `${urlEnum.templates}/getDoc/${fileName}`;
      setClientFilePathForGDPR({
        id: client.id,
        file: file,
        fileName: fileName,
        email: client.email,
        filePath: client?.name,
        isSignature: client.isSignature,
      });
      setOpenGDPRModal(true);
    }
  }

  /**
   *
   * @param client
   */
  async function handleOpenBeforeContract(
    client: ClientData,
    firmaId: string,
    carId: string
  ) {
    const res = await postData(
      `${urlEnum.templates}/generateBeforeContract/${client.id}/${firmaId}/${carId}`
    );
    const fileName = res.data.client?.filePath.split("/").pop() as string;
    const file = `${urlEnum.templates}/getDoc/${fileName}`;
    setClientFilePathForGDPR({
      id: client.id,
      file: file,
      fileName: fileName,
      email: client.email,
      filePath: client?.filePath,
      isSignature: client.isSignature,
    });
    setOpenGDPRModal(true);
  }

  /**
   *
   */
  const clientsHeader = [
    {
      label: `${Vocabulary.lastName}`,
      name: "lastName",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.firstName}`,
      name: "firstName",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.companyName}`,
      name: "companyName",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: `${Vocabulary.email}`,
      name: "email",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      label: `${Vocabulary.phone}`,
      name: "phone",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      label: `CNP`,
      name: "CNP",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.CUI}`,
      name: "CUI",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.country}`,
      name: "country",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      label: `${Vocabulary.city}`,
      name: "city",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      label: `${Vocabulary.street}`,
      name: "street",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      label: `${Vocabulary.personType}`,
      name: "isLegalPerson",
      options: {
        sort: false,
        isLegalPerson: true,
        boolean: true,
        customBodyRender: (isLegalPerson: any) => (
          <Typography>
            {isLegalPerson === 1
              ? Vocabulary.isLegalPerson
              : Vocabulary.naturalPerson}
          </Typography>
        ),
      },
    },
    // {
    //   label: `${Vocabulary.GDPR}`,
    //   name: "isSignature",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     display: true,
    //     boolean: true,
    //     setCellHeaderProps: () => ({
    //       align: "center",
    //     }),
    //     setCellProps: () => ({
    //       align: "center",
    //     }),
    //     customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
    //       if (!state.clients[rowIndex].isSignature) return null;
    //       const fileName = state.clients[rowIndex].filePath || "GDPR.pdf";
    //       const path = `${urlEnum.templates}/getDoc/${fileName}`;
    //       return (
    //         <Button
    //           size="small"
    //           color="primary"
    //           variant="contained"
    //           onClick={() => downloadPDF(path, fileName)}
    //         >
    //           {Vocabulary.downloadGDPR}
    //         </Button>
    //       );
    //     },
    //   },
    // },
    {
      label: Vocabulary.options,
      name: "",
      options: {
        viewColumns: false,
        download: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (rowIndex: number) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10, float: "right" }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    onClick={() => {
                      setEditedClient(state.clients[rowIndex]);
                      setOpenEditModal(true);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.delete}>
                  <ToggleButton
                    onClick={() => {
                      setDeletedIds([state.clients[rowIndex].id as number]);
                      setOpenDeleteModal(true);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
                {/* <Tooltip title="GDPR">
                  <ToggleButton
                    onClick={() => {
                      handleOpenGDPR(state.clients[rowIndex], "");
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <AssignmentIcon />
                  </ToggleButton>
                </Tooltip> */}
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    return {
      selectableRows: "none" as any,
      responsive: tableLayoutOnMobile,
      viewColumns: true,
      rowsPerPageOptions: [
        10,
        50,
        100,
        state?.clientsNumber > 100 ? state?.clientsNumber : 1000,
      ],
      confirmFilters: true,
      filter: false,
      print: false,
      download: true,
      fixedHeader: true,
      count: state.clientsNumber,
      rowsPerPage: state.perPage,
      page: state.page,
      search: false,
      serverSide: true,
      sort: true,
      textLabels: {
        // selectedRows: {
        //   text: "selectate",
        //   delete: "Sterge",
        //   deleteAria: "Sterge liniile selectate",
        // },
        viewColumns: {
          title: Vocabulary.viewColumns,
        },
        toolbar: {
          downloadCsv: Vocabulary.downloadExcel,
          viewColumns: Vocabulary.selectColumns,
        },
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: Vocabulary.pagination,
      },
      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true,
        },
        filename: Vocabulary.clientsName?.toLowerCase() + ".xlsx",
      },
      onRowsDelete: (rowsDeleted: any, newData: any) => {
        const ids = new Array<number>();
        rowsDeleted.data.forEach((row: any) => {
          ids.push(state.clients[row.dataIndex].id as number);
        });
        setDeletedIds(ids);
        setOpenDeleteModal(true);
      },
      onChangePage: (page: number) => {
        setState({ ...state, page: page });
        if (state.search)
          navigate(
            `/clients?page=${page + 1}?perPage=${state.perPage}?search=${
              state.search
            }`
          );
        else navigate(`/clients?page=${page + 1}?perPage=${state.perPage}`);
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        setState({ ...state, perPage: numberOfRows });
        if (state.search)
          navigate(
            `/clients?page=${1}?perPage=${numberOfRows}?search=${state.search}`
          );
        else navigate(`/clients?page=${1}?perPage=${numberOfRows}`);
        window.scrollTo(0, 0);
      },
      onDownload: (buildHead: any, buildBody: any, columns: any, data: any) => {
        const alteredData = data.map((column: any, index: number) => {
          column.data = column?.data?.map((value: any, key: number) => {
            if (typeof value == "object") {
              if (value) {
                const name = Object.values(value)[1];
                value = name;
              }
            } else if (columns[key].boolean) {
              value = value == "1" ? Vocabulary.yes : Vocabulary.no;
            } else if (columns[key].isLegalPerson) {
              value =
                value == "1"
                  ? Vocabulary.isLegalPerson
                  : Vocabulary.naturalPerson;
            }
            return value;
          });

          return column;
        });
        return `${buildHead(columns)}${buildBody(alteredData)}`;
      },
    };
  }

  /**
   * Close delete modal
   */
  function onCloseDeleteModal() {
    setOpenDeleteModal(false);
    setDeletedIds([]);
  }

  /**
   * Delete clients
   */
  function deleteClients() {
    const data = { data: { ids: deletedIds } };
    multipleDelete(urlEnum.clients, data)
      .then((res) => {
        onCloseDeleteModal();
        getClients(state.page, state.perPage, state.search);
      })
      .catch((err) => {
        onCloseDeleteModal();
      });
  }

  /**
   *
   * @returns
   */
  function previewDocument() {
    const extension = getFileExtensionByFileType(clientFilePathForGDPR.file);
    const fileName = clientFilePathForGDPR.fileName || "GDPR.pdf";
    const path = `${urlEnum.templates}/getDoc/${fileName}`;
    switch (extension) {
      case "pdf":
        return (
          <PreviewPDF
            doc={docId}
            firmaId={firmaId?.id}
            title={clientFilePathForGDPR.file.split("/").pop() as string}
            open={openGDPRModal}
            onClose={() => {
              setOpenGDPRModal(false);
              setClientFilePathForGDPR(null);
              getClients(state.page, state.perPage, state.search);
            }}
            id={clientFilePathForGDPR.id || 0}
            url={clientFilePathForGDPR.file}
            emailData={{
              to: clientFilePathForGDPR.email || "",
              subject: "Semnarea acordului GDPR",
              message: "GDPR",
              docPath: clientFilePathForGDPR.filePath,
            }}
            signature={true}
            isSign={clientFilePathForGDPR.isSignature === "1" ? false : true}
            resetClientFilePathForGDPR={(client: ClientData | null) => {
              if (client) {
                handleOpenGDPR(client, undefined);
              } else {
                setOpenGDPRModal(false);
                setClientFilePathForGDPR(null);
              }
            }}
          />
        );
      case "doc":
      case "docx":
        downloadPDF(path, fileName);
        setOpenGDPRModal(false);
        setClientFilePathForGDPR(null);
        return null;
      case "xls":
      case "xlsx":
        downloadPDF(path, fileName);
        setOpenGDPRModal(false);
        setClientFilePathForGDPR(null);
        return null;
      case "jpg":
      case "jpeg":
      case "png":
      case "webp":
        return (
          <PreviewImage
            title={clientFilePathForGDPR.file.split("/").pop() as string}
            open={openGDPRModal}
            onClose={() => {
              setOpenGDPRModal(false);
              setClientFilePathForGDPR(null);
              getClients(state.page, state.perPage, state.search);
            }}
            url={`/api/template/${clientFilePathForGDPR.fileName}`}
          />
        );
      default:
        return null;
    }
  }

  /**
   *
   */
  const exportData = (props: any) => {
    let exportedColumns = props?.columns;
    exportedColumns = exportedColumns.filter((column: any) => {
      if (column.download == true) return column.name;
    });

    const fileName = Vocabulary.clientsName?.toLowerCase() + ".xlsx";
    const url = `${urlEnum.exportClientsTables}`;

    exportTable(url, state.search, fileName, exportedColumns);
  };

  /**
   *
   * @param props
   * @returns
   */
  const CustomToolbar = (props: any) => {
    return (
      <Toolbar>
        <Button
          startIcon={<CloudDownloadIcon />}
          onClick={(event) => exportData(props)}
        >
          {Vocabulary.exportTable}
        </Button>
      </Toolbar>
    );
  };

  return (
    <div className={style.containerTable}>
      <ThemeProvider theme={getMuiTheme()}>
        <DinamicTabs
          name={componentNames.DinamicTabs}
          labels={[Vocabulary.clientsName, ""]}
          openModalWindow={() => setOpenEditModal(true)}
          addNewLabel={Vocabulary.addNewClient}
          search={true}
        ></DinamicTabs>
        <div>
          <MUIDataTable
            title={""}
            data={state.clients}
            columns={clientsHeader}
            options={getTableOptions()}
            components={{
              TableToolbar: CustomToolbar,
            }}
          />
        </div>
        <Client
          open={openEditModal}
          onClose={(shouldRefetch) => {
            setOpenEditModal(false);
            setEditedClient(null);
            if (shouldRefetch) {
              getClients(state.page, state.perPage, state.search);
            }
          }}
          title={editedClient ? Vocabulary.editClient : Vocabulary.addNewClient}
          editedClient={editedClient}
          handleOpenGDPR={handleOpenGDPR}
          handleOpenBeforeContract={handleOpenBeforeContract}
          handleFirmaId={handleFirmaId}
        />

        <GenericModal
          open={openDeleteModal}
          onClose={onCloseDeleteModal}
          title={Vocabulary.deleteClient}
        >
          <p style={{ textAlign: "center", fontSize: 20 }}>
            {Vocabulary.deleteMessage}
          </p>
          <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
            <Grid item md={6} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={onCloseDeleteModal}
                fullWidth
              >
                {Vocabulary.no}
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <Button
                variant="contained"
                fullWidth
                onClick={deleteClients}
                style={{ color: "white" }}
              >
                {Vocabulary.yes}
              </Button>
            </Grid>
          </Grid>
        </GenericModal>

        {openGDPRModal && clientFilePathForGDPR ? previewDocument() : null}
      </ThemeProvider>
    </div>
  );
}
