/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-destructuring */
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MUIDataTable from "mui-datatables";
import { deleteData } from "../Services/deleteData";
import { localUrlEnum, urlEnum } from "../Utils/urlEnum";
import { Button, IconButton, Toolbar, Tooltip } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import GenericModal from "../Modal/GenericModal";
import { ThemeProvider } from "@emotion/react";
import { Vocabulary } from "../Utils/Vocabulary";
import { getMuiTheme } from "../Utils/globalStyleTables";
import DinamicTabs from "./DinamicTabs";
import { exportTable, isMobile } from "../Utils/utils";
import {
  componentNames,
  tableLayoutOnMobile,
  actionTypeForGeneralModal,
  vehiclesTabs,
} from "../Utils/Constants";
import withRole from "../Utils/withRole";
import { PillsProps, StateProps } from "./MainDashboard";
import CircleIcon from "@mui/icons-material/Circle";
import { VehicleContext } from "../Context/vehicleContext";
import { VehicleModel } from "../Models/VehicleModel";
import { formatNumber } from "../Utils/formatDate";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import PostAdsIntegrationModal from "./VehicleDasboard/PostAdsIntegrationModal";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
//import SearchIcon from "@mui/icons-material/YoutubeSearchedFor";
import { updateData } from "../Services/updateData";
import style from "../Styles/modal.module.css";
import LibraryAddRoundedIcon from "@mui/icons-material/LibraryAddRounded";
import TableSearch from "../Utils/TableSearch";
import { VehiclesTab } from "../Utils/autocompleteEnum";

const VehicleStatus = {
  NotReceived: "NotReceived",
  Unsold: "Unsold",
} as const;

export interface MainTableProps {
  children?: React.ReactNode;
  index?: number;
  value?: number;
}

function TabPanel(props: MainTableProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

type TableProps = {
  permission?: any;
  data: any;
  pills: PillsProps;
  state: StateProps;
  value: number;
  info?: any;
  handleChange: (event: any, index: number) => void;
  getDataRefresh?: any;
  type?: any;
  vehiclesCategories?: any;
  deleteFilters?: any;
};
function MainTable(props: TableProps) {
  const {
    info,
    pills,
    state,
    value,
    handleChange,
    getDataRefresh,
    type,
    deleteFilters,
  } = props;
  const vehicleContext: any = useContext(VehicleContext);
  const navigate = useNavigate();
  const [actionType, setActionType]: any = useState();
  const [openIntegrationModal, setOpenIntegrationModal] = useState(false);
  const [currentVehicle, setCurrentVehicle]: any = useState();
  const stockTabs = [Vocabulary.physicalStock, Vocabulary.inTransit];

  /**
   *
   */
  const columnsUnsold = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
        download: false,
      },
    },
    {
      name: "is_postable_on_autovit",
      label: " ",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
        download: false,
      },
    },
    {
      name: "numeComplet",
      label: Vocabulary.name,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "vin",
      label: Vocabulary.vin,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (vin: any) => (
          <Typography style={{ fontSize: "0.875rem" }}>
            {isMobile() ? vin : "..." + vin.substring(vin.length - 6)}
          </Typography>
        ),
      },
    },
    {
      name: "status_vehicul_r",
      label: Vocabulary.status,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (status_vehicul_r: any) => (
          <Typography>{status_vehicul_r.nume}</Typography>
        ),
      },
    },
    {
      name: "physical_status",
      label: Vocabulary.physicalStatus,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (status_vehicul_r: any) => (
          <Typography>{status_vehicul_r?.nume}</Typography>
        ),
      },
    },
    {
      name: "purchasePrice",
      label: Vocabulary.acquisitionPrice,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (purchasePrice: any) => formatNumber(purchasePrice),
      },
    },
    {
      name: "tip_achizitie_r",
      label: Vocabulary.purchaseType,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (object: any) => (
          <Typography>{object?.nume}</Typography>
        ),
      },
    },
    // {
    //   name: "salePrice",
    //   label: Vocabulary.sellPrice,
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (sellPrice: any) => formatNumber(sellPrice),
    //   },
    // },
    {
      name: "user_achizitie_r",
      label: Vocabulary.user,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (user_r: any) => (
          <Typography>{user_r?.name}</Typography>
        ),
      },
    },
    {
      name: "tip_tva_r",
      label: Vocabulary.TVA,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (tip_tva_r: any) => (
          <Typography>{tip_tva_r?.nume}</Typography>
        ),
      },
    },
    {
      name: "TvaRecovered",
      label: Vocabulary.tvaRecovered,
      options: {
        filter: true,
        sort: false,
        boolean: true,
        customBodyRender: (tvaRecovered: any) => (
          <div>
            {tvaRecovered === 0 ? (
              <Tooltip title={"TVA de recuperat"}>
                <CircleIcon style={{ color: "#7a7a7a63" }} />
              </Tooltip>
            ) : (
              <Tooltip title={"TVA de recuperat"}>
                <CircleIcon style={{ color: "#49ab518c" }} />
              </Tooltip>
            )}
          </div>
        ),
      },
    },
    {
      name: "vizibil_site",
      label: Vocabulary.visibleOnSite,
      options: {
        filter: true,
        sort: false,
        boolean: true,

        customBodyRender: (vizibil_site: any) => (
          <Typography
            style={{
              color: "#fff",
              backgroundColor: vizibil_site === "1" ? "#FF6633" : "#7a7a79",
              textAlign: "center",
              borderRadius: 15,
            }}
          >
            {vizibil_site === "1" ? Vocabulary.yes : Vocabulary.no}
          </Typography>
        ),
      },
    },
    {
      name: "date",
      label: Vocabulary.year,
      options: {
        filter: true,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "firma_achizitie_r",
      label: Vocabulary.nume_firma,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (firma: any) => (
          <Typography style={{ fontSize: 12 }}>{firma?.nume_firma}</Typography>
        ),
      },
    },

    {
      name: Vocabulary.options,
      label: Vocabulary.options,
      options: {
        filter: false,
        sort: false,
        download: false,
        viewColumns: false,
        display: props?.info.edit || props?.info.delete ? true : false,
        customBodyRender: (index: any, data: any) => (
          <div style={{ display: "flex", marginRight: 10 }} id="optionButtons">
            {props?.info.edit ? (
              <IconButton
                onClick={(event: any) => {
                  event.stopPropagation();
                  vehicleContext.updateVehicle({});
                  vehicleContext.updateVehicleDocuments({});
                  vehicleContext.updateVehicleImages({});
                  navigate(
                    `${localUrlEnum.vehicles}/vehicleDetails/${data?.rowData[0]}`
                  );
                }}
                style={{ marginRight: 10 }}
                aria-label="delete"
              >
                <ModeEditIcon />
              </IconButton>
            ) : null}
            {props?.info.delete ? (
              <IconButton
                onClick={(event: any) => {
                  setCurrentVehicle(state.vehicles[data?.rowIndex]);
                  event.stopPropagation();
                  setActionType(actionTypeForGeneralModal?.delete);
                }}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
            {
              <Tooltip title={Vocabulary.postAd}>
                <IconButton
                  onClick={(event: any) => {
                    event.stopPropagation();
                    setOpenIntegrationModal(true);
                    setCurrentVehicle(state.vehicles[data?.rowIndex]);
                  }}
                  style={{ marginRight: 10 }}
                  aria-label="delete"
                >
                  <ViewCarouselIcon />
                </IconButton>
              </Tooltip>
            }
            {props?.permission?.duplicateVehicle && (
              <Tooltip title={Vocabulary.duplicateVehicleButtonText}>
                <IconButton
                  onClick={(event: any) => {
                    setCurrentVehicle(state.vehicles[data?.rowIndex]);
                    event.stopPropagation();
                    setActionType(actionTypeForGeneralModal?.duplicate);
                  }}
                >
                  <LibraryAddRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        ),
      },
    },
  ];
  /**
   *
   */
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "numeComplet",
      label: Vocabulary.name,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "vin",
      label: Vocabulary.vin,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (vin: any) => (
          <Typography style={{ fontSize: "0.875rem" }}>
            {isMobile() ? vin : "..." + vin.substring(vin.length - 6)}
          </Typography>
        ),
      },
    },
    {
      name: "status_vehicul_r",
      label: Vocabulary.status,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (status_vehicul_r: any) => (
          <Typography>{status_vehicul_r.nume}</Typography>
        ),
      },
    },
    {
      name: "pysical_status",
      label: Vocabulary.status,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (status_vehicul_r: any) => (
          <Typography>{status_vehicul_r.nume}</Typography>
        ),
      },
    },
    {
      name: "purchasePrice",
      label: Vocabulary.acquisitionPrice,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (acquisitionPrice: any) =>
          formatNumber(acquisitionPrice),
      },
    },
    {
      name: "tip_achizitie_r",
      label: Vocabulary.purchaseType,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (object: any) => (
          <Typography>{object?.nume}</Typography>
        ),
      },
    },
    // {
    //   name: "salePrice",
    //   label: Vocabulary.sellPrice,
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (sellPrice: any) => formatNumber(sellPrice),
    //   },
    // },
    // {
    //   name: "user_r",
    //   label: Vocabulary.user,
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (user_r: any) => (
    //       <Typography>{user_r?.name}</Typography>
    //     ),
    //   },
    // },
    // {
    //   name: "modifyBy",
    //   label: Vocabulary.user,
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (modifyBy: any) => (
    //       <div>
    //         {Array.isArray(modifyBy) ? (
    //           modifyBy
    //             .map((m: any) => (m?.index?.includes("3.1") ? m?.userId : null))
    //             .filter((value: any) => value !== null)
    //             .slice(-1)[0] // Access the last element
    //         ) : (
    //           <Typography> </Typography>
    //         )}
    //       </div>
    //     ),
    //   },
    // },
    {
      name: "tip_tva_r",
      label: Vocabulary.TVA,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (tip_tva_r: any) => (
          <Typography>{tip_tva_r?.nume}</Typography>
        ),
      },
    },
    {
      name: "date",
      label: Vocabulary.year,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "firma_achizitie_r",
      label: Vocabulary.nume_firma,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (firma: any) => (
          <Typography style={{ fontSize: 12 }}>{firma?.nume_firma}</Typography>
        ),
      },
    },
    {
      name: "optiuni",
      label: Vocabulary.options,
      options: {
        filter: false,
        sort: false,
        download: false,
        viewColumns: false,
        display: props?.info.edit || props?.info.delete ? true : false,
        customBodyRender: (index: any, data: any) => (
          <div style={{ display: "flex" }}>
            {props?.info?.edit ? (
              <IconButton
                onClick={(event: any) => {
                  event.stopPropagation();
                  vehicleContext.updateVehicle({});
                  vehicleContext.updateVehicleImages({});
                  vehicleContext.updateVehicleDocuments({});
                  navigate(
                    `${localUrlEnum.vehicles}/vehicleDetails/${data?.rowData[0]}`
                  );
                }}
                style={{ marginRight: 5 }}
                aria-label="delete"
              >
                <ModeEditIcon />
              </IconButton>
            ) : null}
            {props?.info?.delete ? (
              <IconButton
                onClick={(event: any) => {
                  setCurrentVehicle(state.vehicles[data?.rowIndex]);
                  event.stopPropagation();
                  setActionType(actionTypeForGeneralModal?.delete);
                }}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
            {props?.permission?.duplicateVehicle && (
              <Tooltip title={Vocabulary.duplicateVehicleButtonText}>
                <IconButton
                  onClick={(event: any) => {
                    setCurrentVehicle(state.vehicles[data?.rowIndex]);
                    event.stopPropagation();
                    setActionType(actionTypeForGeneralModal?.duplicate);
                  }}
                  aria-label="delete"
                >
                  <LibraryAddRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        ),
      },
    },
  ];

  /**
   *
   */
  const columnsForSoldVehicles = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "numeComplet",
      label: Vocabulary.name,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "vin",
      label: Vocabulary.vin,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (vin: any) => (
          <Typography style={{ fontSize: "0.875rem" }}>
            {isMobile() ? vin : "..." + vin.substring(vin.length - 6)}
          </Typography>
        ),
      },
    },
    {
      name: "status_vehicul_r",
      label: Vocabulary.status,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (status_vehicul_r: any) => (
          <Typography>{status_vehicul_r.nume}</Typography>
        ),
      },
    },
    {
      name: "purchasePrice",
      label: Vocabulary.acquisitionPrice,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (acquisitionPrice: any) =>
          formatNumber(acquisitionPrice),
      },
    },
    {
      name: "salePrice",
      label: Vocabulary.sellPrice,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (sellPrice: any) => formatNumber(sellPrice),
      },
    },
    {
      name: "tip_achizitie_r",
      label: Vocabulary.purchaseType,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (object: any) => (
          <Typography>{object?.nume}</Typography>
        ),
      },
    },
    {
      name: "user_r",
      label: Vocabulary.user,
      options: {
        filter: true,
        sort: false,
        display: false,
        download: false,
        customBodyRender: (user_r: any) => (
          <Typography>{user_r?.name}</Typography>
        ),
      },
    },
    {
      name: "vanzator_r",
      label: Vocabulary.seller,
      options: {
        filter: true,
        sort: false,

        customBodyRender: (seller: any) => (
          <Typography>{seller?.name}</Typography>
        ),
      },
    },
    {
      name: "tip_tva_r",
      label: Vocabulary.TVA,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (tip_tva_r: any) => (
          <Typography>{tip_tva_r?.nume}</Typography>
        ),
      },
    },
    {
      name: "date",
      label: Vocabulary.year,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "firma_achizitie_r",
      label: Vocabulary.nume_firma,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (firma: any) => (
          <Typography style={{ fontSize: 12 }}>{firma?.nume_firma}</Typography>
        ),
      },
    },

    {
      name: "optiuni",
      label: Vocabulary.options,
      options: {
        filter: false,
        sort: false,
        download: false,
        display: props?.info.edit || props?.info.delete ? true : false,
        customBodyRender: (index: any, data: any) => (
          <div style={{ display: "flex" }}>
            {props?.info?.edit ? (
              <IconButton
                onClick={(event: any) => {
                  event.stopPropagation();
                  vehicleContext.updateVehicle({});
                  vehicleContext.updateVehicleImages({});
                  vehicleContext.updateVehicleDocuments({});
                  navigate(
                    `${localUrlEnum.vehicles}/vehicleDetails/${data?.rowData[0]}`
                  );
                }}
                style={{ marginRight: 5 }}
                aria-label="delete"
              >
                <ModeEditIcon />
              </IconButton>
            ) : null}
            {props?.info?.delete ? (
              <IconButton
                onClick={(event: any) => {
                  setCurrentVehicle(state.vehicles[data?.rowIndex]);
                  event.stopPropagation();
                  setActionType(actionTypeForGeneralModal?.delete);
                }}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
            {props?.permission?.duplicateVehicle && (
              <Tooltip title={Vocabulary.duplicateVehicleButtonText}>
                <IconButton
                  onClick={(event: any) => {
                    setCurrentVehicle(state.vehicles[data?.rowIndex]);
                    event.stopPropagation();
                    setActionType(actionTypeForGeneralModal?.duplicate);
                  }}
                >
                  <LibraryAddRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        ),
      },
    },
  ];

  /**
   *
   * @param id
   */
  const submitDeletedCar = (id: any) => {
    deleteVehicle(id);
  };

  /**
   *
   * @param id
   */
  async function deleteVehicle(id: any) {
    const url = `${urlEnum.vehicles}/${id}`;
    await deleteData(url);
    setActionType();
    setCurrentVehicle();
    getDataRefresh();
  }

  /**
   *
   * @param id
   */
  const submitDuplicatedCar = async (id: any) => {
    const url = `${urlEnum.duplicateVehicle}/${id}`;
    const response = await updateData(url);
    if (response?.data) {
      const vehicleId = response?.data?.duplicatedVehicleId;
      window.scrollTo(0, 0);
      navigate(`/vehiclesDashboard/vehicleDetails/${vehicleId}`, {
        replace: true,
      });
      vehicleContext.updateVehicle(new VehicleModel());
      setActionType();
    }
  };

  /**
   *
   */
  const exportData = (props: any) => {
    let exportedColumns = props?.columns;
    exportedColumns = exportedColumns.filter((column: any) => {
      if (column.download == true) return column.name;
    });

    const menu = vehiclesTabs.find((tab: any) => tab.code === pills.status);
    const label = menu?.label;
    const tab = pills.status == "stock" ? "_" + stockTabs[value] : "";
    const fileName = (
      Vocabulary.vehicles +
      "_" +
      label +
      tab
    ).toLocaleLowerCase();
    const url = `${urlEnum.exportVehiclesTable}`;

    exportTable(url, pills, fileName, exportedColumns);
  };

  /**
   *
   */
  const options = {
    filter: false,
    download: true,
    viewColumns: false,
    responsive: tableLayoutOnMobile,
    rowsPerPageOptions: [
      10,
      50,
      100,
      state?.vehiclesNumber > 100 ? state?.vehiclesNumber : 1000,
    ],
    sort: false,
    textLabels: {
      viewColumns: {
        title: Vocabulary.viewColumns,
      },
      toolbar: {
        downloadCsv: Vocabulary.downloadExcel,
        viewColumns: Vocabulary.selectColumns,
      },
      pagination: Vocabulary.pagination,
    },
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: false,
      },
      filename: (
        Vocabulary.vehicles +
        "_" +
        (Object.assign(Vocabulary)[pills.status + "s"]
          ? Object.assign(Vocabulary)[pills.status + "s"]
          : Object.assign(Vocabulary)[pills.status]) +
        ".xlsx"
      ).toLocaleLowerCase(),
    },

    fixedHeader: false,
    fixedSelectColumn: false,
    selectableRows: "none" as any,
    print: false,
    page: pills.page,
    setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
      if (state.vehicles[rowIndex].statusError) {
        if (state.vehicles[rowIndex].statusError.length === 1) {
          if (state.vehicles[rowIndex].statusError[0] === VehicleStatus.Unsold)
            return {
              style: {
                backgroundColor: "#ffccbb63",
                cursor: "pointer",
              },
            };
          else if (
            state.vehicles[rowIndex].statusError[0] ===
            VehicleStatus.NotReceived
          )
            return {
              style: {
                backgroundColor: "#cc43144d",
                cursor: "pointer",
              },
            };
        } else if (state.vehicles[rowIndex].statusError.length === 2) {
          return {
            style: {
              backgroundColor: "#4d5e8042",
              cursor: "pointer",
            },
          };
        }
      }
      return {
        style: { cursor: "pointer" },
      };
    },
    serverSide: true,
    rowsPerPage: pills.perPage,
    search: false,
    count: state?.vehiclesNumber,
    rowHover: true,
    onRowClick: (rowData: any, rowState: any) => {
      vehicleContext.updateVehicle({});
      vehicleContext.updateVehicleImages({});
      vehicleContext.updateVehicleDocuments({});
      navigate(`/vehiclesDashboard/${info.route}/${rowData[0]}`);
    },
    onChangePage: (page: number) => {
      navigate(
        `${type ? type : localUrlEnum.vehicles}?${pills.status}?page=${
          page + 1
        }?perPage=${pills.perPage}?statusVehicul=${
          pills.statusVehicul ? pills.statusVehicul : null
        }?purchaseType=${
          pills.purchaseType ? pills.purchaseType : null
        }?users=${pills.users ? pills.users : null}?company=${
          pills.company ? pills.company : null
        }?locations=${pills.locations ? pills.locations : null}?vehicleTypes=${
          pills.vehicleTypes ? pills.vehicleTypes : null
        }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
          pills.visible
        }?statusError=${pills.statusError}${
          props.pills?.search ? `?search=${props.pills?.search}` : ""
        }?showIncompleteVehicles=${pills.showIncompleteVehicles}?category_id=${
          pills.category_id
        }`
      );
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      navigate(
        `${type ? type : localUrlEnum.vehicles}?${
          pills.status
        }?page=${1}?perPage=${numberOfRows}?statusVehicul=${
          pills.statusVehicul ? pills.statusVehicul : null
        }?purchaseType=${
          pills.purchaseType ? pills.purchaseType : null
        }?users=${pills.users ? pills.users : null}?company=${
          pills.company ? pills.company : null
        }?locations=${pills.locations ? pills.locations : null}?vehicleTypes=${
          pills.vehicleTypes ? pills.vehicleTypes : null
        }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
          pills.visible
        }?statusError=${pills.statusError}${
          props.pills?.search ? `?search=${props.pills?.search}` : ""
        }?showIncompleteVehicles=${pills.showIncompleteVehicles}?category_id=${
          pills.category_id
        }`
      );
      window.scrollTo(0, 0);
    },
    onDownload: (buildHead: any, buildBody: any, columns: any, data: any) => {
      const alteredData = data.map((column: any, index: number) => {
        column.data = column?.data?.map((value: any, key: number) => {
          if (typeof value == "object") {
            if (value) {
              const name = Object.values(value)[1];
              value = name;
            }
          } else if (columns[key].boolean) {
            value = value == "1" ? Vocabulary.yes : Vocabulary.no;
          }
          return value;
        });

        return column;
      });
      return `${buildHead(columns)}${buildBody(alteredData)}`;
    },
  };
  /**
   *
   * @param props
   * @returns
   */
  const CustomToolbar = (props: any) => {
    return (
      <Toolbar
        style={{
          display: isMobile() ? "block" : "flex",
          paddingTop: isMobile() ? 10 : 0,
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: isMobile() ? "block" : "flex" }}>
          <TableSearch />
          <Button
            startIcon={<CloudDownloadIcon />}
            onClick={(event) => exportData(props)}
          >
            {Vocabulary.exportTable}
          </Button>
        </div>
        {pills?.status != VehiclesTab?.intermediated && (
          <div style={{ margin: isMobile() ? "1em 0" : "" }}>
            <Typography
              style={{ fontSize: "16px", width: isMobile() ? "100%" : "" }}
            >
              {Vocabulary?.totalAcquisitions}:{" "}
              <strong>{formatNumber(state?.acquisitionsSum)}</strong>
              {" Lei"}
            </Typography>
            <Typography style={{ fontSize: "16px" }}>
              {Vocabulary?.totalVehicles}:{" "}
              <strong>{state?.vehiclesNumber}</strong>
            </Typography>
          </div>
        )}
      </Toolbar>
    );
  };

  /**
   *
   * @returns a generic modal for actions
   */
  const renderConfimationModal = () => {
    return (
      <GenericModal
        open={actionType ? true : false}
        onClose={() => {
          setActionType();
          setCurrentVehicle(null);
        }}
        title=""
        children={
          <div className={style.content} style={{ textAlign: "center" }}>
            <p className={style.header}>
              {actionType == actionTypeForGeneralModal?.duplicate
                ? Vocabulary.askForDuplicateVehicle
                : Vocabulary.askBeforeDeleteVehicle}
            </p>
            <div className={style.modalContent}>
              <p className={style.boldParagraph}>
                {currentVehicle?.numeComplet}
              </p>
              <p className={style.paragraph}>{`cu seria`}</p>
              <p className={style.boldParagraph}>{currentVehicle?.vin}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                fullWidth
                color="secondary"
                style={{ margin: 10 }}
                onClick={() => {
                  setActionType();
                  setCurrentVehicle(null);
                }}
              >
                {Vocabulary.no}
              </Button>
              <Button
                onClick={() =>
                  actionType == actionTypeForGeneralModal?.duplicate
                    ? submitDuplicatedCar(currentVehicle?.id)
                    : submitDeletedCar(currentVehicle?.id)
                }
                fullWidth
                variant="contained"
                style={{ color: "white", margin: 10 }}
              >
                {Vocabulary.yes}
              </Button>
            </div>
          </div>
        }
      />
    );
  };

  const handleChangeStatusTab = (status: string) => {
    if (pills?.status != status) {
      //navigate only if different status is selected
      deleteFilters();
      navigate(
        `${
          type ? type : localUrlEnum.vehicles
        }?status=${status}?page=${1}?perPage=${pills.perPage}`
      );
    }
  };

  return (
    <div
      style={{
        marginLeft: isMobile() ? -15 : 0,
        marginRight: isMobile() ? -15 : -20,
      }}
    >
      <ThemeProvider theme={getMuiTheme()}>
        <Box style={{ marginBottom: -24, marginLeft: 9 }}>
          <DinamicTabs
            name={componentNames.DinamicTabs}
            labels={pills?.status == vehiclesTabs[0].code ? stockTabs : []}
            handleChangeTab={handleChange}
            value={value}
            openModalWindow={() => {
              navigate(`${localUrlEnum.vehicles}/vehicleDetails/newVehicle`);
              vehicleContext.updateVehicle(new VehicleModel());
              vehicleContext.updateVehicleImages({});
              vehicleContext.updateVehicleDocuments({});
            }}
            addNewButtonFromRoles={info?.edit}
            search={false}
            showHeaderMenu={true}
            label={
              vehiclesTabs.filter((tab) => tab.code == pills.status)[0]?.label
            }
            changeStatus={handleChangeStatusTab}
          ></DinamicTabs>
        </Box>
        <div
          style={{
            marginLeft: isMobile() ? 0 : -15,
            marginRight: isMobile() ? 0 : -15,
            width: "100%",
          }}
        >
          <TabPanel value={value} index={0}>
            <MUIDataTable
              title={""}
              data={state?.vehicles}
              columns={
                pills?.status == VehiclesTab.sold
                  ? columnsForSoldVehicles
                  : columnsUnsold
              }
              options={options}
              components={{
                TableToolbar: CustomToolbar,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MUIDataTable
              title={""}
              data={state?.vehicles}
              columns={
                pills?.status == VehiclesTab.sold
                  ? columnsForSoldVehicles
                  : columnsUnsold
              }
              options={options}
              components={{
                TableToolbar: CustomToolbar,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <MUIDataTable
              title={""}
              data={state?.vehicles}
              columns={columns}
              options={options}
              components={{
                TableToolbar: CustomToolbar,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <MUIDataTable
              key={pills.page}
              title={""}
              data={state?.vehicles}
              columns={columnsUnsold}
              options={options}
              components={{
                TableToolbar: CustomToolbar,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <MUIDataTable
              key={pills.page}
              title={""}
              data={state?.vehicles}
              columns={columnsUnsold}
              options={options}
              components={{
                TableToolbar: CustomToolbar,
              }}
            />
          </TabPanel>
        </div>
        {/* <GenericModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          title=""
        >
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: 20 }}>{Vocabulary.askBeforeDeleteVehicle}</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p style={{ fontSize: 20, fontWeight: 600 }}>
                {deletedCar?.rowData[1]}
              </p>
              <p style={{ fontSize: 20, marginLeft: 10, marginRight: 10 }}>
                {`cu seria`}
              </p>
              <p style={{ fontSize: 20, fontWeight: 600 }}>
                {deletedCar?.rowData[2]}
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                fullWidth
                color="secondary"
                style={{ margin: 10 }}
                onClick={() => setOpenModal(false)}
              >
                {Vocabulary.no}
              </Button>
              <Button
                onClick={() => submitDeletedCar(deletedCar?.rowData[0])}
                fullWidth
                variant="contained"
                style={{ color: "white", margin: 10 }}
              >
                {Vocabulary.yes}
              </Button>
            </div>
          </div>
        </GenericModal> */}
        {renderConfimationModal()}
        <PostAdsIntegrationModal
          open={openIntegrationModal}
          setOpen={setOpenIntegrationModal}
          vehicle={currentVehicle}
          setVehicle={setCurrentVehicle}
          getDataRefresh={getDataRefresh}
        />
      </ThemeProvider>
    </div>
  );
}

export default withRole(MainTable);
