import { Vocabulary } from "../Vocabulary";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import NoCrashIcon from "@mui/icons-material/NoCrash";

export const ROUTER_ADD_VEHICLE = {
  routes: ["/", "/logs", "/vehiclesDashboard/vehicleDetails/:id?","/sales/vehiclesDashboardvehicleDetails/:id?", "*"],
};

export const MAIN_SLIDER_ADD_VEHICLE = [{
  name: Vocabulary.logsName,
  path: "/logs",
  icon: <AssignmentIndIcon />,
  orderId: 6,
}];

export const VEHICLE_OPTIONS_ADD_VEHICLE = [
  {
    name: Vocabulary.detaliiVehicul,
    path: "/vehiclesDashboard/vehicleDetails/",
    icon: <NoCrashIcon />,
    id: "vehicleDetails",
    orderNumber: 1,
  },
];


export const DINAMIC_TABS_ADD_VEHICLE = {
   add: true,
};

export const MAIN_TABLE_ADD_VEHICLE = {
  edit: true,
};

export const VEHICLE_MAIN_INFO_EDIT_VEHICLE= {
  editVehicle: true,
  editAdmin: true,
};
