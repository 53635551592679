import { IconButton, Modal, Paper, Typography } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import Signature from "./Signature";

type SignatureModalProps = {
  saveSignature: (signature: string) => void;
  showSignature: () => void;
  openModalSignature: boolean;
};
export default function SignatureModal(props: SignatureModalProps) {
  const { saveSignature, showSignature, openModalSignature } = props;
  return (
    <Modal
      open={openModalSignature}
      onClose={showSignature}
      style={{
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        display: "flex",
        justifyContent: "center",
        zIndex: 5000,
      }}
    >
      <Paper>
        <IconButton
          onClick={showSignature}
          style={{ float: "right", top: 10, right: 10 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            display: "flex",
            justifyContent: "center",
            fontSize: 20,
            marginTop: 20,
          }}
        >
          Semnează aici
        </Typography>
        <div>
          <Signature doneCallback={saveSignature}></Signature>
        </div>
        <br></br>
      </Paper>
    </Modal>
  );
}
