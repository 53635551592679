import { Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import styles from "../../Styles/progressAction.module.css";
import { useContext } from "react";
import { VehicleContext } from "../../Context/vehicleContext";

type ProgressInformation2Props = {
  disabled?: boolean;
};

export default function ProgressInformation2(props: ProgressInformation2Props) {
  const vehicleContext: any = useContext(VehicleContext);

  return (
    <div className={styles.informationContainer}>
      <Grid container spacing={2}>
        <Grid className={styles.gridFileText} item xs={12} md={props.disabled ? 6 : 12} xl={props.disabled ? 6 : 12}>
          <p>{Vocabulary.currentExchangeRate} <strong>{vehicleContext?.state?.vehicle?.cursEuro}</strong></p>
          <TextField
            name="cursEuro"
            label={Vocabulary.cursEuro}
            size="small"
            variant="outlined"
            type="number"
            InputLabelProps={{ shrink: true }}
            error={props.disabled?false:vehicleContext?.state?.vehicle?.cursEuro?.length===0}
            helperText={props.disabled?false:vehicleContext?.state?.vehicle?.cursEuro?.length===0?"Camp obligatoriu":""}
            value={vehicleContext?.state?.vehicle?.cursEuro || 1}
            disabled={props.disabled}
            fullWidth
            onChange={(e: any) => vehicleContext.onChangeVehicle(e.target)}
          />
        </Grid>
      </Grid>
    </div>
  );
}
