/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { updateData } from "../../Services/updateData";
import { postData } from "../../Services/postData";
import { urlEnum } from "../../Utils/urlEnum";
import GenericModal from "../../Modal/GenericModal";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {  Button, Grid,  } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import {  } from "../../Utils/autocompleteEnum";

import { isMobile } from "../../Utils/utils";

type StatusProps = {
  open: boolean;
  title: string;
  editedPermissions: any;
  onClose: (shouldRefetch?: boolean) => void;
};

const initialState = {
  name: "",
  description: "",
};
export default function Permission(props: StatusProps) {
  const { open, title, editedPermissions, onClose } = props;
  const [data, setData] = useState(initialState);


  useEffect(() => {
    if (editedPermissions) {
      setData(editedPermissions);
     

    } else {
      setData(initialState);

    }
  }, [editedPermissions]);

  /**
   *
   * @param event
   */
  function handleChangeTextFields(event: any) {
    setData({ ...data, [event.target.name]: event.target.value });
  }



  /**
   * Save status
   */
  function saveStatus() {
    if (editedPermissions) {
      updateData(`${urlEnum.permissions_id}/${editedPermissions.id}`, data)
        .then((response) => {
          setData(initialState);
          onClose(true);
        })
        .catch((error) => {
          setData(initialState);
          onClose(false);
        });
    } else {
      postData(urlEnum.permissions_id, data)
        .then((response) => {
          setData(initialState);
          onClose(true);
        })
        .catch((error) => {
          setData(initialState);
          onClose(false);
        });
    }
  }

  return (
    <GenericModal open={open} onClose={() => onClose(false)} title={title}>
      <ValidatorForm onSubmit={saveStatus} style={{marginTop:10}}>
        <Grid container spacing={isMobile() ? 1 : 4}>
          <Grid item md={12} xs={12}>
            <TextValidator
              name="name"
              label={Vocabulary.name}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.name}
              onChange={handleChangeTextFields}
              
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextValidator
              name="description"
              label={Vocabulary.description}
              fullWidth
              value={data.description}
              onChange={handleChangeTextFields}
              multiLine
              rows={5}
            />
          </Grid>

          <Grid container spacing={isMobile() ? 1 : 4} style={{ margin: 10 }}>
            <Grid
              item
              md={12}
              xs={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                style={{ margin: 5 }}
                variant="contained"
                color="secondary"
                onClick={() => onClose(false)}
                fullWidth
              >
                {Vocabulary.cancel}
              </Button>

              <Button
                variant="contained"
                fullWidth
                type="submit"
                style={{ color: "white", margin: 5 }}
              >
                {Vocabulary.save}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
    </GenericModal>
  );
}
