import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import SecurityIcon from "@mui/icons-material/Security";

import { Vocabulary } from "../Vocabulary";

export const ROUTER_GARANTII = {
  routes: [
    "/",
    "/vehiclesDashboard",
    "/vehiclesDashboard/vehicleGuarantees/:id?",
    "*",
  ],
};

export const MAIN_SLIDER_GARANTII = [{
  name: Vocabulary.vehicles,
  path: "/vehiclesDashboard",
  icon: <DirectionsCarIcon />,
}];

export const VEHICLE_OPTIONS_GARANTII = [
  {
    name: Vocabulary.intrareGarantie,
    path: "/vehiclesDashboard/vehicleGuarantees/",
    icon: <SecurityIcon />,
    id: "vehicleGuarantees",
    slug: "intrare_garantie",
    orderNumber: 4,
  },
];

export const DINAMIC_TABS_GARANTII = {
  addVehicles: true,
};


