/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Checkbox,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../../Utils/Vocabulary";
import { FormControl } from "@mui/base";
import { ChangeEvent, useContext, useState, useMemo, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import ModeIcon from "@mui/icons-material/Mode";
import { CacheContext } from "../../../Context/cacheContext";
import { VehicleContext } from "../../../Context/vehicleContext";
import style from "../../../Styles/optionsVehicle.module.css";
import UploadElement from "../../ProgressActionComponents/UploadElement";
import {
  AutocompleteModel,
  ContractInformationModel,
  GenericAutocompleteModalModel,
} from "../../../Models/Models";
import { PaymentMethods } from "../../../Utils/utils";
import GenericAutocompleteModal from "../../../Modal/GenericAutocompleteModal";
import { AutocompleteSchema } from "../../../Validators/vehicleValidators";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { europeanDate, usDateFormatWithTime } from "../../../Utils/formatDate";
import moment from "moment";
import { UserModel } from "../../../Models/VehicleModel";

/**
 *
 */
type ContractFileProps = {
  contractInformation: ContractInformationModel;
  salesInformation: any;
  onChangeContractInformation: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: any
  ) => void;
  onChangeFinancingMethodOpen: (financingMethodName: string) => void;
  onChangeSaleDate: (event: any) => void;
  onChangeSaleUser: (event: any, value: any) => void;
  onChangeSalesInformation: (salesInformation: any) => void;
};

const initialGenericModalState = new GenericAutocompleteModalModel();

export default function ContractInformation(props: ContractFileProps) {
  const {
    contractInformation,
    salesInformation,
    onChangeContractInformation,
    onChangeFinancingMethodOpen,
    onChangeSaleDate,
    onChangeSaleUser,
    onChangeSalesInformation,
  } = props;
  const { cache }: any = useContext(CacheContext);
  const vehicleContext: any = useContext(VehicleContext); //TODO
  const VATSetting = useMemo(
    () =>
      cache.settings.find(
        (item: { slug: string; value: any }) => item.slug === "procentTVA"
      ),
    [cache]
  );
  const VATPercent = +VATSetting?.value;
  const [genericAutocompleteModel, setGenericAutocompleteModel] = useState(
    initialGenericModalState
  );

  useEffect(() => {
    if (
      vehicleContext.state.vehicle?.pret_vanzare_ron &&
      vehicleContext.state.vehicle?.pret_vanzare_ron !== 0 &&
      !contractInformation.pretVanzare
    ) {
      onChangeContractInformation(
        vehicleContext.state.vehicle?.pret_vanzare_ron
      );
    }
    const newSalesInformation = Object.assign({}, salesInformation);
    if (
      vehicleContext?.state?.vehicle?.data_vanzare &&
      vehicleContext?.state?.vehicle?.data_vanzare !== ""
    ) {
      newSalesInformation.data_vanzare = moment(
        vehicleContext?.state?.vehicle?.data_vanzare,
        usDateFormatWithTime
      );
    }
    if (vehicleContext?.state?.vehicle?.vanzator_id) {
      newSalesInformation.vanzator_id =
        vehicleContext?.state?.vehicle?.vanzator_id;
    }
    onChangeSalesInformation(newSalesInformation);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   *
   * @param data
   * @param title
   * @param id
   */
  const handleClickEditDrop = (data: any, title: any, id: any) => {
    setGenericAutocompleteModel({
      ...genericAutocompleteModel,
      open: true,
      title: title,
      data: data,
      id: "financingTypes",
      columns: columns,
      validationSchema: AutocompleteSchema,
      hiddenFieldsArray: ["id"],
    });
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "nume",
      label: "Nume",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const DropDownButton = ({ children, ...other }: any) => (
    <Paper {...other}>
      <IconButton
        onMouseDown={(event) => {
          event.preventDefault();
          children[1] === "null" || children[1] === null
            ? handleClickEditDrop(
                children[2]?.props?.ownerState?.options,
                children[2]?.props?.ownerState?.label,
                children[2].props?.ownerState?.id
              )
            : handleClickEditDrop(
                children[1]?.props?.ownerState?.options,
                children[1]?.props?.ownerState?.label,
                children[1].props?.ownerState?.id
              );
        }}
        style={{
          position: "absolute",
          top: -40,
          right: -36,
          margin: 5,
          display: "flex",
          justifyContent: "end",
          backgroundColor: "#FF6633",
          color: "#fff",
          borderRadius: 7,
          zIndex: 100,
        }}
        size="small"
        aria-label="editDropdown"
      >
        <ModeIcon fontSize="inherit" />
      </IconButton>
      {children}
    </Paper>
  );

  /**
   * renders the price information of the contract
   * @returns
   */
  const renderPriceInformation = () => {
    const totalNet =
      +vehicleContext?.state?.vehicle?.pret_achizitie_ron +
      +vehicleContext?.state?.vehicle?.cheltuieli +
      +vehicleContext?.state?.vehicle?.dobanda;
    const profitNet = contractInformation.pretVanzare - totalNet;
    return (
      <Grid container spacing={4}>
        <Grid className={style.gridItem} item xs={12} md={6} xl={6}>
          <div className={style.textLines}>
            <Typography>{Vocabulary.acquisitionNetPrice}</Typography>
            <div style={{ display: "flex" }}>
              <Typography className={style.labels}>
                {vehicleContext?.state?.vehicle?.pret_achizitie_ron}&nbsp;
              </Typography>
              <Typography>{Vocabulary.currencyLei}</Typography>
            </div>
          </div>

          <div className={style.textLines}>
            <Typography>{Vocabulary.costsName}</Typography>
            <div style={{ display: "flex" }}>
              <Typography className={style.labels}>
                {vehicleContext?.state?.vehicle?.cheltuieli}&nbsp;
              </Typography>
              <Typography>{Vocabulary.currencyLei}</Typography>
            </div>
          </div>

          <div className={style.textLines}>
            <Typography>{Vocabulary.rate}</Typography>
            <div style={{ display: "flex" }}>
              <Typography className={style.labels}>
                {vehicleContext?.state?.vehicle?.dobanda}&nbsp;
              </Typography>
              <Typography>{Vocabulary.currencyLei}</Typography>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={6} xl={6}>
          <div className={style.textLines}>
            <Typography>{Vocabulary.totalNET}</Typography>
            <div style={{ display: "flex" }}>
              <Typography className={style.labels}>
                {totalNet && totalNet.toFixed(3)}
                &nbsp;
              </Typography>
              <Typography>{Vocabulary.currencyLei}</Typography>
            </div>
          </div>

          <div
            className={style.textLines}
            style={{ color: profitNet < 0 ? "red" : "green" }}
          >
            <Typography>
              {Vocabulary.sellPriceNet} - {Vocabulary.totalNET}
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography className={style.labels}>
                {profitNet.toFixed(3)}
                &nbsp;
              </Typography>
              <Typography>{Vocabulary.currencyLei}</Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  };

  const financingTotal = contractInformation.metodeFinantare.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.value,
    0
  );
  const totalToPay =
    parseFloat(
      contractInformation.avansBrut
        ? contractInformation?.avansBrut?.toString()
        : contractInformation?.avans?.toString() || "0"
    ) +
    parseFloat(contractInformation?.sumaBuyBack?.toString() || "0") +
    parseFloat(contractInformation?.sumaCreditata?.toString() || "0") +
    parseFloat(contractInformation?.restDePlata?.toString() || "0") +
    financingTotal;
  const brutSellingPrice =
    vehicleContext?.state?.vehicle?.tva_id === 1
      ? Math.round(
          contractInformation.pretVanzare * (1 + VATPercent / 100) * 100
        ) / 100
      : contractInformation.pretVanzare;
  const priceDifference = brutSellingPrice - totalToPay;
  return (
    <>
      {renderPriceInformation()}

      <br />
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} xl={6}>
          <TextField
            fullWidth
            label={
              vehicleContext?.state?.vehicle?.tva_id === 1
                ? Vocabulary.sellPriceNet
                : Vocabulary.vehicleBrutPrice
            }
            variant="outlined"
            name="pretVanzare"
            size="small"
            type="number"
            value={contractInformation.pretVanzare}
            onChange={(event) => onChangeContractInformation(event)}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <div className={style.textLines}>
            <Typography>
              <strong>{Vocabulary.sellPriceBrut}:</strong>&nbsp;&nbsp;
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography className={style.labels}>
                {brutSellingPrice && brutSellingPrice.toFixed(3)}
              </Typography>
              <Typography>&nbsp;{Vocabulary.currencyLei}</Typography>
            </div>
          </div>

          <div
            className={style.textLines}
            style={{ color: priceDifference !== 0 ? "red" : "green" }}
          >
            <Typography>
              {`${Vocabulary.sellPriceBrut} - ${Vocabulary.paymentMethods}`}
              :&nbsp;
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography className={style.labels}>
                {priceDifference && priceDifference.toFixed(3)}
              </Typography>
              <Typography>&nbsp;{Vocabulary.currencyLei}</Typography>
            </div>
          </div>
        </Grid>
      </Grid>

      <br />
      <br />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} xl={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              className={style.textField}
              format={europeanDate}
              maxDate={moment()}
              value={salesInformation.data_vanzare}
              onChange={(event) => onChangeSaleDate(event)}
              label={Vocabulary.sellDate}
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <Autocomplete
            freeSolo={false}
            id="vanzator_id"
            disablePortal
            disableClearable
            className={style.textField}
            getOptionLabel={(option: any) => option.nume}
            size="small"
            value={cache?.users.find(
              (user: UserModel) => user.id === +salesInformation.vanzator_id
            )}
            options={cache?.users}
            onChange={(event: any, newValue: any) => {
              onChangeSaleUser(event, newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.seller}
                variant="outlined"
                size="small"
                name="vanzator_id"
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} xl={6}>
          <Autocomplete
            freeSolo={false}
            id="paymentMethod"
            disablePortal
            disableClearable
            getOptionLabel={(option: any) => option.nume}
            size="small"
            value={(PaymentMethods as any).find(
              (item: any) => item.nume === contractInformation.metodaDeIncasare
            )}
            options={PaymentMethods}
            onChange={(event: any, newValue: any) => {
              onChangeContractInformation(event, newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.paymentMethod}
                variant="outlined"
                size="small"
                name="paymentMethod"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <TextField
            disabled
            label={Vocabulary.advance}
            variant="outlined"
            name="avans"
            size="small"
            type="number"
            value={
              contractInformation.avansBrut
                ? parseFloat(contractInformation?.avansBrut?.toString())
                : parseFloat(contractInformation?.avans?.toString())
            }
            style={{ width: "100%" }}
            onChange={(event) => onChangeContractInformation(event)}
          />
        </Grid>
      </Grid>

      <br />
      <Divider />
      <br />

      {/** *** FINANTARE *** */}
      <Autocomplete
        freeSolo={false}
        multiple
        id="financingMethod"
        disablePortal
        disableClearable
        style={{ paddingRight: 10 }}
        getOptionLabel={(option: any) => option.nume}
        size="small"
        value={
          typeof cache?.financingTypes === "string"
            ? null
            : cache?.financingTypes?.filter((item: any) => {
                return contractInformation.metodeFinantare.find(
                  (m) => m.name === item.nume
                );
              })
        }
        PaperComponent={DropDownButton}
        options={cache.financingTypes}
        onChange={(event: any, newValue: any) => {
          onChangeContractInformation(event, newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={Vocabulary.deleteInstructions}
            label={Vocabulary.financingMethod}
            variant="outlined"
            size="small"
            name="financingMethod"
          />
        )}
      />

      <List
        dense
        sx={{ width: "100%", bgcolor: "background.paper", marginLeft: 0 }}
      >
        {contractInformation.metodeFinantare &&
          contractInformation.metodeFinantare.map((value: any) => {
            return (
              <Grid container>
                <Grid item md={4}>
                  <TextField
                    label={`${Vocabulary.amount} ${value.name}`}
                    variant="outlined"
                    inputProps={{ financingMethod: value.name }}
                    name="financingMethod_value"
                    size="small"
                    type="number"
                    value={value?.value}
                    style={{ width: "100%", marginTop: 17 }}
                    onChange={(event) => onChangeContractInformation(event)}
                  />
                </Grid>
                <Grid item md={8}>
                  <UploadElement
                    key={value.name}
                    openModal={value.open}
                    handleModal={() => onChangeFinancingMethodOpen(value.name)}
                    handleEditInfo={(file: any) => {
                      if (file && file.length) {
                        onChangeContractInformation(file[0], {
                          financingMethod: value,
                        });
                      }
                    }}
                    fileName={
                      value.file instanceof File ? value.file.name : value.file
                    }
                    handleSave={() => onChangeFinancingMethodOpen(value.name)}
                    title={value.name}
                  ></UploadElement>
                </Grid>
              </Grid>
            );
          })}
      </List>

      <br />
      <Divider />
      <br />

      {/** *** BUY BACK *** */}
      <FormControlLabel
        control={
          <Checkbox
            name="isBuyBack"
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => onChangeContractInformation(event)}
            checked={contractInformation.isBuyBack}
          />
        }
        label={Vocabulary.buyback}
      />
      {contractInformation.isBuyBack ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} xl={4}>
            <TextField
              label={Vocabulary.buybackPrice}
              variant="outlined"
              name="sumaBuyBack"
              size="small"
              type="number"
              value={contractInformation.sumaBuyBack}
              style={{ width: "100%" }}
              onChange={(event) => onChangeContractInformation(event)}
            />
          </Grid>
          <Grid item xs={12} md={4} xl={4}>
            <TextField
              label={Vocabulary.buybackBrand}
              variant="outlined"
              name="marcaBuyBack"
              size="small"
              type="text"
              value={contractInformation.marcaBuyBack}
              style={{ width: "100%" }}
              onChange={(event) => onChangeContractInformation(event)}
            />
          </Grid>
          <Grid item xs={12} md={4} xl={4} style={{ marginBottom: 20 }}>
            <TextField
              label={Vocabulary.buybackLicenseNumber}
              variant="outlined"
              name="nrInmatriculareBuyBack"
              size="small"
              type="text"
              value={contractInformation.nrInmatriculareBuyBack}
              style={{ width: "100%" }}
              onChange={(event) => onChangeContractInformation(event)}
            />
          </Grid>
        </Grid>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} xl={6}>
          <TextField
            label={Vocabulary.amountCredited}
            variant="outlined"
            name="sumaCreditata"
            size="small"
            type="number"
            value={contractInformation.sumaCreditata}
            style={{ width: "100%" }}
            onChange={(event) => onChangeContractInformation(event)}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <TextField
            label={Vocabulary.restOfPayment}
            variant="outlined"
            name="restDePlata"
            size="small"
            type="number"
            value={contractInformation.restDePlata}
            style={{ width: "100%" }}
            onChange={(event) => onChangeContractInformation(event)}
          />
        </Grid>
        <Grid item xs={12} md={12} xl={12} style={{ marginTop: 30 }}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              {Vocabulary.technicalCondition}
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="verificariStareTehnica"
              defaultValue={contractInformation.verificariStareTehnica}
              onChange={(event) => onChangeContractInformation(event)}
            >
              <FormControlLabel
                value={Vocabulary.askForDiagnosis}
                control={<Radio />}
                label={Vocabulary.askForDiagnosis}
              />
              <FormControlLabel
                value={Vocabulary.doesNotAskForDiagnosis}
                control={<Radio />}
                label={Vocabulary.doesNotAskForDiagnosis}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <input
        type="hidden"
        name="priceDifference"
        id="priceDifference"
        value={priceDifference}
      />
      {genericAutocompleteModel.open && (
        <GenericAutocompleteModal
          open={genericAutocompleteModel.open}
          onClose={() =>
            setGenericAutocompleteModel({
              ...genericAutocompleteModel,
              open: false,
              modelForFields: new AutocompleteModel(),
            })
          }
          title={genericAutocompleteModel.title}
          data={genericAutocompleteModel.data}
          id={genericAutocompleteModel.id}
          columns={genericAutocompleteModel.columns}
          validationSchema={genericAutocompleteModel.validationSchema}
          hiddenFieldsArray={genericAutocompleteModel.hiddenFieldsArray}
          modelForFields={genericAutocompleteModel.modelForFields}
          onEdit={(value, tableMeta: any) => {
            setGenericAutocompleteModel({
              ...genericAutocompleteModel,
              modelForFields: tableMeta.tableData[tableMeta.rowIndex],
            });
          }}
        >
          <></>
        </GenericAutocompleteModal>
      )}
    </>
  );
}
