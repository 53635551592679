import {Typography } from "@mui/material";

export default function Total(props: any) {

  const getTotal = (data: any) => {
    let total=0
    data.forEach((value: any) => {
       total+=parseFloat(value?.cost)
    });
    const totalFloat=total.toFixed(2);
    return totalFloat
  };
  return (
    <div style={{ marginRight: 10,marginTop: 5, float: "right"}}>
        {props?.data[0]?.cost?
        <Typography style={{ width:"100%",fontSize: 25, color: "#4449" }}>Total: {getTotal(props.data)} Lei</Typography>:null}

    </div>
  );
}
