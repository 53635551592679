/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { urlEnum } from "../../Utils/urlEnum";
import { Alert } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { updateData } from "../../Services/updateData";

type EmailFormProps = {
  clientID: number;
  to: string;
  subject: string;
  message: string;
  classes?: any;
  onEmailSent: () => void;
  doc?:any;
  firmaId?:any;
};

type EmailFormState = {
  to: string;
  subject: string;
  message: string;
  loading: boolean;
  success: any;
};

function EmailForm(props: EmailFormProps) {
  const {
    clientID,
    to,
    subject,
    message,
    classes,
    doc,
    firmaId,
    onEmailSent,
  } = props;
  const [state, setState] = React.useState<EmailFormState>({
    to: to || "",
    subject: subject || "",
    message: message || "",
    loading: false,
    success: null,
  });

  const contentRef: any = React.createRef();

  let success;
  if (state.success !== null) {
    success = (
      <Alert
        style={{ float: "right" }}
        severity={state.success ? "success" : "error"}
        className={classes.alert}
      >
        {state.success
          ? Vocabulary.sendEmailSuccess
          : Vocabulary.sendEmailError}
      </Alert>
    );
  }
  /**
   *
   */
  function sendEmail() {
    setState({ ...state, loading: true });
    updateData(`${urlEnum.clients}/sendMail/${clientID}/${doc}/${firmaId}`, {
      to: state.to,
      subject: state.subject,
      message: state.message,
    })
      .then((response: any) => {
        if (response.errors) {
          setState({ ...state, loading: false, success: false });
        } else {
          setState({ ...state, loading: false, success: true });
        }
        setTimeout(onEmailSent, 3000);
      })
      .catch(() => {
        setState({ ...state, loading: false, success: false });
      });
  }

  /**
   *
   */
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newState: any = Object.assign({}, state);
    newState[event.target.name] = event.target.value;
    setState(newState);
  }

  /**
   *
   * @param event
   */
  function handleBlurContentEditable(
    event: React.FocusEvent<HTMLInputElement>
  ) {
    const newMessage = event.currentTarget.innerHTML;
    setState({ ...state, message: newMessage });
  }

  return (
    <div className={classes.wrapper}>
      <ValidatorForm onSubmit={sendEmail}>
        <TextValidator
          required={true}
          fullWidth
          id="to"
          label={Vocabulary.to}
          onChange={handleChange}
          name="to"
          value={state.to}
          style={{ marginBottom: 10 }}
          validators={["required"]}
          errorMessages={[Vocabulary.fieldRequired]}
        />
        <TextValidator
          required={true}
          id="subject"
          fullWidth
          label={Vocabulary.subject}
          onChange={handleChange}
          name="subject"
          value={state.subject}
          validators={["required"]}
          errorMessages={[Vocabulary.fieldRequired]}
        />
        <div
          id="message"
          ref={contentRef}
          className={classes.message}
          contentEditable={true}
          dangerouslySetInnerHTML={{ __html: state.message }}
          onBlur={handleBlurContentEditable}
        ></div>
        <br />
        <br />
        {success}
        <Button
          classes={{ root: classes.sendBtn }}
          variant="contained"
          type="submit"
        >
          {Vocabulary.send}
          {state.loading ? (
            <CircularProgress
              color="secondary"
              classes={{ root: classes.loading }}
            />
          ) : (
            ""
          )}
        </Button>
      </ValidatorForm>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      position: "absolute",
      width: "96%",
      height: 460,
      bottom: 0,
      left: 0,
      padding: 20,
      background: "#f0f0f0",
      [theme.breakpoints.down("md")]: {
        height: "100%",
        boxShadow: "none",
      },
    },
    loading: {
      width: "20px !important",
      height: "20px !important",
      marginLeft: 10,
    },
    message: {
      height: 180,
      overflowY: "scroll",
      background: "rgb(255,255,255,0.7)",
      padding: 10,
      marginTop: 10,
      marginBottom: 10,
      [theme.breakpoints.down("md")]: {
        maxHeight: 250,
      },
    },
    sendBtn: {
      float: "left",
      color: "#fff",
      background: "#ff6633",
    },
    alert: {
      padding: 4,
    },
  });

export default withStyles(styles, { withTheme: true })(EmailForm);
