import { Typography } from "@mui/material";
import { Vocabulary } from "./Vocabulary";

export default function NotFoundPage() {
  return (
    <div style={{ display:"flex"}}>
      <Typography style={{ width: "100%", fontSize: 25, color: "#4449", textAlign:"center",marginTop:"5%" }}>
        {Vocabulary.noAcces}
      </Typography>
    </div>
  );
}
