import { Autocomplete, Button, Divider, Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import { Controller } from "react-hook-form";
import { isMobile } from "../../../../Utils/utils";
import {
  tipDocument,
  tipDocumente,
} from "../../../../Utils/Enums/TipDocumente";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { anafFormatDate } from "../../../../Utils/formatDate";
import styles from "../../../../Styles/progressAction.module.css";

export default function DocumenteTransport(props: any) {
  const { control, deleteDocument, addDocument, documents, defaultValue } = props;

  const getLabel = (option: any, document: any) => {
    const val = tipDocumente?.find((doc) => {
      return doc.id === document.tipDocument;
    });
    return val?.denumire || option.denumire;
  };

  const renderDocuments = () => {
    const docs = documents();
    const defaultValues = defaultValue ? defaultValue():null;
    return docs.map((document: any, documentIndex: any) => (
      <div key={documentIndex}>
        <Grid
          container
          spacing={isMobile() ? 1 : 3}
          style={{ marginTop: 5 }}
          key={documentIndex}
        >
          <Grid item md={3} xs={12}>
            <Controller
              name={`documenteTransport.${documentIndex}.tipDocument`}
              control={control}
              defaultValue={10}
              render={({
                field: { ref, value, onChange, ...field },
                fieldState: { error },
              }) => (
                <Autocomplete
                  freeSolo={false}
                  style={{
                    marginBottom: 15,
                  }}
                  size="small"
                  disableClearable
                  disablePortal
                  getOptionLabel={(option: tipDocument) =>
                    getLabel(option, document)
                  }
                  renderOption={(props, option: any) => {
                    return <li {...props}>{option.denumire}</li>;
                  }}
                  id={`documenteTransport.${documentIndex}.tipDocument`}
                  {...field}
                  // onChange={(event, value: tipDocument) =>
                  //   field.onChange(value.id)
                  // }
                  onChange={(event, value: tipDocument) => {
                    onChange(value?.id || ""); // Update form value to the selected option's cod
                  }}
                  value={
                    tipDocumente?.find((option) => option.id == value) ||
                    undefined
                  }
                  options={tipDocumente}
                  renderInput={(params) => (
                    <TextField
                      error={!!error}
                      helperText={error?.message}
                      label={Vocabulary.tipDocument}
                      name={`documenteTransport.${documentIndex}.tipDocument`}
                      type="search"
                      inputRef={ref}
                      {...params}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Controller
              name={`documenteTransport.${documentIndex}.numarDocument`}
              control={control}
              defaultValue={undefined}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  fullWidth
                  style={{
                    marginBottom: 15,
                  }}
                  value={value}
                  error={!!error}
                  helperText={error?.message}
                  id={`documenteTransport.${documentIndex}.numarDocument`}
                  label={`${Vocabulary.numarDocument}`}
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Controller
              name={`documenteTransport.${documentIndex}.dataDocument`}
              control={control}
              defaultValue={defaultValues ? defaultValues[documentIndex]?.dataDocument : null}
              render={({
                field: { ref, onChange, value, ...field },
                fieldState: { error },
              }) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    value={value || null}
                    className={styles.datePTF}
                    format={anafFormatDate}
                    onChange={(date) => onChange(date)}
                    slotProps={{
                      textField: {
                        size: "small",
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                    label={Vocabulary.dataDocument}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              verticalAlign: "middle",
              marginTop: -24,
            }}
          >
            <Button
              variant="contained"
              style={{ marginTop: 10, color: "white" }}
              color="primary"
              onClick={() => deleteDocument(documentIndex)}
            >
              {Vocabulary.sterge}
            </Button>
          </Grid>
        </Grid>
        <Divider />
      </div>
    ));
  };

  return (
    <div>
      <fieldset
        style={{
          border: "1px solid #0000001f",
          borderRadius: 10,
          marginTop: 10,
        }}
      >
        <legend
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "0rem 0.8rem",
          }}
        >
          {Vocabulary.documenteTransport}
        </legend>
        <Button
          variant="contained"
          style={{ marginTop: 10, color: "white" }}
          color="primary"
          onClick={() => addDocument()}
        >
          {Vocabulary.addDocument}
        </Button>
        {renderDocuments()}
      </fieldset>
    </div>
  );
}
