/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import GenericModal from "../../Modal/GenericModal";
import { Vocabulary } from "../../Utils/Vocabulary";
import { CacheContext } from "../../Context/cacheContext";
import { useContext, useState } from "react";
import { isMobile } from "../../Utils/utils";
import { updateData } from "../../Services/updateData";
import { ToastContainer, toast } from "react-toastify";
import { urlEnum } from "../../Utils/urlEnum";

type AllocatedCarOrderProps = {
  open: boolean;
  carOrderId: number | null;
  onClose: (shouldRefetch: boolean) => void;
};
export default function AllocatedCarOrder(props: AllocatedCarOrderProps) {
  const { open, carOrderId, onClose } = props;
  const notifyError = (message: string) => toast.error(message);
  const [user, setUser] = useState<any>(null);
  const cacheContext = useContext(CacheContext);

  /**
   *
   * @param event
   * @param value
   */
  function handleChangeAutocomplete(event: any, value: any) {
    setUser(value);
  }

  /**
   *
   */
  function handleClose(shouldRefetch: boolean) {
    setUser(null);
    onClose(shouldRefetch);
  }

  function allocatedCarOrder() {
    if (!user) {
      notifyError(Vocabulary.selectUser);
      return;
    }
    updateData(
      `${urlEnum.carOrders}/allocateCarOrder/${carOrderId}/${
        user.id ? user.id : user
      }`
    ).then(() => {
      handleClose(true);
    });
  }
  return (
    <>
      <ToastContainer />
      <GenericModal
        open={open}
        onClose={() => handleClose(false)}
        title={Vocabulary.assignToAUser}
      >
        <Autocomplete
          id="users"
          freeSolo={false}
          disablePortal
          getOptionLabel={(option: any) => option.nume}
          size="small"
          options={cacheContext.cache.users}
          value={user}
          onChange={(event: any, newValue: any) =>
            handleChangeAutocomplete(event, newValue)
          }
          sx={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={Vocabulary.assignToAUser}
              variant="outlined"
              size="small"
            />
          )}
        />
        <Grid container spacing={isMobile() ? 1 : 4} style={{ marginTop: 10 }}>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleClose(false)}
              fullWidth
            >
              {Vocabulary.cancel}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={allocatedCarOrder}
              style={{ color: "white" }}
            >
              {Vocabulary.save}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
    </>
  );
}
