/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Button,
  Grid,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import MUIDataTable from "mui-datatables";
import GenericModal from "../../Modal/GenericModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { urlEnum } from "../../Utils/urlEnum";
import { getData } from "../../Services/getData";
import { Delete, Edit } from "@mui/icons-material";
import { multipleDelete } from "../../Services/multipleDelete";
import { isMobile } from "../../Utils/utils";
import FaceIcon from "@mui/icons-material/Face";
import { CacheContext } from "../../Context/cacheContext";
import ShippingModal from "./ShippingModal";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { tableLayoutOnMobile } from "../../Utils/Constants";
import SmallCloseIcon  from '@mui/icons-material/Close';
import { deleteData } from "../../Services/deleteData";

type State = {
  shipping: Array<ShippingData>;
  shippingNumber: number;
  page: number;
  perPage: number;
  search: string | null;
  order: string;
  filter: string | null;
};
export type ShippingData = {
  id?: number | undefined;
  name: string;
  culoare: string;
  tip_shipping: string;
};
export default function Shipping(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const cacheContext = useContext(CacheContext);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedIds, setDeletedIds] = useState<Array<number>>([]);

  const [editedShipping, setEditedShipping]: any = useState(null);
  // console.log(editedShipping);
  const [state, setState] = useState<State>({
    shipping: new Array<ShippingData>(),
    shippingNumber: 0,
    page: 0,
    perPage: 10,
    search: null,
    order: "asc",
    filter: null,
  });

  /**
   *
   * @param {*} event
   * @param {*} newValue
   */

  /**
   * Get shipping
   */
  useEffect(() => {
    const search = location.search.split("?");
    const newState = {
      shipping: new Array<ShippingData>(),
      shippingNumber: 0,
      page: 0,
      perPage: 10,
      search: null,
      order: "asc",
      filter: null,
    } as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1] - 1;
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    getShipping(newState.page, newState.perPage, newState.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  function getShipping(page: number, perPage: number, search?: string | null) {
    let url = `${urlEnum.shipping}/${page}/${perPage}`;
    if (search) {
      url = `${urlEnum.shipping}/${page}/${perPage}/${search}`;
    }
    getData(url)
      .then((res) => {
        setState({
          ...state,
          shipping: res.data.shippings,
          shippingNumber: res.data.shippingsNumber,
          page: page,
          perPage: perPage,
          search: search ? search : null,
        });
      })
      .catch((err) => {
        //
      });
  }

  const onDelete = (vehicul:string, nrVehicul: number, idShipping: number ) => {
    const url = `${urlEnum.removeShipping}/${vehicul}/${nrVehicul}/${idShipping}`;
    deleteData(url);
    const search = location.search.split("?");
    const newState = {
      shipping: new Array<ShippingData>(),
      shippingNumber: 0,
      page: 0,
      perPage: 10,
      search: null,
      order: "asc",
      filter: null,
    } as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1] - 1;
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    getShipping(newState.page, newState.perPage, newState.search);
  };

  /**
   *
   */
  const shippingHeader = [
    {
      label: `${Vocabulary.name}`,
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: "Soferi",
      name: "drivers",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRender: (rowData: any, index: any) => {
          return (
            <>
              {rowData.map((value: any) => (
                <Tooltip title={`${Vocabulary.phone}:${value.phoneNumber}`}>
                  <Chip
                    key={value.id}
                    icon={<FaceIcon />}
                    label={value.name}
                    style={{
                      margin: 5,
                      backgroundColor: "#4442",
                    }}
                  />
                </Tooltip>
              ))}
            </>
          );
        },
      },
    },

    {
      label: "Numere telefon soferi",
      name: "drivers",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRender: (rowData: any, index: any) => {
          return (
            <>
              {rowData.map((value: any) => (
                <Chip
                  key={value.id}
                  icon={<LocalPhoneIcon />}
                  label={value.phoneNumber}
                  style={{
                    margin: 5,
                    backgroundColor: "#f836",
                  }}
                />
              ))}
            </>
          );
        },
      },
    },
    {
      label: `${Vocabulary.ntVehicul}`,
      name: "nrVehicul",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRender: (rowData: any, index: any) => {
          return (
            <>
              {JSON.parse(rowData)?.map((value: any) => (
                <Chip
                  key={value.id}
                  label={value}
                  style={{
                    margin: 5,
                    backgroundColor: "#4442",
                  }}
                  onDelete={() => onDelete("nrVehicul",value, index.rowData[2][0].pivot.id_shipping)}
                  deleteIcon={<SmallCloseIcon  />}
                />
              ))}
            </>
          );
        },
      },
    },
    {
      label: `${Vocabulary.nrRemorca}`,
      name: "nrRemorca",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRender: (rowData: any, index: any) => {
          return (
            <>
              {JSON.parse(rowData)?.map((value: any) => (
                <Chip
                  key={value.id}
                  label={value}
                  style={{
                    margin: 5,
                    backgroundColor: "#4442",
                  }}
                  onDelete={() => onDelete("nrRemorca",value, index.rowData[2][0].pivot.id_shipping)}
                  deleteIcon={<SmallCloseIcon  />}
                />
              ))}
            </>
          );
        },
      },
    },
    {
      label: "",
      name: "",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (rowIndex: number, value: any) => {
          return (
            <>
              <div style={{ marginTop: -10, marginBottom: -10 }}>
                <ToggleButtonGroup exclusive aria-label="text alignment">
                  <Tooltip title={Vocabulary.edit}>
                    <ToggleButton
                      onClick={() => {
                        props.setOpenEditModal(true);
                        setEditedShipping(state.shipping[rowIndex]);
                      }}
                      value="center"
                      aria-label="centered"
                    >
                      <Edit />
                    </ToggleButton>
                  </Tooltip>
                  {state.shipping[rowIndex].name !== "Administrator" ? (
                    <Tooltip title={Vocabulary.delete}>
                      <ToggleButton
                        onClick={() => {
                          setDeletedIds([
                            state.shipping[rowIndex].id as number,
                          ]);
                          setOpenDeleteModal(true);
                        }}
                        value="center"
                        aria-label="centered"
                      >
                        <Delete />
                      </ToggleButton>
                    </Tooltip>
                  ) : null}
                </ToggleButtonGroup>
              </div>
            </>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {

    return {
      responsive: tableLayoutOnMobile,
      viewColumns: false,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      fixedHeader: true,
      count: state.shippingNumber,
      rowsPerPage: state.perPage,
      page: state.page,
      search: false,
      serverSide: true,
      sort: true,
      textLabels: {
        // selectedRows: {
        //   text: "selectate",
        //   delete: "Sterge",
        //   deleteAria: "Sterge liniile selectate",
        // },
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: Vocabulary.pagination,
      },
      onRowsDelete: (rowsDeleted: any, newData: any) => {
        const ids = new Array<number>();
        rowsDeleted.data.forEach((row: any) => {
          ids.push(state.shipping[row.dataIndex].id as number);
        });
        setDeletedIds(ids);
        setOpenDeleteModal(true);
      },
      onChangePage: (page: number) => {
        setState({ ...state, page: page });
        if (state.search)
          navigate(
            `?settings=shipping?page=${page + 1}?perPage=${
              state.perPage
            }?search=${state.search}`
          );
        else
          navigate(
            `?settings=shipping?page=${page + 1}?perPage=${state.perPage}`
          );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        setState({ ...state, perPage: numberOfRows });
        if (state.search)
          navigate(
            `?settings=shipping?page=${1}?perPage=${numberOfRows}?search=${
              state.search
            }`
          );
        else navigate(`?settings=shipping?page=${1}?perPage=${numberOfRows}`);
        window.scrollTo(0, 0);
      },
    };
  }

  /**
   * Close delete modal
   */
  function onCloseDeleteModal() {
    setOpenDeleteModal(false);
    setDeletedIds([]);
  }

  /**
   * Delete shipping
   */
  function deleteShipping() {
    const data = { data: { ids: deletedIds } };
    multipleDelete(urlEnum.shipping, data)
      .then((response) => {
        onCloseDeleteModal();
        cacheContext.setCache({
          ...cacheContext.cache,
          shipping: response.data.shipping,
        });
        getShipping(state.page, state.perPage, state.search);
      })
      .catch((err) => {
        onCloseDeleteModal();
      });
  }
  return (
    <div>
      <div>
        <MUIDataTable
          title={""}
          data={state.shipping}
          columns={shippingHeader}
          options={getTableOptions()}
        />
      </div>
      <ShippingModal
        open={props.openEditModal}
        title={
          editedShipping ? Vocabulary.editShipping : Vocabulary.addNewShipping
        }
        editedShipping={editedShipping}
        onClose={(shouldRefetch) => {
          props.setOpenEditModal(false);
          setEditedShipping(null);
          if (shouldRefetch) {
            getShipping(state.page, state.perPage, state.search);
          }
        }}
      ></ShippingModal>

      <GenericModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        title={`${Vocabulary.deleteShipping}`}
      >
        <p style={{ textAlign: "center", fontSize: 20 }}>
          {Vocabulary.deleteShippingMessage}
        </p>
        <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onCloseDeleteModal}
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={deleteShipping}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
    </div>
  );
}
