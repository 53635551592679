import { Vocabulary } from "../Vocabulary";
import RequestPageIcon from "@mui/icons-material/RequestPage";

export const ROUTER_MANAGE_COSTURI_ADMINISTRATIVE = {
  routes: ["/", "/administrativeCosts", "*"],
};

export const MAIN_SLIDER_MANAGE_COSTURI_ADMINISTRATIVE = [{
  name: Vocabulary.costsName,
  path: "/administrativeCosts",
  icon: <RequestPageIcon />,
  orderId: 5,
}];

export const VEHICLE_OPTIONS_MANAGE_COSTURI_ADMINISTRATIVE = [];

export const DINAMIC_TABS_MANAGE_COSTURI_ADMINISTRATIVE = { 
  // add: true 
};

export const MAIN_TABLE_MANAGE_COSTURI_ADMINISTRATIVE = null;
