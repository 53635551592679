import { Vocabulary } from "../Vocabulary";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

export const ROUTER_ACCES_LOGS = {
  routes: ["/", "/logs", "*"],
};

export const MAIN_SLIDER_ACCES_LOGS = [{
  name: Vocabulary.logsName,
  path: "/logs",
  icon: <AssignmentIndIcon />,
  orderId: 6,
}];

export const VEHICLE_OPTIONS_ACCES_LOGS = [];

export const DINAMIC_TABS_ACCES_LOGS = null;

export const MAIN_TABLE_ACCES_LOGS = null;
