import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
// import ConstructionIcon from "@mui/icons-material/Construction";

import { Vocabulary } from "../Vocabulary";

export const ROUTER_DASHBOARD = {
  routes: [
    "/",
    "/vehiclesDashboard",
    "/vehiclesDashboard/vehicleOptions/:id?",
    "/vehiclesDashboard/vehicleDashboard/:id?",
    "*",
  ],
};
export const MAIN_SLIDER_DASHBOARD = [
  {
    name: Vocabulary.vehicles,
    path: "/vehiclesDashboard",
    icon: <DirectionsCarIcon />,
    orderId: 1,
  },
];

export const VEHICLE_OPTIONS_DASHBOARD = [];

export const DINAMIC_TABS_DASHBOARD = {
  addVehicles: true,
};

export const MAIN_TABLE_DASHBOARD = {
  // route: "vehicleService",
};
export const DASHBOARD_FINANCIAL_DEPARTMENT = [1, 2, 3, 4];

export const LOGIN_PAGE_DASHBOARD = "/vehiclesDashboard";

export const VEHICLE_MAIN_INFO_DASHBOARD = {};
