import { Vocabulary } from "../Vocabulary";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

export const ROUTER_HOMEPAGE = {
  routes: ["/", "/home", "*",'/salaries/user/:id?/:month?/:year?'],
};

export const MAIN_SLIDER_HOMEPAGE = [
  {
    name: Vocabulary.dashboard,
    path: "/home",
    icon: <HomeRoundedIcon />,
    orderId: 0,
  },
];

export const VEHICLE_OPTIONS_HOMEPAGE = [];

export const DINAMIC_TABS_HOMEPAGE = {
  // add: true
};

export const MAIN_TABLE_HOMEPAGE = null;
