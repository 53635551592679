/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { Button, Grid, InputLabel, Menu, MenuItem } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { CacheContext } from "../Context/cacheContext";
import { updateData } from "../Services/updateData";
import { urlEnum } from "../Utils/urlEnum";
import { Vocabulary } from "../Utils/Vocabulary";
import GenericModal from "../Modal/GenericModal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { europeanDate } from "../Utils/formatDate";
import { VehiclesStatus } from "../Utils/autocompleteEnum";
import { VehicleContext } from "../Context/vehicleContext";
import { VehicleStatusModel } from "../Models/VehicleModel";
import { tipStatusVehicule } from "../Utils/Constants";
import { getData } from "../Services/getData";
import { isMobile } from "../Utils/utils";

export type VehicleStatusProps = {
  showPhysicalStatus?: boolean; // i useed this so that on mobile to render selling and physical status sepparately, but keep the already existing logic
  showSellingStatus?: boolean;
};

export default function VehicleStatus(props: VehicleStatusProps) {
  const vehicleContext: any = useContext(VehicleContext);
  const cacheContext = useContext(CacheContext) as any;
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openMenu, setOpenMenu] = useState({
    physical: false,
    selling: false,
  });
  const actualDate: any = new Date();
  const [date, setDate] = useState(moment(actualDate));
  const [statusArray, setStatusArray] = useState<any>({
    physicalStatuses: [],
    sellingStatuses: [],
  });

  const [status, setStatus]: any = useState({
    physicalStatus: {},
    sellingStatus: {},
  });

  //set statuses
  useEffect(() => {
    if (cacheContext.cache.length !== 0) {
      getData(
        `/${urlEnum.status_id}/getByType/${tipStatusVehicule.vehicleCategory}`
      ).then((res: any) => {
        const arrayIds = res.data.data
          ?.filter((status: any) => status.nume === Vocabulary.physicalStock)
          .flatMap((status: any) => JSON.parse(status.children_ids) || []); // Flatten children_ids

        const array = cacheContext.cache.vehicleStatus.filter((status: any) =>
          arrayIds.includes(status.id)
        );
        const sellingStatuses = cacheContext.cache.vehicleStatus.filter(
          (status: any) =>
            // status.nume !== VehiclesStatus.received &&
            status.nume !== VehiclesStatus.reserved &&
            status.nume !== VehiclesStatus.advancePaid &&
            status.nume !== VehiclesStatus.contractToSign &&
            status.nume !== VehiclesStatus.sold &&
            status.nume !== VehiclesStatus.paid &&
            status.nume !== VehiclesStatus.unpaid &&
            status.nume !== VehiclesStatus.postOnSite &&
            !arrayIds.includes(status.id)
        );
        setStatusArray({
          ...statusArray,
          physicalStatuses: array,
          sellingStatuses: sellingStatuses,
        });
      });
    }
  }, [cacheContext.cache]);

  //set status for vehicle
  useEffect(() => {
    if (
      vehicleContext?.state?.vehicle &&
      Object.keys(vehicleContext?.state?.vehicle).length !== 0
    ) {
      // selling status
      const status = cacheContext.cache.vehicleStatus.find(
        (status: VehicleStatusModel) =>
          status.id === vehicleContext?.state?.vehicle?.status_id
      );
      const physicalStatus = cacheContext.cache.vehicleStatus.find(
        (status: VehicleStatusModel) =>
          status.id === vehicleContext?.state?.vehicle?.physical_status_id
      );

      setStatus({
        ...status,
        sellingStatus: status,
        physicalStatus: physicalStatus,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleContext.state?.vehicle]);

  /**
   *
   * @param event
   */
  function handleClick(
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ) {
    if (
      status.nume === VehiclesStatus.reserved ||
      status.nume === VehiclesStatus.advancePaid ||
      status.nume === VehiclesStatus.contractToSign ||
      status.nume === VehiclesStatus.sold
    )
      return null;
    if (!anchorEl) setAnchorEl(event.currentTarget);
  }

  /**
   *
   *
   */
  function updateStatus() {
    const urlVehicles = `${urlEnum.vehicles}/statusVehicles/${vehicleContext?.state?.vehicle?.id}`;
    const data: any = {
      status_id: status?.sellingStatus?.id,
      data_vanzare: null,
      vanzator_id: null,
      data_pregatire_vanzare: null, // Default value
    };

    // Update `data` based on specific conditions
    if (getClickedStatusName() === VehiclesStatus.readyForSale) {
      data.data_pregatire_vanzare = moment(date).format("YYYY-MM-DD");
    } else if (status?.type != "selling") {
      data.physical_status_id = status?.physicalStatus.id; // Use `physical_status_id` for this case
      delete data.status_id; // Remove `status_id` to avoid conflicts
    }

    updateData(urlVehicles, data)
      .then((res) => {
        const updatedData = res.data.data; // Extract `data` for easier reference
        const newVehicleData = {
          ...vehicleContext?.state?.vehicle,
          // Dynamically set either status_id or physical_status_id
          ...(status?.type == "selling"
            ? { status_id: updatedData.status.id }
            : { physical_status_id: updatedData.status.id }),

          data_vanzare: updatedData.data_vanzare,
          vanzator: updatedData.vanzator,
        };
        vehicleContext.updateVehicle(newVehicleData);
        setOpenStatusModal(false);
      })
      .catch((error) => {
        setOpenStatusModal(false);
      });
  }

  /**
   *
   * @param st
   */
  function handleChange(st?: any) {
    if (st) {
      setOpenStatusModal(true);
      if (openMenu.selling) {
        setStatus({ ...status, type: "selling", sellingStatus: st });
      } else setStatus({ ...status, physicalStatus: st });
    }
    setAnchorEl(null);
    setOpenMenu({ ...openMenu, physical: false, selling: false });
  }

  function handleDateChange(date?: any) {
    setDate(moment(date));
  }

  /**
   * Determine which status to check based on openMenu
   * @returns the name of the status that was clicked
   */
  const getClickedStatusName = () => {
    // Determine which status to check based on openMenu
    const currentStatus = openMenu.selling
      ? status.sellingStatus
      : status.physicalStatus;
    return currentStatus?.name;
  };

  /**
   *
   * @returns physical status component
   */
  const renderPhysicalStatus = () => {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor:
            status?.physicalStatus?.culoare ?? "rgb(153 153 153)",
          borderRadius: isMobile() ? "10px" : "4px 4px 0 0",
          padding: 10,
        }}
        onClick={(e) => {
          setOpenMenu({ ...openMenu, physical: true });
          handleClick(e);
          setAnchorEl(e.currentTarget);
        }}
      >
        <InputLabel
          style={{
            marginRight: 20,
            color: "#fff",
            textShadow: "1px 1px 2px #000",
          }}
        >
          {Vocabulary.physicalStatus.toUpperCase()}:
        </InputLabel>
        <Button
          variant="contained"
          size="large"
          style={{
            color: "#fff",
            boxShadow: "none",
            textShadow: "1px 2px 2px #000",
            padding: 0,
            lineHeight: 0,
            backgroundColor:
              status?.physicalStatus?.culoare ?? "rgb(153 153 153)",
          }}
        >
          {status?.physicalStatus?.nume}
        </Button>
      </div>
    );
  };

  /**
   *
   * @returns  selling status component
   */
  const renderSellingStatus = () => {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: status?.sellingStatus?.culoare,
          padding: 10,
          borderRadius: isMobile() ? "10px" : "4px 4px 0 0",
          marginBottom: isMobile() ? "0.5em" : "0",
        }}
        onClick={(e) => {
          setOpenMenu({ ...openMenu, selling: true });
          handleClick(e);
          setAnchorEl(e.currentTarget);
        }}
      >
        {" "}
        <InputLabel
          style={{
            marginRight: 20,
            color: "#fff",
            textShadow: "1px 1px 2px #000",
          }}
        >
          {Vocabulary.status.toUpperCase()}:
        </InputLabel>
        <Button
          variant="contained"
          size="large"
          style={{
            backgroundColor: status?.sellingStatus?.culoare,
            color: "#fff",
            boxShadow: "none",
            textShadow: "1px 2px 2px #000",
            padding: 0,
            lineHeight: 0,
          }}
        >
          {status?.sellingStatus?.nume}
        </Button>
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          display: isMobile() ? "block" : "grid",
          alignItems: "center",
          gridTemplateColumns: "1fr 1fr",
          gap: "2em",
          width: "auto",
        }}
      >
        {props?.showSellingStatus && renderSellingStatus()}
        {props?.showPhysicalStatus && renderPhysicalStatus()}
        <Menu
          open={openMenu.physical || openMenu.selling}
          anchorEl={anchorEl}
          onClose={() => {
            handleChange();
          }}
        >
          {(openMenu.physical
            ? statusArray?.physicalStatuses
            : statusArray?.sellingStatuses
          )?.map((stEnum: any, index: number) => (
            <MenuItem
              key={`${stEnum.id}-${stEnum.nume}`}
              onClick={() => handleChange(stEnum)}
            >
              {stEnum?.nume.toUpperCase()}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <GenericModal
        open={openStatusModal}
        onClose={() => {
          setOpenStatusModal(false);
        }}
        title={Vocabulary.saveStatusMessage}
      >
        <p style={{ textAlign: "center", fontSize: 20 }}>
          {Vocabulary.statusMessage}
        </p>
        <Grid container spacing={4} style={{ padding: 10 }}>
          {getClickedStatusName() === VehiclesStatus.readyForSale ? (
            <Grid item md={12} xs={12} xl={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label={Vocabulary.dataPregatireVanzare}
                  slotProps={{ textField: { style: { width: "100%" } } }}
                  defaultValue={moment(actualDate)}
                  onChange={handleDateChange}
                  format={europeanDate}
                />
              </LocalizationProvider>
            </Grid>
          ) : null}
          <Grid item md={6} xs={12}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpenStatusModal(false);
              }}
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={updateStatus}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
    </>
  );
}
