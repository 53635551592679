import { Vocabulary } from "../Vocabulary";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { localUrlEnum } from "../urlEnum";
import MessageIcon from "@mui/icons-material/Message";
import PriceChangeIcon from "@mui/icons-material/PriceChange";

export const ROUTER_CLIENT_MESSAGES = {
  routes: [
    "/",
    "/vehiclesDashboard/vehicleDashboard/:id?",
    "/tasksDashboard",
    localUrlEnum.reports,
    localUrlEnum.clientMessages,
    localUrlEnum.financingRequests,
    "*",
  ],
};

export const MAIN_SLIDER_CLIENT_MESSAGES = [
  {
    name: Vocabulary.clientMessages,
    path: localUrlEnum.clientMessages,
    icon: <MessageIcon />,
    orderId: 10,
  },
  {
    name: Vocabulary.financingRequests,
    path: localUrlEnum.financingRequests,
    icon: <PriceChangeIcon />,
    orderId: 11,
  },
];

export const VEHICLE_CLIENT_MESSAGES = [
  {
    name: Vocabulary.dashboard,
    path: `/vehiclesDashboard/vehicleDashboard/`,
    icon: <DashboardIcon />,
    id: "vehicleDashboard",
    orderNumber: 0,
  },
];

export const VEHICLES_CLIENT_MESSAGES = [1, 2, 3, 4];

export const DINAMIC_TABS_CLIENT_MESSAGES = null;

export const MAIN_TABLE_CLIENT_MESSAGES = null;