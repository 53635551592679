/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Grid } from "@mui/material";
import GenericModal from "../../Modal/GenericModal";
import { Vocabulary } from "../../Utils/Vocabulary";
import useResetContextState from "../../Hooks/useResetContextState";
import { useEffect } from "react";

type GenerateDocProps = {
  openGenerateDoc: boolean;
  onCloseGenerateDocModal: () => void;
  generateDoc: () => void;
  children: any;
  title: string;
};

export default function GenerateDoc(props: GenerateDocProps) {
  const { openGenerateDoc, onCloseGenerateDocModal, generateDoc, title } = props;
  const resetContextState = useResetContextState();

  useEffect(() => {
    if(props.openGenerateDoc === true){
      resetContextState.setInitialState();
    }
  }, [props.openGenerateDoc]);

  return (
    <GenericModal
      open={openGenerateDoc}
      onClose={() => {
        if(openGenerateDoc){
          resetContextState.resetState();
        }
        onCloseGenerateDocModal();
      }
      }
      title={title}
    >
      <Grid container spacing={3} style={{ marginTop: 10 }}>
        {props.children}
        <Grid item xs={6}>
          <Button
            size="large"
            onClick={onCloseGenerateDocModal}
            variant="contained"
            color="secondary"
            fullWidth
          >
            {Vocabulary.no}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={generateDoc}
            style={{ color: "white" }}
          >
            {Vocabulary.yes}
          </Button>
        </Grid>
      </Grid>
    </GenericModal>
  );
}
