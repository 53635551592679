/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import DinamicTabs from "../DinamicTabs";
import Status from "../Status/Status";
import { useLocation, useNavigate } from "react-router-dom";
import { localUrlEnum } from "../../Utils/urlEnum";
import { ThemeProvider } from "@emotion/react";
import { getMuiTheme } from "../../Utils/globalStyleTables";
import style from "../../Styles/tableFilter.module.css";
import PlatformSettings from "./PlatformSettings";
import Roles from "./Roles";
import Permissions from "./Permissions";
import Shipping from "./Shipping";
import Companies from "./Companies";
import NotificationsSettings from "./NotificationsSettings";
import WebsiteIntegration from "./WebsitesIntegration";
import SalaryCOnfiguration from "./SalaryConfiguration";

export default function MainSettings() {
  const [value, setValue]: any = useState(0);
  const navigate = useNavigate();
  const [openEditModal, setOpenEditModal] = useState(false);
  const location = useLocation();

  /**
   * Get function
   */
  useEffect(() => {
    const search = location.search.split(/[?&]/);
    const newState = {
      status: [],
      statusNumber: 0,
      page: 0,
      perPage: 10,
      search: null,
      order: "asc",
      filter: null,
    } as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1] - 1;
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    setValue(getPageOption(newState.settings));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   * @param event
   * @param newValue
   */
  const handleChange = (event: any, newValue: number) => {
    setOpenEditModal(false);
    setValue(newValue);
    navigate(`?settings=${localUrlEnum.settings[newValue]}`);
  };

  /**
   *
   * @param item
   * @returns
   */
  const getSettingsPage = (item: number) => {
    switch (item) {
      case 0:
        return (
          <div style={{ position: "relative", zIndex: 50 }}>
            <PlatformSettings />
          </div>
        );
      case 1:
        return <SalaryCOnfiguration />;
      case 2:
        return (
          <Status
            openEditModal={openEditModal}
            setOpenEditModal={setOpenEditModal}
          />
        );
      case 3:
        return (
          <Roles
            openEditModal={openEditModal}
            setOpenEditModal={setOpenEditModal}
            key={value}
          />
        );

      case 4:
        return (
          <Permissions
            openEditModal={openEditModal}
            setOpenEditModal={setOpenEditModal}
          />
        );

      case 5:
        return (
          <Shipping
            openEditModal={openEditModal}
            setOpenEditModal={setOpenEditModal}
          />
        );
      case 6:
        return (
          <Companies
            openEditModal={openEditModal}
            setOpenEditModal={setOpenEditModal}
          />
        );
      case 7:
        return (
          <WebsiteIntegration
            openEditModal={openEditModal}
            setOpenEditModal={setOpenEditModal}
          />
        );
      case 8:
        return (
          <NotificationsSettings
            openEditModal={openEditModal}
            setOpenEditModal={setOpenEditModal}
          />
        );
      default:
        return (
          <div style={{ position: "relative", zIndex: 50 }}>
            <PlatformSettings />
          </div>
        );
    }
  };

  /**
   *
   * @param item
   * @returns
   */
  const getAddButton = (item: number) => {
    switch (item) {
      case 0:
        return true;

      case 2:
        return false;

      case 3:
        return false;

      case 4:
        return true;
      case 5:
        return false;
      case 6:
        return false;
      case 8:
        return false;
      default:
        return true;
    }
  };

  /**
   *
   * @param item
   * @returns
   */
  const getSaveButton = (item: number) => {
    switch (item) {
      case 0:
        return true;
      case 1:
        return true;
      case 2:
        return false;

      case 3:
        return false;

      case 4:
        return false;
      case 5:
        return false;
      case 6:
        return false;
      default:
        return false;
    }
  };

  /**
   *
   * @param item
   * @returns
   */
  const getPageOption = (item: string) => {
    switch (item) {
      case "settings":
        return 0;
      case "salaryConfigurations":
        return 1;
      case "statusVehicle":
        return 2;
      case "roles":
        return 3;
      case "permissions":
        return 4;

      case "shipping":
        return 5;
      case "companies":
        return 6;
      case "websitesIntegration":
        return 7;
      case "notifications":
        return 8;
      default:
        return 0;
    }
  };

  return (
    <div className={style.containerTable}>
      <ThemeProvider theme={getMuiTheme()}>
        <DinamicTabs
          key={value + 2}
          hideAddNew={getAddButton(value)}
          hideSave={getSaveButton(value)}
          value={value}
          handleChangeTab={handleChange}
          labels={[
            Vocabulary.appSettings,
            Vocabulary.salaryConfiguration,
            Vocabulary.status,
            Vocabulary.roles,
            Vocabulary.permissionsName,
            Vocabulary.shipping,
            Vocabulary.companies,
            Vocabulary.websitesIntegration,
            Vocabulary.notifications,
          ]}
          openModalWindow={() => setOpenEditModal(true)}
          addNewLabel={Vocabulary.add}
          saveIdButton="settingsPlatform"
        ></DinamicTabs>
        {getSettingsPage(value)}
      </ThemeProvider>
    </div>
  );
}
