/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import GenericModal from "../../Modal/GenericModal";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import DeleteIcon from "@mui/icons-material/Delete";

import { isMobile } from "../../Utils/utils";
import { useContext, useEffect, useState } from "react";
import { CompanyModel, Delegat, NrDocs } from "../../Models/Models";
import { urlEnum } from "../../Utils/urlEnum";

import { postData } from "../../Services/postData";
import { DropzoneArea } from "material-ui-dropzone";
import { CacheContext } from "../../Context/cacheContext";

type StatusProps = {
  open: boolean;
  title: string;
  editedShipping: any | null;
  onClose: (shouldRefetch?: boolean) => void;
};

export default function CompanyModal(props: StatusProps) {
  const { open, title, onClose, editedShipping } = props;
  const [data, setData] = useState(new CompanyModel());
  const [files, setFiles]: any = useState([]);
  const [deleteSucces, setDeleteSucces] = useState(false);
  const cacheContext = useContext(CacheContext) as any;

  useEffect(() => {
    if (editedShipping) {
      const newEditedCompany = Object.assign({}, editedShipping);
      if (editedShipping.delegat === null) {
        newEditedCompany.delegat = new Delegat();
      }
      if (editedShipping.nrDocs === null) {
        newEditedCompany.nrDocs = new NrDocs();
      }
      setData(newEditedCompany);
    }
  }, [editedShipping]);

  /**
   *
   * @param data
   */
  function localClose() {
    onClose(false);
    setData(new CompanyModel());
    setDeleteSucces(false);
  }

  /**
   *
   * @param data
   */
  async function saveCompany() {
    const formData: any = new FormData();
    formData.append("nume_firma", data.nume_firma);
    formData.append("adresa", data.adresa);
    formData.append("CIF", data.CIF);
    formData.append("nrRegCom", data.nrRegCom);
    formData.append("telefon", data.telefon);
    formData.append("banca", data.banca);
    formData.append("IBAN", data.IBAN);
    formData.append("email", data.email);
    formData.append("delegat", JSON.stringify(data.delegat));
    formData.append("nrDocs", JSON.stringify(data.nrDocs));
    formData.append("serieDocs", JSON.stringify(data.serieDocs));

    if (files?.length > 0) {
      formData.append("logoPath", files[0]);
    } else if (!deleteSucces && data?.logoPath?.length > 0) {
      formData.append("logoPath", data.logoPath);
    } else {
      formData.append("logoPath", null);
    }

    if (editedShipping) {
      const url = `${urlEnum.firma_achizitie_r}/${editedShipping.id}`;
      postData(url, formData)
        .then((res) => {
          cacheContext.setCache({
            ...cacheContext.cache,
            company: res.data.company,
          });
          setData(new CompanyModel());
          onClose(true);
          setDeleteSucces(false);
          setFiles([]);
        })
        .catch(() => {
          setData(new CompanyModel());
          onClose(false);
          setDeleteSucces(false);
          setFiles([]);
        });
    } else {
      const url = `/${urlEnum.firma_achizitie_r}`;
      postData(url, formData)
        .then((res) => {
          cacheContext.setCache({
            ...cacheContext.cache,
            company: res.data.company,
          });
          setData(new CompanyModel());
          onClose(true);
          setFiles([]);
        })
        .catch(() => {
          setData(new CompanyModel());
          onClose(false);
          setFiles([]);
        });
    }
  }

  /**
   *
   * @param {*} files
   */
  function handleChangeDropFile(files: any) {
    if (files?.length !== 0) {
      setFiles(files);
    }
  }

  /**
   *
   * @param event
   */
  const handleChangeTextFields = (event: any) => {
    const newCompany: any = Object.assign({}, data);
    const nameParts = event.target.name.split(".");
    if (nameParts?.length === 1) {
      newCompany[nameParts[0]] = event.target.value;
    } else {
      let submodel = newCompany;
      for (let i = 0; i < nameParts?.length - 1; i++) {
        if (nameParts[i].indexOf("[") > 0) {
          const name = nameParts[i].substring(0, nameParts[i].indexOf("["));
          const index = parseInt(
            nameParts[i].substring(
              nameParts[i].indexOf("[") + 1,
              nameParts[i].indexOf("]")
            )
          );
          submodel = submodel[name][index];
        } else {
          submodel = submodel[nameParts[i]];
        }
      }
      submodel[nameParts[nameParts?.length - 1]] = event.target.value;
    }
    setData(newCompany);
  };

  return (
    <GenericModal open={open} onClose={() => localClose()} title={title}>
      <ValidatorForm onSubmit={saveCompany}>
        <Grid container spacing={isMobile() ? 1 : 4} style={{ marginTop: 5 }}>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="nume_firma"
              label={Vocabulary.companyName}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.nume_firma}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="adresa"
              label={Vocabulary.address}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.adresa}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="CIF"
              label={Vocabulary.CIF}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.CIF}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="nrRegCom"
              label={Vocabulary.regCom}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.nrRegCom}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="telefon"
              label={Vocabulary.phone}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.telefon}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="email"
              label={Vocabulary.email}
              type="email"
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.email}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="banca"
              label={Vocabulary.bank}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.banca}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="IBAN"
              label={Vocabulary.IBAN}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.IBAN}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <div style={{ marginTop: -10, marginBottom: -30 }}>
              <Typography
                style={{
                  marginBottom: -8,
                  marginLeft: 0,
                  backgroundColor: "#fff",
                  padding: 5,
                  color: "#757575",
                  zIndex: 100,
                }}
              >
                {Vocabulary.nrDocumente}
              </Typography>
              <Divider style={{ width: "100%" }} />
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="nrDocs.nrProforma"
              label={Vocabulary.nrProforma}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.nrDocs?.nrProforma}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="nrDocs.nrContract"
              label={Vocabulary.nrContract}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.nrDocs?.nrContract}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <div style={{ marginTop: -10, marginBottom: -30 }}>
              <Typography
                style={{
                  marginBottom: -8,
                  marginLeft: 0,
                  backgroundColor: "#fff",
                  padding: 5,
                  color: "#757575",
                  zIndex: 100,
                }}
              >
                {Vocabulary.serieDocumente}
              </Typography>
              <Divider style={{ width: "100%" }} />
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="serieDocs.serieProforma"
              label={Vocabulary.serieProforma}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.serieDocs?.serieProforma}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="serieDocs.serieContract"
              label={Vocabulary.serieContract}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.serieDocs?.serieContract}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <div style={{ marginTop: -10, marginBottom: -30 }}>
              <Typography
                style={{
                  marginBottom: -8,
                  marginLeft: 0,
                  backgroundColor: "#fff",
                  padding: 5,
                  color: "#757575",
                  zIndex: 100,
                }}
              >
                {Vocabulary.legalRepresentative}
              </Typography>
              <Divider style={{ width: "100%" }} />
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="delegat.nume"
              label={Vocabulary.nume}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.delegat?.nume}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="delegat.prenume"
              label={Vocabulary.firstName}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.delegat?.prenume}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="delegat.CNP"
              label={Vocabulary.CNP}
              fullWidth
              validators={[
                "required",
                "matchRegexp:^[1,2,5,6,7,8].*",
                "matchRegexp:^.{13}$",
                // "matchRegexp:^.{3}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])",
              ]}
              errorMessages={[
                Vocabulary.requiredField,
                Vocabulary.firstNumberCNP,
                Vocabulary.numberCNP,
                // Vocabulary.invalidCNP,
              ]}
              value={data.delegat?.CNP}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextValidator
              name="delegat.seria"
              label={Vocabulary.seria}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.delegat?.seria}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextValidator
              name="delegat.numar"
              label={Vocabulary.number}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.delegat?.numar}
              onChange={handleChangeTextFields}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <div style={{ marginTop: -10, marginBottom: -30 }}>
              <Typography
                style={{
                  marginBottom: -8,
                  marginLeft: 0,
                  backgroundColor: "#fff",
                  padding: 5,
                  color: "#757575",
                  zIndex: 100,
                }}
              >
                {Vocabulary.logo}
              </Typography>
              <Divider style={{ width: "100%" }} />
            </div>
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            {!deleteSucces && data?.logoPath?.length > 0 ? (
              <div style={{ display: "flex" }}>
                <div style={{ marginTop: 10, marginLeft: 20 }}>
                  <img
                    src={`/api/${data.logoPath}`}
                    alt="No file"
                    style={{
                      position: "relative",
                      top: 5,
                      paddingRight: 5,
                      height: 150,
                    }}
                  />
                </div>
                <DeleteIcon
                  onClick={() => {
                    setDeleteSucces(true);
                    setFiles([]);
                  }}
                  style={{
                    backgroundColor: "#ff6633",
                    color: "#fff",
                    padding: 5,
                    borderRadius: 7,
                    cursor: "pointer",
                  }}
                ></DeleteIcon>
              </div>
            ) : (
              <div style={{ textDecoration: "none" }}>
                <DropzoneArea
                  acceptedFiles={["image/*,application/*"]}
                  //   dropzoneClass={style.dropzoneArea}
                  maxFileSize={30000000} //30 MB
                  filesLimit={1}
                  showAlerts={false}
                  onChange={handleChangeDropFile}
                  onDelete={() => setFiles([])}
                />
              </div>
            )}
          </Grid>
          <Grid
            container
            spacing={isMobile() ? 1 : 4}
            style={{ margin: 10, marginTop: 10 }}
          >
            <Grid item md={6} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => localClose()}
                fullWidth
              >
                {Vocabulary.cancel}
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                style={{ color: "white" }}
              >
                {Vocabulary.save}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
    </GenericModal>
  );
}
