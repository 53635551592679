/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import { updateData } from "../../Services/updateData";
import { postData } from "../../Services/postData";
import { urlEnum } from "../../Utils/urlEnum";
import GenericModal from "../../Modal/GenericModal";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Autocomplete, Button, Grid } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
// import { tipStatusVehicule } from "../../Utils/autocompleteEnum";

import { isMobile } from "../../Utils/utils";
import PermisionsForEachRole from "./PermisionsForEachRole";
import { CacheContext } from "../../Context/cacheContext";

type StatusProps = {
  open: boolean;
  title: string;
  editedRoles: any;
  permissions: any;
  onClose: (shouldRefetch?: boolean) => void;
};

export default function Role(props: StatusProps) {
  const initialState = {
    name: "",
    description: "",
    permissions: [],
    departmentPermission: "",
    departmentPermissions: [],
  };
  const cacheContext = useContext(CacheContext);
  const { open, title, editedRoles, onClose, permissions } = props;
  const [data, setData] = useState(initialState);
  const [filteredData, setFilteredData]: any = useState([]);
  const [isDepartmentOptions, setIsDepartmentOptions]: any = useState([]);

  // const [permissions, setPermissions]:any = useState({
  //   id: "",
  //   name: "",
  // });
  useEffect(() => {
    if (!editedRoles) {
      setFilteredData(handleChangeEmpty(permissions));
      setIsDepartmentOptions(handleIsDepartmentOptions(permissions));
    }
  }, [permissions, editedRoles]);

  useEffect(() => {
    if (editedRoles) {
      setData(editedRoles);
      handleChangeDepartmentPermission(
        editedRoles,
        handleSelectedDepartmentOptions(editedRoles?.permissions)
      );
      setFilteredData(
        handleChangeStatusType(permissions, editedRoles.permissions)
      );
    } else {
      setData(initialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedRoles]);

  function handleChangePermissions(data: any) {
    const newObj = Object.assign([], filteredData);
    newObj[data]["isUsed"] = !newObj[data]["isUsed"];
    setFilteredData(newObj);
  }

  function handleChangeEmpty(completeData: any) {
    const newArray = Object.assign([], completeData);
    newArray.forEach((element: any) => {
      element.isUsed = true;
    });
    return newArray;
  }

  function handleIsDepartmentOptions(completeData: any) {
    const newArray: any = [];
    completeData.forEach((element: any) => {
      if (element.isDepartment === 1) newArray.push(element);
    });
    return newArray;
  }

  function handleSelectedDepartmentOptions(completeData: any) {
    const newArray: any = [];
    completeData.forEach((element: any) => {
      if (element.isDepartment === 1) newArray.push(element);
    });
    return newArray;
  }

  /**
   *
   * @param event
   */
  function handleChangeTextFields(event: any) {
    setData({ ...data, [event.target.name]: event.target.value });
  }

  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeDepartmentPermission(event: any, newValue: any) {
    if (editedRoles) {
      const newObject = Object.assign({}, editedRoles);
      newObject.departmentPermissions = newValue;
      setData(newObject);
    } else {
      const newObject = Object.assign({}, data);
      newObject.departmentPermissions = newValue;
      setData(newObject);
    }
  }

  function excludeDepartmentFunctions(data: any) {
    const newArray: any = [];
    const newObject = Object.assign([], data);
    newObject.forEach((element: any) => {
      if (element.isDepartment !== 1) newArray.push(element);
    });
    return newArray;
  }

  /**
   *
   */
  function handleChangeStatusType(completeData: any, data: any) {
    let newArray = [];
    let res = [];
    const filteredData = excludeDepartmentFunctions(data);
    res = completeData.filter((el: any) => {
      return !filteredData.find((obj: any) => {
        return el.name === obj.name;
      });
    });
    res.forEach((element: any) => {
      element.isUsed = true;
    });
    filteredData.forEach((element: any) => {
      element.isUsed = false;
    });
    newArray = filteredData.concat(res);
    return newArray;
  }

  /**
   * Save status
   */
  function saveRoles() {
    const ids: any = [];
    const newData: any = Object.assign({}, data);
    filteredData.forEach((element: any) => {
      if (element.isUsed === false) {
        ids.push(element.id);
      }
    });
    data.departmentPermissions.forEach((element: any) => {
      ids.push(element.id);
    });
    newData.permissions = ids;

    if (editedRoles) {
      updateData(`${urlEnum.roles}/${editedRoles.id}`, newData)
        .then((response) => {
          setData(initialState);
          cacheContext.setCache({
            ...cacheContext.cache,
            roles: response.data.roles,
          });
          onClose(true);
        })
        .catch((error) => {
          setData(initialState);
          onClose(false);
        });
    } else {
      postData(urlEnum.roles, newData)
        .then((response) => {
          setData(initialState);
          cacheContext.setCache({
            ...cacheContext.cache,
            roles: response.data.roles,
          });
          onClose(true);
        })
        .catch((error) => {
          setData(initialState);
          onClose(false);
        });
    }
  }

  return (
    <GenericModal
      open={open}
      onClose={() => onClose(false)}
      title={title}
      buttons={
        <Grid container spacing={isMobile() ? 1 : 4}>
          <Grid
            item
            md={12}
            xs={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              style={{ margin: 5 }}
              variant="contained"
              color="secondary"
              onClick={() => onClose(false)}
              fullWidth
            >
              {Vocabulary.cancel}
            </Button>

            <Button
              variant="contained"
              fullWidth
              type="submit"
              form="rolesForm"
              style={{ color: "white", margin: 5 }}
            >
              {Vocabulary.save}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <ValidatorForm
        id="rolesForm"
        onSubmit={saveRoles}
        style={{ marginTop: 10 }}
      >
        <Grid
          container
          spacing={isMobile() ? 1 : 4}
          style={{ position: "relative" }}
        >
          <Grid
            item
            md={12}
            xs={12}
            style={{
              gap: 10,
              zIndex: 15,
              backgroundColor: "#fff",
              marginLeft: 8,
              display: "flex",
            }}
          >
            <TextValidator
              name="name"
              style={{ marginLeft: -3, marginBottom: 10 }}
              label={Vocabulary.name}
              sx={{ width: "100%" }}
              fullWidth
              validators={["required"]}
              disabled={data.name === "Administrator"}
              errorMessages={[Vocabulary.requiredField]}
              value={data.name}
              onChange={handleChangeTextFields}
            />
            <Autocomplete
              id="permisiuneDepartament"
              // freeSolo={false}
              multiple
              getOptionLabel={(option: any) => Vocabulary[option.name]}
              options={isDepartmentOptions}
              fullWidth
              onChange={(event: any, newValue: any) =>
                handleChangeDepartmentPermission(event, newValue)
              }
              value={
                data?.departmentPermissions ? data?.departmentPermissions : []
              }
              getOptionDisabled={(option: any) =>
                !!data?.departmentPermissions?.find(
                  (element: any) => element.name === option.name
                )
              }
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="permisiuneDepartament"
                  label={Vocabulary.permisiuneDepartament}
                  validators={["required"]}
                  errorMessages={[Vocabulary.requiredField]}
                  variant="outlined"
                  value={data.departmentPermissions}
                  onChange={handleChangeDepartmentPermission}
                />
              )}
            />
          </Grid>

          <Grid item md={12} xs={12} style={{ marginTop: 5, zIndex: 10 }}>
            <PermisionsForEachRole
              key={filteredData}
              data={filteredData}
              handleChangePermissions={handleChangePermissions}
            />
          </Grid>
        </Grid>
      </ValidatorForm>
    </GenericModal>
  );
}
