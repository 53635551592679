export interface tipDocument {
  id: number;
  cod: string;
  denumire: string;
}

export const tipDocumente: tipDocument[] = [
  {
    id: 10,
    cod: "CMR",
    denumire: "CMR",
  },
  {
    id: 20,
    cod: "FCT",
    denumire: "Factură",
  },
  {
    id: 30,
    cod: "AIM",
    denumire: "Aviz de însoțire a mărfii",
  },
  {
    id: 9999,
    cod: "ALT",
    denumire: "Altele",
  },
];
