import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import style from "../../Styles/secondVehiclesDashboard.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import HistoryComponent from "../HistoryComponent";
import { useContext, useMemo } from "react";
import { VehicleContext } from "../../Context/vehicleContext";
import ReusableStepper from "./ReusableStepper";
import ProgressAction1 from "../ProgressActions/ProgressAction1";
import ProgressAction2 from "../ProgressActions/ProgressAction2";
import ProgressAction5 from "../ProgressActions/ProgressAction5";
import withRole from "../../Utils/withRole";
import Wrapper from "../Wrapper";
import { disableStep, isMobile, PurchaseTypes } from "../../Utils/utils";
import ProgressAction3 from "../ProgressActions/ProgressAction3";
import ProgressAction4 from "../ProgressActions/ProgressAction4";
import VehicleStatus from "../VehicleStatus";
import { RolesEnum } from "../../Utils/Constants";
import CancelSell from "../ProgressActions/CancelSell";
import { CacheContext } from "../../Context/cacheContext";
import { VehiclesStatus } from "../../Utils/autocompleteEnum";
import TotalCosts from "./TotalCosts";
import VehicleSideInfo from "../VehicleSideInfo";
import SaleDuration from "./SaleDuration";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function SecondVehiclesDashboard(props: any) {
  const vehicleContext: any = useContext(VehicleContext);
  const logs = vehicleContext?.state.vehicle?.status_logs;
  const cacheContext: any = useContext(CacheContext);

  const soldStatusID = useMemo(() => {
    const sold = cacheContext.cache.vehicleStatus.find(
      (item: any) => item.nume === VehiclesStatus.sold
    );
    return sold.id ? sold.id : 0;
  }, [cacheContext.cache.vehicleStatus]);
  const roles = localStorage.getItem("roles") || "";

  const acquisitionsSteps = [
    {
      name: Vocabulary.invoiceProvider,
      children: props?.info?.includes(1) ? <ProgressAction1 /> : null,
      status: disableStep("1.1", vehicleContext?.state?.vehicle?.indexes),
    },
    {
      name: Vocabulary.paymentOP,
      children: props?.info?.includes(2) ? <ProgressAction2 /> : null,
      status: disableStep("2.1", vehicleContext?.state?.vehicle?.indexes),
    },
  ];

  if (
    vehicleContext?.state?.vehicle?.tip_achizitie_r?.nume !==
    Vocabulary.buyBackLabel
  )
    acquisitionsSteps.push({
      name: Vocabulary.shipping,
      children:
        vehicleContext?.state?.vehicle?.tip_achizitie_r?.nume !==
        Vocabulary.buyBackLabel ? (
          <ProgressAction5 />
        ) : null,
      status: vehicleContext?.state?.vehicle?.indexes?.includes("0.1"),
    });

  const sellingSteps = [
    {
      name: Vocabulary.reserve_advance,
      children: props?.info?.includes(3) ? <ProgressAction3 /> : null,
      status: disableStep(
        vehicleContext?.state?.vehicle?.isLeasing === PurchaseTypes.totalFunding
          ? "3.2"
          : "3.4",
        vehicleContext?.state?.vehicle?.indexes
      ),
    },
    {
      name: Vocabulary.clientInvoice,
      children: props?.info?.includes(4) ? (
        <>
          <ProgressAction4 />
          {vehicleContext.state.vehicle?.status_id === soldStatusID ? (
            <>{roles.includes(RolesEnum.admin) ? <CancelSell /> : null}</>
          ) : null}
        </>
      ) : null,
      status: disableStep("4.3", vehicleContext?.state?.vehicle?.indexes),
    },
  ];

  return (
    <div className={style.mainContainer}>
      <div className={style.headingLabel}>
        <Typography>{Vocabulary.buyingAndSellingLabel}</Typography>
        {!isMobile() && <Typography>{Vocabulary.physicalLocation}</Typography>}
      </div>
      <VehicleStatus
        showPhysicalStatus={!isMobile()}
        showSellingStatus={true}
      />
      <div className={style.dividedContainers}>
        {/********************* Selling Status ********************/}
        <div className={style.secondContainer}>
          <div className={style.stepsContainer}>
            <Wrapper title={Vocabulary.acquisition}>
              <ReusableStepper steps={acquisitionsSteps} />
            </Wrapper>
            <Wrapper title={Vocabulary.clientInvoice}>
              <ReusableStepper steps={sellingSteps} />
            </Wrapper>
          </div>
        </div>
        {/********************* Physical Status ********************/}
        <div className={style.secondContainer}>
          <div>
            {isMobile() ? (
              <>
                <div className={style.headingLabel}>
                  <Typography>{Vocabulary.physicalLocation}</Typography>
                </div>
                <VehicleStatus showPhysicalStatus={isMobile()} />
                <Accordion
                  style={{
                    zIndex: 10,
                    marginBottom: 10,
                    marginTop: 20,
                    color: "#fff",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "#f63" }}
                  >
                    <Typography>{Vocabulary.vehicleStatusHistory}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <HistoryComponent
                      logs={logs ?? []}
                      createdMessage={Vocabulary.vehicleCreated}
                      statusChangedMessage={Vocabulary.carStatusChanged}
                      firstTimeStatusSetMessage={Vocabulary.carStatusSet}
                    />
                  </AccordionDetails>
                </Accordion>
              </>
            ) : (
              <Wrapper title={Vocabulary.vehicleStatusHistory}>
                <HistoryComponent
                  logs={logs ?? []}
                  createdMessage={Vocabulary.vehicleCreated}
                  statusChangedMessage={Vocabulary.carStatusChanged}
                  firstTimeStatusSetMessage={Vocabulary.carStatusSet}
                />
              </Wrapper>
            )}
          </div>
        </div>
      </div>
      <div>
        {isMobile() ? (
          <Accordion
            style={{
              zIndex: 10,
              marginBottom: 10,
              marginTop: 20,
              color: "#fff",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ backgroundColor: "#f63" }}
            >
              <Typography style={{ marginTop: 1 }}>
                {Vocabulary.carDetails}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <VehicleSideInfo />
            </AccordionDetails>
          </Accordion>
        ) : (
          <div style={{ marginTop: 20 }}>
            <VehicleSideInfo />
          </div>
        )}
      </div>
      <SaleDuration />
      <TotalCosts />
    </div>
  );
}

export default withRole(SecondVehiclesDashboard);
