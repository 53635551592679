/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";

import { Vocabulary } from "../../../Utils/Vocabulary";
import { useContext, useEffect, useState } from "react";
import { VehicleContext } from "../../../Context/vehicleContext";

import GenericModal from "../../../Modal/GenericModal";
// import moment from "moment";
import { CacheContext } from "../../../Context/cacheContext";
import { VehiclesStatus } from "../../../Utils/autocompleteEnum";
import { urlEnum } from "../../../Utils/urlEnum";
import useResetContextState from "../../../Hooks/useResetContextState";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ShippingModal from "../../Settings/ShippingModal";
import { postData } from "../../../Services/postData";
import moment from "moment";

type SubProgressInformation5_1Props = {
  disabled?: boolean;
  handleModalReserve: () => void;
  open: boolean;
  shippings: object[];
  refreshShippingList?: any;
};

export default function SubProgressInformation5_1(
  props: SubProgressInformation5_1Props
) {
  const vehicleContext: any = useContext(VehicleContext);
  const cacheContext = useContext(CacheContext) as any;
  const resetContextState = useResetContextState();
  const [openModalClient, setOpenModalClient] = useState(false);

  useEffect(() => {
    if(props.open === true){
      resetContextState.setInitialState();
    }
  }, [props.open]);
  /**
   *
   */
  const handleOpenCloseModal = () => {
    if (props.open) {
      resetContextState.resetState();
    }
    props.handleModalReserve && props.handleModalReserve();
  };

  /**
   *
   * @param param0
   */
  function EditButton({ children, ...other }: any) {
    return (
      <Paper {...other}>
        <IconButton
          onMouseDown={(event) => {
            event.preventDefault();
            setOpenModalClient(true);
          }}
          style={{
            position: "absolute",
            top: -40,
            right: -36,
            margin: 5,
            display: "flex",
            justifyContent: "end",
            backgroundColor: "#FF6633",
            color: "#fff",
            borderRadius: 7,
            zIndex: 100,
          }}
          size="small"
          aria-label="editDropdown"
        >
          <AddCircleIcon fontSize="inherit" />
        </IconButton>
        {children}
      </Paper>
    );
  }

  /**
   *
   */
  async function reserveVehicle() {
    const status = cacheContext.cache?.vehicleStatus?.find(
      (status: any) => status.nume === VehiclesStatus.scheduledTransport
    );
    const localVehicleInfo = {
      ...vehicleContext.state.vehicle,
      indexes: vehicleContext.updateIndex(["0.1"], null),
      modifyBy: vehicleContext.updateModifyBy([{index: "0.1",userId: localStorage.getItem("userName"),date: moment().format("DD-MM-YYYY HH:mm:ss")}]),

    };
    const data = {
      vehicle: localVehicleInfo,
      vehicle_id: vehicleContext.state.vehicle.id,
      status_id: status.id,
      shipping_id: vehicleContext?.state?.vehicle?.shipping_id,
   
    };

    const url = `${urlEnum.reserveForVehicle}`;
    const response = await postData(url, data);
    // update documents
    vehicleContext.updateVehicle(response?.data?.vehicle);

    props.handleModalReserve();
  }

  return (
    <div style={{ margin: 15 }}>
      <GenericModal
        open={props.open}
        onClose={handleOpenCloseModal}
        title={Vocabulary.reserveShippingVehicleMessage}
      >
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} md={12} xl={12}>
            <Autocomplete
              id="shippings"
              freeSolo={false}
              disableClearable
              disablePortal
              PaperComponent={EditButton}
              getOptionLabel={(option: any) => option.name}
              size="small"
              disabled={props.disabled}
              value={props?.shippings?.find(
                (element: any) =>
                  element.id === +vehicleContext?.state?.vehicle?.shipping_id
              )}
              options={props.shippings}
              onChange={(event: any, newValue: any) =>
                vehicleContext.onChangeVehicle({
                  name: "shipping_id",
                  value: newValue.id,
                })
              }
              sx={{ width: "100%" }}
              ListboxProps={{ style: { maxHeight: 150 } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Vocabulary.carrier}
                  variant="outlined"
                  size="small"
                  error={!vehicleContext?.state?.vehilce?.shipping_id}
                  helperText={
                    vehicleContext?.state?.vehicle?.shipping_id
                      ? null
                      : Vocabulary.required
                  }
                />
              )}
            />
          </Grid>
          {/* <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid item xs={12} md={4} xl={4}>
                <DesktopDatePicker
                  disabled={props.disabled}
                  format={europeanDate}
                  label={Vocabulary.pickupDate}
                  value={moment(vehicleContext?.state?.vehicle?.pickupDate)}
                  onChange={(e: any) =>
                    vehicleContext.onChangeVehicleTransportDate(e)
                  }
                  slotProps={{
                    textField: { size: "small", style: { width: "100%" } },
                  }}
                />
                <p
                  style={{
                    fontSize: 12,
                    marginTop: 5,
                    marginLeft: 10,
                    color: "#d32f2f",
                  }}
                >
                  {vehicleContext?.state?.vehicle?.pickupDate
                    ? null
                    : Vocabulary.required}
                </p>
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <DesktopDatePicker
                  disabled={props.disabled}
                  format={europeanDate}
                  label={Vocabulary.estimatedArrivalDate}
                  value={moment(
                    vehicleContext?.state?.vehicle?.estimatedArrivalDate
                  )}
                  onChange={(e: any) =>
                    vehicleContext.onChangeVehicleTransportEstimatedDate(e)
                  }
                  slotProps={{
                    textField: { size: "small", style: { width: "100%" } },
                  }}
                />
                <p
                  style={{
                    fontSize: 12,
                    marginTop: 5,
                    marginLeft: 10,
                    color: "#d32f2f",
                  }}
                >
                  {vehicleContext?.state?.vehicle?.estimatedArrivalDate
                    ? null
                    : Vocabulary.required}
                </p>
              </Grid>
            </LocalizationProvider> */}

          <Grid item xs={6}>
            <Button
              size="large"
              onClick={props.handleModalReserve}
              variant="contained"
              color="secondary"
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={reserveVehicle}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
      <ShippingModal
        open={openModalClient}
        title={"ADAUGA UN TRANSPORTATOR NOU "}
        editedShipping={null}
        onClose={() => {
          props.refreshShippingList();
          setOpenModalClient(false);
        }}
      />
    </div>
  );
}
