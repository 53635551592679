/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThemeProvider } from "@emotion/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  //Checkbox,
  Switch,
  Grid,
  Button,
  FormControlLabel,
  Divider,
} from "@mui/material";
import style from "../../Styles/mainDashboard.module.css";
import DinamicTabs from "../DinamicTabs";
import { getMuiTheme } from "../../Utils/globalStyleTables";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  localUrlEnum,
  urlEnum,
  vehiclesExcelFields,
} from "../../Utils/urlEnum";
import Statistically from "./Statistically";
import VehiclesTable from "./VehiclesTable";
import BrandsTable from "./BrandsTable";
import ModelsTable from "./ModelsTable";
import LocationsTable from "./LocationsTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneIcon from "@mui/icons-material/Tune";
import moment from "moment";
import Filters from "./Filters";
import { getData } from "../../Services/getData";
import { ReportsTab } from "../../Utils/autocompleteEnum";
import GenericModal from "../../Modal/GenericModal";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { download, isMobile } from "../../Utils/utils";

export type FiltersProps = {
  page: number;
  perPage: number;
  reports: string;
  startDate: string;
  endDate: string;
  vehicleStatus: string | null;
  users: string | number;
  company: string | number;
  locations: string | number;
  purchaseType: string | number;
  sort: string;
  tva: string | number;
  periods?: string[] | null;
};
export type StateProps = {
  vehicles: Array<any>;
  vehiclesNumber: number;
};
export type ProfitGraphsProps = {
  profit: Array<{
    month: string;
    profit: number;
    profitColor: string;
  }>;
  keys: Array<string>;
};
export type CostsGraphsProps = {
  vehicleCosts: Array<{
    month: string;
    profit: number;
    profitColor: string;
  }>;
  vehicleCostsAverage: Array<{
    month: string;
    profit: number;
    profitColor: string;
  }>;
  keys: Array<string>;
};
export type VehiclesInfoProps = {
  interestRate: number;
  purchaseValue: number;
  saleDuration: number;
  saleDurationAverage: number;
  saleValue: number;
  totalVehicles: number;
  negativeGrossProfitCount?: number;
  positiveGrossProfitCount?: number;
  totalNegativeGrossProfit?: number;
  totalPositiveGrossProfit?: number;
};
export type CostsInfoProps = {
  additionalCosts: number;
  administrativeCosts: number;
  detailingCosts: number;
  guaranteeCosts: number;
  logisticsCosts: number;
  marketingCosts: number;
  paintCosts: number;
  serviceCosts: number;
  vehicleCosts: number;
  vehicleCostsAverage: number;
};
export type ProfitInfoProps = {
  grossProfit: number;
  grossProfitAverage: number;
  netIncome: number;
  netIncomeAverage: number;
};

let filters = {
  page: 0,
  perPage: 50,
  reports: ReportsTab.statistically,
  startDate: moment().startOf("year").format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
  //vehicleStatus: 8, //aici nu este tocmai bine facut
  vehicleStatus: "",
  users: "",
  company: "",
  locations: "",
  purchaseType: "",
  tva: "",
  sort: "null",
  reset: false,
  periods: [],
};

export default function Reports() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue]: any = useState(0);
  const [openAccordion, setOpenAccordion] = useState(false);
  const [state, setState] = useState<StateProps>({
    vehicles: new Array<any>(),
    vehiclesNumber: 0,
  });
  const [costsGraphs, setCostsGraphs] = useState<CostsGraphsProps>({
    vehicleCosts: [],
    vehicleCostsAverage: [],
    keys: [],
  });
  const [grossProfitGraphs, setGrossProfitGraphs] = useState<ProfitGraphsProps>(
    {
      profit: [],
      keys: [],
    }
  );
  const [vehicleNumberGraphs, setVehicleNumberGraphs] =
    useState<ProfitGraphsProps>({
      profit: [],
      keys: [],
    });
  const [purchaseInfoGraphs, setPurchaseInfoGraphs] =
    useState<ProfitGraphsProps>({
      profit: [],
      keys: [],
    });
  const [saleValueGraphs, setSaleValueGraphs] = useState<ProfitGraphsProps>({
    profit: [],
    keys: [],
  });
  const [netIncomeGraphs, setNetIncomeGraphs] = useState<ProfitGraphsProps>({
    profit: [],
    keys: [],
  });
  const [vehiclesInfo, setVehiclesInfo] = useState<VehiclesInfoProps | null>(
    null
  );
  // const [saleDurationGraphs, setSaleDurationGraphs] =
  //   useState<ProfitGraphsProps>({
  //     profit: [],
  //     keys: [],
  //   });
  const [saleDurationAverageGraphs, setSaleDurationAverageGraphs] =
    useState<ProfitGraphsProps>({
      profit: [],
      keys: [],
    });

  // const [interestRateGraphs, setInterestRateGraphs] =
  //   useState<ProfitGraphsProps>({
  //     profit: [],
  //     keys: [],
  //   });
  const [profitInfo, setProfitInfo] = useState<ProfitInfoProps | null>(null);
  const [costsInfo, setCostsInfo] = useState<CostsInfoProps | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [allChecked, setAllChecked] = useState(true);
  const [excelFields, setExcelFields] = useState(vehiclesExcelFields);
  const [openGraphs, setOpenGraphs] = useState(false);
  moment.locale("Ro");
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const [periods, setPeriods] = useState<any[]>([]);

  //setexcelFields({ test });

  /**
   * Get function
   */
  useEffect(() => {
    const search = location.search.split("?");
    const newFilters = Object.assign({}, filters) as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        else newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    setValue(getPageOption(newFilters.reports));
    newFilters.reset = true; //in this way i ensure the the change of periods only after i get the data from url
    if (
      newFilters.periods &&
      newFilters?.periods != "null" &&
      search?.length > 1
    ) {
      if (!newFilters?.periods?.length) {
        newFilters.periods = null;
        setPeriods([]);
      } else {
        const periods = decodeURIComponent(newFilters.periods);
        newFilters.periods = periods;
        setPeriods(JSON.parse(periods));
      }
    } else {
      newFilters.periods = null;
      setPeriods([]);
    }
    filters = newFilters;
    getInformation(newFilters);
    if (newFilters.reports === ReportsTab.statistically) {
      getDataForGraphs(newFilters);
      openGraphs && getDataForSecodsGraphs(newFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  function openSecondGraphs(open = true) {
    const search = location.search.split("?");
    const newFilters = Object.assign({}, filters) as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        else newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    setValue(getPageOption(newFilters.reports));
    filters = newFilters;
    getInformation(newFilters);
    if (newFilters.reports === ReportsTab.statistically)
      getDataForGraphs(newFilters);
    setOpenGraphs(!openGraphs);
    getDataForSecodsGraphs(newFilters);
  }

  /**
   *
   * @param newFilters
   */
  function getDataForGraphs(newFilters: FiltersProps) {
    getData(
      `${urlEnum.reports}/graphs/${newFilters.startDate}/${
        newFilters.endDate
      }/grossProfit/${newFilters.users ? newFilters.users : null}/${
        newFilters.company ? newFilters.company : null
      }/${newFilters.locations ? newFilters.locations : null}/${
        newFilters.purchaseType ? newFilters.purchaseType : null
      }/${newFilters.tva ? newFilters.tva : null}/${newFilters.periods}`
    ).then((res) => {
      setGrossProfitGraphs({
        profit: res?.data.grossProfit,
        keys: res?.data.keys,
      });
    });
    getData(
      `${urlEnum.reports}/graphs/${newFilters.startDate}/${
        newFilters.endDate
      }/netIncome/${newFilters.users ? newFilters.users : null}/${
        newFilters.company ? newFilters.company : null
      }/${newFilters.locations ? newFilters.locations : null}/${
        newFilters.purchaseType ? newFilters.purchaseType : null
      }/${newFilters.tva ? newFilters.tva : null}/${newFilters.periods}`
    ).then((res) => {
      setNetIncomeGraphs({
        profit: res?.data.netIncome,
        keys: res?.data.keys,
      });
    });
    getData(
      `${urlEnum.reports}/graphs/${newFilters.startDate}/${
        newFilters.endDate
      }/vehicleCosts/${newFilters.users ? newFilters.users : null}/${
        newFilters.company ? newFilters.company : null
      }/${newFilters.locations ? newFilters.locations : null}/${
        newFilters.purchaseType ? newFilters.purchaseType : null
      }/${newFilters.tva ? newFilters.tva : null}/${newFilters.periods}`
    ).then((res) => {
      setCostsGraphs({
        vehicleCosts: res?.data.vehicleCosts,
        vehicleCostsAverage: res?.data.vehicleCostsAverage,
        keys: res?.data.keys,
      });
    });
  }

  function getDataForSecodsGraphs(newFilters: FiltersProps) {
    getData(
      `${urlEnum.reports}/graphs/${newFilters.startDate}/${
        newFilters.endDate
      }/vehiclesNumber/${newFilters.users ? newFilters.users : null}/${
        newFilters.company ? newFilters.company : null
      }/${newFilters.locations ? newFilters.locations : null}/${
        newFilters.purchaseType ? newFilters.purchaseType : null
      }/${newFilters.tva ? newFilters.tva : null}/${newFilters.periods}`
    ).then((res) => {
      setVehicleNumberGraphs({
        profit: res?.data.vehiclesNumber,
        keys: res?.data.keys,
      });
    });
    getData(
      `${urlEnum.reports}/graphs/${newFilters.startDate}/${
        newFilters.endDate
      }/purchaseValue/${newFilters.users ? newFilters.users : null}/${
        newFilters.company ? newFilters.company : null
      }/${newFilters.locations ? newFilters.locations : null}/${
        newFilters.purchaseType ? newFilters.purchaseType : null
      }/${newFilters.tva ? newFilters.tva : null}/${newFilters.periods}`
    ).then((res) => {
      setPurchaseInfoGraphs({
        profit: res?.data.purchaseValue,
        keys: res?.data.keys,
      });
    });
    getData(
      `${urlEnum.reports}/graphs/${newFilters.startDate}/${
        newFilters.endDate
      }/saleValue/${newFilters.users ? newFilters.users : null}/${
        newFilters.company ? newFilters.company : null
      }/${newFilters.locations ? newFilters.locations : null}/${
        newFilters.purchaseType ? newFilters.purchaseType : null
      }/${newFilters.tva ? newFilters.tva : null}/${newFilters.periods}`
    ).then((res) => {
      setSaleValueGraphs({
        profit: res?.data.saleValue,
        keys: res?.data.keys,
      });
    });
    // getData(
    //   `${urlEnum.reports}/graphs/${newFilters.startDate}/${
    //     newFilters.endDate
    //   }/saleDuration/${newFilters.users ? newFilters.users : null}/${
    //     newFilters.company ? newFilters.company : null
    //   }/${newFilters.locations ? newFilters.locations : null}/${
    //     newFilters.purchaseType ? newFilters.purchaseType : null
    //   }/${newFilters.tva ? newFilters.tva : null}`
    // ).then((res) => {
    //   setSaleDurationGraphs({
    //     profit: res?.data.saleDuration,
    //     keys: res?.data.keys,
    //   });
    // });
    // getData(
    //   `${urlEnum.reports}/graphs/${newFilters.startDate}/${
    //     newFilters.endDate
    //   }/interestRate/${newFilters.users ? newFilters.users : null}/${
    //     newFilters.company ? newFilters.company : null
    //   }/${newFilters.locations ? newFilters.locations : null}/${
    //     newFilters.purchaseType ? newFilters.purchaseType : null
    //   }/${newFilters.tva ? newFilters.tva : null}`
    // ).then((res) => {
    //   setInterestRateGraphs({
    //     profit: res?.data.interestRate,
    //     keys: res?.data.keys,
    //   });
    // });
    getData(
      `${urlEnum.reports}/graphs/${newFilters.startDate}/${
        newFilters.endDate
      }/saleDurationAverage/${newFilters.users ? newFilters.users : null}/${
        newFilters.company ? newFilters.company : null
      }/${newFilters.locations ? newFilters.locations : null}/${
        newFilters.purchaseType ? newFilters.purchaseType : null
      }/${newFilters.tva ? newFilters.tva : null}/${newFilters.periods}`
    ).then((res) => {
      setSaleDurationAverageGraphs({
        profit: res?.data.saleDurationAverage,
        keys: res?.data.keys,
      });
    });
  }
  /**
   *
   * @param newFilters
   */
  async function getInformation(newFilters: FiltersProps) {
    if (newFilters.reports === ReportsTab.statistically)
      await getStatistics(newFilters);
    else await getInfoForTables(newFilters);
  }

  /**
   *
   * @param newFilters
   */
  async function getStatistics(newFilters: FiltersProps) {
    getData(
      `${urlEnum.reports}/${newFilters.reports}/${newFilters.startDate}/${
        newFilters.endDate
      }/vehiclesInfo/${newFilters.users ? newFilters.users : null}/${
        newFilters.company ? newFilters.company : null
      }/${newFilters.locations ? newFilters.locations : null}/${
        newFilters.purchaseType ? newFilters.purchaseType : null
      }/${newFilters.tva ? newFilters.tva : null}/${newFilters.periods}`
    ).then((res) => {
      setVehiclesInfo(res?.data.statistics);
    });
    getData(
      `${urlEnum.reports}/${newFilters.reports}/${newFilters.startDate}/${
        newFilters.endDate
      }/profit/${newFilters.users ? newFilters.users : null}/${
        newFilters.company ? newFilters.company : null
      }/${newFilters.locations ? newFilters.locations : null}/${
        newFilters.purchaseType ? newFilters.purchaseType : null
      }/${newFilters.tva ? newFilters.tva : null}/${newFilters.periods}`
    ).then((res) => {
      setProfitInfo(res?.data.statistics);
    });
    getData(
      `${urlEnum.reports}/${newFilters.reports}/${newFilters.startDate}/${
        newFilters.endDate
      }/costs/${newFilters.users ? newFilters.users : null}/${
        newFilters.company ? newFilters.company : null
      }/${newFilters.locations ? newFilters.locations : null}/${
        newFilters.purchaseType ? newFilters.purchaseType : null
      }/${newFilters.tva ? newFilters.tva : null}/${newFilters.periods}`
    ).then((res) => {
      setCostsInfo(res?.data.statistics);
    });
  }

  /**
   *
   * @param newFilters
   */
  async function getInfoForTables(newFilters: FiltersProps) {
    const url = `${urlEnum.reports}/${newFilters.reports}/${newFilters.page}/${
      newFilters.perPage
    }/${newFilters.startDate}/${newFilters.endDate}/${
      JSON.parse(filters.vehicleStatus)?.length
        ? JSON.parse(filters.vehicleStatus)
        : null
    }/${newFilters.users ? newFilters.users : null}/${
      newFilters.company ? newFilters.company : null
    }/${newFilters.locations ? newFilters.locations : null}/${
      newFilters.purchaseType ? newFilters.purchaseType : null
    }/${newFilters.tva ? newFilters.tva : null}/${
      newFilters.sort ? newFilters.sort : null
    }/${newFilters?.periods}`;

    const res = await getData(url);
    setState({
      ...state,
      vehicles: res?.data.vehicles,
      vehiclesNumber: res?.data.vehiclesNumber ? res.data.vehiclesNumber : 0,
    });
  }

  /**
   *
   * @param item
   * @returns
   */
  const getSettingsPage = (item: any) => {
    switch (item) {
      case 0:
        return (
          <Statistically
            grossProfitGraphs={grossProfitGraphs}
            vehicleNumberGraphs={vehicleNumberGraphs}
            netIncomeGraphs={netIncomeGraphs}
            costsGraphs={costsGraphs}
            vehiclesInfo={vehiclesInfo}
            profitInfo={profitInfo}
            costsInfo={costsInfo}
            purchaseInfo={purchaseInfoGraphs}
            saleValueInfo={saleValueGraphs}
            // saleDurationInfo={saleDurationGraphs}
            // interestRateInfo={interestRateGraphs}
            saleDurationAverageInfo={saleDurationAverageGraphs}
            openGraphs={openGraphs}
            openSecondGraphs={openSecondGraphs}
            setOpenGraphs={setOpenGraphs}
          />
        );

      case 1:
        return (
          <VehiclesTable filters={filters} state={state} periods={periods} />
        );

      case 2:
        return <BrandsTable filters={filters} state={state}></BrandsTable>;

      case 3:
        return <ModelsTable filters={filters} state={state}></ModelsTable>;
      case 4:
        return (
          <LocationsTable filters={filters} state={state}></LocationsTable>
        );
      default:
        return (
          <Statistically
            grossProfitGraphs={grossProfitGraphs}
            netIncomeGraphs={netIncomeGraphs}
            costsGraphs={costsGraphs}
            vehiclesInfo={vehiclesInfo}
            profitInfo={profitInfo}
            costsInfo={costsInfo}
            setOpenGraphs={setOpenGraphs}
          />
        );
    }
  };

  /**
   * Export excel
   */
  async function exportExcel() {
    const arr = [];

    for (const key in excelFields) {
      arr.push({ [key]: excelFields[key as keyof typeof excelFields] });
    }

    //enable loading
    let element = document.getElementById("loading");
    element?.classList.remove("loadingDisabled");
    element?.classList.add("loading");
    const url = `${urlEnum.reports}/exportExcel/${filters.startDate}/${
      filters.endDate
    }/${
      JSON.parse(filters.vehicleStatus)?.length
        ? JSON.parse(filters.vehicleStatus)
        : null
    }/${filters.users ? filters.users : null}/${
      filters.company ? filters.company : null
    }/${filters.locations ? filters.locations : null}/${
      filters.purchaseType ? filters.purchaseType : null
    }/${filters.tva ? filters.tva : null}/${JSON.stringify(arr)}/${
      filters.sort
    }/${JSON.stringify(periods)}`;
    const token = localStorage.getItem("access_token");
    const fileName = `Report_${moment().unix()}`;
    if (url)
      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.blob())
        .then((response) => {
          download(
            response,
            fileName,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
          //disable loading
          element = document.getElementById("loading");
          element?.classList.remove("loading");
          element?.classList.add("loadingDisabled");
        });
  }
  /**
   *
   * @param item
   * @returns
   */
  const getPageOption = (item: any) => {
    switch (item) {
      case ReportsTab.statistically:
        return 0;

      case ReportsTab.vehicles:
        return 1;

      case ReportsTab.brands:
        return 2;

      case ReportsTab.models:
        return 3;

      case ReportsTab.administrativeLocations:
        return 4;
      default:
        return 0;
    }
  };

  /**
   *
   * @param event
   * @param newValue
   */
  const handleChange = (event: any, newValue: number) => {
    navigate(
      `?reports=${
        localUrlEnum.reportsTab[newValue]
      }?page=${1}?perPage=${50}?startDate=${filters.startDate}?endDate=${
        filters.endDate
      }?vehicleStatus=${
        filters.vehicleStatus ? filters.vehicleStatus : null
      }?users=${filters.users ? filters.users : null}?company=${
        filters.company ? filters.company : null
      }?locations=${
        filters.locations ? filters.locations : null
      }?purchaseType=${
        filters.purchaseType ? filters.purchaseType : null
      }?tva=${
        filters.tva ? filters.tva : null
      }?sort=null?periods=${JSON.stringify(periods)}`
    );
  };

  /**
   *
   * @param open
   */
  function handleAccordion(open: any) {
    setOpenAccordion(open);
  }

  /**
   *
   * @param e
   */
  function handleChangeStartDate(e: any) {
    const date = moment(e._d).format("YYYY-MM-DD");
    navigate(
      `?reports=${filters.reports}?page=${1}?perPage=${
        filters.perPage
      }?startDate=${date}?endDate=${filters.endDate}?vehicleStatus=${
        filters.vehicleStatus ? filters.vehicleStatus : null
      }?users=${filters.users ? filters.users : null}?company=${
        filters.company ? filters.company : null
      }?locations=${
        filters.locations ? filters.locations : null
      }?purchaseType=${
        filters.purchaseType ? filters.purchaseType : null
      }?tva=${filters.tva ? filters.tva : null}?sort=${
        filters.sort ? filters.sort : null
      }?periods=null`
    );
  }

  /**
   *
   * @param e
   */
  function handleChangeEndDate(e: any) {
    const date = moment(e._d).format("YYYY-MM-DD");
    navigate(
      `?reports=${filters.reports}?page=${1}?perPage=${
        filters.perPage
      }?startDate=${filters.startDate}?endDate=${date}?vehicleStatus=${
        filters.vehicleStatus ? filters.vehicleStatus : null
      }?users=${filters.users ? filters.users : null}?company=${
        filters.company ? filters.company : null
      }?locations=${
        filters.locations ? filters.locations : null
      }?purchaseType=${
        filters.purchaseType ? filters.purchaseType : null
      }?tva=${filters.tva ? filters.tva : null}?sort=${
        filters.sort ? filters.sort : null
      }`
    );
  }

  /**
   *
   * @param e
   */
  function handleChangePeriods(
    e: any,
    index: number | null,
    deleteMode = false
  ) {
    const date = moment(e?._d).format("YYYY-MM-DD");
    const newValue = [...periods];
    if (deleteMode) {
      if (index) newValue.splice(index, 1);
    } else if (index != null) {
      newValue[index] = date;
    }
    navigate(
      `?reports=${filters.reports}?page=${1}?perPage=${
        filters.perPage
      }?startDate=${filters.startDate}?endDate=${
        filters.endDate
      }?vehicleStatus=${
        filters.vehicleStatus ? filters.vehicleStatus : null
      }?users=${filters.users ? filters.users : null}?company=${
        filters.company ? filters.company : null
      }?locations=${
        filters.locations ? filters.locations : null
      }?purchaseType=${
        filters.purchaseType ? filters.purchaseType : null
      }?tva=${filters.tva ? filters.tva : null}?sort=${
        filters.sort ? filters.sort : null
      }?periods=${JSON.stringify(newValue)}`
    );
  }
  /**
   *
   * @param event
   * @param newValue
   */
  function onChangeTextField(event: any, newValue: any, name: string) {
    const newFilters = Object.assign({}, filters) as any;
    if (name == "vehicleStatus") {
      newFilters[name] = newValue ? JSON.stringify(newValue) : "";
    } else newFilters[name] = newValue ? newValue.id : null;
    navigate(
      `?reports=${newFilters.reports}?page=${1}?perPage=${
        newFilters.perPage
      }?startDate=${newFilters.startDate}?endDate=${
        newFilters.endDate
      }?vehicleStatus=${newFilters.vehicleStatus}?users=${
        newFilters.users ? newFilters.users : null
      }?company=${newFilters.company ? newFilters.company : null}?locations=${
        newFilters.locations ? newFilters.locations : null
      }?purchaseType=${
        newFilters.purchaseType ? newFilters.purchaseType : null
      }?tva=${newFilters.tva ? newFilters.tva : null}?sort=${
        newFilters.sort ? newFilters.sort : null
      }?periods=${newFilters.periods}`
    );
  }

  /**
   *
   */
  const handleToggleModal = () => {
    if (!openModal) {
      setAllChecked(true);
      const newExcelFields = excelFields;
      for (const key in newExcelFields) {
        newExcelFields[key as keyof typeof newExcelFields] = true;
      }
      //assign the new array to trigger the checked method from the swith
      setExcelFields({ ...newExcelFields });
    }
    setOpenModal(!openModal);
  };

  // const checkExcelFieldChecked = (value: string): boolean => {
  //   const index = excelFields.findIndex((el) => Object.keys(el)[0] === value);
  //   return index >= 0 ? false : true;
  // };

  return (
    <div>
      <ThemeProvider theme={getMuiTheme()}>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile() ? "column" : "row",
          }}
        >
          {isMobile() ? (
            <Accordion
              expanded={openAccordion}
              onChange={() => handleAccordion(!openAccordion)}
              style={{ zIndex: 10 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <TuneIcon style={{ marginRight: 10 }}></TuneIcon>
                <Typography style={{ marginTop: 1 }}>Filtre </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Filters
                  onChangeTextField={onChangeTextField}
                  handleAccordion={handleAccordion}
                  handleChangeStartDate={handleChangeStartDate}
                  handleChangeEndDate={handleChangeEndDate}
                  exportExcel={exportExcel}
                  data={filters}
                  handleToggleModal={handleToggleModal}
                  periods={periods}
                  setPeriods={setPeriods}
                  currentDate={currentDate}
                  handleChangePeriods={handleChangePeriods}
                />
              </AccordionDetails>
            </Accordion>
          ) : (
            <div className={style.vehicleSlider}>
              <Filters
                onChangeTextField={onChangeTextField}
                handleAccordion={handleAccordion}
                handleChangeStartDate={handleChangeStartDate}
                handleChangeEndDate={handleChangeEndDate}
                exportExcel={exportExcel}
                data={filters}
                handleToggleModal={handleToggleModal}
                periods={periods}
                setPeriods={setPeriods}
                currentDate={currentDate}
                handleChangePeriods={handleChangePeriods}
              />
            </div>
          )}
          <div
            className={style.mainTable}
            style={isMobile() ? {} : { padding: "0px 20px" }}
          >
            <DinamicTabs
              hideAddNew
              hideSave
              value={value}
              key={value}
              handleChangeTab={handleChange}
              labels={[
                Vocabulary.statistically,
                Vocabulary.vehicles,
                Vocabulary.brands,
                Vocabulary.models,
                isMobile()
                  ? Vocabulary.administrativeLocationsMobile
                  : Vocabulary.administrativeLocations,
              ]}
              openModalWindow={() => true}
              saveIdButton="settingsDetails"
            />
            <div style={{ paddingBottom: isMobile() ? 60 : 10 }}>
              {getSettingsPage(value)}
            </div>
          </div>
        </div>
        {openModal && (
          <GenericModal
            maxWidth={600}
            open={openModal}
            onClose={handleToggleModal}
            title={Vocabulary.selectExcelTabs}
            buttons={
              <Button
                variant="outlined"
                style={{
                  color: "#ff6633",
                  borderColor: "#ff6633",
                  marginTop: 5,
                }}
                onClick={exportExcel}
              >
                <CloudDownloadIcon style={{ marginRight: 10 }} />
                {Vocabulary.downloadExcel}
              </Button>
            }
            children={
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <FormControlLabel
                      label={
                        <Typography style={{ fontSize: "1rem" }}>
                          {Vocabulary.all}
                        </Typography>
                      }
                      control={
                        <Switch
                          checked={allChecked}
                          onChange={(e) => {
                            //create a new array with all the values true or false
                            const newExcelFields = excelFields;
                            for (const key in newExcelFields) {
                              newExcelFields[
                                key as keyof typeof newExcelFields
                              ] = e.target.checked;
                            }
                            //assign the new array to trigger the checked method from the swith
                            setExcelFields({ ...newExcelFields });
                            setAllChecked(e.target.checked);
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {" "}
                    <Divider />{" "}
                  </Grid>
                  <Grid item xs={12} md={12}></Grid>

                  {Object.keys(vehiclesExcelFields).map(
                    (value: string, index) => {
                      return (
                        <Grid item xs={12} md={4} key={value}>
                          <FormControlLabel
                            label={
                              <Typography style={{ fontSize: "1rem" }}>
                                {value}
                              </Typography>
                            }
                            control={
                              <Switch
                                className="excelFieldSwitch"
                                checked={Object.values(excelFields)[index]}
                                onChange={(e) => {
                                  setExcelFields({
                                    ...excelFields,
                                    [value]: e.target.checked,
                                  });
                                  if (!e.target.checked) setAllChecked(false);
                                }}
                              />
                            }
                          />
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </>
            }
          />
        )}
      </ThemeProvider>
    </div>
  );
}
