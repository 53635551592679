import {
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  //Paper,
  Box,
  Checkbox,
  Button,
} from "@mui/material";
import GenericModal from "../../Modal/GenericModal";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ChangeEvent, useState } from "react";
import { urlEnum } from "../../Utils/urlEnum";
import { postData } from "../../Services/postData";
import { AUTODELRULATE_AD_LINK } from "../../Utils/Constants";
//import InsertLinkIcon from "@mui/icons-material/InsertLink";

export interface ModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  vehicle: any[] | null;
  setVehicle: (data: any) => void;
  getDataRefresh: () => void;
}

export interface WebsiteModel {
  name: string;
  code: string;
  url: string;
}

export default function PostAdsIntegrationModal(props: ModalProps) {
  const { open, setOpen, vehicle, setVehicle, getDataRefresh } = props;
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const websitesToPostOn: WebsiteModel[] = [
    //aici se adauga celelalte site-uri
    {
      name: Vocabulary.autodelrulate,
      code: "autodelrulate",
      url: urlEnum.postOnSite,
    },
    {
      name: Vocabulary.autovit,
      code: "autovit",
      url: urlEnum.autovit,
    },
    {
      name: Vocabulary.bestauto,
      code: "bestauto",
      url: urlEnum.bestauto,
    },
  ];
  const [newCar, setNewCar] = useState({});
  const [data, setData] = useState({});

  /**
   *
   * @param url
   */
  const postAds = async () => {
    try {
      websitesToPostOn.forEach(async (website: WebsiteModel) => {
        const websiteCode = website?.code;
        if (Object.assign(data)[website.code]) {
          const url = `${website.url}/${
            vehicle ? vehicle["id" as keyof typeof vehicle] : "null"
          }`;
          const isNewCar = Object.assign(newCar)[websiteCode];
          const response = await postData(url, { isNewCar });
          if (response?.status == 200 && response?.data) {
            setVehicle(response?.data?.vehicle);
            setShouldRefresh(true);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  /**
   *
   * @param advertUrlData
   * @returns
   */
  const renderAdvertUrl = (advertUrlData: any) => {
    const advertUrl = JSON.parse(advertUrlData);
    const advertUrlParsed = advertUrl["advert_url"].split(",");
    return advertUrlParsed.map((url: string) => {
      return (
        <a
          style={{
            textDecoration: "none",
            color: "black",
            cursor: "pointer",
            wordBreak: "break-word",
          }}
          rel="noreferrer"
          target="_blank"
          href={url}
        >
          {url}
        </a>
      );
    });
  };

  /**
   *
   */
  const onClose = () => {
    setOpen(false);
    if (shouldRefresh) {
      // sa faca refresh la date doar daca a fost postat un anunt
      getDataRefresh();
      setShouldRefresh(false);
    }
    setNewCar({});
  };

  return (
    <>
      <GenericModal
        open={open}
        onClose={onClose}
        title={Vocabulary.postAd}
        buttons={
          <Grid container spacing={3} style={{ padding: "1rem" }}>
            <Grid item md={6} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={onClose}
              >
                {Vocabulary.cancel}
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ color: "white", height: "95%" }}
                onClick={() => {
                  postAds();
                }}
              >
                {Vocabulary.sync}
              </Button>
            </Grid>
          </Grid>
        }
        children={
          <Grid container spacing={2}>
            {websitesToPostOn.map((website: WebsiteModel) => {
              const advertUrl = vehicle
                ? website?.code == "autodelrulate" &&
                  vehicle["slug" as keyof typeof vehicle]
                  ? JSON.stringify({
                      advert_url:
                        AUTODELRULATE_AD_LINK +
                        vehicle["slug" as keyof typeof vehicle],
                    }) ?? null
                  : vehicle[
                      (website.code.toLowerCase() +
                        "_advert_data") as keyof typeof vehicle
                    ]
                : null;

              const isPostableOnWebsite = vehicle
                ? vehicle[
                    ("is_postable_on_" +
                      website.code.toLowerCase()) as keyof typeof vehicle
                  ]
                : null;
              return (
                <>
                  <Grid item md={12} xs={12}>
                    <Box
                      style={{
                        padding: "1rem",
                        borderRadius: "7px",
                        backgroundColor: "#fff !important",
                        boxShadow:
                          "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                        position: "relative",
                        marginTop: "10px",
                      }}
                    >
                      <div>
                        <FormControlLabel
                          label={
                            <Typography style={{ fontSize: "1rem" }}>
                              {Vocabulary.newCar}
                            </Typography>
                          }
                          control={
                            <Checkbox
                              defaultChecked={false}
                              onClick={(event: any) => {
                                //folosesc obiect sa tin pentru fiecare integrare optiunea selectata
                                setNewCar({
                                  [website.code]: event.target.checked,
                                });
                              }}
                            />
                          }
                        />
                      </div>

                      <FormControlLabel
                        label={
                          <Typography style={{ fontSize: "1.3rem" }}>
                            {website.name}
                          </Typography>
                        }
                        control={
                          <Switch
                            // disabled={!isPostableOnWebsite}
                            //defaultChecked={advertUrl ? true : false}
                            onChange={async (
                              e: ChangeEvent<HTMLInputElement>
                            ) => {
                              setData({
                                ...data,
                                [website.code]: e.target.checked,
                              });
                            }}
                          />
                        }
                      />
                      {
                        //se afiseaza link-ul catre anunt doar daca el exista si nu se poate reposta un nou anunt
                        //adica inca nu a expirat anuntul curent
                        advertUrl && !isPostableOnWebsite && (
                          <>
                            <Typography style={{ fontSize: "1rem" }}>
                              {Vocabulary.advertLink}{" "}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                              }}
                            >
                              {renderAdvertUrl(advertUrl)}
                            </Typography>
                          </>
                        )
                      }
                    </Box>
                  </Grid>
                </>
              );
            })}
          </Grid>
        }
      />
    </>
  );
}
