import { Autocomplete, Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import { Controller } from "react-hook-form";
import { isMobile } from "../../../../Utils/utils";
import {
  nomenclatorJudet,
  nomenclatorJudete,
} from "../../../../Utils/Enums/NomenclatorJudete";

export default function ADR(props: any) {
  const { control, tipLocatie, onChangeADRJudet } = props;
  return (
    <div>
      <Grid container spacing={isMobile() ? 1 : 3}>
        <Grid item md={3} xs={12}>
          <Controller
            name={`locatie.${tipLocatie}.codJudet`}
            control={control}
            defaultValue={33}
            render={({
              field: { ref, value, onChange, ...field },
              fieldState: { error },
            }) => (
              <Autocomplete
                freeSolo={false}
                style={{
                  marginBottom: 15,
                }}
                size="small"
                disableClearable
                disablePortal
                getOptionLabel={(option: nomenclatorJudet) => option.denumire}
                id={`locatie.${tipLocatie}.codJudet`}
                onChange={(event, value: nomenclatorJudet | null) => {
                  onChangeADRJudet(value?.cod);
                  onChange(value?.cod || ""); // Update form value to the selected option's cod
                }}
                value={
                  nomenclatorJudete?.find((option) => option.cod === value || option.cod.toString() === value) ||
                  undefined
                }
                options={nomenclatorJudete}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.judet}
                    name={`locatie.${tipLocatie}.codJudet`}
                    type="search"
                    inputRef={ref}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name={`locatie.${tipLocatie}.orasLocalitate`}
            control={control}
            defaultValue={"Suceava"}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                fullWidth
                style={{
                  marginBottom: 15,
                }}
                value={value}
                error={!!error}
                helperText={error?.message}
                id={`locatie.${tipLocatie}.orasLocalitate`}
                label={`${Vocabulary.oras} / ${Vocabulary.county}`}
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name={`locatie.${tipLocatie}.numar`}
            defaultValue={52}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                fullWidth
                style={{
                  marginBottom: 15,
                }}
                value={value}
                type="number"
                error={!!error}
                helperText={error?.message}
                id={`locatie.${tipLocatie}.numar`}
                label={`${Vocabulary.numar}`}
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name={`locatie.${tipLocatie}.denumireStrada`}
            defaultValue={"Bulevardul Sofia Vicoveanca"}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                fullWidth
                style={{
                  marginBottom: 15,
                }}
                value={value}
                error={!!error}
                helperText={error?.message}
                id={`locatie.${tipLocatie}.denumireStrada`}
                label={`${Vocabulary.denumire} ${Vocabulary.street}`}
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name={`locatie.${tipLocatie}.codPostal`}
            defaultValue={"720285"}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                fullWidth
                style={{
                  marginBottom: 15,
                }}
                value={value}
                error={!!error}
                helperText={error?.message}
                id={`locatie.${tipLocatie}.codPostal`}
                label={`${Vocabulary.codPostal}`}
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name={`locatie.${tipLocatie}.alteInfo`}
            defaultValue={"Showroom Autodel"}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                fullWidth
                style={{
                  marginBottom: 15,
                }}
                value={value}
                error={!!error}
                helperText={error?.message}
                id={`locatie.${tipLocatie}.alteInfo`}
                label={`${Vocabulary.alteInformatii}`}
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
