/* eslint-disable @typescript-eslint/no-explicit-any */
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
// import {  RouterProvider } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { CacheContextProvider } from "./Context/cacheContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LoginPage from "./Components/LoginPage";
import Loading from "./Utils/loading";
import LocalRouter from "./Utils/LocalRouter";
import { componentNames } from "./Utils/Constants";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { VehicleContextProvider } from "./Context/vehicleContext";
import { roRO } from "@mui/material/locale";
import GDPRClient from "./Components/Clients/GDPRClient";
import { FilePreviewContextProvider } from "./Context/dataFileContext";

let theme = createTheme(
  {
    palette: {
      primary: {
        main: "#FF6633",
      },
      secondary: {
        main: "#4D5E80",
      },
      info: {
        main: "#f25",
      },
    },
  },
  roRO
);

theme = createTheme(theme, {
  palette: {
    info: {
      main: theme.palette.secondary.main,
    },
  },
});

const portrait = window.matchMedia("(orientation: portrait)");

portrait.addEventListener("change", function (e) {
  if (e.matches) {
    window.location.reload();
  } else {
    window.location.reload();
  }
});

// window.addEventListener('resize', function () {
//   window.location.reload();
// });

if (window.location.pathname.includes("gdprClient")) {
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <ThemeProvider theme={theme}>
      <CacheContextProvider>
        <ToastContainer />
        <BrowserRouter>
          <Routes>
            <Route path="/gdprClient/:id" element={<GDPRClient />} />
          </Routes>
        </BrowserRouter>
        <Loading />
      </CacheContextProvider>
    </ThemeProvider>
  );
} else {
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <ThemeProvider theme={theme}>
      <CacheContextProvider>
        <ToastContainer />
        {!window.localStorage.getItem("permissions") ? (
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<LoginPage />} />
            </Routes>
          </BrowserRouter>
        ) : (
          <VehicleContextProvider>
            <FilePreviewContextProvider>
              <LocalRouter name={componentNames.LocalRouter}></LocalRouter>
            </FilePreviewContextProvider>
          </VehicleContextProvider>
        )}
        <Loading />
      </CacheContextProvider>
    </ThemeProvider>
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals)
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
