export interface nomenclatorJudet {
  id: number;
  cod: number;
  denumire: string;
}

export const nomenclatorJudete: nomenclatorJudet[] = [
  {
    id: 37,
    cod: 1,
    denumire: "Alba",
  },
  {
    id: 27,
    cod: 2,
    denumire: "Arad",
  },
  {
    id: 15,
    cod: 3,
    denumire: "Argeş",
  },
  {
    id: 3,
    cod: 4,
    denumire: "Bacău",
  },
  {
    id: 31,
    cod: 5,
    denumire: "Bihor",
  },
  {
    id: 32,
    cod: 6,
    denumire: "Bistriţa-Năsăud",
  },
  {
    id: 4,
    cod: 7,
    denumire: "Botoşani",
  },
  {
    id: 38,
    cod: 8,
    denumire: "Braşov",
  },
  {
    id: 9,
    cod: 9,
    denumire: "Brăila",
  },
  {
    id: 10,
    cod: 10,
    denumire: "Buzău",
  },
  {
    id: 28,
    cod: 11,
    denumire: "Caraş-Severin",
  },
  {
    id: 20,
    cod: 51,
    denumire: "Călăraşi",
  },
  {
    id: 33,
    cod: 12,
    denumire: "Cluj",
  },
  {
    id: 11,
    cod: 13,
    denumire: "Constanţa",
  },
  {
    id: 39,
    cod: 14,
    denumire: "Covasna",
  },
  {
    id: 16,
    cod: 15,
    denumire: "Dâmboviţa",
  },
  {
    id: 22,
    cod: 16,
    denumire: "Dolj",
  },
  {
    id: 12,
    cod: 17,
    denumire: "Galaţi",
  },
  {
    id: 21,
    cod: 52,
    denumire: "Giurgiu",
  },
  {
    id: 23,
    cod: 18,
    denumire: "Gorj",
  },
  {
    id: 40,
    cod: 19,
    denumire: "Harghita",
  },
  {
    id: 29,
    cod: 20,
    denumire: "Hunedoara",
  },
  {
    id: 17,
    cod: 21,
    denumire: "Ialomiţa",
  },
  {
    id: 5,
    cod: 22,
    denumire: "Iaşi",
  },
  {
    id: 1,
    cod: 23,
    denumire: "Ilfov",
  },
  {
    id: 34,
    cod: 24,
    denumire: "Maramureş",
  },
  {
    id: 24,
    cod: 25,
    denumire: "Mehedinţi",
  },
  {
    id: 41,
    cod: 26,
    denumire: "Mureş",
  },
  {
    id: 6,
    cod: 27,
    denumire: "Neamţ",
  },
  {
    id: 25,
    cod: 28,
    denumire: "Olt",
  },
  {
    id: 18,
    cod: 29,
    denumire: "Prahova",
  },
  {
    id: 35,
    cod: 30,
    denumire: "Satu Mare",
  },
  {
    id: 36,
    cod: 31,
    denumire: "Sălaj",
  },
  {
    id: 42,
    cod: 32,
    denumire: "Sibiu",
  },
  {
    id: 7,
    cod: 33,
    denumire: "Suceava",
  },
  {
    id: 19,
    cod: 34,
    denumire: "Teleorman",
  },
  {
    id: 30,
    cod: 35,
    denumire: "Timiş",
  },
  {
    id: 13,
    cod: 36,
    denumire: "Tulcea",
  },
  {
    id: 8,
    cod: 37,
    denumire: "Vaslui",
  },
  {
    id: 26,
    cod: 38,
    denumire: "Vâlcea",
  },
  {
    id: 14,
    cod: 39,
    denumire: "Vrancea",
  },
  {
    id: 2,
    cod: 40,
    denumire: "Mun. Bucureşti",
  },
];
