/* eslint-disable @typescript-eslint/no-explicit-any */
import VehicleDashboardTable from "./VehicleDashboardTable";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useContext, useState } from "react";
import { VehicleDashboardTableType } from "../Utils/autocompleteEnum";
import { Vocabulary } from "../Utils/Vocabulary";
import { urlEnum } from "../Utils/urlEnum";
import { VehicleContext } from "../Context/vehicleContext";
import moment from "moment";
import { europeanDate, usDateFormatWithTime } from "../Utils/formatDate";

export default function VehicleGuarantees() {
  const vehicleContext: any = useContext(VehicleContext);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selected, setSelected]: any = useState(null);

  const emptyFields = () => {
    setSelected(null);
  };

  const columns = [
    {
      name: "tipCost",
      label: "Tip cost",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "data_start",
      label: "Data start",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (date: any) => (
          <>
            {date
              ? moment(date, usDateFormatWithTime).format(europeanDate)
              : ""}
          </>
        ),
      },
    },
    {
      name: "data_sfarsit",
      label: "Data finalizare",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (date: any) => (
          <>
            {date
              ? moment(date, usDateFormatWithTime).format(europeanDate)
              : ""}
          </>
        ),
      },
    },
    {
      name: "mentiuni",
      label: "Mentiuni",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "cost",
      label: "Cost",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (cost: string) => (
          <Typography>{cost ? parseFloat(cost).toFixed(2) : 0} Lei</Typography>
        ),
      },
    },

    {
      name: "platit",
      label: "Platit",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (platit: string) => (
          <CircleIcon
            style={{ color: platit === "0" ? "#ff6251" : "#12cc5a" }}
          ></CircleIcon>
        ),
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },

    {
      name: "tip_cost_id",
      label: "tip_cost_id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "file",
      label: " ",
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (file: any) => {
          if (!file) return null;
          const path = `${urlEnum.vehicles}/${file.file}`;
          return (
            <Button
              style={{ float: "right" }}
              size="small"
              color="primary"
              variant="contained"
              data-file={path}
            >
              {Vocabulary.open}
            </Button>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex: number) => (
          <div style={{ float: "right" }}>
            <EditIcon
              style={{
                marginRight: 15,
                height: 30,
                cursor: "pointer",
                color: "#4449",
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                setSelected(
                  vehicleContext?.state?.vehicle?.intrare_garantie[rowIndex]
                );
                setOpenEditModal(true);
              }}
            ></EditIcon>
            <DeleteIcon
              style={{ height: 30, cursor: "pointer", color: "#4449" }}
              onClick={(event: any) => {
                event.stopPropagation();
                setSelected(
                  vehicleContext?.state?.vehicle?.intrare_garantie[rowIndex]
                );
                setOpenModal(true);
              }}
            ></DeleteIcon>
          </div>
        ),
      },
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "100%",
            margin: 10,
            overflowY: "auto",
          }}
        >
          <VehicleDashboardTable
            title={Vocabulary.intrareGarantie}
            data={vehicleContext?.state?.vehicle?.intrare_garantie}
            columns={columns}
            type={VehicleDashboardTableType.guarantees}
            editData={selected}
            openEditModal={openEditModal}
            closeModal={() => {
              setOpenEditModal(false);
              setSelected(null);
            }}
            emptyFields={emptyFields}
            openModalDelete={openModal}
            closeModalDelete={() => {
              setSelected(null);
              setOpenModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}
