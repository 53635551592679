import PhotoVehicle from "./PhotoVehicle";


export default function VehiclePhotos() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex" }}>
      
        <div
          style={{
            width: "100%",
            margin: 10,
            overflowY: "auto",
          }}
        >
          <PhotoVehicle></PhotoVehicle>
        </div>
      </div>
    </div>
  );
}
