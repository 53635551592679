// import { Vocabulary } from "../Vocabulary";
// import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

export const ROUTER_MANAGE_TASKURI = {
  routes: ["/tasksDashboard", "*"],
};

export const MAIN_SLIDER_MANAGE_TASKURI = [
//   {
//   name: Vocabulary.tasksName,
//   path: "/tasksDashboard",
//   icon: <AssignmentTurnedInIcon />,
//   orderId: 3,
// }
];

export const VEHICLE_OPTIONS_MANAGE_TASKURI = [];

export const DINAMIC_TABS_MANAGE_TASKURI = { 
  // add: true 
};

export const MAIN_TABLE_MANAGE_TASKURI = null;
