import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Vocabulary } from "../../Utils/Vocabulary";
import { isMobile } from "../../Utils/utils";
import { FilePreviewContext } from "../../Context/dataFileContext";

type Step = {
  name: string;
  children: any;
  status?: any;
};

interface ReusableStepperProps {
  steps: Step[];
  initialStep?: number; // Optional starting step index (defaults to 0)
}

const ReusableStepper: React.FC<ReusableStepperProps> = ({ steps }) => {
  const initialStep = steps.findIndex((step) => !step.status);

  const [activeStep, setActiveStep] = React.useState<number>(initialStep);
  const { reloadFilePreview, setReloadFilePreview } =
    React.useContext(FilePreviewContext);

  React.useEffect(() => {
    setActiveStep(initialStep);
  }, [initialStep]);

  /**
   * Returns the total number of steps available in the stepper.
   *
   * @returns {number} - The total count of steps.
   */
  const totalSteps = (): number => {
    return steps.length;
  };

  /**
   * Returns the number of steps that have been completed(finished) in the stepper.
   *
   * @returns {number} - The number of completed steps.
   */
  const completedSteps = (): number => {
    return totalSteps() - 1 - initialStep;
  };

  /**
   * Checks if the current step is the last step in the stepper.
   *
   * @returns {boolean} - Returns true if the current step is the last step, otherwise false.
   */
  const isLastStep = (): boolean => {
    return activeStep == -1 || activeStep === totalSteps() - 1;
  };

  /**
   * Checks if all steps in the stepper are completed.
   *
   * @returns {boolean} - Returns true if all steps are completed, otherwise false.
   */
  const allStepsCompleted = (): boolean => {
    return completedSteps() === totalSteps();
  };

  /**
   * Moves to the next step in the stepper, or to the first incomplete step.
   *
   * If the current step is the last step and not all steps are completed,
   * moves to the first step that has not been completed. Otherwise moves to the next step.
   */
  const handleNext = (): void => {
    setReloadFilePreview(!reloadFilePreview);
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => step.status)
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  /**
   * Moves to the previous step in the stepper.
   *
   */
  const handleBack = (): void => {
    setReloadFilePreview(!reloadFilePreview);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ margin: "1em" }}>
      <Stepper
        nonLinear={!isMobile()}
        alternativeLabel={isMobile()}
        activeStep={activeStep}
      >
        {steps.map((step, index) => (
          <Step
            onClick={() => {
              setReloadFilePreview(!reloadFilePreview);
              setActiveStep(index);
            }}
            key={step?.name}
            completed={step?.status}
          >
            <StepButton color="inherit">{step?.name}</StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {/* Render the children of the active step */}
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
            {steps[activeStep]?.children}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {/* Previous button */}
            {activeStep > 0 && (
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {Vocabulary.previous}
              </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />
            {/* Next button */}
            {!isLastStep() && (
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                {Vocabulary.next}
              </Button>
            )}
          </Box>
        </React.Fragment>
      </div>
    </Box>
  );
};

export default ReusableStepper;
