/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import MUIDataTable from "mui-datatables";
import GenericModal from "../../Modal/GenericModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { urlEnum } from "../../Utils/urlEnum";
import { getData } from "../../Services/getData";
import { Delete, Edit } from "@mui/icons-material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { multipleDelete } from "../../Services/multipleDelete";
import { isMobile } from "../../Utils/utils";
import { CacheContext } from "../../Context/cacheContext";
import CompanyModal from "./CompanyModal";
import { tableLayoutOnMobile } from "../../Utils/Constants";
import { postData } from "../../Services/postData";
// import { FirmaAchizitieModel } from "../../Models/VehicleModel";
import { jwtDecode } from "jwt-decode";
import { usDateFormatWithTime } from "../../Utils/formatDate";
import moment from "moment";

type State = {
  company: any;
  companiesNumber: number;
  page: number;
  perPage: number;
  search: string | null;
  order: string;
  filter: string | null;
};
export type ShippingData = {
  id?: number | undefined;
  name: string;
  culoare: string;
  tip_shipping: string;
};

interface JwtPayload {
  exp: number; // 'exp' field represents the expiration timestamp
}

export default function Companies(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const cacheContext = useContext(CacheContext);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [eTransport, setOpenTransportModal] = useState(false);
  const [deletedIds, setDeletedIds] = useState<Array<number>>([]);

  const [editedCompany, setEditedCompany]: any = useState(null);
  const [state, setState] = useState<State>({
    company: [],
    companiesNumber: 0,
    page: 0,
    perPage: 10,
    search: null,
    order: "asc",
    filter: null,
  });

  /**
   *
   * @param {*} event
   * @param {*} newValue
   */

  /**
   * Get companies
   */
  useEffect(() => {
    const search = location.search.split("?");
    const newState = {
      company: [],
      companiesNumber: 0,
      page: 0,
      perPage: 10,
      search: null,
      order: "asc",
      filter: null,
    } as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1] - 1;
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    getCompany(newState.page, newState.perPage, newState.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  function getCompany(page: number, perPage: number, search?: string | null) {
    let url = `${urlEnum.firma_achizitie_r}/${page}/${perPage}`;
    if (search) {
      url = `${urlEnum.firma_achizitie_r}/${page}/${perPage}/${search}`;
    }
    getData(url)
      .then((res) => {
        setState({
          ...state,
          company: res.data.firme,
          companiesNumber: res.data.firmeNumber,
          page: page,
          perPage: perPage,
          search: search ? search : null,
        });
      })
      .catch((err) => {
        //
      });
  }

  const isTokenExpired = (accessToken: string): boolean => {
    try {
      // Decode the token to extract the payload
      const decodedToken: JwtPayload = jwtDecode<JwtPayload>(accessToken);

      // Get the current time in seconds since epoch
      const currentTime = Math.floor(Date.now() / 1000);

      // Check if the current time has passed the expiration time
      return currentTime > decodedToken.exp;
    } catch (error) {
      console.error("Invalid token:", error);
      return true; // Return true if there's an error (e.g., invalid token)
    }
  };

  /**
   *
   */
  const companyHeader = [
    {
      label: `${Vocabulary.name}`,
      name: "nume_firma",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.address}`,
      name: "adresa",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.phoneNumber}`,
      name: "telefon",
      options: {
        sort: false,
      },
    },
    {
      label: "",
      name: "",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (rowIndex: number, value: any) => {
          return (
            <>
              <div style={{ marginTop: -10, marginBottom: -10 }}>
                <ToggleButtonGroup exclusive aria-label="text alignment">
                  {state.company[rowIndex].name !== "Administrator" &&
                  state.company[rowIndex].nume_firma !==
                    "Intermediere Vanzare" ? (
                    <Tooltip title={Vocabulary.etransport}>
                      <ToggleButton
                        onClick={
                          !state.company[rowIndex].access_token ||
                          isTokenExpired(state.company[rowIndex].access_token)
                            ? () => {
                                // setDeletedIds([state.company[rowIndex].id as number]);
                                localStorage.setItem(
                                  "tempSelectedCompany",
                                  JSON.stringify(state.company[rowIndex])
                                );
                                setOpenTransportModal(true);
                              }
                            : () => {
                                console.log("test integrare");
                              }
                        }
                        value="center"
                        aria-label="centered"
                        style={
                          state.company[rowIndex].access_token &&
                          !isTokenExpired(state.company[rowIndex].access_token)
                            ? { backgroundColor: "#97ce9d" }
                            : { backgroundColor: "#ff7a7a" }
                        }
                      >
                        <LocalShippingIcon />
                      </ToggleButton>
                    </Tooltip>
                  ) : null}
                  <Tooltip title={Vocabulary.edit}>
                    <ToggleButton
                      onClick={() => {
                        props.setOpenEditModal(true);
                        setEditedCompany(state.company[rowIndex]);
                      }}
                      value="center"
                      aria-label="centered"
                    >
                      <Edit />
                    </ToggleButton>
                  </Tooltip>
                  {state.company[rowIndex].name !== "Administrator" ? (
                    <Tooltip title={Vocabulary.delete}>
                      <ToggleButton
                        onClick={() => {
                          setDeletedIds([state.company[rowIndex].id as number]);
                          setOpenDeleteModal(true);
                        }}
                        value="center"
                        aria-label="centered"
                      >
                        <Delete />
                      </ToggleButton>
                    </Tooltip>
                  ) : null}
                </ToggleButtonGroup>
              </div>
            </>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    return {
      responsive: tableLayoutOnMobile,
      viewColumns: false,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      fixedHeader: true,
      count: state.companiesNumber,
      rowsPerPage: state.perPage,
      page: state.page,
      search: false,
      serverSide: true,
      sort: true,
      textLabels: {
        // selectedRows: {
        //   text: "selectate",
        //   delete: "Sterge",
        //   deleteAria: "Sterge liniile selectate",
        // },
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: Vocabulary.pagination,
      },
      onRowsDelete: (rowsDeleted: any, newData: any) => {
        const ids = new Array<number>();
        rowsDeleted.data.forEach((row: any) => {
          ids.push(state.company[row.dataIndex].id as number);
        });
        setDeletedIds(ids);
        setOpenDeleteModal(true);
      },
      onChangePage: (page: number) => {
        setState({ ...state, page: page });
        if (state.search)
          navigate(
            `?settings=companies?page=${page + 1}?perPage=${
              state.perPage
            }?search=${state.search}`
          );
        else
          navigate(
            `?settings=companies?page=${page + 1}?perPage=${state.perPage}`
          );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        setState({ ...state, perPage: numberOfRows });
        if (state.search)
          navigate(
            `?settings=companies?page=${1}?perPage=${numberOfRows}?search=${
              state.search
            }`
          );
        else navigate(`?settings=companies?page=${1}?perPage=${numberOfRows}`);
        window.scrollTo(0, 0);
      },
    };
  }

  /**
   * Close delete modal
   */
  function onCloseDeleteModal() {
    setOpenDeleteModal(false);
    setOpenTransportModal(false);
    setDeletedIds([]);
  }

  useEffect(() => {
    integrateWithEtransport();
  }, [location.search]);

  /**
   * integrateWithEtransport
   */
  function integrateWithEtransport() {
    const search = location.search.split(/[?&]/);
    let code = null;
    search.forEach((part) => {
      if (part) {
        // This check avoids the empty string element
        const [key, value] = part.split("=");
        if (key === "code") code = value;
      }
    });
    if (code) {
      getData(`${urlEnum.callbackEtransport}/${code}`)
        .then((response) => {
          saveCompany(response.data);
        })
        .catch((err) => {
          onCloseDeleteModal();
        });
    }
  }

  async function saveCompany(response: any) {
    const tempSelectedCompany = JSON.parse(
      localStorage.getItem("tempSelectedCompany") ?? "{}"
    );

    const formData: any = new FormData();
    formData.append("nume_firma", tempSelectedCompany.nume_firma);
    formData.append("adresa", tempSelectedCompany.adresa);
    formData.append("CIF", tempSelectedCompany.CIF);
    formData.append("nrRegCom", tempSelectedCompany.nrRegCom);
    formData.append("access_token", response.access_token ?? null);
    formData.append("refresh_token", response.refresh_token ?? null);
    formData.append("expires_in", response.expires_in ?? null);
    formData.append(
      "token_creation_date",
      moment().format(usDateFormatWithTime)
    );

    const url = `${urlEnum.firma_achizitie_r}/${tempSelectedCompany.id}`;

    postData(url, formData)
      .then((res) => {
        cacheContext.setCache({
          ...cacheContext.cache,
          company: res.data.company,
        });
        getCompany(state.page, state.perPage, state.search);
      })
      .catch(() => {
        console.log();
      });
  }

  /**
   * Delete company
   */
  function deleteCompany() {
    const data = { data: { ids: deletedIds } };
    multipleDelete(urlEnum.firma_achizitie_r, data)
      .then((response) => {
        onCloseDeleteModal();
        cacheContext.setCache({
          ...cacheContext.cache,
          company: response.data.company,
        });
        getCompany(state.page, state.perPage, state.search);
      })
      .catch((err) => {
        onCloseDeleteModal();
      });
  }

  return (
    <div>
      <div>
        <MUIDataTable
          title={""}
          data={state.company}
          columns={companyHeader}
          options={getTableOptions()}
        />
      </div>
      <CompanyModal
        open={props.openEditModal}
        title={
          editedCompany ? Vocabulary.editCompany : Vocabulary.addNewCompany
        }
        editedShipping={editedCompany}
        onClose={(shouldRefetch) => {
          props.setOpenEditModal(false);
          setEditedCompany(null);
          if (shouldRefetch) {
            getCompany(state.page, state.perPage, state.search);
          }
        }}
      ></CompanyModal>

      <GenericModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        title={`${Vocabulary.deleteShipping}`}
      >
        <p style={{ textAlign: "center", fontSize: 20 }}>
          {Vocabulary.deleteShippingMessage}
        </p>
        <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onCloseDeleteModal}
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={deleteCompany}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
      <GenericModal
        open={eTransport}
        onClose={onCloseDeleteModal}
        title={`${Vocabulary.etransport}`}
      >
        <p style={{ textAlign: "center", fontSize: 20 }}>
          {Vocabulary.integrareTransport}
        </p>
        <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onCloseDeleteModal}
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <a href="/api/oauth">
              <Button
                variant="contained"
                fullWidth
                onClick={integrateWithEtransport}
                style={{ color: "white" }}
              >
                {Vocabulary.yes}
              </Button>
            </a>
          </Grid>
        </Grid>
      </GenericModal>
    </div>
  );
}
