/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThemeProvider } from "@emotion/react";
import { getMuiTheme } from "../../Utils/globalStyleTables";
import {
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import MUIDataTable from "mui-datatables";
import GenericModal from "../../Modal/GenericModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { urlEnum } from "../../Utils/urlEnum";
import { getData } from "../../Services/getData";
import { Delete, Edit } from "@mui/icons-material";
import { multipleDelete } from "../../Services/multipleDelete";
import style from "../../Styles/tableFilter.module.css";
import DinamicTabs from "../DinamicTabs";
import { isMobile } from "../../Utils/utils";
import formatDate from "../../Utils/formatDate";


export default function Negotiation(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedIds, setDeletedIds] = useState<Array<number>>([]);
  //   const [openEditModal, setOpenEditModal] = useState(false);
  //   const [editedNegotiation, setEditedNegotiation] = useState(null);
  const [state, setState] = useState({
    negotiation: [] as any,
    negotiationNumber: 0,
    page: 0,
    perPage: 10,
    search: null,
    order: "asc",
    filter: null,
  });

  /**
   * Get negotiations
   */
  useEffect(() => {
    const search = location.search.split("?");
    const newState = {
      negotiation: [],
      negotiationNumber: 0,
      page: 0,
      perPage: 10,
      search: null,
      order: "asc",
      filter: null,
    } as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1] - 1;
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    getNegotiation(newState.page, newState.perPage, newState.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  function getNegotiation(page: number, perPage: number, search?: any) {
    let url = `${urlEnum.negotiation_id}`;
    if (search) {
      url = `${urlEnum.negotiation_id}`;
    }
    getData(url)
      .then((res) => {
        setState({
          ...state,
          negotiation: res.data.data,
          negotiationNumber: res.data.negotiationNumber,
          page: page,
          perPage: perPage,
          search: search ? search : null,
        });
      })
      .catch((err) => {
        //
      });
  }
  /**
   *
   */
  const negotiationHeader = [
    {
      label: "Descriere",
      name: "text",
      options: {
        sort: false,
      },
    },
    {
      label: "Ultima modificare",
      name: "updated_at",
      options: {
        sort: false,
      },
      customBodyRenderLite: (rowIndex: number) => {
        return(formatDate(rowIndex))
      }
    },
    {
      label: "",
      name: "",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (rowIndex: number) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10 }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    // onClick={() => {
                    //   setEditedNegotiation(state.negotiation[rowIndex]);
                    //   setOpenEditModal(true);
                    // }}
                    value="center"
                    aria-label="centered"
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.delete}>
                  <ToggleButton
                    onClick={() => {
                      setDeletedIds([state.negotiation[rowIndex].id as number]);
                      setOpenDeleteModal(true);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";

    return {
      responsive: responsive,
      viewColumns: false,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      fixedHeader: true,
      count: state.negotiationNumber,
      rowsPerPage: state.perPage,
      page: state.page,
      search: false,
    //   serverSide: true,
      sort: true,
      textLabels: {
        // selectedRows: {
        //   text: "selectate",
        //   delete: "Sterge",
        //   deleteAria: "Sterge liniile selectate",
        // },
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: Vocabulary.pagination,
      },
      onRowsDelete: (rowsDeleted: any, newData: any) => {
        const ids: any = new Array<number>();
        rowsDeleted.data.forEach((row: any) => {
          ids.push(state.negotiation[row.dataIndex].id as number);
        });
        setDeletedIds(ids);
        setOpenDeleteModal(true);
      },
      onChangePage: (page: number) => {
        setState({ ...state, page: page });
        if (state.search)
          navigate(
            `/negotiation?page=${page + 1}?perPage=${state.perPage}?search=${
              state.search
            }`
          );
        else navigate(`/negotiation?page=${page + 1}?perPage=${state.perPage}`);
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        setState({ ...state, perPage: numberOfRows });
        if (state.search)
          navigate(
            `/negotiation?page=${1}?perPage=${numberOfRows}?search=${
              state.search
            }`
          );
        else navigate(`/negotiation?page=${1}?perPage=${numberOfRows}`);
        window.scrollTo(0, 0);
      },
    };
  }

  /**
   * Close delete modal
   */
  function onCloseDeleteModal() {
    setOpenDeleteModal(false);
    setDeletedIds([]);
  }

  /**
   * Delete negotiation
   */
  function deleteNegotiation() {
    const data = { data: { ids: deletedIds } };
    multipleDelete(urlEnum.negotiation_id, data)
      .then((res) => {
        onCloseDeleteModal();
        getNegotiation(state.page, state.perPage, state.search);
      })
      .catch((err) => {
        onCloseDeleteModal();
      });
  }
  return (
    <div className={style.containerTable}>
      <ThemeProvider theme={getMuiTheme()}>
        <DinamicTabs
          labels={[Vocabulary.negotiation,""]}
          openModalWindow={() => (console.log())}
          addNewLabel={Vocabulary.addNewNegotiation}
        ></DinamicTabs>

        <div>
          <MUIDataTable
            title={""}
            data={state.negotiation}
            columns={negotiationHeader}
            options={getTableOptions()}
          />
        </div>

        <GenericModal
          open={openDeleteModal}
          onClose={onCloseDeleteModal}
          title={Vocabulary.deleteNegotiation}
        >
          <p style={{ textAlign: "center", fontSize: 20 }}>
            {Vocabulary.deleteNegotiationMessage}
          </p>
          <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
            <Grid item md={6} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={onCloseDeleteModal}
                fullWidth
              >
                {Vocabulary.no}
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <Button
                variant="contained"
                fullWidth
                onClick={deleteNegotiation}
                style={{ color: "white" }}
              >
                {Vocabulary.yes}
              </Button>
            </Grid>
          </Grid>
        </GenericModal>
      </ThemeProvider>
    </div>
  );
}
