import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PaidIcon from "@mui/icons-material/Paid";

import { Vocabulary } from "../Vocabulary";

export const ROUTER_COSTURI_ADITIONALE = {
  routes: [
    "/",
    "/vehiclesDashboard",
    "/vehiclesDashboard/vehicleAdditionalCost/:id?",
    "*",
  ],
};

export const MAIN_SLIDER_COSTURI_ADITIONALE = [{
  name: Vocabulary.vehicles,
  path: "/vehiclesDashboard",
  icon: <DirectionsCarIcon />,
}];

export const VEHICLE_OPTIONS_COSTURI_ADITIONALE = [
  {
    name: Vocabulary.costAditional,
    path: "/vehiclesDashboard/vehicleAdditionalCost/",
    icon: <PaidIcon />,
    id: "vehicleAdditionalCost",
    slug: "costuri_aditionale",
    orderNumber: 6,
  },
];

export const DINAMIC_TABS_COSTURI_ADITIONALE = {
  addVehicles: true,
};

export const MAIN_TABLE_COSTURI_ADITIONALE = {
  add: false,
  allAccess: false,
};
