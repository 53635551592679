import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalCarWashIcon from "@mui/icons-material/LocalCarWash";

import { Vocabulary } from "../Vocabulary";

export const ROUTER_INTERVENTII_DETAILING = {
  routes: [
    "/",
    "/vehiclesDashboard",
    "/vehiclesDashboard/vehicleDetailing/:id?",
    "*",
  ],
};

export const MAIN_SLIDER_INTERVENTII_DETAILING = [{
  name: Vocabulary.vehicles,
  path: "/vehiclesDashboard",
  icon: <DirectionsCarIcon />,
}];

export const VEHICLE_OPTIONS_INTERVENTII_DETAILING = [
  {
    name: Vocabulary.detailing,
    path: "/vehiclesDashboard/vehicleDetailing/",
    icon: <LocalCarWashIcon />,
    id: "vehicleDetailing",
    slug: "interventii_detailing",
    orderNumber: 4.5,
  },
];

export const DINAMIC_TABS_INTERVENTII_DETAILING = {
  addNewLabel: null,
  // add: true,
};


export const VEHICLE_TABLE_INTERVENTII_DETAILING = {
  addVehicle: true,
};
