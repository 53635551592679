/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from "@mui/material/Box";
import React, { useContext, useState } from "react";
import MUIDataTable from "mui-datatables";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";

import Total from "../Utils/Total";
import GenericServicesModal from "../Modal/GenericServicesModal";
import { getData } from "../Services/getData";
import { multipleDelete } from "../Services/multipleDelete";
import { urlEnum } from "../Utils/urlEnum";
import { useLocation } from "react-router-dom";
import { deleteData } from "../Services/deleteData";
import GenericModal from "../Modal/GenericModal";
import DocumentsModal from "./DocumentsModal";
import { getMuiTheme } from "../Utils/globalStyleTables";
import DinamicTabs from "./DinamicTabs";
import { Vocabulary } from "../Utils/Vocabulary";
import { isEmpty, isMobile } from "../Utils/utils";
import { componentNames, tableLayoutOnMobile } from "../Utils/Constants";
import { VehicleDashboardTableType } from "../Utils/autocompleteEnum";
import { VehicleContext } from "../Context/vehicleContext";

export default function VehicleDashboardTable(props: any) {
  const filterType: "dropdown" | undefined = "dropdown";
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const vehicleContext: any = useContext(VehicleContext);
  const location = useLocation();

  const test = location.pathname;
  const words = test.split("/");
  const id = words[words.length - 1];

  /**
   *
   */
  async function getVehicles() {
    const url = `${urlEnum.vehicles}/${id}`;
    const res = (await getData(url)) as any;
    if (!res || res?.error) {
      return;
    }

    const vehicle = res?.data?.vehicles[0];
    if (
      isEmpty(vehicle?.descriere) &&
      !isEmpty(vehicleContext.state.vehicle?.descriere)
    )
      vehicle.descriere = vehicleContext.state.vehicle.descriere;
    vehicleContext.updateVehicle(vehicle);
    (window as any).vehicleIdForDeletionFromVehiclesEditing =
      res?.data?.vehicles[0].id;
    vehicleContext.updateVehicleDocuments(res?.data?.documents);
    vehicleContext.updateVehicleImages(res?.data?.images);
    vehicleContext.updateVehicleInvoices({
      clientInvoice: res.data.clientInvoice ? res.data.clientInvoice : [],
      providerInvoice: res.data.providerInvoice ? res.data.providerInvoice : [],
      OPInvoice: res.data.OPInvoice ? res.data.OPInvoice : [],
      reservedDocuments: res.data.reservedDocuments
        ? res.data.reservedDocuments
        : [],
    });
  }

  /**
   *
   * @param data
   */
  const deleteSelected = (data: any) => {
    deleteServices(data);
  };

  /**
   *
   * @param id
   * @returns
   */
  async function deleteServices(id: any) {
    switch (props.type) {
      case VehicleDashboardTableType.files:
        {
          const url = `${urlEnum[props.type]}/${id}/${
            vehicleContext?.state?.vehicle?.id
          }/${props.editData.id}`;
          const res = (await deleteData(url)) as any;
          if (!res || res?.error) {
            return;
          }
          getVehicles();
          props.closeModalDelete();
        }
        break;
      case VehicleDashboardTableType.costs:
      case VehicleDashboardTableType.guarantees: {
        const url = `/${urlEnum[props.type]}`;
        const data = {
          data: { ids: [props.editData.id] },
        };
        const res = (await multipleDelete(url, data)) as any;
        if (!res || res?.error) {
          return;
        }
        getVehicles();
        props.closeModalDelete();
        break;
      }
      case VehicleDashboardTableType.detailing:
      case VehicleDashboardTableType.painting:
      case VehicleDashboardTableType.service:
        {
          const url = `/${urlEnum[props.type]}/${id}`;
          const res = (await deleteData(url)) as any;
          if (!res || res?.error) {
            return;
          }
          getVehicles();
          props.closeModalDelete();
        }
        break;
      default:
        return "";
    }
  }

  /**
   *
   * @param id
   * @returns
   */
  const getDeleteText = (id: any) => {
    switch (id) {
      case VehicleDashboardTableType.guarantees:
        return Vocabulary.askForDeleteGuarantees;
      case VehicleDashboardTableType.files:
        return Vocabulary.askForDeleteFinancialDoc;
      case VehicleDashboardTableType.detailing:
        return Vocabulary.askForDeleteDetailing;
      case VehicleDashboardTableType.painting:
        return Vocabulary.askForDeletePainting;
      case VehicleDashboardTableType.service:
        return Vocabulary.askForDeleteService;
      case VehicleDashboardTableType.costs:
        return Vocabulary.askForDeleteCost;
      default:
        return "";
    }
  };

  /**
   *
   */
  const options = {
    selectableRows: "none" as any,
    responsive: tableLayoutOnMobile,
    filterType: filterType,
    filter: false,
    onChangePage: (page: number) => {
      setPage(page);
    },
    onChangeRowsPerPage: (perPage: number) => {
      setPerPage(perPage);
    },
    rowsPerPageOptions: [50, 100],
    rowsPerPage: perPage,
    page: page,
    download: false,
    sort: false,
    viewColumns: false,
    fixedHeader: false,
    fixedSelectColumn: false,
    print: false,
    search: false,
    rowHover: false,
    textLabels: {
      selectedRows: {
        text: "selectate",
        delete: "Sterge",
        deleteAria: "Sterge liniile selectate",
      },
      body: {
        noMatch: "Nu exista date!",
      },
    },
    setTableProps: () => {
      return {
        padding: "default",
        fontWeight: 500,
        size: "small",
      };
    },
    // onRowsDelete: (index: any, onRowsDelete: any) => {
    //   const ids: any = [];
    //   onRowsDelete.forEach((element: any, index: any) => {
    //     ids.push(element[6]);
    //   });
    //   //
    //   return ids;
    // },
  };

  /**
   *
   * @param data
   */
  const openModalWindow = (data?: any) => {
    // props.emptyFields();
    setOpenModal(true);
  };

  /**
   *
   * @param data
   */
  const closeModal = (data?: any) => {
    setOpenModal(false);
    if (props.closeModal) {
      props.closeModal(false);
    }
  };

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: isMobile() ? 0 : 50,
        }}
      >
        <MUIDataTable
          title={""}
          data={props.data ? props.data : []}
          columns={props.columns ? props.columns : []}
          options={options}
        />
        {!props?.showTotal ? (
          <Total data={props?.data ? props?.data : []}></Total>
        ) : null}
      </Box>
      {props.type === VehicleDashboardTableType.files ? (
        <DocumentsModal
          key={props?.editData?.id}
          data={props}
          open={openModal || props.openEditModal}
          onClose={() => closeModal()}
          title={`Adauga ${props.title}`}
          type={props.type}
          edit={props.openEditModal}
          editData={props.editData}
          getNewData={getVehicles}
        ></DocumentsModal>
      ) : (
        <GenericServicesModal
          key={props?.editData?.id}
          data={props}
          open={openModal || props.openEditModal}
          onClose={() => closeModal()}
          title={
            props.openEditModal
              ? `Editeaza ${props.title}`
              : `Adauga ${props.title}`
          }
          type={props.type}
          edit={props.openEditModal}
          editData={props.editData}
          getNewData={getVehicles}
        ></GenericServicesModal>
      )}
      <GenericModal
        open={props.openModalDelete}
        onClose={props.closeModalDelete}
        title="Confirmare stergere"
      >
        <div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
            }}
          >
            <p style={{ fontSize: 20 }}>{getDeleteText(props.type)}</p>
            <p style={{ fontSize: 20, fontWeight: 600 }}>
              {props?.editData?.suma_financiar &&
              props?.editData?.suma_financiar !== "null"
                ? props?.editData?.suma_financiar
                : props?.editData?.cost
                ? props?.editData?.cost
                : 0}{" "}
              LEI
            </p>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              style={{ color: "white", margin: 10 }}
              onClick={props.closeModalDelete}
            >
              {Vocabulary.no}
            </Button>
            <Button
              onClick={() => deleteSelected(props?.editData?.id)}
              fullWidth
              variant="contained"
              style={{ color: "white", margin: 10 }}
            >
              {Vocabulary.yes}
            </Button>
          </div>
        </div>
      </GenericModal>

      <DinamicTabs
        labels={[props.title, ""]}
        name={componentNames.DinamicTabsServices}
        addNewLabel={Vocabulary.add}
        openModalWindow={openModalWindow}
      ></DinamicTabs>
    </ThemeProvider>
  );
}
