import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DropzoneArea } from "material-ui-dropzone";
import styles from "../../Styles/progressAction.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Grid, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import useResetContextState from "../../Hooks/useResetContextState";

type UploadElementProps = {
  type?: string[];
  fileName?: any; //TODO!!!
  handleEditInfo?: any; //TODO!!!
  handleSave?: any;
  children?: JSX.Element;
  title: string;
  handleChange?: any;
  disabled?: boolean;
  openModal?: any;
  handleModal?: any;
  file?: any; //TODO
  fileIsRequired?: boolean;
};

export default function UploadElement(props: UploadElementProps) {
  const [deleteSuccess, setDeleteSuccess] = useState(true);
  const [softUpload, setSoftUpload] = useState(false);
  const resetContextState = useResetContextState(props.openModal);

  useEffect(() => {
    if (props.fileIsRequired&&!(props?.fileName?.length>0)) setSoftUpload(true);
    if(props.openModal === true){
      resetContextState.setInitialState();
    }
  }, [props.openModal]);

  const handleOpenCloseModal = () => {
    if (props.openModal) {
      resetContextState.resetState();
    }
    props.handleModal && props.handleModal();
    setSoftUpload(false);
    setDeleteSuccess(true);
    setDeleteSuccess(false);
  };

  const handleSave = () => {
    if (!softUpload) {
      if (props.handleSave) {
        props.handleSave();
      }
    }
  };

  const handleDropZoneContent = (file: any) => {
    if (file.length) {
      props.handleEditInfo && file && props.handleEditInfo(file);
      setDeleteSuccess(true);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.fieldset}>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#f6f6f6",
            borderRadius: 10,
            marginLeft: 2,
          }}
          container
          columnSpacing={{ xs: 1, sm: 1 }}
          xl={12}
        >
          <Grid className={styles.gridFileText} item xl={8} xs={12} md={8}>
            <Typography
              data-file={`/api/${props.file}`}
              style={{ wordBreak: "break-all", padding: 10, cursor: "pointer" }}
            >
              {props.fileName ? props.fileName : Vocabulary.noFile}
            </Typography>
          </Grid>
          <Grid className={styles.gridFileButton} item xl={4} xs={12} md={4}>
            <Button
              style={{ margin: 5 }}
              variant="contained"
              className={styles.buttons}
              onClick={handleOpenCloseModal}
              disabled={props.disabled ? props.disabled : false}
            >
              {props.title}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          // onClose={handleOpenCloseModal}
          aria-labelledby="customized-dialog-title"
          open={props.openModal}
        >
          <div className={styles.containerModal}>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              {props.title}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleOpenCloseModal}
              className={styles.closeIcon}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <div>
                <div>{props.children}</div>
                {props.fileName && !deleteSuccess ? (
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#f6f6f6",
                      borderRadius: 10,
                      marginLeft: 2,
                    }}
                    container
                    columnSpacing={{ xs: 1, sm: 1 }}
                    xl={12}
                  >
                    <Grid item xl={10} xs={10}>
                      <Typography
                        style={{ wordBreak: "break-all", padding: 10 }}
                      >
                        {props.fileName ? props.fileName : Vocabulary.noFile}
                      </Typography>
                    </Grid>
                    <Grid style={{ textAlign: "center" }} item xl={2} xs={2}>
                      <div
                        onClick={() => {
                          setDeleteSuccess(true);
                          setSoftUpload(true);
                        }}
                      >
                        <DeleteIcon className={styles.deleteIcon}></DeleteIcon>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <div>
                    <DropzoneArea
                      acceptedFiles={["image/*,application/*"]}
                      dropzoneClass={styles.dropzoneArea}
                      maxFileSize={30000000} //30 MB
                      filesLimit={1}
                      onDrop={() => {
                        setSoftUpload(true);
                        setSoftUpload(false);
                      }}
                      onChange={(file: any) => {
                        handleDropZoneContent(file);
                      }}
                      onDelete={() => setSoftUpload(true)}
                    />

                    <p
                      style={{
                        fontSize: 12,
                        marginTop: 5,
                        marginLeft: 10,
                        color: "#d32f2f",
                      }}
                    >
                      {softUpload ? Vocabulary.required : null}
                    </p>
                  </div>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                className={styles.buttons}
                autoFocus
                onClick={handleSave}
              >
                {Vocabulary.save}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    </div>
  );
}
