import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/urlEnum";
import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import GenericModal from "../../Modal/GenericModal";
import { Settings, tableLayoutOnMobile } from "../../Utils/Constants";
import { updateData } from "../../Services/updateData";
import { isMobile } from "../../Utils/utils";
import Delete from "@mui/icons-material/Delete";
import { CacheContext } from "../../Context/cacheContext";
//import { updateData } from "../../Services/updateData";

export type NotificationData = {
  id?: number | null;
  type: any[];
  departments: any[];
  users?: any[];
};

const initialState: NotificationData = {
  id: null,
  type: [],
  departments: [],
  users: [],
};

type State = {
  notifications: any;
  allNotifications: NotificationData[];
  notificationsNumber: number;
  page: number;
  perPage: number;
  search: string | null;
  order: string;
  filter: string | null;
  openModal: boolean;
  departments: [];
};

export default function NotificationsSettings(props: any) {
  const { openEditModal, setOpenEditModal } = props;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [editedRowIndex, setEditedRowIndex] = useState<number>(-1);
  const [departments, setDepartemnts] = useState([]);
  const cacheContext: any = useContext(CacheContext);
  const cacheData = cacheContext.cache;
  const [currentNotification, setCurrentNotification] =
    useState<NotificationData>(initialState);
  const [state, setState] = useState<State>({
    notifications: [],
    allNotifications: [],
    notificationsNumber: 0,
    page: 0,
    perPage: 10,
    search: null,
    order: "asc",
    filter: null,
    openModal: false,
    departments: [],
  });

  useEffect(() => {
    getNotificationsTypes();
    getDepartments();
    getNotifications();
  }, []);

  /**
   *
   */
  const getNotificationsTypes = async () => {
    const response = await getData(urlEnum.notificationsType);
    if (response?.data) {
      setNotificationTypes(response.data?.notificationsType);
    }
  };

  /**
   *
   * @param page
   * @param perPage
   */
  const getNotifications = async (
    page = state.page,
    perPage = state.perPage
  ) => {
    const response = await getData(
      `${urlEnum.settings}/notificationSettings/${page}/${perPage}`
    );

    if (response?.data) {
      setState({
        ...state,
        allNotifications: response?.data.allSettings,
        notifications: response?.data.setting,
        notificationsNumber: response?.data.settingsCount,
        page: page,
        perPage: perPage,
      });
    }

    return response.data.setting;
  };

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  async function getDepartments() {
    const url = `${urlEnum.permissions_id}/${0}/${100}`;

    await getData(url)
      .then((res) => {
        const departments = res.data.permissions.filter((permision: any) => {
          if (permision.isDepartment) return permision;
        });
        setDepartemnts(departments);
      })
      .catch((err) => {
        //
      });
  }

  const getDepartmentsUsers = () => {
    const userMap: Record<number, { id: number; name: string }> = {};

    if (currentNotification.departments.length > 0) {
      cacheData?.users?.forEach((user: any) => {
        if (
          user.departments_ids?.some((departmentId: number) =>
            currentNotification.departments.some(
              (department) => department.id === departmentId
            )
          )
        ) {
          userMap[user.id] = { id: user.id, name: user.nume };
        }
      });
    }

    // Convert the userMap values to an array
    return Object.values(userMap);
  };

  /**
   * @returns
   */
  function getTableOptions() {
    return {
      responsive: tableLayoutOnMobile,
      selectableRows: "none" as any,
      viewColumns: false,
      rowsPerPageOptions: [2, 10, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      fixedHeader: true,
      count: state.notificationsNumber,
      rowsPerPage: state.perPage,
      page: state.page,
      search: false,
      serverSide: true,
      sort: true,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: Vocabulary.pagination,
      },
      onChangePage: (page: number) => {
        getNotifications(page, state.perPage);
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        getNotifications(0, numberOfRows);
        window.scrollTo(0, 0);
      },
    };
  }

  /**
   * columns for table
   */
  const columns = [
    {
      label: `${Vocabulary.notificationType}`,
      name: "type",
      options: {
        sort: false,
        customBodyRender: (rowIndex: any, index: any) => {
          return (
            <>
              {rowIndex?.map((value: any, index: number) => (
                <Chip
                  key={index}
                  label={value}
                  style={{
                    margin: 5,
                  }}
                />
              ))}
            </>
          );
        },
      },
    },
    {
      label: `${Vocabulary.departments}`,
      name: "departments",
      options: {
        sort: false,
        customBodyRender: (rowIndex: any, index: any) => {
          return (
            <>
              {rowIndex?.map((value: any, index: number) => {
                return departments.map((department: any) => {
                  if (department.id == value.id) {
                    return (
                      <Chip
                        key={index}
                        label={Vocabulary[department.name]}
                        style={{
                          margin: 5,
                        }}
                      />
                    );
                  }
                });
              })}
            </>
          );
        },
      },
    },
    {
      label: Vocabulary.departmentsUsers,
      name: "users",
      options: {
        sort: false,
        customBodyRender: (rowIndex: any, index: any) => {
          return (
            <>
              {rowIndex?.length
                ? rowIndex?.map((value: any, index: number) => {
                    return (
                      <Chip
                        key={index}
                        label={value.name}
                        style={{
                          margin: 5,
                        }}
                      />
                    );
                  })
                : Vocabulary.allDepartmentUsers}
            </>
          );
        },
      },
    },
    {
      label: "",
      name: "",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (rowIndex: number, value: any) => {
          return (
            <>
              <div style={{ marginTop: -10, marginBottom: -10 }}>
                <ToggleButtonGroup exclusive aria-label="text alignment">
                  <Tooltip title={Vocabulary.edit}>
                    <ToggleButton
                      value="center"
                      aria-label="centered"
                      onClick={() => {
                        setOpenEditModal(true);
                        setCurrentNotification(state.notifications[rowIndex]);
                        setEditedRowIndex(rowIndex);
                      }}
                    >
                      <Edit />
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip title={Vocabulary.delete}>
                    <ToggleButton
                      onClick={() => {
                        setEditedRowIndex(rowIndex);
                        setOpenDeleteModal(true);
                      }}
                      value="center"
                      aria-label="centered"
                    >
                      <Delete />
                    </ToggleButton>
                  </Tooltip>
                </ToggleButtonGroup>
              </div>
            </>
          );
        },
      },
    },
  ];

  /**
   *
   * @param notifications
   * @returns
   */
  const updateNotificationSetting = async (
    notifications: NotificationData[]
  ) => {
    const url = `${urlEnum.settings}`;
    const params = { [Settings.notificationSettings]: notifications };
    const res = await updateData(url, params);
    if (!res) {
      return;
    }
    getNotifications();
    handleOnClose();
  };

  /**
   * checks if the combination of type already exists in the notificatios array
   *
   * @param data => array of notifications
   * @param targetType => combination of searched types
   * @returns -1 if the types weren't found or else the index of the notification with the targetType
   */
  function containsTypeCombination(data: any[], targetType: string[]) {
    const sortedTargetType = [...targetType].sort(); // sort to have them in order
    return data.findIndex((item) => {
      // Check if the length and elements of type array match the targetType array
      const sortedItemType = [...item.type].sort();
      return (
        sortedItemType.length === sortedTargetType.length &&
        sortedItemType.every(
          (type: string, index: number) => type === sortedTargetType[index]
        )
      );
    });
  }

  /**
   * saves notifications in settings
   * @returns
   */
  const saveNotificationSettings = async () => {
    //format the departments
    const departmentsId = currentNotification.departments.map(
      (department: any) => {
        if (department.id) return { id: department.id, name: department.name };
        else return department;
      }
    );
    let notifications = state.allNotifications.length
      ? state.allNotifications
      : [];

    const newNotification = [
      {
        type: currentNotification.type,
        departments: departmentsId,
        users: currentNotification.users,
      },
    ];
    const typeCombinationFounded = containsTypeCombination(
      notifications,
      currentNotification.type
    );

    //if index was found or the type combination already exists, update the notification, else add it
    if (typeCombinationFounded >= 0 || editedRowIndex >= 0)
      notifications[
        typeCombinationFounded >= 0 ? typeCombinationFounded : editedRowIndex //
      ] = newNotification[0];
    else notifications = notifications.concat(newNotification);

    //update notifications
    updateNotificationSetting(notifications);
  };

  /**
   *  close modal and reset params
   */
  const handleOnClose = () => {
    setOpenEditModal(false);
    setCurrentNotification(initialState);
    setEditedRowIndex(-1);
    setOpenDeleteModal(false);
  };

  /**
   *
   */
  const deleteNotification = () => {
    const notifications = state.allNotifications;
    notifications.splice(editedRowIndex + state.perPage * state.page, 1);
    updateNotificationSetting(notifications);
  };

  /**
   *
   * @returns content for the "delete notification" dialog
   */
  const deleteNotificationModalContent = () => {
    return (
      <GenericModal
        open={openDeleteModal}
        onClose={handleOnClose}
        title={Vocabulary.deleteNotification}
        fullScreenOnMobile={false}
      >
        <p style={{ textAlign: "center", fontSize: 20 }}>
          {Vocabulary.deleteShippingMessage}
        </p>
        <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOnClose}
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={deleteNotification}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
    );
  };

  return (
    <>
      <MUIDataTable
        title={""}
        data={state.notifications}
        columns={columns}
        options={getTableOptions()}
      />
      {openEditModal && (
        <GenericModal
          open={openEditModal}
          onClose={() => {
            handleOnClose();
          }}
          title={editedRowIndex >= 0 ? Vocabulary.edit : Vocabulary.add}
          buttons={
            <Grid container spacing={3} style={{ padding: "1rem" }}>
              <Grid item md={6} xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    setOpenEditModal(false);
                    setCurrentNotification(initialState);
                  }}
                >
                  {Vocabulary.cancel}
                </Button>
              </Grid>
              <Grid item md={6} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ color: "white", height: "95%" }}
                  onClick={() => {
                    saveNotificationSettings();
                  }}
                >
                  {Vocabulary.save}
                </Button>
              </Grid>
            </Grid>
          }
          children={
            <Grid
              container
              spacing={2}
              style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
            >
              <Grid item md={12} xs={12}>
                <Autocomplete
                  id="type"
                  freeSolo={false}
                  fullWidth
                  disablePortal
                  multiple
                  getOptionLabel={(option: any) => option}
                  size="small"
                  value={currentNotification.type}
                  options={notificationTypes}
                  onChange={(
                    event: SyntheticEvent<Element, Event>,
                    newValue: any[]
                  ) => {
                    setCurrentNotification({
                      ...currentNotification,
                      type: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.notificationType}
                      variant="outlined"
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option == value
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  id="departments"
                  freeSolo={false}
                  fullWidth
                  disablePortal
                  multiple
                  getOptionLabel={(option: any) =>
                    option.name
                      ? Vocabulary[option.name]
                      : Vocabulary[option[1]]
                  }
                  size="small"
                  onChange={(
                    event: SyntheticEvent<Element, Event>,
                    newValue: any[]
                  ) => {
                    setCurrentNotification({
                      ...currentNotification,
                      departments: newValue,
                    });
                  }}
                  value={currentNotification.departments}
                  options={departments}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.permisiuneDepartament}
                      variant="outlined"
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option: any, value) =>
                    option.name == value.name
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  id="users"
                  freeSolo={false}
                  fullWidth
                  disablePortal
                  multiple
                  getOptionLabel={(option: any) => option.name}
                  size="small"
                  onChange={(
                    event: SyntheticEvent<Element, Event>,
                    newValue: any[]
                  ) => {
                    setCurrentNotification({
                      ...currentNotification,
                      users: newValue,
                    });
                  }}
                  value={currentNotification?.users}
                  options={getDepartmentsUsers()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.departmentsUsers}
                      variant="outlined"
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.name == value.name
                  }
                />
              </Grid>
            </Grid>
          }
        />
      )}
      {deleteNotificationModalContent()}
    </>
  );
}
