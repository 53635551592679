/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import { isMobile } from "../../Utils/utils";
import { Vocabulary } from "../../Utils/Vocabulary";
import style from "../../Styles/reports.module.css";
import GraphicBar from "./GraphicBar";
import {
  CostsGraphsProps,
  CostsInfoProps,
  ProfitGraphsProps,
  ProfitInfoProps,
  VehiclesInfoProps,
} from "./Reports";
import { formatNumber } from "../../Utils/formatDate";
import { useRef } from "react";
// import domtoimage from 'dom-to-image-more';
import html2canvas from "html2canvas";
import moment from "moment";
//import VrpanoIcon from "@mui/icons-material/Vrpano";
import DownloadIcon from "@mui/icons-material/Download";

type StatisticallyProps = {
  costsGraphs: CostsGraphsProps;
  grossProfitGraphs: ProfitGraphsProps;
  netIncomeGraphs: ProfitGraphsProps;
  vehiclesInfo: VehiclesInfoProps | null;
  profitInfo: ProfitInfoProps | null;
  costsInfo: CostsInfoProps | null;
  purchaseInfo?: ProfitGraphsProps;
  vehicleNumberGraphs?: ProfitGraphsProps;
  saleValueInfo?: ProfitGraphsProps;
  saleDurationInfo?: ProfitGraphsProps;
  interestRateInfo?: ProfitGraphsProps;
  saleDurationAverageInfo?: ProfitGraphsProps;
  openGraphs?: boolean;
  openSecondGraphs?: any;
  setOpenGraphs: (open: boolean) => void;
};
export default function Statistically(props: StatisticallyProps) {
  const sectionRef: any = useRef(null);

  function captureImage(graphicId: string): any {
    // Get the element to capture
    const element = document.getElementById(graphicId) as HTMLElement;

    // Use html2canvas to capture the element and convert it to canvas
    html2canvas(element)
      .then((canvas: HTMLCanvasElement) => {
        // Convert the canvas to a data URL
        const imageUrl = canvas.toDataURL("image/png");

        // Create an anchor element to trigger the download
        const link = document.createElement("a");
        link.href = imageUrl;
        const formatedDate = moment(new Date()).format("DD-MM-YYYY");
        link.download = `Rapoarte_${graphicId}_${formatedDate}.png`;

        // Trigger the download by simulating a click
        link.click();
      })
      .catch((error: any) => {
        console.error("Error capturing the DOM:", error);
      });
  }

  const scrollToSection = () => {
    // Use scrollIntoView to scroll to the section
    sectionRef.current &&
      sectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const {
    costsGraphs,
    grossProfitGraphs,
    vehicleNumberGraphs,
    // netIncomeGraphs,
    vehiclesInfo,
    purchaseInfo,
    profitInfo,
    costsInfo,
    saleValueInfo,
    // saleDurationInfo,
    // interestRateInfo,
    saleDurationAverageInfo,
    openGraphs,
    openSecondGraphs,
    setOpenGraphs,
  } = props;

  /**
   * Renders a button that captures an image of the specified graphic.
   *
   * @param graphic - The ID of the graphic element to capture.
   * @returns A JSX element containing the capture button.
   */
  const renderCaptureButton = (graphic: string) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          position: "relative",
          top: isMobile() ? "1rem" : "4rem",
        }}
      >
        <Button
          className={style.saveAsImage}
          variant="contained"
          color="secondary"
          onClick={() => {
            captureImage(graphic);
          }}
          startIcon={<DownloadIcon />}
          size="small"
        >
          {Vocabulary.saveAsImage}
        </Button>
      </div>
    );
  };

  const renderProfitMetrics = () => {
    return (
      <div className={style.profitMetrics}>
        <div className={style.profitSide}>
          <p className={style.statisticallySubtitle}>
            <span>{Vocabulary.positiveGrossProfitCount}: </span>
            <div>{vehiclesInfo?.positiveGrossProfitCount}</div>
          </p>
          <p className={style.statisticallySubtitle}>
            <span>{Vocabulary.profit}: </span>
            <div>
              {formatNumber(vehiclesInfo?.totalPositiveGrossProfit)} Lei
            </div>
          </p>
        </div>
        <div className={style.lossSide}>
          <p className={style.statisticallySubtitle}>
            <span>{Vocabulary.negativeGrossProfitCount}: </span>
            <div>{vehiclesInfo?.negativeGrossProfitCount}</div>
          </p>
          <p className={style.statisticallySubtitle}>
            <span>{Vocabulary.loss}: </span>
            <div>
              {formatNumber(vehiclesInfo?.totalNegativeGrossProfit)} Lei
            </div>
          </p>
        </div>
      </div>
    );
  };
  return (
    <Paper
      style={{
        padding: 15,
        marginBottom: 40,
        borderRadius: "0px 0px 15px 15px ",
      }}
    >
      <div
        id="statisticallyContainer"
        className={style.statisticallyContainer}
        style={{
          flexDirection: isMobile() ? "column" : "row",
        }}
      >
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          xl={12}
          md={12}
          xs={12}
        >
          <Grid item xl={12} xs={12}>
            <fieldset className={style.statisticallyFieldset}>
              <legend>Informatii vehicule</legend>
              <Grid
                container
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                xl={12}
                md={12}
                xs={12}
              >
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.totalVehicles} </span>
                    {vehiclesInfo?.totalVehicles}
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.purchaseValue}</span>
                    {formatNumber(vehiclesInfo?.purchaseValue)} Lei
                  </p>
                  {/* <p className={style.statisticallyText}>
                    <span>{Vocabulary.positiveGrossProfitCount} </span>
                    {vehiclesInfo?.positiveGrossProfitCount}
                  </p> */}
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.saleValue}</span>
                    {formatNumber(vehiclesInfo?.saleValue)} Lei
                  </p>
                  {/* <p className={style.statisticallyText}>
                    <span>{Vocabulary.positiveGrossProfit} </span>
                    {formatNumber(vehiclesInfo?.totalPositiveGrossProfit)} Lei
                  </p> */}
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.statisticallySaleDuration}</span>
                    {vehiclesInfo?.saleDuration}
                  </p>
                  {/* <p className={style.statisticallyText}>
                    <span>{Vocabulary.negativeGrossProfitCount} </span>
                    {vehiclesInfo?.negativeGrossProfitCount}
                  </p> */}
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.saleDurationAverage}</span>
                    {vehiclesInfo?.saleDurationAverage}
                  </p>
                  {/* <p className={style.statisticallyText}>
                    <span>{Vocabulary.negativeGrossProfit} </span>
                    {formatNumber(vehiclesInfo?.totalNegativeGrossProfit)} Lei
                  </p> */}
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.interestRate}</span>
                    {formatNumber(vehiclesInfo?.interestRate)} Lei
                  </p>
                </Grid>
                <Grid item xl={12} md={12} xs={12}>
                  {renderProfitMetrics()}
                </Grid>
                <div ref={sectionRef}> </div>

                {openGraphs ? (
                  <>
                    <Grid item xl={6} xs={12}>
                      {renderCaptureButton(Vocabulary.vehiclesNumber)}
                      <GraphicBar
                        keys={vehicleNumberGraphs?.keys}
                        data={vehicleNumberGraphs?.profit}
                        title={Vocabulary.vehiclesNumber}
                        enableLabel={true}
                        value={formatNumber(vehiclesInfo?.totalVehicles)}
                        color={true}
                        noCurency={true}
                        miniGraph={true}
                      />
                    </Grid>
                    <Grid item xl={6} xs={12}>
                      {renderCaptureButton(Vocabulary.purchaseValue)}
                      <GraphicBar
                        keys={purchaseInfo?.keys}
                        data={purchaseInfo?.profit}
                        title={Vocabulary.purchaseValue}
                        value={formatNumber(vehiclesInfo?.purchaseValue)}
                        color={true}
                        enableLabel={true}
                        miniGraph={true}
                      />
                    </Grid>
                    <Grid item xl={6} xs={12}>
                      {renderCaptureButton(Vocabulary.saleValue)}
                      <GraphicBar
                        keys={saleValueInfo?.keys}
                        data={saleValueInfo?.profit}
                        title={Vocabulary.saleValue}
                        value={formatNumber(vehiclesInfo?.saleValue)}
                        color={true}
                        miniGraph={true}
                      />
                    </Grid>
                    {/* <Grid item xl={4} xs={12}>
                      <GraphicBar
                        keys={saleDurationInfo?.keys}
                        data={saleDurationInfo?.profit}
                        title={Vocabulary.statisticallySaleDuration}
                        value={formatNumber(vehiclesInfo?.saleDuration)}
                        color={true}
                        noCurency={true}
                        miniGraph={true}
                      />
                    </Grid> */}
                    <Grid item xl={6} xs={12}>
                      {renderCaptureButton(Vocabulary.saleDurationAverage)}
                      <GraphicBar
                        keys={saleDurationAverageInfo?.keys}
                        data={saleDurationAverageInfo?.profit}
                        title={Vocabulary.saleDurationAverage}
                        value={formatNumber(vehiclesInfo?.saleDurationAverage)}
                        color={true}
                        noCurency={true}
                        miniGraph={true}
                        averageValue={true}
                      />
                    </Grid>
                    {/* <Grid item xl={4} xs={12}>
                      <GraphicBar
                        keys={interestRateInfo?.keys}
                        data={interestRateInfo?.profit}
                        title={Vocabulary.interestRate}
                        value={formatNumber(vehiclesInfo?.interestRate)}
                        color={true}
                        miniGraph={true}
                      />
                    </Grid> */}

                    {/* <Grid item xl={6} xs={12}>
                <GraphicBar
                  keys={netIncomeGraphs?.keys}
                  data={netIncomeGraphs.profit}
                  title={Vocabulary.netIncome}
                  value={formatNumber(profitInfo?.netIncome)}
                  color={false}
                />
              </Grid> */}
                  </>
                ) : null}
                {}
              </Grid>
            </fieldset>
            <Grid item xl={12} xs={12}>
              <Button
                variant="contained"
                color="primary"
                style={{ float: "right" }}
                onClick={() => {
                  openGraphs
                    ? setOpenGraphs(false)
                    : openSecondGraphs(!openGraphs);
                  scrollToSection();
                }}
              >
                {openGraphs ? Vocabulary.showLess : Vocabulary.seeGraphs}
              </Button>
            </Grid>
          </Grid>
          {costsGraphs.keys ? (
            <>
              <Grid item xl={12} xs={12}>
                {renderCaptureButton(Vocabulary.grossProfit)}
                <GraphicBar
                  keys={grossProfitGraphs?.keys}
                  data={grossProfitGraphs.profit}
                  title={Vocabulary.grossProfit}
                  value={formatNumber(profitInfo?.grossProfit)}
                  color={true}
                />
              </Grid>
              {/* <Grid item xl={6} xs={12}>
                <GraphicBar
                  keys={netIncomeGraphs?.keys}
                  data={netIncomeGraphs.profit}
                  title={Vocabulary.netIncome}
                  value={formatNumber(profitInfo?.netIncome)}
                  color={false}
                />
              </Grid> */}
            </>
          ) : null}

          <Grid item xl={12} md={12} xs={12}>
            <fieldset className={style.statisticallyFieldset}>
              <legend>Profit</legend>
              <Grid
                container
                columnSpacing={{ xs: 1, sm: 1, md: 3 }}
                xl={12}
                md={12}
                xs={12}
              >
                {/* <Grid item xl={6} md={6} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.netIncomeAverage}</span>
                    {formatNumber(profitInfo?.netIncomeAverage)}Lei
                  </p>
                </Grid> */}
                <Grid item xl={12} md={12} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.grossProfitAverage}</span>
                    {formatNumber(profitInfo?.grossProfitAverage)}
                    Lei
                  </p>
                </Grid>
              </Grid>
            </fieldset>
          </Grid>
          {costsGraphs.keys ? (
            <>
              <Grid item xl={6} xs={12}>
                {renderCaptureButton(Vocabulary.vehiclesCosts)}
                <GraphicBar
                  keys={costsGraphs?.keys}
                  data={costsGraphs.vehicleCosts}
                  title={Vocabulary.vehiclesCosts}
                  value={formatNumber(costsInfo?.vehicleCosts)}
                  color={true}
                />
              </Grid>
              <Grid item xl={6} xs={12}>
                {renderCaptureButton(Vocabulary.vehicleCostsAverage)}
                <GraphicBar
                  keys={costsGraphs?.keys}
                  data={costsGraphs.vehicleCostsAverage}
                  title={Vocabulary.vehicleCostsAverage}
                  value={formatNumber(costsInfo?.vehicleCostsAverage)}
                  color={false}
                  averageValue={true}
                />
              </Grid>
            </>
          ) : null}
          <Grid item xl={12} md={12} xs={12}>
            <fieldset className={style.statisticallyFieldset}>
              <legend>Costuri</legend>
              <Grid
                container
                columnSpacing={{ xs: 1, sm: 1, md: 3 }}
                xl={12}
                md={12}
                xs={12}
              >
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.additionalCosts}</span>
                    {formatNumber(costsInfo?.additionalCosts)} Lei
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.administrativeCosts}</span>
                    {formatNumber(costsInfo?.administrativeCosts)} Lei
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.logisticsCosts}</span>
                    {formatNumber(costsInfo?.logisticsCosts)} Lei
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.marketingCosts}</span>
                    {formatNumber(costsInfo?.marketingCosts)} Lei
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.guaranteeCosts}</span>
                    {formatNumber(costsInfo?.guaranteeCosts)} Lei
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.detailingCosts}</span>
                    {formatNumber(costsInfo?.detailingCosts)} Lei
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.paintCosts}</span>
                    {formatNumber(costsInfo?.paintCosts)} Lei
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.serviceCosts}</span>
                    {formatNumber(costsInfo?.serviceCosts)} Lei
                  </p>
                </Grid>
              </Grid>
            </fieldset>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
