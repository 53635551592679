/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-destructuring */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MUIDataTable from "mui-datatables";
import { localUrlEnum, urlEnum } from "../../Utils/urlEnum";
import { Button, IconButton, Toolbar, Tooltip } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import DeleteIcon from "@mui/icons-material/Delete";
import { ThemeProvider } from "@emotion/react";
import { Vocabulary } from "../../Utils/Vocabulary";
import { getMuiTheme } from "../../Utils/globalStyleTables";
import DinamicTabs from "../DinamicTabs";
import { exportTable, isMobile } from "../../Utils/utils";
import {
  CarOrderSource,
  DEFAULT_MAX_NUMBER_OF_MESSAGES,
  Settings,
  componentNames,
  tableLayoutOnMobile,
} from "../../Utils/Constants";
import withRole from "../../Utils/withRole";
import { FiltersProps, StateProps } from "./DashboardSalesOnDemand";
import CarOrder from "./CarOrder";
import CarOrderDeleteModal from "./CarOrderDeleteModal";
import { CarOrdersTab } from "../../Utils/autocompleteEnum";
import AllocatedCarOrder from "./AllocatedCarOrder";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";
import { anafFormatDate, europeanDateWithTime } from "../../Utils/formatDate";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

export interface MainTableProps {
  children?: React.ReactNode;
  index?: number;
  value?: number;
}

export type CarOrderModel = {
  id?: number;
  nume_complet: string;
  localitate: string;
  email: string;
  telefon: string;
  brand_id: number | null;
  model_id: number | null;
  buget: string;
  detalii_extra: string;
  status_id: number | null;
  user_id: number | null;
  sursa_comanda: string;
  vehicle_name: string;
  vehicul_id: number | null;
  logs: object | [];
};

function TabPanel(props: MainTableProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

type TableProps = {
  filters: FiltersProps;
  state: StateProps;
  value: number;
  info?: any;
  handleChange: (event: any, index: number) => void;
  getCarOrders?: any;
  tabsLabelsArray: Array<any>;
  cacheContext: any;
  unassignedMessagesCount: number;
  unreadCarOrdersCount: number | null;
};

function MainTableSalesOnDemand(props: TableProps) {
  const {
    info,
    filters,
    state,
    value,
    getCarOrders,
    handleChange,
    cacheContext,
    unreadCarOrdersCount,
    tabsLabelsArray,
  } = props;
  const navigate = useNavigate();
  const [selectedCarOrder, setSelectedCarOrder] =
    useState<CarOrderModel | null>(null);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAllocatedCarOrder, setOpenAllocatedCarOrder] = useState(false);
  const [deletedCarOrderId, setDeletedCarOrderId]: any = useState(null);
  const [carOrderId, setCarOrderId] = useState<number | null>(null);

  /**
   *
   */
  const columns = [
    {
      name: "nume_complet",
      label: Vocabulary.name,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "localitate",
      label: Vocabulary.county,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email",
      label: Vocabulary.email,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "telefon",
      label: Vocabulary.phone,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "buget",
      label: Vocabulary.budget,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (buget: any) => (
          <Typography>{parseFloat(buget)}</Typography>
        ),
      },
    },
    {
      name: "brand_nume",
      label: Vocabulary.brand,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "model_nume",
      label: Vocabulary.model,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "sursa_comanda",
      label: isMobile() ? Vocabulary.mobileCarOrderSource : Vocabulary.source,
      options: {
        filter: true,
        sort: false,
        downloadType: "boolean",
        customBodyRender: (sursa_comanda: any) => (
          <div>
            {CarOrderSource[sursa_comanda as keyof typeof CarOrderSource] ? (
              <Tooltip
                title={
                  CarOrderSource[sursa_comanda as keyof typeof CarOrderSource]
                }
              >
                {sursa_comanda == Object.keys(CarOrderSource)[0] ? (
                  <CircleIcon style={{ color: "#7a7a7a63" }} />
                ) : (
                  <CircleIcon style={{ color: "#49ab518c" }} />
                )}
              </Tooltip>
            ) : null}
          </div>
        ),
      },
    },
    {
      name: "status_nume",
      label: Vocabulary.status,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "created_at",
      label: Vocabulary.date,
      options: {
        filter: true,
        sort: false,
        downloadType: "date",
        customBodyRender: (date: string | null) => {
          const d = moment.utc(date);
          return (
            <Typography>{d.local().format(europeanDateWithTime)}</Typography>
          );
        },
      },
    },
    {
      name: "user_name",
      label: Vocabulary.userName,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (user_name: any) => (
          <Typography>
            {user_name ? user_name : Vocabulary.unassigned}
          </Typography>
        ),
      },
    },
    {
      name: "optiuni",
      label: Vocabulary.options,
      options: {
        filter: false,
        sort: false,
        display: props?.info.edit || props?.info.delete ? true : false,
        viewColumns: false,
        download: false,
        customBodyRender: (index: any, data: any) => (
          <div style={{ display: "flex" }}>
            {props?.info.edit ? (
              <Tooltip title={Vocabulary.edit}>
                <IconButton
                  onClick={(event: any) => {
                    event.stopPropagation();
                    setOpenAddNew(true);
                    setSelectedCarOrder(state.carOrders[data.rowIndex]);
                  }}
                  style={{ marginRight: 10 }}
                  aria-label="delete"
                >
                  <ModeEditIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            {props?.info.delete ? (
              <Tooltip title={Vocabulary.delete}>
                <IconButton
                  onClick={(event: any) => {
                    event.stopPropagation();
                    setOpenDelete(true);
                    setDeletedCarOrderId([state.carOrders[data.rowIndex].id]);
                  }}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            {filters.type === CarOrdersTab.unallocated ? (
              <Tooltip title={Vocabulary.assignToAUser}>
                <IconButton
                  onClick={(event: any) => {
                    event.stopPropagation();
                    setOpenAllocatedCarOrder(true);
                    setCarOrderId(state.carOrders[data.rowIndex].id);
                  }}
                  aria-label="delete"
                >
                  <PersonPinIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        ),
      },
    },
  ];

  /**
   *
   */
  const options: any = {
    filter: false,
    download: true,
    responsive: tableLayoutOnMobile,
    sort: false,
    textLabels: {
      viewColumns: {
        title: Vocabulary.viewColumns,
      },
      toolbar: {
        downloadCsv: Vocabulary.downloadExcel,
        viewColumns: Vocabulary.selectColumns,
      },
      pagination: Vocabulary.pagination,
    },

    fixedHeader: false,
    fixedSelectColumn: false,
    selectableRows: "none" as any,
    print: false,
    page: filters.page,
    serverSide: true,
    rowsPerPage: filters.perPage,
    search: false,
    count: state?.carOrdersNumber,
    rowHover: true,
    rowsPerPageOptions: [
      10,
      50,
      100,
      state?.carOrdersNumber > 100 ? state?.carOrdersNumber : 1000,
    ],
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
      filename: (
        Vocabulary.vehicles +
        "_" +
        Vocabulary.onDemand +
        "_" +
        tabsLabelsArray[props.value]?.label +
        ".xlsx"
      ).toLocaleLowerCase(),
    },
    setRowProps: (row: [], dataIndex: number, rowIndex: number) => {
      let styleProps = {};
      if (state.carOrders && !state.carOrders[rowIndex]?.user_id) {
        styleProps = {
          filter: "blur(5px)",
        };
      }
      if (state.carOrders[rowIndex]?.status_r?.culoare) {
        styleProps = {
          ...styleProps,
          backgroundColor: state.carOrders[rowIndex]?.status_r?.culoare,
        };
      }
      return {
        style: { ...styleProps, cursor: "pointer" },
      };
    },
    onRowClick: (rowData: any, rowState: any) => {
      setOpenAddNew(true);
      setSelectedCarOrder(state.carOrders[rowState.rowIndex]);
    },
    onChangePage: (page: number) => {
      navigate(
        `${localUrlEnum.onDemand}?${filters.type}?page=${page + 1}?perPage=${
          filters.perPage
        }${filters?.search ? `?search=${filters?.search}` : ""}`
      );
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      navigate(
        `${localUrlEnum.onDemand}?${
          filters.type
        }?page=${1}?perPage=${numberOfRows}${
          filters?.search ? `?search=${filters?.search}` : ""
        }`
      );
      window.scrollTo(0, 0);
    },
    onDownload: (buildHead: any, buildBody: any, columns: any, data: any) => {
      const alteredData = data.map((column: any, index: number) => {
        column.data = column?.data?.map((value: any, key: number) => {
          if (columns[key].downloadType) {
            switch (columns[key].downloadType) {
              case "boolean": {
                value =
                  value == "siteOrder"
                    ? Vocabulary.siteCarOrder
                    : Vocabulary.manualCarOrder;
                break;
              }
              case "date": {
                value = moment.utc(value).local().format(europeanDateWithTime);
                break;
              }
            }
          } else if (typeof value == "object") {
            if (value) {
              const name = Object.values(value)[1];
              value = name;
            }
          }
          return value;
        });

        return column;
      });
      return `${buildHead(columns)}${buildBody(alteredData)}`;
    },
  };

  /**
   *
   * @returns full warning message for desktop
   */
  const getWarningMessage = () => {
    if (getShortWarningMessage())
      return getShortWarningMessage() + getLongWarningMessageVersion();
    else return null;
  };

  /**
   *
   * @returns the long version of warning message only showed oon descktop if automated allocation is on
   */
  const getLongWarningMessageVersion = () => {
    let message = "";
    const isAutomatedAllocationON = cacheContext.cache.settings.find(
      (user: any) => user.slug == Settings.automatedClientMessages
    )?.value;
    if (isAutomatedAllocationON == 1) {
      const maxNrOfMsgs = cacheContext.cache.settings.find(
        (user: any) => user.slug == Settings.maxNumberForMessages
      )?.value;
      message = ` , din ${
        maxNrOfMsgs ? maxNrOfMsgs : DEFAULT_MAX_NUMBER_OF_MESSAGES
      } maxime!`;
    }
    return message;
  };

  /**
   *
   * @returns the warning message to be show to let user know how many unread car orders he has
   * this is the short version to be shown only on mobile and if the automated allocation is off
   */
  const getShortWarningMessage = () => {
    let message = "";
    if (unreadCarOrdersCount) {
      message = `Ai ${unreadCarOrdersCount} `;
      if (unreadCarOrdersCount == 1) {
        message += "mesaj necitit";
      } else {
        message += "mesaje necitite";
      }
    }
    return message;
  };

  /**
   * export table as excel
   */
  const exportData = (props: any) => {
    let exportedColumns = props?.columns;
    exportedColumns = exportedColumns.filter((column: any) => {
      if (column.download == true) return column.name;
    });

    filters.endDate = moment(filters.endDate).format(anafFormatDate);
    const fileName = (
      Vocabulary.vehicles +
      "_" +
      Vocabulary.onDemand +
      "_" +
      tabsLabelsArray[value]?.label
    ).toLocaleLowerCase();

    const url = `${urlEnum.carOrders}/export/table/data`;
    exportTable(url, filters, fileName, exportedColumns);
  };

  /**
   *
   * @param props
   * @returns
   */
  const CustomToolbar = (props: any) => {
    return (
      <Toolbar>
        <Button
          startIcon={<CloudDownloadIcon />}
          onClick={(event) => exportData(props)}
        >
          {Vocabulary.exportTable}
        </Button>
      </Toolbar>
    );
  };

  return (
    <div
      style={{
        marginBottom: isMobile() ? 70 : 0,
        marginLeft: isMobile() ? -15 : 0,
        marginRight: isMobile() ? -15 : -20,
        marginTop: isMobile() ? 0 : 10,
        paddingTop: 10,
      }}
    >
      <ThemeProvider theme={getMuiTheme()}>
        <Box style={{ marginTop: -20, marginLeft: 9 }}>
          <DinamicTabs
            name={componentNames.DinamicTabs}
            key={info}
            labels={tabsLabelsArray}
            handleChangeTab={handleChange}
            value={value}
            addNewLabel={Vocabulary.addNewCarOrder}
            openModalWindow={() => setOpenAddNew(true)}
            warningMessage={
              getWarningMessage() !== "" ? getWarningMessage() : null
            }
            shortWarningMessage={getShortWarningMessage()}
          />
        </Box>
        <div
          style={{
            marginLeft: isMobile() ? 0 : -15,
            marginRight: isMobile() ? 0 : -15,
            width: "100%",
            marginTop: -24,
          }}
        >
          <TabPanel value={value} index={0}>
            <MUIDataTable
              key={filters.page}
              title={""}
              data={state?.carOrders}
              columns={columns}
              options={options}
              components={{
                TableToolbar: CustomToolbar,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MUIDataTable
              title={""}
              data={state?.carOrders}
              columns={columns}
              options={options}
              components={{
                TableToolbar: CustomToolbar,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <MUIDataTable
              key={filters.page}
              title={""}
              data={state?.carOrders}
              columns={columns}
              options={options}
              components={{
                TableToolbar: CustomToolbar,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <MUIDataTable
              key={filters.page}
              title={""}
              data={state?.carOrders}
              columns={columns}
              options={options}
              components={{
                TableToolbar: CustomToolbar,
              }}
            />
          </TabPanel>
        </div>
        <CarOrder
          open={openAddNew}
          onClose={(shouldRefetch) => {
            setOpenAddNew(false);
            setSelectedCarOrder(null);
            // if (shouldRefetch) {
            getCarOrders(filters);
            //}
          }}
          selectedCarOrder={selectedCarOrder}
          title={
            selectedCarOrder
              ? Vocabulary.editCarOrder
              : Vocabulary.addNewCarOrder
          }
          type={filters.type}
        />
        <CarOrderDeleteModal
          open={openDelete}
          deletedCarOrderId={deletedCarOrderId}
          onClose={(shouldRefetch) => {
            setOpenDelete(false);
            setDeletedCarOrderId(null);
            if (shouldRefetch) {
              getCarOrders(filters);
            }
          }}
        />
        <AllocatedCarOrder
          open={openAllocatedCarOrder}
          carOrderId={carOrderId}
          onClose={(shouldRefetch) => {
            setOpenAllocatedCarOrder(false);
            setCarOrderId(null);
            if (shouldRefetch) {
              getCarOrders(filters);
            }
          }}
        />
      </ThemeProvider>
    </div>
  );
}

export default withRole(MainTableSalesOnDemand);
