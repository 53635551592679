import { Vocabulary } from "../Vocabulary";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

export const ROUTER_EDIT_STATUS = {
  routes: ["/", "/logs", "*"],
};

export const MAIN_SLIDER_EDIT_STATUS = [{
  name: Vocabulary.logsName,
  path: "/logs",
  icon: <AssignmentIndIcon />,
  orderId: 6,
}];

export const VEHICLE_OPTIONS_EDIT_STATUS= [
];

export const DINAMIC_TABS_EDIT_STATUS = {
   add: true,
};

export const VEHICLES_DASHBOARD_EDIT_STATUS= [];

export const MAIN_TABLE_EDIT_STATUS = {
  edit: true,
};
