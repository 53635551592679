import { Autocomplete, Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import { Controller } from "react-hook-form";
import { isMobile } from "../../../../Utils/utils";
import { vameRomania } from "../../../../Utils/Constants";

export default function PTF(props: any) {
  const { control, cofPTFName, ptfIntrareIesire} = props;
  return (
    <div>
      <Grid container spacing={isMobile() ? 1 : 3}>

        <Grid item md={3} xs={12}>
          <Controller
            name={`codPtf.${cofPTFName}`}
            control={control}
            defaultValue={ptfIntrareIesire ? ptfIntrareIesire():undefined}
            render={({ field: { ref, value, ...field }, fieldState: { error } }) => (
              <Autocomplete
                value={ value || ""}
                freeSolo={false}
                style={{
                  marginBottom: 15,
                }}
                size="small"
                disableClearable
                disablePortal
                isOptionEqualToValue={(option: any, value: any) => {
                  return option.cod.toString() === value;
                }}
                getOptionLabel={(option: any) => { return vameRomania.find(vama=> vama.cod.toString()  === (option.cod ? option.cod.toString() : option.toString()))?.denumire || "";}}
                id={`codPtf.${cofPTFName}`}
                onChange={(event, value: any) => field.onChange(value.cod ? value.cod : value)}
                options={vameRomania}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.ptfIntrare}
                    name={`codPtf.${cofPTFName}`}
                    type="search"
                    inputRef={ref}
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
