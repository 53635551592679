import { Vocabulary } from "../Vocabulary";
import SettingsIcon from "@mui/icons-material/Settings";

export const ROUTER_PLATFORM_SETTINGS = {
  routes: ["/", "/settings", "*"],
};

export const MAIN_SLIDER_PLATFORM_SETTINGS = [{
  name: Vocabulary.settings,
  path: "/settings",
  icon: <SettingsIcon />,
  orderId:20,
}];

export const VEHICLE_OPTIONS_PLATFORM_SETTINGS = [];

export const DINAMIC_TABS_PLATFORM_SETTINGS = {
  //  add: true
   };

export const MAIN_TABLE_PLATFORM_SETTINGS = null;
export const MAIN_SETTINGS_PLATFORM_SETTINGS = [Vocabulary.appSettings,"null", "null","null"];
