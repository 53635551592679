import { Vocabulary } from "../Vocabulary";
import AssessmentIcon from "@mui/icons-material/Assessment";

export const ROUTER_VIZUALIZARE_STATISTICI = {
  routes: ["/", "/reports", "*"],
};

export const MAIN_SLIDER_VIZUALIZARE_STATISTICI = [
  {
    name: Vocabulary.reportsName,
    path: "/reports",
    icon: <AssessmentIcon />,
    orderId: 4,
  },
];

export const VEHICLE_OPTIONS_VIZUALIZARE_STATISTICI = [];

export const DINAMIC_TABS_VIZUALIZARE_STATISTICI = { 
  // add: true
};

export const MAIN_TABLE_VIZUALIZARE_STATISTICI = null;
