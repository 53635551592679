/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import { localUrlEnum, urlEnum } from "../../Utils/urlEnum";
import { useLocation, useNavigate } from "react-router-dom";
import UsersTable from "./UsersTable";
import { Vocabulary } from "../../Utils/Vocabulary";
import { getMuiTheme } from "../../Utils/globalStyleTables";
import { ThemeProvider } from "@emotion/react";
import style from "../../Styles/tableFilter.module.css";
import DinamicTabs from "../DinamicTabs";
import { componentNames } from "../../Utils/Constants";

export type State = {
  users: Array<UserData>;
  usersNumber: number;
  isActive: boolean;
  page: number;
  perPage: number;
  search: string | null;
  order: string;
  filter: string | null;
};

export type AdministrativeLocation = {
  id: number | string;
  nume: string;
};

export type Role = {
  id: number;
  name: string;
};
export type UserData = {
  id?: number | undefined;
  name: string;
  telefon: string;
  email: string;
  locatie_administrativa: AdministrativeLocation;
  locatie_administrativa_id?: any;
  comision_user: number;
  is_managing_users?: boolean;
  base_net_salary?: number | string;
  managed_user_ids?: number[];
  password: string;
  confirmPassword: string;
  roles?: Array<Role>;
  is_subcontractor?: boolean;
};

export default function UsersDashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [openEditModal, setOpenEditModal]: any = useState(false);
  const [editedUser, setEditedUser] = useState<UserData | null>(null);
  const [state, setState] = useState<State>({
    users: new Array<UserData>(),
    usersNumber: 0,
    isActive: true,
    page: 0,
    perPage: 10,
    search: null,
    order: "asc",
    filter: null,
  });

  /**
   * Get users
   */
  useEffect(() => {
    const search = location.search.split("?");
    const newState = {
      users: new Array<UserData>(),
      usersNumber: 0,
      isActive: true,
      page: 0,
      perPage: 10,
      search: null,
      order: "asc",
      filter: null,
    } as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1] - 1;
        else if (values[0] === "isActive")
          newState[values[0]] = values[1] === "true" ? true : false;
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    setValue(newState.isActive ? 0 : 1);
    getUsers(
      newState.isActive,
      newState.page,
      newState.perPage,
      newState.search
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   * @param isActive
   * @param page
   * @param perPage
   * @param search
   */
  function getUsers(
    isActive: boolean,
    page: number,
    perPage: number,
    search?: string | null
  ) {
    let url = `${urlEnum.users}/${isActive ? 1 : 0}/${page}/${perPage}`;
    if (search) {
      url = `${urlEnum.users}/${isActive ? 1 : 0}/${page}/${perPage}/${search}`;
    }
    getData(url)
      .then((res) => {
        setState({
          ...state,
          users: res.data.users,
          usersNumber: res.data.usersNumber,
          isActive: isActive,
          page: page,
          perPage: perPage,
          search: search ? search : null,
        });
      })
      .catch((err) => {
        //
      });
  }

  function setEditedUserInfo(index: any) {
    setEditedUser(state.users[index]);
  }

  /**
   *
   * @param {*} event
   * @param {*} newValue
   */
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
    navigate(
      `${localUrlEnum.users}?isActive=${!newValue}?page=${1}?perPage=${
        state.perPage
      }`
    );
  };
  const handleOpenModalWindow = (value: any) => {
    setOpenEditModal(value);
  };

  return (
    <div className={style.containerTable}>
      <ThemeProvider theme={getMuiTheme()}>
        <DinamicTabs
          key={value}
          name={componentNames.DinamicTabs}
          labels={[Vocabulary.activeUsers, Vocabulary.inactiveUsers]}
          handleChangeTab={handleChange}
          value={value}
          addNewLabel={Vocabulary.addNewUser}
          openModalWindow={handleOpenModalWindow}
          search={true}
        ></DinamicTabs>
        <UsersTable
          key={new Date().getTime()}
          state={state}
          getUsers={getUsers}
          openNewOption={openEditModal}
          handleOpenModalWindow={handleOpenModalWindow}
          setEditedUser={setEditedUserInfo}
          editedUser={editedUser}
        />
      </ThemeProvider>
    </div>
  );
}
