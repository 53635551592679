import { Vocabulary } from "../Vocabulary";
import GroupsIcon from "@mui/icons-material/Groups";

export const ROUTER_RESURSE = {
  routes: ["/", "/clients", "*"],
};

export const MAIN_SLIDER_RESURSE = [{
  name: Vocabulary.clientsName,
  path: "/clients",
  icon: <GroupsIcon />,
  orderId:2,
}];

export const VEHICLE_OPTIONS_RESURSE = [];

export const DINAMIC_TABS_RESURSE = { 
  // add: true 
};

export const MAIN_TABLE_RESURSE = null;
