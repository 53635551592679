/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneIcon from "@mui/icons-material/Tune";

import style from "../../Styles/mainDashboard.module.css";

import { isMobile } from "../../Utils/utils";
import MainTableFinancial from "./MainTableFinancial";
import { componentNames } from "../../Utils/Constants";
import { getData } from "../../Services/getData";
import { localUrlEnum, urlEnum } from "../../Utils/urlEnum";
import { VehiclesTab } from "../../Utils/autocompleteEnum";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import VehicleSlider from "../VehicleSlider";
import {
  NumberOfVehicleForFinancialDepartment,
  StatusModel,
} from "../../Models/Models";
import { PillsProps } from "../MainDashboard";

export type StateProps = {
  vehicles: Array<any>;
  vehiclesNumber: number;
  sellingStatuses: Array<StatusModel>;
};

export default function DashboardFinancial(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [users, setUsers] = useState({
    vanzatori: [],
    useriAchizitie: [],
  });

  const [state, setState] = useState<StateProps>({
    vehicles: [],
    vehiclesNumber: 0,
    sellingStatuses: [],
  });

  const [pills, setPills] = useState<PillsProps>({
    status: VehiclesTab.unpaid,
    statusVehicul: "[]",
    users: "[]",
    company: "[]",
    locations: "[]",
    vehicleTypes: "[]",
    purchaseType: "[]",
    minPrice: null,
    maxPrice: null,
    visible: "null",
    statusError: "null",
    page: 0,
    perPage: 10,
    tvaRecovered: "null",
    search: null,
    showIncompleteVehicles: false,
    showNegativeProfit: false,
    sortByCreatedAt: false,
    category_id: null,
  });

  const [openAccordion, setOpenAccordion] = useState(false);
  const [value, setValue] = useState(0);

  const [vehicleCounter, setVehicleCounter] =
    useState<NumberOfVehicleForFinancialDepartment>(
      new NumberOfVehicleForFinancialDepartment()
    );

  /**
   *
   */
  useEffect(() => {
    getData(urlEnum.userFilters).then((res: any) => {
      setUsers(res.data);
    });
    // getData(
    //   `${urlEnum.numberOfVehicleForFinancialDepartment}/${pills.showIncompleteVehicles}`
    // ).then((res: any) => {
    //   setVehicleCounter(res.data);
    // });
  }, []);

  /**
   *
   */
  useEffect(() => {
    getUrlVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getUrlVehicles = () => {
    const search = location.search.split("?");
    const newFilters = Object.assign({}, pills) as any;
    search.forEach((value) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "search")
          newFilters[values[0]] = decodeURIComponent(values[1]);
        if (values[0] === "visible") newFilters[values[0]] = values[1];
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        else
          newFilters[values[0]] = !isNaN(+values[1]) ? +values[1] : values[1];
        if (values[1] === "null") newFilters[values[0]] = null;
      }
    });
    setPageOption(newFilters.status);
    getVehicles(newFilters);
    if (newFilters.showIncompleteVehicles)
      newFilters.showIncompleteVehicles =
        newFilters.showIncompleteVehicles == "true" ||
        newFilters.showIncompleteVehicles == true; //pentru ca valoarea venea ca string si nu functiona switch-ul cu string
    setPills(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  /**
   *
   * @param newFilters
   */
  async function getVehicles(newFilters: any) {
    const url = `${urlEnum.vehicles}/${newFilters.status}/${newFilters.page}/${
      newFilters.perPage
    }/${
      JSON.parse(newFilters.statusVehicul)?.length
        ? JSON.parse(newFilters.statusVehicul)
        : null
    }/${
      JSON.parse(newFilters.users)?.length ? JSON.parse(newFilters.users) : null
    }/${
      JSON.parse(newFilters.company)?.length
        ? JSON.parse(newFilters.company)
        : null
    }/${
      JSON.parse(newFilters?.locations)?.length
        ? JSON.parse(newFilters?.locations)
        : null
    }/${
      JSON.parse(newFilters?.vehicleTypes)?.length
        ? JSON.parse(newFilters?.vehicleTypes)
        : null
    }/${newFilters.minPrice}/${newFilters.maxPrice}/${null}/${
      newFilters.statusError
    }/${newFilters.tvaRecovered}/${
      newFilters.search ? newFilters.search : null
    }/${newFilters.showIncompleteVehicles}/${
      JSON.parse(newFilters.purchaseType)?.length
        ? JSON.parse(newFilters.purchaseType)
        : null
    }/${newFilters?.sortByCreatedAt}/${newFilters.showNegativeProfit}/${
      newFilters?.category_id
    }/${
      newFilters?.physicalVehicleStatus &&
      JSON.parse(newFilters?.physicalVehicleStatus)?.length
        ? JSON.parse(newFilters?.physicalVehicleStatus)
        : null
    }`;

    const res = await getData(url);
    setState(res?.data);
    const vehiclesNumberURL = `${
      urlEnum.numberOfVehicleForFinancialDepartment
    }/${
      JSON.parse(newFilters.users)?.length ? JSON.parse(newFilters.users) : null
    }/${
      JSON.parse(newFilters.company)?.length
        ? JSON.parse(newFilters.company)
        : null
    }/${
      JSON.parse(newFilters?.locations)?.length
        ? JSON.parse(newFilters?.locations)
        : null
    }/${
      JSON.parse(newFilters.vehicleTypes)?.length
        ? JSON.parse(newFilters.vehicleTypes)
        : null
    }/${newFilters.maxPrice ? newFilters.maxPrice : null}/${
      newFilters.minPrice
    }/${newFilters?.showIncompleteVehicles}`;
    getData(vehiclesNumberURL).then((res: any) => {
      setVehicleCounter(res?.data?.data);
    });
  }

  /**
   *
   * @param item
   * @returns
   */
  const setPageOption = (item: any) => {
    switch (item) {
      case VehiclesTab.unpaid:
        setValue(0);
        break;
      case VehiclesTab.paid:
        setValue(1);
        break;
      case VehiclesTab.advancePaid:
        setValue(2);
        break;
      case VehiclesTab.contractToSign:
        setValue(3);
        break;
      case VehiclesTab.contractSigned:
        setValue(4);
        break;
      default:
        setValue(0);
        break;
    }
  };
  /**
   *
   * @param event
   * @param newValue
   */
  const handleSliderChange = (event: any, newValue: any) => {
    navigate(
      `${localUrlEnum.financialDepartment}?status=${
        pills.status
      }?page=${1}?perPage=${pills.perPage}?statusVehicul=${
        pills.statusVehicul ? pills.statusVehicul : null
      }?users=${pills.users ? pills.users : null}?company=${
        pills.company ? pills.company : null
      }?locations=${pills.locations ? pills.locations : null}?vehicleTypes=${
        pills.vehicleTypes ? pills.vehicleTypes : null
      }?minPrice=${newValue[0]}?maxPrice=${newValue[1]}?visible=${
        pills.visible
      }?statusError=${pills.statusError}?tvaRecovered=${pills.tvaRecovered}${
        pills?.search ? `?search=${pills?.search}` : ""
      }?showIncompleteVehicles=${pills.showIncompleteVehicles}?purcahseType=${
        pills.purchaseType
      }?sortByCreatedAt=${pills.sortByCreatedAt ? pills.sortByCreatedAt : null}`
    );
  };

  /**
   *
   * @param event
   */
  const handleMinInputChangeURL = (event: any) => {
    navigate(
      `${localUrlEnum.financialDepartment}?status=${
        pills.status
      }?page=${1}?perPage=${pills.perPage}?statusVehicul=${
        pills.statusVehicul ? pills.statusVehicul : null
      }?users=${pills.users ? pills.users : null}?company=${
        pills.company ? pills.company : null
      }?locations=${pills.locations ? pills.locations : null}?vehicleTypes=${
        pills.vehicleTypes ? pills.vehicleTypes : null
      }?minPrice=${
        event.target.value === "" ? "null" : event.target.value
      }?maxPrice=${pills.maxPrice}?visible=${pills.visible}?statusError=${
        pills.statusError
      }?tvaRecovered=${pills.tvaRecovered}${
        pills?.search ? `?search=${pills?.search}` : ""
      }?showIncompleteVehicles=${pills.showIncompleteVehicles}?purcahseType=${
        pills.purchaseType
      }?sortByCreatedAt=${pills.sortByCreatedAt ? pills.sortByCreatedAt : null}`
    );
  };

  /**
   *
   * @param event
   */
  const handleMaxInputChangeURL = (event: any) => {
    navigate(
      `${localUrlEnum.financialDepartment}?status=${
        pills.status
      }?page=${1}?perPage=${pills.perPage}?statusVehicul=${
        pills.statusVehicul ? pills.statusVehicul : null
      }?users=${pills.users ? pills.users : null}?company=${
        pills.company ? pills.company : null
      }?locations=${pills.locations ? pills.locations : null}?vehicleTypes=${
        pills.vehicleTypes ? pills.vehicleTypes : null
      }?minPrice=${pills.minPrice}?maxPrice=${
        event.target.value === "" ? "null" : event.target.value
      }?visible=${pills.visible}?statusError=${
        pills.statusError
      }?tvaRecovered=${pills.tvaRecovered}?search=${
        pills.search ? pills.search : null
      }?showIncompleteVehicles=${pills.showIncompleteVehicles}?purcahseType=${
        pills.purchaseType
      }?sortByCreatedAt=${pills.sortByCreatedAt ? pills.sortByCreatedAt : null}`
    );
  };

  /**
   *
   * @param event
   */
  const onChangeTextField = (event: any, newValue: any, name: string) => {
    switch (name) {
      case "statusVehicul":
        navigate(
          `${localUrlEnum.financialDepartment}?status=${
            pills.status
          }?page=${1}?perPage=${pills.perPage}?statusVehicul=${
            newValue ? JSON.stringify(newValue) : null
          }?users=${pills.users ? pills.users : null}?company=${
            pills.company ? pills.company : null
          }?locations=${
            pills.locations ? pills.locations : null
          }?vehicleTypes=${
            pills.vehicleTypes ? pills.vehicleTypes : null
          }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
            pills.visible
          }?statusError=${pills.statusError}?tvaRecovered=${
            pills.tvaRecovered
          }${
            pills?.search ? `?search=${pills?.search}` : ""
          }?showIncompleteVehicles=${
            pills.showIncompleteVehicles
          }?purcahseType=${pills.purchaseType}?sortByCreatedAt=${
            pills.sortByCreatedAt ? pills.sortByCreatedAt : null
          }`
        );
        break;
      case "users":
        navigate(
          `${localUrlEnum.financialDepartment}?status=${
            pills.status
          }?page=${1}?perPage=${pills.perPage}?statusVehicul=${
            pills.statusVehicul ? pills.statusVehicul : null
          }?users=${newValue ? JSON.stringify(newValue) : null}?company=${
            pills.company ? pills.company : null
          }?locations=${
            pills.locations ? pills.locations : null
          }?vehicleTypes=${
            pills.vehicleTypes ? pills.vehicleTypes : null
          }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
            pills.visible
          }?statusError=${pills.statusError}?tvaRecovered=${
            pills.tvaRecovered
          }${
            pills?.search ? `?search=${pills?.search}` : ""
          }?showIncompleteVehicles=${
            pills.showIncompleteVehicles
          }?purcahseType=${pills.purchaseType}?sortByCreatedAt=${
            pills.sortByCreatedAt ? pills.sortByCreatedAt : null
          }`
        );
        break;
      case "company":
        navigate(
          `${localUrlEnum.financialDepartment}?status=${
            pills.status
          }?page=${1}?perPage=${pills.perPage}?statusVehicul=${
            pills.statusVehicul ? pills.statusVehicul : null
          }?users=${pills.users ? pills.users : null}?company=${
            newValue ? JSON.stringify(newValue) : null
          }?locations=${
            pills.locations ? pills.locations : null
          }?vehicleTypes=${
            pills.vehicleTypes ? pills.vehicleTypes : null
          }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
            pills.visible
          }?statusError=${pills.statusError}?tvaRecovered=${
            pills.tvaRecovered
          }${
            pills?.search ? `?search=${pills?.search}` : ""
          }?showIncompleteVehicles=${
            pills.showIncompleteVehicles
          }?purcahseType=${pills.purchaseType}?sortByCreatedAt=${
            pills.sortByCreatedAt ? pills.sortByCreatedAt : null
          }`
        );
        break;
      case "locations":
        navigate(
          `${localUrlEnum.financialDepartment}?status=${
            pills.status
          }?page=${1}?perPage=${pills.perPage}?statusVehicul=${
            pills.statusVehicul ? pills.statusVehicul : null
          }?users=${pills.users ? pills.users : null}?company=${
            pills.company ? pills.company : null
          }?locations=${
            newValue ? JSON.stringify(newValue) : null
          }?vehicleTypes=${
            pills.vehicleTypes ? pills.vehicleTypes : null
          }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
            pills.visible
          }?statusError=${pills.statusError}?tvaRecovered=${
            pills.tvaRecovered
          }${
            pills?.search ? `?search=${pills?.search}` : ""
          }?showIncompleteVehicles=${
            pills.showIncompleteVehicles
          }?purcahseType=${pills.purchaseType}?sortByCreatedAt=${
            pills.sortByCreatedAt ? pills.sortByCreatedAt : null
          }`
        );
        break;
      case "vehicleTypes":
        navigate(
          `${localUrlEnum.financialDepartment}?status=${
            pills.status
          }?page=${1}?perPage=${pills.perPage}?statusVehicul=${
            pills.statusVehicul ? pills.statusVehicul : null
          }?users=${pills.users ? pills.users : null}?company=${
            pills.company ? pills.company : null
          }?locations=${
            pills.locations ? pills.locations : null
          }?vehicleTypes=${
            newValue ? JSON.stringify(newValue) : null
          }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
            pills.visible
          }?statusError=${pills.statusError}?tvaRecovered=${
            pills.tvaRecovered
          }${
            pills?.search ? `?search=${pills?.search}` : ""
          }?showIncompleteVehicles=${
            pills.showIncompleteVehicles
          }?purcahseType=${pills.purchaseType}?sortByCreatedAt=${
            pills.sortByCreatedAt ? pills.sortByCreatedAt : null
          }`
        );
        break;
      case "showIncompleteVehicles":
        navigate(
          `${localUrlEnum.financialDepartment}?status=${
            pills.status
          }?page=${1}?perPage=${pills.perPage}?statusVehicul=${
            pills.statusVehicul ? pills.statusVehicul : null
          }?users=${pills.users ? pills.users : null}?company=${
            pills.company ? pills.company : null
          }?locations=${
            pills.locations ? pills.locations : null
          }?vehicleTypes=${newValue?.id ? newValue?.id : null}?minPrice=${
            pills.minPrice
          }?maxPrice=${pills.maxPrice}?visible=${pills.visible}?statusError=${
            pills.statusError
          }?tvaRecovered=${pills.tvaRecovered}${
            pills?.search ? `?search=${pills?.search}` : ""
          }?showIncompleteVehicles=${newValue}?purcahseType=${
            pills.purchaseType
          }?sortByCreatedAt=${
            pills.sortByCreatedAt ? pills.sortByCreatedAt : null
          }`
        );
        break;
      default:
        break;
    }
  };

  /**
   *
   * @param statusError
   */
  function changeStatusError(statusError: string) {
    navigate(
      `${localUrlEnum.financialDepartment}?status=${pills.status}?page=${
        pills.page + 1
      }?perPage=${pills.perPage}?statusVehicul=${
        pills.statusVehicul ? pills.statusVehicul : null
      }?users=${pills.users ? pills.users : null}?company=${
        pills.company ? pills.company : null
      }?locations=${pills.locations ? pills.locations : null}?vehicleTypes=${
        pills.vehicleTypes ? pills.vehicleTypes : null
      }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
        pills.visible
      }?statusError=${statusError}?tvaRecovered=${pills.tvaRecovered}${
        pills?.search ? `?search=${pills?.search}` : ""
      }?showIncompleteVehicles=${pills.showIncompleteVehicles}`
    );
  }

  /**
   *
   * @param tvaRecovered
   */
  function changeTvaRecovered(tvaRecovered: string) {
    navigate(
      `${localUrlEnum.financialDepartment}?status=${pills.status}?page=${
        pills.page + 1
      }?perPage=${pills.perPage}?statusVehicul=${
        pills.statusVehicul ? pills.statusVehicul : null
      }?users=${pills.users ? pills.users : null}?company=${
        pills.company ? pills.company : null
      }?locations=${pills.locations ? pills.locations : null}?vehicleTypes=${
        pills.vehicleTypes ? pills.vehicleTypes : null
      }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
        pills.visible
      }?visible=${pills.visible}?statusError=${
        pills.statusError
      }?tvaRecovered=${tvaRecovered}${
        pills?.search ? `?search=${pills?.search}` : ""
      }?showIncompleteVehicles=${pills.showIncompleteVehicles}`
    );
  }

  /**
   *
   * @param event
   * @param index
   */
  const handleChange = (event: any, index: number) => {
    deleteFilters();
    navigate(
      `${localUrlEnum.financialDepartment}?status=${
        localUrlEnum.financialTab[index]
      }?page=${1}?perPage=${pills.perPage}?showIncompleteVehicles=${
        pills.showIncompleteVehicles
      }`
    );
  };

  /**
   *
   * @param newFilters
   */
  function handleAccordion(open: boolean) {
    setOpenAccordion(open);
  }

  /**
   *
   * @param newFilters
   */
  function setShowIncompleteVehicles(
    event: ChangeEvent<HTMLInputElement>,
    newValue: boolean
  ) {
    setPills({ ...pills, showIncompleteVehicles: newValue });
    onChangeTextField(event, newValue, "showIncompleteVehicles");
  }

  /**
   *
   */
  function deleteFilters() {
    setPills({
      ...pills,
      status: VehiclesTab.unpaid,
      statusVehicul: '[]',
      users: "[]",
      company: "[]",
      locations: "[]",
      vehicleTypes: "[]",
      minPrice: null,
      maxPrice: null,
      visible: "null",
      statusError: "null",
      page: 0,
      perPage: 10,
      tvaRecovered: "null",
      search: null,
      sortByCreatedAt: false,
      showNegativeProfit: false,
      category_id: null,

    });
  }

  return (
    <div
      style={{ display: "flex", flexDirection: isMobile() ? "column" : "row" }}
    >
      {isMobile() ? (
        <Accordion
          expanded={openAccordion}
          onChange={() => handleAccordion(!openAccordion)}
          style={{ zIndex: 10 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <TuneIcon style={{ marginRight: 10 }}></TuneIcon>
            <Typography style={{ marginTop: 1 }}>Filtre </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <VehicleSlider
              key={new Date().getTime()}
              data={pills}
              users={users}
              handleSliderChange={handleSliderChange}
              handleMinInputChangeURL={handleMinInputChangeURL}
              handleMaxInputChangeURL={handleMaxInputChangeURL}
              onChangeTextField={onChangeTextField}
              changeStatusError={changeStatusError}
              changeTvaRecovered={changeTvaRecovered}
              handleAccordion={handleAccordion}
              setShowIncompleteVehicles={setShowIncompleteVehicles}
              statusesForFilters={[]}
              sellingStatuses={state?.sellingStatuses}
            />
          </AccordionDetails>
        </Accordion>
      ) : (
        <div className={style.vehicleSlider}>
          <VehicleSlider
            key={new Date().getTime()}
            users={users}
            data={pills}
            handleSliderChange={handleSliderChange}
            handleMinInputChangeURL={handleMinInputChangeURL}
            handleMaxInputChangeURL={handleMaxInputChangeURL}
            onChangeTextField={onChangeTextField}
            changeStatusError={changeStatusError}
            changeTvaRecovered={changeTvaRecovered}
            handleAccordion={handleAccordion}
            setShowIncompleteVehicles={setShowIncompleteVehicles}
            sellingStatuses={state?.sellingStatuses}
            statusesForFilters={[]}
          />
        </div>
      )}

      <div className={style.mainTable}>
        {
          <MainTableFinancial
            type={localUrlEnum.financialDepartment}
            name={componentNames.MainTable}
            key={new Date().getTime()}
            info={{ route: "vehicleDashboard" }}
            pills={pills}
            state={state}
            value={value}
            handleChange={handleChange}
            getDataRefresh={getUrlVehicles}
            vehicleCounter={vehicleCounter}
          ></MainTableFinancial>
        }
      </div>
    </div>
  );
}
