/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Circle,
  Close,
  DoneAll,
  NotificationsActive,
  TagFaces,
} from "@mui/icons-material";
import {
  Badge,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
} from "@mui/material";
import styles from "../Styles/mainHeader.module.css";
import { Fragment, useEffect, useMemo, useState } from "react";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/urlEnum";
import { Vocabulary } from "../../Utils/Vocabulary";
import moment from "moment";
import { postData } from "../../Services/postData";
import createConnectionForWebsocket from "../../Utils/utils";
import MUIDataTable from "mui-datatables";

export default function NewsFeedCardContent() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [pusher, setPusher] = useState<any>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  /**
   *
   */
  useEffect(() => {
    getNotificationsForUser();
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (!pusher) setPusher(createConnectionForWebsocket());
    if (pusher) pusherEvent();
    return () => {
      if (pusher) {
        pusher.unsubscribe(`notifications.${localStorage.getItem("useId")}`);
        pusher.unbind("send-notifications");
        pusher.disconnect();
      }
    };
  }, [pusher]);

  /**
   *
   */
  const getNotificationsForUser = () => {
    getData(`${urlEnum.notifications}/${localStorage.getItem("useId")}`).then(
      (res) => {
        if (res) {
          setNotifications(res.data.notifications);
          setNotificationCount(res.data.notificationsNumber);
        }
      }
    );
  };

  function CustomRowComponent(props: any) {
    const { entityName, message, created_at } = props;

    return (
      <div
        style={{
          margin: 10,
          borderRadius: 10,
          backgroundColor: "#fd3",
          padding: 10,
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <div>
          <h3>{entityName}</h3>
          <p>
            {message} <br />
          </p>
        </div>
        <div>{created_at}</div>
      </div>
    );
  }

  /**
   *
   */
  const options = {
    pagination: false,
    filter: false,
    download: false,
    viewColumns: false,
    responsive: "standard" as any,
    sort: false,
    textLabels: {
      viewColumns: {
        title: "Afisare coloane",
        titleAria: "Afisare Coloane",
      },
      pagination: Vocabulary.pagination,
    },

    fixedHeader: false,
    fixedSelectColumn: false,
    selectableRows: "none" as any,
    print: false,
    serverSide: true,
    search: false,
    perPage: 5,
    rowHover: true,
    customRowRender: (data: any) => {
      const [id, entityName, message, created_at] = data;

      return (
        <tr key={message}>
          <td colSpan={2}>
            <CustomRowComponent
              entityName={message}
              message={entityName}
              created_at={created_at}
            />
          </td>
        </tr>
      );
    },
  };

  /**
   *
   */
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "message",
      label: "Notificare",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "entityName",
      label: "Nume vehicul",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "created_at",
      label: "Nume vehicul",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
  ];

  /**
   *
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  /**
   *
   */
  const handleClose = () => {
    //set notifications to read
    //create array containing ids of notifications  with status unread
    const unreadNotifications = notifications
      .filter((notification) => !!notification.isRead === false)
      .map((notification) => notification.id);
    if (unreadNotifications.length > 0) {
      postData(urlEnum.markNotificationAsRead, {
        notificationsIds: unreadNotifications,
      }).then((res: any) => {
        if (res) {
          getNotificationsForUser();
        }
      });
    }
    setAnchorEl(null);
  };

  /**
   *
   */
  function pusherEvent() {
    // const channel = pusher.subscribe(`notifications`);
    const channel = pusher.subscribe(
      `notifications.${localStorage.getItem("useId")}`
    );
    channel.bind("send-notifications", (data: any) => {
      // setNotifications([data.notification, ...notifications]);
      // setNotificationCount(notificationCount + 1);
      getNotificationsForUser();
      if (data?.notification?.message) {
        new Notification(data?.notification?.message || "");
        const audio = new Audio("/notificare.mp3");
        audio.play();
      }
    });
  }

  return (
    <>
      <MUIDataTable
        title={"Ultimele notificari"}
        data={notifications}
        columns={columns}
        options={options}
      />
    </>
  );
}
