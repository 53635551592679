import { Vocabulary } from "../Vocabulary";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import NoCrashIcon from "@mui/icons-material/NoCrash";

// import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';

export const ROUTER_FINANCIAL_DEPARTMENT = {
  routes: [
    "/",
    "/financialDepartment",
    "/controlPanel",
    "/vehiclesDashboard/vehicleDashboard/:id?",
    "/vehiclesDashboard/vehicleDetails/:id?",
    "*",
  ],
};

export const MAIN_SLIDER_FINANCIAL_DEPARTMENT = [{
  name: Vocabulary.shortDashboardFinancial,
  path: "/financialDepartment",
  icon: <FactCheckIcon />,
  orderId: 13,
},
//DOAR DE SCOS COMENTARIILE SI APARARE PANOUL DE CONTROL
// {
//   name: Vocabulary.controlPanel,
//   path: "/controlPanel",
//   icon: <ViewCarouselIcon />,
//   orderId: 0,
// }
];

export const VEHICLE_FINANCIAL_DEPARTMENT = [
  {
    name: Vocabulary.dashboard,
    path: `/vehiclesDashboard/vehicleDashboard/`,
    icon: <DashboardIcon />,
    id: "vehicleDashboard",
    orderNumber: 0,
  },
  {
    name: Vocabulary.detaliiVehicul,
    path: "/vehiclesDashboard/vehicleDetails/",
    icon: <NoCrashIcon />,
    id: "vehicleDetails",
    orderNumber: 1,
  },
];

export const DINAMIC_TABS_FINANCIAL_DEPARTMENT = null;

export const MAIN_TABLE_FINANCIAL_DEPARTMENT = null;

export const VEHICLES_DASHBOARD_FINANCIAL_DEPARTMENT = [1,2,3,4];

export const LOGIN_PAGE_FINANCIAL_DEPARTMENT = "/financialDepartment";

export const MAIN_HEADER_FINANCIAL_DEPARTMENT = "vehicleDashboard";

