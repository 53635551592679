/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Vocabulary } from "../../../Utils/Vocabulary";
import { useContext, useEffect, useState } from "react";
import { VehicleContext } from "../../../Context/vehicleContext";

import GenericModal from "../../../Modal/GenericModal";
import { europeanDate } from "../../../Utils/formatDate";
import moment from "moment";
import { updateData } from "../../../Services/updateData";
import { CacheContext } from "../../../Context/cacheContext";
import { VehiclesStatus } from "../../../Utils/autocompleteEnum";
import { sellTypeOrReserve, urlEnum } from "../../../Utils/urlEnum";
import useResetContextState from "../../../Hooks/useResetContextState";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Client from "../../Clients/Client";

type SubProgressInformation3_1Props = {
  disabled?: boolean;
  handleModalReserve: () => void;
  open: boolean;
  clients: object[];
  refreshClientsList?: any;
};

export default function SubProgressInformation3_1(
  props: SubProgressInformation3_1Props
) {
  const vehicleContext: any = useContext(VehicleContext);
  const cacheContext = useContext(CacheContext) as any;
  const resetContextState = useResetContextState();
  const [openModalClient, setOpenModalClient] = useState(false);

  useEffect(() => {
    if(props.open === true){
      resetContextState.setInitialState();
    }
  }, [props.open]);

  /**
   *
   */
  const handleOpenCloseModal = () => {
    if (props.open) {
      resetContextState.resetState();
    }
    props.handleModalReserve && props.handleModalReserve();
  };

  /**
   *
   * @param param0
   */
  function EditButton({ children, ...other }: any) {
    return (
      <Paper {...other}>
        <IconButton
          onMouseDown={(event) => {
            event.preventDefault();
            setOpenModalClient(true);
          }}
          style={{
            position: "absolute",
            top: -40,
            right: -36,
            margin: 5,
            display: "flex",
            justifyContent: "end",
            backgroundColor: "#FF6633",
            color: "#fff",
            borderRadius: 7,
            zIndex: 100,
          }}
          size="small"
          aria-label="editDropdown"
        >
          <AddCircleIcon fontSize="inherit" />
        </IconButton>
        {children}
      </Paper>
    );
  }

  /**
   *
   */
  async function reserveVehicle() {
    if (
      vehicleContext?.state?.vehicle?.sellTypeOrReserve === 2 &&
      !vehicleContext?.state?.vehicle?.contractData.observatii?.length
    )
      return;

    const status = cacheContext.cache?.vehicleStatus?.find(
      (status: any) => status.nume === VehiclesStatus.reserved
    );
    const data = {
      ...vehicleContext.state.vehicle,
      status_id: status.id,
      client_id: vehicleContext?.state?.vehicle?.client_id,
      data_rezervare: moment(
        vehicleContext?.state?.vehicle?.data_rezervare
      ).format("YYYY-MM-DD HH:mm:ss"),

      indexes: vehicleContext.updateIndex(["3.1"], null),
      modifyBy: vehicleContext.updateModifyBy([{index: "3.1",userId: localStorage.getItem("userName"),date: moment().format("DD-MM-YYYY HH:mm:ss")}]),
    };

    const url = `${urlEnum.reserveVehicle}/${vehicleContext?.state?.vehicle?.id}`;
    if (
      vehicleContext?.state?.vehicle?.data_rezervare &&
      vehicleContext?.state?.vehicle?.client_id
    ) {
      const response = await updateData(url, data);

      // update documents
      vehicleContext.updateVehicle(response?.data?.vehicle);
      vehicleContext.updateVehicleDocuments(response?.data?.documents);
      vehicleContext.updateVehicleInvoices({
        clientInvoice: response?.data?.clientInvoice
          ? response.data.clientInvoice
          : [],
        providerInvoice: response.data.providerInvoice
          ? response.data.providerInvoice
          : [],
        OPInvoice: response.data.OPInvoice ? response.data.OPInvoice : [],
        reservedDocuments: response.data.reservedDocuments
          ? response.data.reservedDocuments
          : [],
      });

      props.handleModalReserve();
    }
  }

  /**
   *
   * @param event
   */
  function onChangeObservationFieldInContractInformation(event: any) {
    const newContractInformation = {
      ...vehicleContext?.state?.vehicle?.contractData,
      observatii: event.target.value,
    };

    vehicleContext.onChangeVehicle({
      name: "contractData",
      value: newContractInformation,
    });
  }

  return (
    <div style={{ margin: 15 }}>
      <GenericModal
        open={props.open}
        onClose={handleOpenCloseModal}
        title={Vocabulary.reserveVehicleMessage}
      >
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <>
            <Grid item xs={6} md={12} xl={6}>
              <Autocomplete
                id="client"
                freeSolo={false}
                disableClearable
                disablePortal
                PaperComponent={EditButton}
                getOptionLabel={(option: any) => option.nume}
                size="small"
                disabled={props.disabled}
                value={props?.clients?.find(
                  (element: any) =>
                    element.id === +vehicleContext?.state?.vehicle?.client_id
                )}
                options={props.clients}
                onChange={(event: any, newValue: any) =>
                  vehicleContext.onChangeVehicle({
                    name: "client_id",
                    value: newValue.id,
                  })
                }
                sx={{ width: "100%" }}
                ListboxProps={{ style: { maxHeight: 150 } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={Vocabulary.client}
                    variant="outlined"
                    size="small"
                    error={!vehicleContext?.state?.vehicle?.client_id}
                    helperText={
                      vehicleContext?.state?.vehicle?.client_id
                        ? null
                        : Vocabulary.required
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={12} xl={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  disabled={props.disabled}
                  format={europeanDate}
                  label={Vocabulary.reservedDate}
                  value={moment(vehicleContext?.state?.vehicle?.data_rezervare)}
                  onChange={(e: any) =>
                    vehicleContext.onChangeVehicleReservationDate(e)
                  }
                  slotProps={{
                    textField: { size: "small", style: { width: "100%" } },
                  }}
                />
                <p
                  style={{
                    fontSize: 12,
                    marginTop: 5,
                    marginLeft: 10,
                    color: "#d32f2f",
                  }}
                >
                  {vehicleContext?.state?.vehicle?.data_rezervare
                    ? null
                    : Vocabulary.required}
                </p>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <Autocomplete
                id="sellTypeOrReserve"
                disablePortal
                getOptionLabel={(option: any) => option.nume}
                size="small"
                disabled={props.disabled}
                options={sellTypeOrReserve}
                onChange={(event: any, newValue: any) => {
                  vehicleContext.onChangeVehicle({
                    name: "sellTypeOrReserve",
                    value: newValue?.id,
                  });
                  vehicleContext.onChangeVehicle({
                    name: "isLeasing",
                    value: newValue?.value,
                  });
                }}
                sx={{ width: "100%" }}
                ListboxProps={{ style: { maxHeight: 150 } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={Vocabulary.sellTypeOrReserve}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
          </>
          {vehicleContext.state.vehicle?.sellTypeOrReserve === 2 ? (
            <Grid item xs={12} md={12} xl={12}>
              <TextField
                fullWidth
                label={Vocabulary.observations}
                variant="outlined"
                name="observation"
                size="small"
                type="text"
                disabled={
                  vehicleContext?.state?.vehicle?.sellTypeOrReserve !== 2
                }
                value={
                  vehicleContext?.state?.vehicle?.contractData?.observatii
                    ? vehicleContext?.state?.vehicle?.contractData.observatii
                    : ""
                }
                onChange={(event) =>
                  onChangeObservationFieldInContractInformation(event)
                }
                error={
                  vehicleContext?.state?.vehicle?.sellTypeOrReserve === 2 &&
                  !vehicleContext?.state?.vehicle?.contractData.observatii
                    ?.length
                    ? true
                    : false
                }
                helperText={
                  vehicleContext?.state?.vehicle?.sellTypeOrReserve === 2 &&
                  !vehicleContext?.state?.vehicle?.contractData.observatii
                    ?.length
                    ? Vocabulary.required
                    : null
                }
              />
            </Grid>
          ) : null}

          <Grid item xs={6}>
            <Button
              size="large"
              onClick={handleOpenCloseModal}
              variant="contained"
              color="secondary"
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={reserveVehicle}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
      <Client
        open={openModalClient}
        onClose={(shouldRefetch) => {
          setOpenModalClient(false);
          props.refreshClientsList();
        }}
        title={Vocabulary.addNewClient}
      />
    </div>
  );
}
