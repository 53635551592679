import axios from "axios";
import { toast } from "react-toastify";
import { Vocabulary } from "./Vocabulary";
import { urlEnum } from "./urlEnum";
import { disableTostifyConfig } from "./utils";

const notifyError = (message: string) => toast.error(message);
const notifySuccess = (message: string) => toast.success(message);
const token = localStorage.getItem("access_token");
let axiosConfig = {} as any;
if (token) {
  axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

const request = axios.create(axiosConfig);
let requestNumber = 0;
request.interceptors.response.use(
  (response) => {
    if (response?.status === 200 || response?.status === 201) {
      if (response.config.method === "post") {
        if (
          response.config.url &&
          response.config.url !== "/api/logout" &&
          response.config.url !== "/api/login" &&
          !response.config.url.includes("api/vehicule/vehiclesEditing") &&
          !response.config.url.includes("api/notifications") &&
          !response.config.url.includes("/api/templates/generateGDPR") &&
          !response.config.url.includes("/api/vehicule/search") &&
          !response.config.url.includes("/api/transports") &&
          response.config.url != urlEnum.getCountries &&
          !response.config.url.includes(urlEnum.userBonuses) &&
          !response.config.url.includes(disableTostifyConfig)
        )
          notifySuccess(Vocabulary.successfullyAdded);
      }
      if (response.config.method === "put") {
        if (
          !response.config.url?.includes(urlEnum.notes) &&
          !response?.config.url?.includes(disableTostifyConfig)
        )
          notifySuccess(Vocabulary.successfullyUpdated);
      }
      if (response.config.method === "delete") {
        if (
          response.config.url &&
          !response.config.url.includes("api/vehicule/vehiclesEditing")
        )
          notifySuccess(Vocabulary.successfullyDeleted);
      }
    } else {
      //
    }
    requestNumber--;
    if (requestNumber === 0) {
      const element = document.getElementById("loading");
      element?.classList.remove("loading");
      element?.classList.add("loadingDisabled");
    }
    return response;
  },
  (error) => {
    requestNumber--;
    if (error.response?.status && error.response?.status === 401) {
      localStorage.setItem("useId", "");
      localStorage.setItem("userName", "");
      localStorage.setItem("userEmail", "");
      localStorage.setItem("access_token", "");
      localStorage.setItem("cacheData", "");
      localStorage.setItem("permissions", "");
      localStorage.setItem("roles", "");
      document.cookie = "accept_notification =; ";
      window.location.href = "/login";
    }
    if (error.response?.data.message && error.response?.data.message !== "")
      notifyError(error.response.data.message);
    else {
      if (
        error.config.method === "post" &&
        !error?.config?.url?.includes(disableTostifyConfig)
      ) {
        notifyError(Vocabulary.addError);
      }
      if (error.config.method === "put") {
        notifyError(Vocabulary.updateError);
      }
      if (error.config.method === "delete") {
        notifyError(Vocabulary.deleteError);
      }
    }
    const element = document.getElementById("loading");
    element?.classList.remove("loading");
    element?.classList.add("loadingDisabled");
  }
);

request.interceptors.request.use(
  (config) => {
    requestNumber++;
    const element = document.getElementById("loading");
    //if its the cache request, not show loading element
    if (config?.url?.includes(urlEnum.cacheData)) {
      element?.classList.add("loadingDisabled");
    } else {
      element?.classList.remove("loadingDisabled");
      element?.classList.add("loading");
    }

    return config;
  },
  (error) => {
    const element = document.getElementById("loading");
    element?.classList.remove("loading");
    element?.classList.add("loadingDisabled");
  }
);

export default request;
