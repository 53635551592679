/* eslint-disable @typescript-eslint/no-explicit-any */
// import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import style from "../../Styles/reports.module.css";
import { formatNumber } from "../../Utils/formatDate";
import { isMobile } from "../../Utils/utils";

export default function GraphicBar(props: any) {
  function sumByYear(data: any) {
    const yearSums: any = {};

    data?.forEach((item: any) => {
      Object.keys(item).forEach((key: any) => {
        // Check if the key is a year (i.e., a number)
        if (!isNaN(key)) {
          // Add the value to the corresponding year in yearSums
          if (!yearSums[key]) {
            yearSums[key] = 0;
          }
          yearSums[key] += item[key];
        }
      });
    });

    return yearSums;
  }

  /**
   *
   * @param data
   * @returns calculated averaged value by year
   */
  function averageByYear(data: any) {
    const yearSums: any = {};
    const yearCounts: any = {};

    data.forEach((item: any) => {
      Object.keys(item).forEach((key: any) => {
        // Check if the key is a year (i.e., a number)
        if (!isNaN(key)) {
          // Add the value to the corresponding year in yearSums
          if (!yearSums[key]) {
            yearSums[key] = 0;
            yearCounts[key] = 0;
          }
          yearSums[key] += item[key];

          // Increment the month count for this year
          yearCounts[key] += 1;
        }
      });
    });

    const result: any = [];
    Object.keys(yearSums).forEach((year: any) => {
      const average = yearSums[year] / yearCounts[year];
      result[year] = average;
    });

    return result;
  }

  /**
   *
   * @param
   * @returns
   */
  const CustomLabelLayer = ({ bars }: any) => {
    // Function to check if two labels overlap
    const checkOverlap = (bar1: any, bar2: any) => {
      return Math.abs(bar1.y - bar2.y) < 10; // Adjust tolerance as needed
    };
    // Determine if all bars are from the same year
    const allSameYear = bars.every(
      (bar: any) => bar.data?.id == bars[0].data?.id
    );

    if (!allSameYear) {
      return null; // Don't render anything if years are different
    }

    //get the highest bar
    const highestValue = Math.max(...bars.map((bar: any) => bar.data?.value));

    return bars?.map((bar: any, index: number) => {
      let yOffset = 0;

      if (index > 0) {
        const previousBar = bars[index - 1];
        if (checkOverlap(bar, previousBar)) {
          yOffset = bar.data.value > previousBar.data.value ? -30 : 40; // Shift one up, one down
        }
      }
      return (
        <g
          key={bar.key}
          transform={`translate(${bar.x + bar.width / 2}, ${
            bar.data.value < highestValue ? bar.y + 12 + yOffset : bar.y +12
          })`}
          className={style.reportsBarLabel}
        >
          <text
            textAnchor="middle"
            alignmentBaseline="middle"
            style={{ fill: "black", fontSize: "12px" }}
          >
            {formatNumber(bar.data.value)}
          </text>
        </g>
      );
    });
  };

  return (
    <div
      style={{
        width: "100%",
        height: props.miniGraph ? "400px" : "500px",
        marginTop: 20,
        textAlign: "center",
      }}
      id={props.title}
    >
      <p className={style.statisticallyText}>
        <span>{props.title}</span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 2,
              fontSize: 13,
              lineHeight: 0.5,
              fontWeight: 600,
            }}
          >
            {Object.keys(sumByYear(props.data)).map((key: any) => (
              <p>{key + ":"}</p>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 2,
              fontSize: 13,
              lineHeight: 0.5,
            }}
          >
            {props.averageValue
              ? Object.keys(sumByYear(props.data)).map((key: any) => (
                  <>
                    <p>
                      {props.noCurency
                        ? formatNumber(
                            parseFloat(
                              averageByYear(props.data)[key].toFixed(0)
                            )
                          )
                        : formatNumber(
                            parseFloat(
                              averageByYear(props.data)[key].toFixed(2)
                            )
                          )}
                      {props.noCurency ? " " : " Lei"}
                    </p>
                  </>
                ))
              : Object.keys(sumByYear(props.data)).map((key: any) => (
                  <>
                    <p>
                      {props.noCurency
                        ? formatNumber(
                            parseFloat(sumByYear(props.data)[key].toFixed(0))
                          )
                        : formatNumber(
                            parseFloat(sumByYear(props.data)[key].toFixed(2))
                          )}
                      {props.noCurency ? " " : " Lei"}
                    </p>
                  </>
                ))}
          </div>
        </div>
      </p>
      <ResponsiveBar
        valueFormat={(value) => formatNumber(value)}
        data={props.data}
        groupMode="grouped"
        margin={{ top: 10, right: 100, bottom: 200, left: 80 }}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: "gray",
              },
            },
            legend: {
              text: {
                fill: "gray",
              },
            },
            ticks: {
              line: {
                stroke: "gray",
                strokeWidth: 1,
              },
              text: {
                fill: "gray",
              },
            },
          },
          legends: {
            text: {
              fill: "gray",
            },
          },
        }}
        keys={Object.keys(sumByYear(props.data))}
        indexBy="month"
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: props.color ? "pastel1" : "pastel2" }}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: isMobile() ? -90 : -45,
          legend: "Lunile anului",
          legendPosition: "middle",
          legendOffset: 80,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.noCurency ? props.title : `${props.title} (LEI)`,
          legendPosition: "middle",
          legendOffset: -60,
        }}
        enableLabel={false}
        // labelSkipWidth={67}
        // labelSkipHeight={67}
        // labelTextColor={{
        //   from: "color",
        //   modifiers: [["darker", 1.5]],
        // }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        layers={[
          "grid",
          "axes",
          "bars",
          CustomLabelLayer, // Add custom label
          "markers",
          "legends",
        ]}
      />
    </div>
  );
}
