

type ProgressActionProps = {
  active?: boolean;
  children?: JSX.Element;
  key?: number;
  index:string;
};
export default function ProgressAction({
  active,
  children,
  index,
  key,
}: ProgressActionProps) {
  return <>{children}</>;
}
