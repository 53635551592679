/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContentState, EditorState, Modifier, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { postData } from "../../Services/postData";
import htmlToDraft from "html-to-draftjs";
import SaveIcon from "@mui/icons-material/Save";
import { urlEnum } from "../../Utils/urlEnum";
import { resizeImage } from "../../Utils/utils";
import GenericTemplateDetails from "./GenericTemplateDetails";
import { GenericTemplatesProps } from "./GenericTemplates";
import TemplateMacros from "./TemplateMacros";

type GenericTemplateProps = {
  template: GenericTemplatesProps | null;
  fromVehicle: boolean;
  handleChangeGenericTemplate?: (template: any) => void;
  onClose?: (shouldRefetch?: boolean) => void;
};

export default function GenericTemplate(props: GenericTemplateProps) {
  const { template, fromVehicle, handleChangeGenericTemplate, onClose } = props;
  const [genericTemplate, setGenericTemplate] = useState<GenericTemplatesProps>(
    {
      templateName: "",
      html: undefined,
      subject: "",
      cc: "",
      type: "",
      status_id: null,
    }
  );
  const [genericTemplateHTML, setGenericTemplateHTML] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [lastFocused, setLastFocused] = useState(Vocabulary.subject);
  const [uploadedImages, setUploadedImages] = useState<any[]>([]);

  useEffect(() => {
    if (template) {
      setGenericTemplate({
        ...genericTemplate,
        templateName: template.templateName ? template.templateName : "",
        status_id: template.status_id ? template.status_id : null,
        subject: template.subject ? template.subject : "",
        cc: template.cc ? template.cc : "",
        html: getHtml(template.html as any),
        type: template.type,
      });
      setGenericTemplateHTML(template.html as any);
    } else if (!template) {
      setGenericTemplate({
        ...genericTemplate,
        html: EditorState.createEmpty(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  /**
   *
   * @param html
   * @returns
   */
  function getHtml(html: string) {
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    return EditorState.createWithContent(contentState);
  }
  /**
   *
   * @param event
   */
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   *
   * @param tag
   */
  const handleClose = (tag: any) => {
    if (tag !== "") {
      const text = `{{${tag}}}`;
      setAnchorEl(null);

      if (lastFocused === Vocabulary.subject) {
        setGenericTemplate({
          ...genericTemplate,
          subject: `${genericTemplate.subject} ${text}`,
        });
      } else {
        const editorState = genericTemplate.html as any;
        const contentState = editorState.getCurrentContent();

        let newContentState = contentState.createEntity(
          "unstyled",
          "IMMUTABLE",
          text
        );
        const entityKey = contentState.getLastCreatedEntityKey();
        const selectionState = editorState.getSelection();
        newContentState = Modifier.insertText(
          newContentState,
          selectionState,
          text,
          "" as any,
          entityKey
        );
        const newHtml = EditorState.push(
          editorState,
          newContentState,
          "apply-entity"
        );
        setGenericTemplate({ ...genericTemplate, html: newHtml });
        const htmlString = draftToHtml(
          convertToRaw(newHtml.getCurrentContent())
        );
        setGenericTemplateHTML(htmlString);
      }
    }
    setAnchorEl(null);
  };
  /**
   *
   * @param e
   */
  function onChange(e: any) {
    setGenericTemplate({
      ...genericTemplate,
      html: e,
    });
    const html = draftToHtml(convertToRaw(e.getCurrentContent()));
    setGenericTemplateHTML(html);

    // if (handleChangeGenericTemplate) {
    //   handleChangeGenericTemplate(html);
    // }
  }

  /**
   *
   */
  function handleChangeTemplateProps(e: any) {
    const newGenericTemplate = Object.assign({}, genericTemplate) as any;
    newGenericTemplate[e.target.name] = e.target.value;
    setGenericTemplate(newGenericTemplate);
  }

  /**
   *
   * @param e
   */
  function handleChangeTemplateStatus(e: any) {
    setGenericTemplate({
      ...genericTemplate,
      status_id: e.target.value,
    });
  }

  /**
   *
   * @param e
   */
  function handleChangeTemplateType(e: any) {
    setGenericTemplate({
      ...genericTemplate,
      type: e.target.value,
    });
  }

  /**
   *
   * @param value
   */
  function handleChangeOnFocus(value: string) {
    setLastFocused(value);
  }

  /**
   *
   */
  function handleSubmit() {
    const body = {
      html: genericTemplateHTML,
      templateName: genericTemplate.templateName,
      type: genericTemplate.type,
      status_id: genericTemplate.status_id,
      subject: genericTemplate.subject,
      cc: genericTemplate.cc,
    };
    let url = `${urlEnum.genericTemplates}`;
    if (template && template.id) {
      url += `/${template.id}`;
    }
    postData(url, body).then((res) => {
      if (onClose) onClose(true);
    });
  }

  /**
   *
   * @param file
   * @returns
   */
  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  /**
   *
   * @param file
   * @returns
   */
  const uploadImage = async (file: any) => {
    const newUploadedImages = uploadedImages.slice();
    const resizedFile = (await resizeImage(file)) as any;
    const base64 = await toBase64(resizedFile);
    const imageObject = {
      file: resizedFile,
      localSrc: URL.createObjectURL(resizedFile),
    };
    newUploadedImages.push(imageObject);
    setUploadedImages(newUploadedImages);
    return new Promise((resolve, reject) => {
      resolve({
        data: {
          link: base64,
        },
      });
    });
  };

  return (
    <div style={{ margin: 5 }}>
      {fromVehicle ? null : (
        <TemplateMacros
          openMenu={openMenu}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleClick={handleClick}
        />
      )}
      <GenericTemplateDetails
        fromVehicle={fromVehicle}
        genericTemplate={genericTemplate}
        handleChangeTemplateProps={handleChangeTemplateProps}
        handleChangeTemplateStatus={handleChangeTemplateStatus}
        handleChangeTemplateType={handleChangeTemplateType}
        handleChangeOnFocus={handleChangeOnFocus}
      />

      <div
        style={{
          border: "1px solid #f1f1f1",
          padding: 10,
        }}
      >
        <Editor
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbarClassName"
          handlePastedText={() => false}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            image: {
              uploadCallback: uploadImage,
              alt: { present: false, mandatory: false },
            },
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
          }}
          editorState={genericTemplate.html}
          editorStyle={{ maxHeight: "400px" }}
          onEditorStateChange={onChange}
          onFocus={() => handleChangeOnFocus(Vocabulary.editor)}
          onBlur={() => {
            if (handleChangeGenericTemplate) {
              handleChangeGenericTemplate(genericTemplateHTML);
            }
          }}
        />
      </div>
      {fromVehicle ? (
        ""
      ) : (
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            style={{
              margin: "15px 0px",
              color: "white",
            }}
            onClick={handleSubmit}
          >
            <SaveIcon /> &nbsp;&nbsp;{Vocabulary.save}
          </Button>
        </div>
      )}
    </div>
  );
}
