import { Divider, ListItem, Paper } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useContext } from "react";
import { VehiclesStatus } from "../../Utils/autocompleteEnum";
import { VehicleContext } from "../../Context/vehicleContext";
import { formatNumber } from "../../Utils/formatDate";

export default function TotalCosts() {
  const vehicleContext: any = useContext(VehicleContext);

  function showCosts() {
    if (vehicleContext?.state?.vehicle) {
      if (
        vehicleContext?.state?.vehicle?.data_vanzare &&
        vehicleContext?.state?.vehicle?.vanzator_id &&
        vehicleContext?.state?.vehicle?.statusVehicul === VehiclesStatus.sold
      ) {
        return (
          <>
            <Divider />
            <ListItem
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
              }}
            >
              <span>Costuri logistica</span>
              <span style={{ fontWeight: "bold" }}>
                {vehicleContext?.state?.vehicle?.suma_costuri_logistica} Lei
              </span>
            </ListItem>
            <Divider />
            <ListItem
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
              }}
            >
              <span>Costuri marketing</span>
              <span style={{ fontWeight: "bold" }}>
                {vehicleContext?.state?.vehicle?.suma_costuri_marketing} Lei
              </span>
            </ListItem>
            <Divider />
            <ListItem
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
              }}
            >
              <span>Costuri administrative</span>
              <span style={{ fontWeight: "bold" }}>
                {vehicleContext?.state?.vehicle?.suma_costuri_administrative}{" "}
                Lei
              </span>
            </ListItem>
            <Divider />
            <ListItem
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
                fontSize: 25,
                fontWeight: "bold",
              }}
            >
              <span style={{ color: "#d3d7de" }}>Total costuri</span>
              <span style={{ color: "#d3d7de" }}>
                {vehicleContext?.state?.vehicle?.suma_investita} Lei
              </span>
            </ListItem>
          </>
        );
      }
    }
    return "";
  }
  return (
    <Paper style={{ minHeight: "247px" }}>
      <div
        style={{
          borderTopLeftRadius: "4px 4px",
          borderTopRightRadius: "4px 4px",
          height: 43,
          marginTop: 20,
          backgroundColor: "#f63",
        }}
      >
        <p style={{ color: "white", padding: 12, fontSize: 16 }}>
          {Vocabulary.totalCosts}
        </p>
      </div>
      <div style={{ padding: 10 }}>
        <ListItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 10,
          }}
        >
          <span>Interventii vopsitorie</span>
          <span style={{ fontWeight: "bold" }}>
            {vehicleContext?.state?.vehicle
              ? formatNumber(vehicleContext?.state?.vehicle?.suma_investitii_vopsitorie)
              : 0}{" "}
            Lei
          </span>
        </ListItem>
        <Divider />
        <ListItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 10,
          }}
        >
          <span>Interventii detailing</span>
          <span style={{ fontWeight: "bold" }}>
            {vehicleContext?.state?.vehicle
              ? formatNumber(vehicleContext?.state?.vehicle?.suma_investitii_detailing)
              : 0}{" "}
            Lei
          </span>
        </ListItem>
        <Divider />
        <ListItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 10,
          }}
        >
          <span>Interventii service</span>
          <span style={{ fontWeight: "bold" }}>
            {vehicleContext?.state?.vehicle
              ? formatNumber(vehicleContext?.state?.vehicle?.suma_investitii_service)
              : 0}{" "}
            Lei
          </span>
        </ListItem>
        <Divider />
        <ListItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 10,
          }}
        >
          <span>Costuri aditionale</span>
          <span style={{ fontWeight: "bold" }}>
            {vehicleContext?.state?.vehicle
              ? formatNumber(vehicleContext?.state?.vehicle?.suma_costuri_aditionale)
              : 0}{" "}
            Lei
          </span>
        </ListItem>
        <Divider />
        <ListItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 10,
          }}
        >
          <span>Intrare garantie</span>
          <span style={{ fontWeight: "bold" }}>
            {vehicleContext?.state?.vehicle
              ? formatNumber(vehicleContext?.state?.vehicle?.suma_intrare_garantie)
              : 0}{" "}
            Lei
          </span>
        </ListItem>
        {showCosts()}
      </div>
    </Paper>
  );
}
