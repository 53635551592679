import React, { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Visibility } from "@mui/icons-material";
import style from "../Styles/loginPage.module.css";
import {
  Button,
  Paper,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { postData } from "../Services/postData";
import { urlEnum } from "../Utils/urlEnum";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Login() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errorText, setErrorText] = useState("");

  /**
   *
   */
  async function handleSubmit() {
    try {
      const response = (await postData(urlEnum.login, user)) as any;
      if (response && response.data) {
        const request = axios.create({
          headers: {
            Authorization: `Bearer ${response.data.token}`,
          },
        });
        //enable loading
        let element = document.getElementById("loading");
        element?.classList.remove("loadingDisabled");
        element?.classList.add("loading");
        const cacheDataResponse = await request.get(
          `${urlEnum.cacheData}/null`
        );
        if (cacheDataResponse && cacheDataResponse.data) {
          localStorage.setItem(
            "cacheData",
            JSON.stringify(cacheDataResponse.data)
          );
          localStorage.setItem("useId", response.data.user.id);
          localStorage.setItem("userName", response.data.user.name);
          localStorage.setItem("userEmail", response.data.user.email);
          localStorage.setItem("access_token", response.data.token);
          //save permissions
          const permissionsName: any = [];
          response.data.permissions.forEach((element: any) => {
            permissionsName.push(element.name);
          });
          //save roles
          localStorage.setItem("permissions", permissionsName);
          const rolesName: any = [];
          response.data.roles.forEach((element: any) => {
            rolesName.push(element.name);
          });
          localStorage.setItem("roles", rolesName);
        }

        //disable loading
        element = document.getElementById("loading");
        element?.classList.remove("loading");
        element?.classList.add("loadingDisabled");

        const data: any = window.localStorage.getItem("permissions");
        const formatedData = data.split(",");
        document.cookie = "accept_notification = false;";
        navigate(getPermissions(takeEachRoute(formatedData)));

        window.location.reload();
      }
    } catch (err: any) {
      if (err.response && err.response.status === 401)
        setErrorText("Parola sau nume utilizator greșite!");
      else if (err.message) setErrorText(err.message);
    }
  }

  /**
   *
   * @param event
   */
  function onChangeTextField(event: any) {
    const newUser: any = Object.assign({}, user);
    newUser[event.target.name] = event.target.value;
    setUser(newUser);
  }

  return (
    <div className={style.App}>
      <div className={style.loginContainer}>
        <div style={{ margin: 30 }}>
          <img src="/logoAutodel.png" alt="No file" width="300" />
        </div>
        <Paper className={style.loginFields}>
          <ValidatorForm className={style.form} onSubmit={handleSubmit}>
            <TextValidator
              variant="filled"
              required={true}
              id="email"
              name="email"
              label="Email"
              validators={["required"]}
              value={user.email}
              onChange={(e) => onChangeTextField(e)}
              className={style.textfield}
            />
            <TextValidator
              variant="filled"
              required={true}
              id="password"
              name="password"
              type={!showPassword ? "password" : "text"}
              label="Parola"
              validators={["required"]}
              value={user.password}
              onChange={(e) => onChangeTextField(e)}
              className={style.textfield}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ color: "white" }}
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      <Visibility />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button variant="contained" type="submit" className={style.submit}>
              Logare
            </Button>
            <Typography className={style.errorText}>{errorText}</Typography>
          </ValidatorForm>
        </Paper>
      </div>
    </div>
  );
}

export const getPermissions = (id: any) => {
  switch (id) {
    case "homepage":
      return "/home";
      case "vehiclesDashboard":
        return "/vehiclesDashboard";
    case "financialDepartment":
      return "/financialDepartment";
    case "logistics":
      return "/logistics";
    case "sales":
      return "/sales";
    case "acquisitions":
      return "/acquisitions";
    default:
      return "/";
  }
};

export const takeEachRoute = (data: any) => {
  if (data.includes("homepage")) {
    return "homepage";
  }else 
  if (data.includes("vehiclesDashboard")) {
    return "vehiclesDashboard";
  } else if (data.includes("acquisitions")) {
    return "acquisitions";
  } else if (data.includes("sales")) {
    return "sales";
  } else if (data.includes("financialDepartment")) {
    return "financialDepartment";
  } else if (data.includes("logistics")) {
    return "logistics";
  }
};
