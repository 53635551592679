/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vocabulary } from "../../../Utils/Vocabulary";
import { useContext } from "react";
import { VehicleContext } from "../../../Context/vehicleContext";

import GenericModal from "../../../Modal/GenericModal";
import { ContractInformationModel } from "../../../Models/Models";

import { updateData } from "../../../Services/updateData";
import { CacheContext } from "../../../Context/cacheContext";
import { VehiclesStatus } from "../../../Utils/autocompleteEnum";
import { urlEnum } from "../../../Utils/urlEnum";
import { Button, Grid } from "@mui/material";
import moment from "moment";

type SubProgressInformation3_1DeleteProps = {
  handleModalReserve: any; //TODO
  open: boolean;
};

export default function SubProgressInformation3_1Delete(
  props: SubProgressInformation3_1DeleteProps
) {
  const vehicleContext: any = useContext(VehicleContext);
  const cacheContext = useContext(CacheContext) as any;

  /**
   * unreserve vehicle
   */
  async function unReserveVehicle() {
    const vehicle = vehicleContext.state.vehicle; 
    const status = cacheContext.cache?.vehicleStatus?.find(
      (status: any) => vehicle?.data_receptie ? status.nume === VehiclesStatus.received : status.nume === VehiclesStatus.paid
    );

    const data = {
      ...vehicle,
      client_id: null,
      data_rezervare: null,
      data_vanzare: null,
      vanzator_id: null,
      pret_vanzare_ron: null,
      pret_vanzare_euro: null,
      status_id: status.id,
      isLeasing: false,
      contractData: new ContractInformationModel(),
      indexes: vehicleContext.updateIndex(null, [
        "3.1",
        "3.2",
        "3.3",
        "3.4",
        "4.1",
        "4.2",
        "4.3",
      ]),
      modifyBy: vehicleContext.updateModifyBy([
        {
          index: "3.1",
          userId: localStorage.getItem("userName"),
          date: moment().format("DD-MM-YYYY HH:mm:ss"),
        },
      ]),
    };

    // unreserve vehicle
    const url = `${urlEnum.reserveVehicle}/${vehicleContext?.state?.vehicle?.id}`;

    const response = await updateData(url, data);

    //update documents;
    vehicleContext.updateVehicle(response?.data?.vehicle);
    vehicleContext.updateVehicleDocuments(response?.data?.documents);
    vehicleContext.updateVehicleInvoices({
      clientInvoice: response.data.clientInvoice
        ? response.data.clientInvoice
        : [],
      providerInvoice: response.data.providerInvoice
        ? response.data.providerInvoice
        : [],
      OPInvoice: response.data.OPInvoice ? response.data.OPInvoice : [],
      reservedDocuments: response.data.reservedDocuments
        ? response.data.reservedDocuments
        : [],
    });
    props.handleModalReserve();
  }

  return (
    <div>
      <GenericModal
        open={props.open}
        onClose={props.handleModalReserve}
        title={Vocabulary.unreserveVehicleMessage}
      >
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={6}>
            <Button
              size="large"
              onClick={props.handleModalReserve}
              variant="contained"
              color="secondary"
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={unReserveVehicle}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
    </div>
  );
}
