/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider, Grid } from "@mui/material";
import GenericModal from "../../Modal/GenericModal";
import { Vocabulary } from "../../Utils/Vocabulary";

type LogProps = {
  open: boolean;
  onClose: () => void;
  log: any;
};
export default function Log(props: LogProps) {
  const { open, log, onClose } = props;
  return (
    <GenericModal open={open} onClose={onClose} title={Vocabulary.log}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <span style={{ fontWeight: "bold" }}> {Vocabulary.userName}:</span>
          &nbsp;
          {log?.user_name}
        </Grid>
        <Grid item xs={12} md={6}>
          <span style={{ fontWeight: "bold" }}> {Vocabulary.date}: </span>
          &nbsp;
          {log?.data_event}
        </Grid>
        <Grid item xs={12} md={6}>
          <span style={{ fontWeight: "bold" }}>{Vocabulary.actions}: </span>
          &nbsp;
          {Vocabulary[log?.event]}
        </Grid>
        <Grid item xs={12} md={6}>
          <span style={{ fontWeight: "bold" }}> {Vocabulary.model}:</span>&nbsp;
          {log?.auditable_type.replace("App\\Models\\", "")}
        </Grid>
      </Grid>
      <Divider style={{ margin: "15px 0px" }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <p style={{ fontWeight: "bold" }}>{Vocabulary.newValue}: </p>
          {log &&
            log.new_values_decoded &&
            Object.entries(log.new_values_decoded).map((value: any, index:number) => (
              <p key={index}>
                {value[0]}: {`${value[1]}`}
              </p>
            ))}
        </Grid>
        <Grid item xs={12} md={6}>
          <p style={{ fontWeight: "bold" }}> {Vocabulary.oldValue}:</p>
          {log &&
            log.old_values_decoded &&
            Object.entries(log.old_values_decoded).map((value: any, index:number) => (
              <p key={index}>
                {value[0]}: {`${value[1]}`}
              </p>
            ))}
        </Grid>
      </Grid>

      <Button size="large" onClick={onClose} fullWidth>
        {Vocabulary.cancel}
      </Button>
    </GenericModal>
  );
}
