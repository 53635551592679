/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { useContext, useState } from "react";
import {
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import GenericModal from "../../Modal/GenericModal";
import { CacheContext } from "../../Context/cacheContext";
import { State } from "./AdministrativeCostsDashboard";
import { localUrlEnum, urlEnum } from "../../Utils/urlEnum";
import { useNavigate } from "react-router-dom";
import Cost from "./Cost";
import { multipleDelete } from "../../Services/multipleDelete";
import { exportTable, isMobile } from "../../Utils/utils";
import FilePreview from "../../Utils/FilePreview";
import { formatNumber } from "../../Utils/formatDate";
import { tableLayoutOnMobile } from "../../Utils/Constants";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

type AdministrativeCostsTableProps = {
  state: State;
  status: number;
  idCostType?: any;
  openNewOption?: any;
  openModalWindow?: any;
  getCosts: (
    status: number,
    page: number,
    perPage: number,
    search?: string | null
  ) => void;
  tableLabels: string[];
  costAdminType?: any;
};

export type CostsData = {
  id?: number | undefined;
  descriere: string;
  data: string;
  email: string;
  valoare: string;
  tip_cost_adm_id?: number;
  file_id: any;
  file: any;
};

export default function AdministrativeCostsTable(
  props: AdministrativeCostsTableProps
) {
  const {
    state,
    status,
    getCosts,
    idCostType,
    openNewOption,
    openModalWindow,
    tableLabels,
    costAdminType
  } = props;
  const navigate = useNavigate();
  const cacheContext = useContext(CacheContext) as any;
  // const cacheData: any = Object.assign({}, cacheContext.cache);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedIds, setDeletedIds] = useState<Array<number>>([]);
  const [editedCost, setEditedCost] = useState<CostsData | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  /**
   *
   * @param startDate
   * @param endDate
   * @returns
   */
  function getMonthDifference(startDate: Date, endDate: Date) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }
  /**
   *
   */
  const administrativeCostsHeader = [
    {
      label: `${Vocabulary.description}`,
      name: "descriere",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: `${Vocabulary.value}`,
      name: "valoare",
      options: {
        sort: false,
        customBodyRender: (value: any) => formatNumber(value),
      },
    },
    {
      label: `${Vocabulary.description}`,
      name: "descriere",
      options: {
        sort: false,
        display: false,
      },
    },
    {
      label: `${Vocabulary.date}`,
      name: "data",
      options: {
        sort: false,
      },
    },
    {
      name: "doc",
      label: " Fisier Atasat",
      options: {
        filter: true,
        sort: false,
        display: true,
        download: false,
        customBodyRender: (file: any) => {
          if (file === null) return null;
          let path = `${urlEnum.vehicles}/${file}`;
          if (file.includes("templates")) {
            const fileName = file.split("/").pop();
            path = `${urlEnum.templates}/getDoc/${fileName}`;
          }
          return (
            <Button
              style={{ float: "right" }}
              size="small"
              color="primary"
              variant="contained"
              data-file={path}
            >
              {Vocabulary.open}
            </Button>
          );
        },
      },
    },
    {
      label: "",
      name: "",
      options: {
        download: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (rowIndex: number) => {
          const diff = getMonthDifference(
            new Date(state.costs[rowIndex].data),
            new Date()
          );
          return (
            <>
              {diff < 2 ? (
                <div
                  style={{ marginTop: -10, marginBottom: -10, float: "right" }}
                >
                  <ToggleButtonGroup exclusive aria-label="text alignment">
                    <Tooltip title={Vocabulary.edit}>
                      <ToggleButton
                        onClick={() => {
                          const editedCost = {
                            id: state.costs[rowIndex].id,
                            descriere: state.costs[rowIndex].descriere,
                            data: state.costs[rowIndex].data,
                            tip_cost_adm_id:
                              state.costs[rowIndex].tip_cost_adm_id,
                            valoare: state.costs[rowIndex].valoare,
                            file_id: state.costs[rowIndex].file_id,
                            file: state.costs[rowIndex].file,
                          } as any;
                          setEditedCost(editedCost);
                          openModalWindow(true);
                        }}
                        value="center"
                        aria-label="centered"
                      >
                        <Edit />
                      </ToggleButton>
                    </Tooltip>
                    <Tooltip title={Vocabulary.delete}>
                      <ToggleButton
                        onClick={() => {
                          setDeletedIds([state.costs[rowIndex].id as number]);
                          setOpenDeleteModal(true);
                        }}
                        value="center"
                        aria-label="centered"
                      >
                        <Delete />
                      </ToggleButton>
                    </Tooltip>
                  </ToggleButtonGroup>
                </div>
              ) : (
                <div
                  style={{ marginTop: -10, marginBottom: -10, float: "right" }}
                >
                  <ToggleButtonGroup exclusive aria-label="text alignment">
                    <Tooltip title={Vocabulary.visibility}>
                      <ToggleButton
                        onClick={() => {
                          const editedCost = {
                            id: state.costs[rowIndex].id,
                            descriere: state.costs[rowIndex].descriere,
                            data: state.costs[rowIndex].data,
                            tip_cost_adm_id:
                              state.costs[rowIndex].tip_cost_adm_id,
                            valoare: state.costs[rowIndex].valoare,
                            file_id: state.costs[rowIndex].file_id,
                            file: state.costs[rowIndex].file,
                          } as any;
                          setEditedCost(editedCost);
                          openModalWindow(true);
                          setIsVisible(true);
                        }}
                        value="center"
                        aria-label="centered"
                      >
                        <Visibility />
                      </ToggleButton>
                    </Tooltip>
                  </ToggleButtonGroup>
                </div>
              )}
            </>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    return {
      selectableRows: "none" as any,
      responsive: tableLayoutOnMobile,
      viewColumns: false,
      rowsPerPageOptions: [
        10,
        50,
        100,
        state?.costsNumber > 100 ? state?.costsNumber : 1000,
      ],
      confirmFilters: true,
      filter: false,
      print: false,
      download: true,
      fixedHeader: true,
      count: state.costsNumber,
      rowsPerPage: state.perPage,
      page: state.page,
      search: false,
      serverSide: true,
      sort: true,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
          downloadCSV: "test",
        },
        viewColumns: {
          title: Vocabulary.viewColumns,
        },
        toolbar: {
          downloadCsv: Vocabulary.downloadExcel,
          viewColumns: Vocabulary.selectColumns,
        },
        pagination: Vocabulary.pagination,
      },
      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true,
        },
      },
      searchPlaceholder: "Cauta costuri",
      onRowsDelete: (rowsDeleted: any, newData: any) => {
        const ids = new Array<number>();
        rowsDeleted.data.forEach((row: any) => {
          ids.push(state.costs[row.dataIndex].id as number);
        });
        setDeletedIds(ids);
        setOpenDeleteModal(true);
      },
      onChangePage: (page: number) => {
        if (state.search)
          navigate(
            `${localUrlEnum.administrativeCosts}?status=${state.status}page=${
              page + 1
            }?perPage=${state.perPage}?search=${state.search}`
          );
        else
          navigate(
            `${localUrlEnum.administrativeCosts}?status=${state.status}?page=${
              page + 1
            }?perPage=${state.perPage}`
          );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        if (state.search)
          navigate(
            `${localUrlEnum.administrativeCosts}?status=${
              state.status
            }?page=${1}?perPage=${numberOfRows}?search=${state.search}`
          );
        else
          navigate(
            `${localUrlEnum.administrativeCosts}?status=${
              state.status
            }?page=${1}?perPage=${numberOfRows}`
          );
        window.scrollTo(0, 0);
      },
    };
  }

  /**
   * Close delete modal
   */
  function onCloseDeleteModal() {
    setOpenDeleteModal(false);
    setDeletedIds([]);
  }

  /**
   * Delete administrativeCosts
   */
  function deleteCost() {
    const data = { data: { ids: deletedIds } };
    multipleDelete(`${urlEnum.administrativeCosts}`, data)
      .then((res) => {
        cacheContext.setCache({
          ...cacheContext.cache,
          costs: res.data.costs,
        });
        onCloseDeleteModal();
        getCosts(state.status, state.page, state.perPage, state.search);
      })
      .catch((err) => {
        onCloseDeleteModal();
      });
  }

  /**
   * export table as excel
   */
  const exportData = (props: any) => {
    let exportedColumns = props?.columns;
    exportedColumns = exportedColumns.filter((column: any) => {
      if (column.download == true) return column.name;
    });

    const fileName = (
      Vocabulary.costsName +
      "_" +
      tableLabels[state.status]
    ).toLowerCase();
    const url = `${urlEnum.administrativeCosts}/export/table/data`;

    exportTable(url, costAdminType[state.status]?.id, fileName, exportedColumns);
  };

  /**
   *
   * @param props
   * @returns
   */
  const CustomToolbar = (props: any) => {
    return (
      <Toolbar>
        <Button
          startIcon={<CloudDownloadIcon />}
          onClick={(event) => exportData(props)}
        >
          {Vocabulary.exportTable}
        </Button>
      </Toolbar>
    );
  };

  return (
    <div>
      <div style={{ display: "none", top: 100, right: 40 }}>
        <Button
          startIcon={<AddCircleIcon />}
          onClick={() => openModalWindow(true)}
          variant="contained"
          style={{
            color: "white",
            padding: "10px 15px 10px 15px",
            fontWeight: 600,
            borderRadius: "10px 10px 0px 0px",
          }}
        >
          {Vocabulary.addCosts}
        </Button>
      </div>
      <MUIDataTable
        title={""}
        data={state.costs}
        columns={administrativeCostsHeader}
        options={getTableOptions()}
        components={{
          TableToolbar: CustomToolbar,
        }}
      />
      <Cost
        open={openNewOption}
        onClose={(shouldRefetch) => {
          openModalWindow(false);
          setEditedCost(null);
          if (shouldRefetch) {
            getCosts(state.status, state.page, state.perPage, state.search);
          }
          setIsVisible(false);
        }}
        refetch={() =>
          getCosts(state.status, state.page, state.perPage, state.search)
        }
        title={editedCost ? Vocabulary.editCosts : Vocabulary.addNewCost}
        tabStatus={status}
        status={idCostType}
        editedCost={editedCost}
        isVisible={isVisible}
      />

      <GenericModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        title={Vocabulary.deleteCost}
      >
        <p style={{ textAlign: "center", fontSize: 20 }}>
          {Vocabulary.deleteMessageCosts}
        </p>
        <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onCloseDeleteModal}
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={deleteCost}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
      <FilePreview key={state.costs.length} />
    </div>
  );
}
