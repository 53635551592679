import { Vocabulary } from "../Vocabulary";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

export const ROUTER_EDITEAZA_VEHICULE = {
  routes: ["/", "/logs", "*"],
};

export const MAIN_SLIDER_EDITEAZA_VEHICULE = [{
  name: Vocabulary.logsName,
  path: "/logs",
  icon: <AssignmentIndIcon />,
  orderId: 6,
}];

export const VEHICLE_OPTIONS_EDITEAZA_VEHICULE = [
  {
    delete: true,
  },
];

export const DINAMIC_TABS_EDITEAZA_VEHICULE = {
  // add: true,
};
export const DINAMIC_TABS_EDITEAZA_SERVICII = {
  // add: true,
  // edit: true,
};

export const MAIN_TABLE_EDITEAZA_VEHICULE = {
  delete: true,
};
