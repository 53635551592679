import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  Button,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { tableLayoutOnMobile } from "../../Utils/Constants";
import { urlEnum } from "../../Utils/urlEnum";
import { getData } from "../../Services/getData";
import { useLocation, useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import GenericModal from "../../Modal/GenericModal";
import { postData } from "../../Services/postData";

type State = {
  integrations: any;
  integrationsNumber: number;
  page: number;
  perPage: number;
  search: string | null;
  order: string;
  filter: string | null;
};

export default function WebsiteIntegration(props: any) {
  const { openEditModal, setOpenEditModal } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [editedIndex, setEditedIndex] = useState<number>(0);
  const [state, setState] = useState<State>({
    integrations: [],
    integrationsNumber: 0,
    page: 0,
    perPage: 10,
    search: null,
    order: "asc",
    filter: null,
  });

  /**
   *
   */
  useEffect(() => {
    const search = location.search.split("?");
    const newState = {
      integrations: [],
      integrationsNumber: 0,
      page: 0,
      perPage: 10,
      search: null,
      order: "asc",
      filter: null,
    } as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1];
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    getIntegrations(newState.page, newState.perPage, newState.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  function getIntegrations(
    page: number,
    perPage: number,
    search?: string | null
  ) {
    let url = `${urlEnum.websitesIntegration}/${page}/${perPage}`;
    if (search) {
      url = `${urlEnum.websitesIntegration}/${page}/${perPage}/${search}`;
    }
    getData(url)
      .then((res) => {
        setState({
          ...state,
          integrations: res?.data?.websitesIntegrations,
          integrationsNumber: res?.data?.websitesIntegrationsCount,
        });
      })
      .catch((err) => {
        //
      });
  }

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  function saveIntegration() {
    const url = `${urlEnum.websitesIntegration}/update/${JSON.stringify(
      state.integrations[editedIndex]
    )}`;

    postData(url)
      .then((res) => {
        getIntegrations(state.page, state.perPage);
        setOpenEditModal(false);
      })
      .catch((err) => {
        //
      });
  }

  /**
   *
   * @returns
   */
  function getTableOptions() {
    return {
      responsive: tableLayoutOnMobile,
      selectableRows: "none" as any,
      viewColumns: false,
      rowsPerPageOptions: [5, 10, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      fixedHeader: true,
      count: state.integrationsNumber,
      rowsPerPage: state.perPage,
      page: state.page,
      search: false,
      serverSide: true,
      sort: true,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: Vocabulary.pagination,
      },
      //   onRowsDelete: (rowsDeleted: any, newData: any) => {
      //     const ids = new Array<number>();
      //     rowsDeleted.data.forEach((row: any) => {
      //       ids.push(state.company[row.dataIndex].id as number);
      //     });
      //     setDeletedIds(ids);
      //     setOpenDeleteModal(true);
      //   },
      onChangePage: (page: number) => {
        setState({ ...state, page: page });
        if (state.search)
          navigate(
            `?settings=websitesIntegration?page=${page}?perPage=${state.perPage}?search=${state.search}`
          );
        else
          navigate(
            `?settings=websitesIntegration?page=${page}?perPage=${state.perPage}`
          );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        setState({ ...state, perPage: numberOfRows });
        if (state.search)
          navigate(
            `?settings=websitesIntegration?page=${0}?perPage=${numberOfRows}?search=${
              state.search
            }`
          );
        else
          navigate(
            `?settings=websitesIntegration?page=${0}?perPage=${numberOfRows}`
          );
        window.scrollTo(0, 0);
      },
    };
  }

  /**
   *
   */
  const columns = [
    {
      label: `${Vocabulary.name}`,
      name: "slug",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.settings}`,
      name: "value",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          const asArray = Object.keys(value).map((key) => [
            key + ": " + (key.includes("password") ? "" : value[key]),
          ]);
          return (
            <Typography style={{ fontSize: "1rem" }}>
              {asArray.join(", ")}
            </Typography>
          );
        },
      },
    },
    {
      label: "",
      name: "",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (rowIndex: number, value: any) => {
          return (
            <>
              <div style={{ marginTop: -10, marginBottom: -10 }}>
                <ToggleButtonGroup exclusive aria-label="text alignment">
                  <Tooltip title={Vocabulary.edit}>
                    <ToggleButton
                      value="center"
                      aria-label="centered"
                      onClick={() => {
                        props?.setOpenEditModal(true);
                        setEditedIndex(rowIndex);
                      }}
                    >
                      <Edit />
                    </ToggleButton>
                  </Tooltip>
                </ToggleButtonGroup>
              </div>
            </>
          );
        },
      },
    },
  ];
  return (
    <div>
      {" "}
      <MUIDataTable
        title={""}
        data={state.integrations}
        columns={columns}
        options={getTableOptions()}
      />
      {openEditModal && (
        <GenericModal
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          title={Vocabulary.edit}
          buttons={
            <Grid container spacing={3} style={{ padding: "1rem" }}>
              <Grid item md={6} xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    setOpenEditModal(false);
                    getIntegrations(state.page, state.perPage);
                  }}
                >
                  {Vocabulary.cancel}
                </Button>
              </Grid>
              <Grid item md={6} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ color: "white", height: "95%" }}
                  onClick={() => {
                    saveIntegration();
                  }}
                >
                  {Vocabulary.save}
                </Button>
              </Grid>
            </Grid>
          }
          children={
            <Grid
              container
              spacing={2}
              style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
            >
              {Object.keys(state?.integrations[editedIndex].value).map(
                (param) => {
                  if (!param.includes("password"))
                    return (
                      <Grid item md={6} xs={12}>
                        <TextField
                          name={param}
                          fullWidth
                          value={state?.integrations[editedIndex].value[param]}
                          label={param}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const newData = state?.integrations;
                            newData[editedIndex].value[param] = e.target?.value;
                            setState({ ...state, integrations: newData });
                          }}
                        />
                      </Grid>
                    );
                }
              )}
            </Grid>
          }
        />
      )}
    </div>
  );
}
