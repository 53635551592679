/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button, Grid } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import MUIDataTable from "mui-datatables";
import GenericModal from "../../Modal/GenericModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { urlEnum } from "../../Utils/urlEnum";
import { getData } from "../../Services/getData";
import { multipleDelete } from "../../Services/multipleDelete";
import { isMobile } from "../../Utils/utils";
import Permission from "./Permission";

type State = {
  permissions: any;
  permissionsNumber: number;
  page: number;
  perPage: number;
  search: string | null;
  order: string;
  filter: string | null;
};
export type PermissionsData = {
  id?: number | undefined;
  nume: string;
  culoare: string;
  tip_permissions: string;
};
export default function Permissions(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedIds, setDeletedIds] = useState<Array<number>>([]);

  const [editedPermissions, setEditedPermissions] = useState(null);
  const [state, setState] = useState<State>({
    permissions: new Array<PermissionsData>(),
    permissionsNumber: 0,
    page: 0,
    perPage: 10,
    search: null,
    order: "asc",
    filter: null,
  });

  /**
   *
   * @param {*} event
   * @param {*} newValue
   */

  /**
   * Get permissions
   */
  useEffect(() => {
    const search = location.search.split("?");
    const newState: any = {
      permissions: new Array<any>(),
      permissionsNumber: 0,
      page: 0,
      perPage: 10,
      search: null,
      order: "asc",
      filter: null,
    } as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1] - 1;
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    getPermissions(newState.page, newState.perPage, newState.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  function getPermissions(
    page: number,
    perPage: number,
    search?: string | null
  ) {
    let url = `${urlEnum.permissions_id}/${page}/${perPage}`;
    if (search) {
      url = `${urlEnum.permissions_id}/${page}/${perPage}/${search}`;
    }
    getData(url)
      .then((res) => {
        setState({
          ...state,
          permissions: res.data.permissions,
          permissionsNumber: res.data.permissionsNumber,
          page: page,
          perPage: perPage,
          search: search ? search : null,
        });
      })
      .catch((err) => {
        //
      });
  }
  /**
   *
   */
  const permissionsHeader = [
    {
      label: `${Vocabulary.name}`,
      name: "name",
      options: {
        sort: false,
        customBodyRender: (rowIndex: any, index: any) => {
          return Vocabulary[rowIndex]
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";

    return {
      responsive: responsive,
      selectableRows: "none" as any,
      viewColumns: false,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      fixedHeader: true,
      count: state.permissionsNumber,
      rowsPerPage: state.perPage,
      page: state.page,
      search: false,
      serverSide: true,
      sort: true,
      textLabels: {
        // selectedRows: {
        //   text: "selectate",
        //   delete: "Sterge",
        //   deleteAria: "Sterge liniile selectate",
        // },
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: Vocabulary.pagination,
      },
      onRowsDelete: (rowsDeleted: any, newData: any) => {
        const ids = new Array<number>();
        rowsDeleted.data.forEach((row: any) => {
          ids.push(state.permissions[row.dataIndex].id as number);
        });
        setDeletedIds(ids);
        setOpenDeleteModal(true);
      },
      onChangePage: (page: number) => {
        setState({ ...state, page: page });
        if (state.search)
          navigate(
            `?settings=permissions?page=${page + 1}?perPage=${
              state.perPage
            }?search=${state.search}`
          );
        else
          navigate(
            `?settings=permissions?page=${page + 1}?perPage=${state.perPage}`
          );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        setState({ ...state, perPage: numberOfRows });
        if (state.search)
          navigate(
            `?settings=permissions?page=${1}?perPage=${numberOfRows}?search=${
              state.search
            }`
          );
        else
          navigate(`?settings=permissions?page=${1}?perPage=${numberOfRows}`);
        window.scrollTo(0, 0);
      },
    };
  }

  /**
   * Close delete modal
   */
  function onCloseDeleteModal() {
    setOpenDeleteModal(false);
    setDeletedIds([]);
  }

  /**
   * Delete permissions
   */
  function deletePermissions() {
    const data = { data: { ids: deletedIds } };
    multipleDelete(urlEnum.permissions_id, data)
      .then((res) => {
        onCloseDeleteModal();
        getPermissions(state.page, state.perPage, state.search);
      })
      .catch((err) => {
        onCloseDeleteModal();
      });
  }
  return (
    <div>
      <div>
        <MUIDataTable
          title={""}
          data={state.permissions}
          columns={permissionsHeader}
          options={getTableOptions()}
        />
      </div>
      <Permission
        open={props.openEditModal}
        onClose={(shouldRefetch) => {
          props.setOpenEditModal(false);
          setEditedPermissions(null);
          if (shouldRefetch) {
            getPermissions(state.page, state.perPage, state.search);
          }
        }}
        title={
          editedPermissions
            ? Vocabulary.editPermissions
            : Vocabulary.addNewPermission
        }
        editedPermissions={editedPermissions}
      />

      <GenericModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        title={Vocabulary.deletePermissions}
      >
        <p style={{ textAlign: "center", fontSize: 20 }}>
          {Vocabulary.deletePermissionsMessage}
        </p>
        <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onCloseDeleteModal}
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={deletePermissions}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
    </div>
  );
}
