/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import MUIDataTable from "mui-datatables";
import AddNewStatus from "./AddNewStatus";
import GenericModal from "../../Modal/GenericModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { urlEnum } from "../../Utils/urlEnum";
import { getData } from "../../Services/getData";
import { Delete, Edit } from "@mui/icons-material";
import { multipleDelete } from "../../Services/multipleDelete";
import { isMobile } from "../../Utils/utils";
import {
  CarOrderStatus,
  ClientMessagesStatus,
  VehiclesStatus,
} from "../../Utils/autocompleteEnum";
import { tableLayoutOnMobile } from "../../Utils/Constants";

type State = {
  status: Array<StatusData>;
  statusNumber: number;
  page: number;
  perPage: number;
  search: string | null;
  order: string;
  filter: string | null;
};
export type StatusData = {
  id?: number | undefined;
  nume: string;
  culoare: string;
  tip_status: string;
  children_ids: string[] | null;
};

export default function Status(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedIds, setDeletedIds] = useState<Array<number>>([]);
  const [editedStatus, setEditedStatus] = useState<StatusData | null>(null);
  const [state, setState] = useState<State>({
    status: new Array<StatusData>(),
    statusNumber: 0,
    page: 0,
    perPage: 10,
    search: null,
    order: "asc",
    filter: null,
  });

  /**
   *
   * @param {*} event
   * @param {*} newValue
   */

  /**
   * Get status
   */
  useEffect(() => {
    const search = location.search.split("?");
    const newState = {
      status: new Array<StatusData>(),
      statusNumber: 0,
      page: 0,
      perPage: 10,
      search: null,
      order: "asc",
      filter: null,
    } as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1] - 1;
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    getStatus(newState.page, newState.perPage, newState.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  function getStatus(page: number, perPage: number, search?: string | null) {
    let url = `${urlEnum.status_id}/${page}/${perPage}`;
    if (search) {
      url = `${urlEnum.status_id}/${page}/${perPage}/${search}`;
    }
    getData(url)
      .then((res) => {
        setState({
          ...state,
          status: res.data.status,
          statusNumber: res.data.statusNumber,
          page: page,
          perPage: perPage,
          search: search ? search : null,
        });
      })
      .catch((err) => {
        //
      });
  }

  /**
   *
   * @param status
   * @returns
   */
  function getDeleteButton(status: any) {
    switch (status.nume) {
      case VehiclesStatus.sold:
      case VehiclesStatus.reserved:
      case VehiclesStatus.received:
      case VehiclesStatus.advancePaid:
      case VehiclesStatus.contractToSign:
      case VehiclesStatus.paid:
      case VehiclesStatus.unpaid:
      case VehiclesStatus.canceled:
      case ClientMessagesStatus.canceled:
      case ClientMessagesStatus.unread:
      case CarOrderStatus.canceled:
      case CarOrderStatus.unread:
        return null;
      default:
        return (
          <Tooltip title={Vocabulary.delete}>
            <ToggleButton
              onClick={() => {
                setDeletedIds([status.id as number]);
                setOpenDeleteModal(true);
              }}
              value="center"
              aria-label="centered"
            >
              <Delete />
            </ToggleButton>
          </Tooltip>
        );
    }
  }
  /**
   *
   */
  const statusHeader = [
    {
      label: `${Vocabulary.name}`,
      name: "nume",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.color}`,
      name: "culoare",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.statusType}`,
      name: "tip_status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: "",
      name: "",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (rowIndex: number) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10 }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    onClick={() => {
                      setEditedStatus(state.status[rowIndex]);
                      props.setOpenEditModal(true);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
                {getDeleteButton(state.status[rowIndex])}
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    return {
      responsive: tableLayoutOnMobile,
      viewColumns: false,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      fixedHeader: true,
      count: state.statusNumber,
      rowsPerPage: state.perPage,
      page: state.page,
      search: false,
      serverSide: true,
      sort: true,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: Vocabulary.pagination,
      },
      onRowsDelete: (rowsDeleted: any, newData: any) => {
        const ids = new Array<number>();
        rowsDeleted.data.forEach((row: any) => {
          ids.push(state.status[row.dataIndex].id as number);
        });
        setDeletedIds(ids);
        setOpenDeleteModal(true);
      },
      onChangePage: (page: number) => {
        setState({ ...state, page: page });
        if (state.search)
          navigate(
            `?settings=statusVehicle?page=${page + 1}?perPage=${
              state.perPage
            }?search=${state.search}`
          );
        else
          navigate(
            `?settings=statusVehicle?page=${page + 1}?perPage=${state.perPage}`
          );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        setState({ ...state, perPage: numberOfRows });
        if (state.search)
          navigate(
            `?settings=statusVehicle?page=${1}?perPage=${numberOfRows}?search=${
              state.search
            }`
          );
        else
          navigate(`?settings=statusVehicle?page=${1}?perPage=${numberOfRows}`);
        window.scrollTo(0, 0);
      },
    };
  }

  /**
   * Close delete modal
   */
  function onCloseDeleteModal() {
    setOpenDeleteModal(false);
    setDeletedIds([]);
  }

  /**
   * Delete status
   */
  function deleteStatus() {
    const data = { data: { ids: deletedIds } };
    multipleDelete(urlEnum.status_id, data)
      .then((response) => {
        onCloseDeleteModal();
        // cacheContext.setCache({
        //   ...cacheContext.cache,
        //   vehicleStatus: response.data.vehicleStatus,
        //   templatesStatus: response.data.templatesStatus,
        //   clientMessagesStatus: response.data.clientMessagesStatus,
        //   carOrdersStatus: response.data.carOrdersStatus,
        // });
        getStatus(state.page, state.perPage, state.search);
      })
      .catch((err) => {
        onCloseDeleteModal();
      });
  }
  return (
    <div>
      <div>
        <MUIDataTable
          title={""}
          data={state.status}
          columns={statusHeader}
          options={getTableOptions()}
        />
      </div>
      <AddNewStatus
        open={props.openEditModal}
        onClose={(shouldRefetch) => {
          props.setOpenEditModal(false);
          setEditedStatus(null);
          if (shouldRefetch) {
            getStatus(state.page, state.perPage, state.search);
          }
        }}
        title={
          editedStatus ? Vocabulary.editStatusName : Vocabulary.addNewStatus
        }
        editedStatus={editedStatus}
      />

      <GenericModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        title={Vocabulary.deleteStatus}
      >
        <p style={{ textAlign: "center", fontSize: 20 }}>
          {Vocabulary.deleteStatusMessage}
        </p>
        <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onCloseDeleteModal}
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={deleteStatus}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
    </div>
  );
}
