import { Vocabulary } from "../Vocabulary";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DashboardIcon from "@mui/icons-material/Dashboard";

export const ROUTER_ATRIBUIE_COMANDA_MASINA = {
  routes: [
    "/",
    "/vehiclesDashboard/vehicleDashboard/:id?",
    "/vehiclesDashboard",
    "*",
  ],
};

export const MAIN_SLIDER_ATRIBUIE_COMANDA_MASINA = [{
  name: Vocabulary.logsName,
  path: "/logs",
  icon: <AssignmentIndIcon />,
}];

export const VEHICLE_OPTIONS_ATRIBUIE_COMANDA_MASINA = {
  name: Vocabulary.dashboard,
  path: `/vehiclesDashboard/vehicleDashboard/`,
  icon: <DashboardIcon />,
  id: "vehicleDashboard",
  orderNumber: 0,
};

export const DINAMIC_TABS_ATRIBUIE_COMANDA_MASINA = null;

export const MAIN_TABLE_ATRIBUIE_COMANDA_MASINA = null;
