import { useContext,  useState } from "react";
import { VehicleContext } from "../Context/vehicleContext";

export default function useResetContextState(forceInit:boolean|undefined = undefined) {
  const [initialState, setSetInitialState] = useState<{
    invoices: Array<any>;
    vehicle: any;
    images: any;
    documents: any;
  } | null>(null);
  const vehicleContext: any = useContext(VehicleContext);

  // useEffect(() => {
  //   if (!vehicleContext.state) return;
  //   if (
  //     !initialState &&
  //     vehicleContext?.state?.vehicle &&
  //     vehicleContext?.state?.vehicle.id > 0 &&
  //   (forceInit === undefined || forceInit === true)
  //   ) {
  //     setSetInitialState({
  //       invoices: { ...vehicleContext.state.invoices },
  //       vehicle: { ...vehicleContext.state.vehicle },
  //       images: vehicleContext.state.images.slice(),
  //       documents: vehicleContext.state.documents.slice(),
  //     });
  //   }
  // }, [vehicleContext.state, initialState]);

  return {
    resetState: () => {
      vehicleContext.resetState(initialState);
      setSetInitialState(null);
    },
    setInitialState: () => {
      setSetInitialState({
        invoices: { ...vehicleContext.state.invoices },
        vehicle: { ...vehicleContext.state.vehicle },
        images: vehicleContext.state.images.slice(),
        documents: vehicleContext.state.documents.slice(),
      });
    }
  };
}
