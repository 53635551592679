import { Button } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useContext, useState } from "react";
import GenericModal from "../../Modal/GenericModal";
import { urlEnum } from "../../Utils/urlEnum";
import { VehicleContext } from "../../Context/vehicleContext";
import { updateData } from "../../Services/updateData";
import { useNavigate } from "react-router-dom";
import { VehicleModel } from "../../Models/VehicleModel";
import withRole from "../../Utils/withRole";
import LibraryAddRoundedIcon from "@mui/icons-material/LibraryAddRounded";

function DuplicateVehicle(props: any) {
  const [open, setOpen] = useState(false);
  const vehicleContext: any = useContext(VehicleContext);
  const navigate = useNavigate();

  /**
   *
   */
  const duplicateVehicle = async () => {
    const url = `${urlEnum.duplicateVehicle}/${vehicleContext?.state?.vehicle?.id}`;
    const response = await updateData(url);
    if (response?.data) {
      const vehicleId = response?.data?.duplicatedVehicleId;
      window.scrollTo(0, 0);
      navigate(`/vehiclesDashboard/vehicleDetails/${vehicleId}`, {
        replace: true,
      });
      vehicleContext.updateVehicle(new VehicleModel());
      setOpen(false);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ color: "white" }}
        startIcon={<LibraryAddRoundedIcon />}
        onClick={(event) => {
          setOpen(true);
          event.stopPropagation();
        }}
      >
        {Vocabulary.duplicateVehicleButtonText}
      </Button>
      <GenericModal
        open={open}
        onClose={() => setOpen(!open)}
        title={Vocabulary.confirmDuplication}
        fullScreenOnMobile={false}
        buttons={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              onClick={() => setOpen(!open)}
              variant="contained"
              color="secondary"
            >
              {Vocabulary.no}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={duplicateVehicle}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </div>
        }
        children={
          <div style={{ display: "flex", justifyContent: "center" }}>
            {Vocabulary.askForDuplicateVehicle}
          </div>
        }
      />
    </div>
  );
}

export default withRole(DuplicateVehicle);
