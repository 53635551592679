import { Vocabulary } from "../Vocabulary";
import GroupIcon from "@mui/icons-material/Group";

export const ROUTER_MANAGE_UTILIZATORI = {
  routes: ["/", "/usersDashboard", "*"],
};

export const MAIN_SLIDER_MANAGE_UTILIZATORI = [{
  name: Vocabulary.usersName,
  path: "/usersDashboard",
  icon: <GroupIcon />,
  orderId: 3,
}];

export const VEHICLE_OPTIONS_MANAGE_UTILIZATORI = [];

export const DINAMIC_TABS_MANAGE_UTILIZATORI = {
  // add: true,
};

export const MAIN_TABLE_MANAGE_UTILIZATORI = null;
