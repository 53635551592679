/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Circle,
  Close,
  DoneAll,
  NotificationsActive,
  TagFaces,
} from "@mui/icons-material";
import {
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
} from "@mui/material";
import styles from "../Styles/mainHeader.module.css";
import { Fragment, useEffect, useMemo, useState } from "react";
import { getData } from "../Services/getData";
import { urlEnum } from "../Utils/urlEnum";
import { Vocabulary } from "../Utils/Vocabulary";
import moment from "moment";
import { postData } from "../Services/postData";
import createConnectionForWebsocket from "../Utils/utils";
//import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import ChecklistIcon from "@mui/icons-material/Checklist";

export default function Notifications() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [pusher, setPusher] = useState<any>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [allUserUnreadNotifications, setAllUnreadUserNotifications] = useState<
    any[]
  >([]);
  /**
   *
   */
  useEffect(() => {
    getNotificationsForUser();
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (!pusher) setPusher(createConnectionForWebsocket());
    if (pusher) pusherEvent();
    return () => {
      if (pusher) {
        pusher.unsubscribe(`notifications.${localStorage.getItem("useId")}`);
        pusher.unbind("send-notifications");
        pusher.unsubscribe("cache-refresh");
        pusher.unbind("cache-refresh");
        pusher.unbind("pusher:subscription_succeeded");
        pusher.disconnect();
      }
    };
  }, [pusher]);

  /**
   *
   */
  const getNotificationsForUser = () => {
    getData(`${urlEnum.notifications}/${localStorage.getItem("useId")}`).then(
      (res) => {
        if (res) {
          setNotifications(res.data.notifications);
          setNotificationCount(res.data.notificationsNumber);
          setAllUnreadUserNotifications(res.data?.unreadNotifications);
        }
      }
    );
  };

  /**
   *
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  /**
   *
   */
  const handleClose = (markAllAsRead = false) => {
    let unreadNotifications: any[] = [];
    //set notifications to read
    //create array containing ids of notifications  with status unread
    if (markAllAsRead) {
      unreadNotifications = allUserUnreadNotifications.map((notification) => {
        return notification.id;
      });
    } else
      unreadNotifications = notifications
        .filter((notification) => !!notification.isRead === false)
        .map((notification) => notification.id);

    if (unreadNotifications.length > 0) {
      postData(urlEnum.markNotificationAsRead, {
        notificationsIds: unreadNotifications,
      }).then((res: any) => {
        if (res) {
          getNotificationsForUser();
        }
      });
    }
    setAnchorEl(null);
  };

  /**
   *
   */
  function pusherEvent() {
    // const channel = pusher.subscribe(`notifications`);
    const channel = pusher.subscribe(
      `notifications.${localStorage.getItem("useId")}`
    );
    channel.bind("send-notifications", (data: any) => {
      // setNotifications([data.notification, ...notifications]);
      // setNotificationCount(notificationCount + 1);
      getNotificationsForUser();
      if (data?.notification?.message) {
        new Notification(data?.notification?.message || "");
        const audio = new Audio("/notificare.mp3");
        audio.play();
      }
    });

    //cache refresh channel
    const cacheChannel = pusher.subscribe("cache-refresh");

    //Check if this is the first time this tab is opened
    const isFirstVisit = sessionStorage.getItem("isFirstVisit");
    if (!isFirstVisit) {
      sessionStorage.setItem("isFirstVisit", "true");

      cacheChannel.bind("pusher:subscription_succeeded", async () => {
        //update cache data
        const cacheDataResponse = await getData(`${urlEnum.cacheData}/null`);
        if (cacheDataResponse && cacheDataResponse.data) {
          localStorage.setItem(
            "cacheData",
            JSON.stringify(cacheDataResponse.data)
          );
        }
      });
    }

    cacheChannel.bind("cache-refresh", async () => {
      const cacheDataResponse = await getData(`${urlEnum.cacheData}/null`);
      if (cacheDataResponse && cacheDataResponse.data) {
        //update cache data
        localStorage.setItem(
          "cacheData",
          JSON.stringify(cacheDataResponse.data)
        );
        //update permissions
        const permissionsName: any = [];
        cacheDataResponse?.data?.permissions?.forEach((element: any) => {
          permissionsName.push(element.name);
        });
        localStorage.setItem("permissions", permissionsName);
        //update logged user role
        const rolesName: any = [];
        cacheDataResponse?.data?.userRole?.forEach((element: any) => {
          rolesName.push(element.name);
        });
        localStorage.setItem("roles", rolesName);
        //reload location to have the new tabs in vehicle slider if the permission was changed
        // window.location.reload();
      }
    });
  }

  return (
    <>
      <Fab size="small" className={styles.fab} onClick={handleClick}>
        <Badge
          badgeContent={notificationCount}
          max={100}
          classes={{ badge: styles.badge }}
        >
          <NotificationsActive />
        </Badge>
      </Fab>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Card>
          <CardHeader
            subheader={Vocabulary.notifications}
            action={
              <>
                <Button
                  onClick={() => handleClose(true)}
                  startIcon={<ChecklistIcon />}
                  style={{ textTransform: "none" }}
                >
                  {Vocabulary.markAsRead}
                </Button>
                <IconButton onClick={() => handleClose()}>
                  <Close />
                </IconButton>
              </>
            }
          />
          <CardContent sx={{ padding: 0 }}>
            <List>
              {notifications && notifications.length > 0 ? (
                notifications.map((notification: any) => {
                  return (
                    <Fragment key={notification.id}>
                      <Tooltip title={notification.message}>
                        <ListItem
                          className={`${
                            notification.isRead
                              ? styles.notificationRead
                              : styles.notificationNotRead
                          }`}
                        >
                          <ListItemAvatar
                            className={`${
                              notification.isRead
                                ? styles.messageRead
                                : styles.messageNotRead
                            }`}
                          >
                            {notification.isRead ? <DoneAll /> : <Circle />}
                          </ListItemAvatar>
                          <ListItemText
                            className={`${
                              notification.isRead
                                ? styles.messageRead
                                : styles.messageNotRead
                            }`}
                            primary={`${notification.message?.substring(
                              0,
                              50
                            )}...`}
                            secondary={moment(notification.created_at).format(
                              "DD-MM-YYYY HH:mm"
                            )}
                          />
                        </ListItem>
                      </Tooltip>
                      <Divider />
                    </Fragment>
                  );
                })
              ) : (
                <ListItem>
                  <ListItemText>{Vocabulary.noNotifications} </ListItemText>
                  <ListItemAvatar>
                    <TagFaces />
                  </ListItemAvatar>
                </ListItem>
              )}
            </List>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
}
