import { CSSProperties } from "react";
import { StatusModel } from "../Models/Models";

export interface Props {
  data: StatusModel[];
  title: string;
  style?: CSSProperties | undefined;
}

export default function LegendComponent(props: Props) {
  const { data, title, style } = props;
  return (
    <div style={style}>
      <fieldset
        style={{
          border: "1px solid #0000001f",
          borderRadius: 10,
          marginTop: 20,
        }}
      >
        <legend
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "0rem 0.8rem",
          }}
        >
          {title}
        </legend>
        {data.map((status: StatusModel) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      height: 30,
                      width: 30,
                      backgroundColor: status.culoare,
                      margin: 10,
                      borderRadius: 10,
                    }}
                  ></div>
                  <p
                    style={{
                      margin: 13,
                      verticalAlign: "center",
                      color: "#4d5e80",
                    }}
                  >
                    {status.nume}
                  </p>
                </div>
              </div>
            </>
          );
        })}
      </fieldset>
    </div>
  );
}
