/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useNavigate } from "react-router-dom";

import { Vocabulary } from "../../Utils/Vocabulary";
import { Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { FiltersProps, StateProps } from "./Reports";
import { localUrlEnum } from "../../Utils/urlEnum";
import { formatNumber } from "../../Utils/formatDate";
import { tableLayoutOnMobile } from "../../Utils/Constants";

type StatisticallyProps = {
  filters: FiltersProps;
  state: StateProps;
};
export default function BrandsTable(props: StatisticallyProps) {
  const navigate = useNavigate();
  const { filters, state } = props;

  /**
   *
   */
  const vehiclesBrandsHeader = [
    {
      label: `${Vocabulary.name}`,
      name: "brand_r",
      options: {
        sort: true,
        customBodyRender: (brand_r: any) => (
          <Typography>{brand_r?.nume}</Typography>
        ),
      },
    },
    {
      label: `${Vocabulary.vehiclesNumber}`,
      name: "number",
      options: {
        sort: true,
      },
    },
    {
      label: `${Vocabulary.purchasePriceValue}`,
      name: "valoare_achizitie_ron",
      options: {
        sort: true,
        customBodyRender: (pret_achizitie_ron: any) => (
          <Typography>{formatNumber(pret_achizitie_ron)} Lei</Typography>
        ),
      },
    },
    {
      label: `${Vocabulary.salePriceValue}`,
      name: "valoare_vanzare_ron",
      options: {
        sort: true,
        customBodyRender: (pret_vanzare_ron: any) => (
          <Typography>{formatNumber(pret_vanzare_ron)} Lei</Typography>
        ),
      },
    },
    {
      label: `${Vocabulary.interestRate}`,
      name: "dobanda",
      options: {
        sort: true,
        customBodyRender: (dobanda: any) => (
          <Typography>{formatNumber(dobanda)} Lei</Typography>
        ),
      },
    },
    {
      label: `${Vocabulary.additionalCosts}`,
      name: "valoare_costuri_aditionale",
      options: {
        sort: true,
        customBodyRender: (suma_costuri_aditionale: any) => (
          <Typography>{formatNumber(suma_costuri_aditionale)} Lei</Typography>
        ),
      },
    },
    {
      label: `${Vocabulary.grossProfit}`,
      name: "valoare_profit_brut_vehicul",
      options: {
        sort: true,
        customBodyRender: (profit_brut_vehicul: any) => (
          <Typography>{formatNumber(profit_brut_vehicul)} Lei</Typography>
        ),
      },
    }
    // ,
    // {
    //   label: `${Vocabulary.netIncome}`,
    //   name: "valoare_profit_net_vehicul",
    //   options: {
    //     sort: true,
    //     customBodyRender: (profit_net_vehicul: any) => (
    //       <Typography>{formatNumber(profit_net_vehicul)} Lei</Typography>
    //     ),
    //   },
    // },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    

    return {
      selectableRows: "none" as any,
      responsive: tableLayoutOnMobile,
      viewColumns: false,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      fixedHeader: true,
      count: state.vehiclesNumber,
      rowsPerPage: filters.perPage,
      page: filters.page,
      search: false,
      serverSide: true,
      sort: true,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: Vocabulary.pagination,
      },
      onChangePage: (page: number) => {
        navigate(
          `${localUrlEnum.reports}?reports=${filters.reports}?page=${
            page + 1
          }?perPage=${filters.perPage}?startDate=${filters.startDate}?endDate=${
            filters.endDate
          }?vehicleStatus=${
            filters.vehicleStatus ? filters.vehicleStatus : null
          }?users=${filters.users ? filters.users : null}?company=${
            filters.company ? filters.company : null
          }?locations=${
            filters.locations ? filters.locations : null
          }?purchaseType=${
            filters.purchaseType ? filters.purchaseType : null
          }?tva=${filters.tva ? filters.tva : null}?sort=${filters.sort}`
        );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        navigate(
          `${localUrlEnum.reports}?reports=${
            filters.reports
          }?page=${1}?perPage=${numberOfRows}?startDate=${
            filters.startDate
          }?endDate=${filters.endDate}?vehicleStatus=${
            filters.vehicleStatus ? filters.vehicleStatus : null
          }?users=${filters.users ? filters.users : null}?company=${
            filters.company ? filters.company : null
          }?locations=${
            filters.locations ? filters.locations : null
          }?purchaseType=${
            filters.purchaseType ? filters.purchaseType : null
          }?tva=${filters.tva ? filters.tva : null}?sort=${filters.sort}`
        );
        window.scrollTo(0, 0);
      },
      onColumnSortChange: (changedColumn: string, direction: string) => {
        navigate(
          `${localUrlEnum.reports}?reports=${
            filters.reports
          }?page=${1}?perPage=${filters.perPage}?startDate=${
            filters.startDate
          }?endDate=${filters.endDate}?vehicleStatus=${
            filters.vehicleStatus ? filters.vehicleStatus : null
          }?users=${filters.users ? filters.users : null}?company=${
            filters.company ? filters.company : null
          }?locations=${
            filters.locations ? filters.locations : null
          }?purchaseType=${
            filters.purchaseType ? filters.purchaseType : null
          }?tva=${
            filters.tva ? filters.tva : null
          }?sort=${changedColumn},${direction}`
        );
        window.scrollTo(0, 0);
      },
    };
  }

  return (
    <div>
      <MUIDataTable
        title={""}
        data={state.vehicles}
        columns={vehiclesBrandsHeader}
        options={getTableOptions()}
      />
    </div>
  );
}
