/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider, InputLabel, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Vocabulary } from "../../Utils/Vocabulary";

const additionalData = {
  vehicleBrand: "Marca vehiculului",
  vehicleModel: "Model vehicul",
  vehicleVariant: "Variantă vehicul",
  color: "Culoare",
  km: "Kilometraj",
  fabricationYear: "An fabricație",
  registrationDate: "Data înmatriculării",
  engineCapacity: "Capacitate cilindrică",
  enginePower: "Putere motor",
  engineFuel: "Combustibil",
  engineGearbox: "Cutie de viteze",
  engineDrive: "Tracțiune",
  pollutionNorm: "Normă poluare",
  facilities: "Dotări",
  linkGDPR: "Link GDPR",
};

type TemplateMacrosProps = {
  openMenu: boolean;
  anchorEl: any;
  handleClose: (tag: any) => void;
  handleClick: (event: any) => void;
};

export default function TemplateMacros(props: TemplateMacrosProps) {
  const { openMenu, anchorEl, handleClose, handleClick } = props;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <InputLabel style={{ color: "black", fontSize: 16 }}>
        {Vocabulary.addDynamicData} &nbsp; &nbsp;
      </InputLabel>
      <Button
        aria-controls={openMenu ? "demo-customized-menu" : undefined}
        aria-expanded={openMenu ? "true" : undefined}
        variant="outlined"
        size="large"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
        style={{
          margin: "15px 0px",
        }}
      >
        {Vocabulary.additionalData}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => handleClose("")}
        elevation={0}
        PaperProps={{
          style: { boxShadow: "1px 1px 12px 5px #e9e9e9", width: 185 },
        }}
      >
        {Object.entries(additionalData).map((data) => (
          <>
            <MenuItem onClick={() => handleClose(data[0])} disableRipple>
              {data[1]}
            </MenuItem>
            <Divider />
          </>
        ))}
      </Menu>
    </div>
  );
}
