import { Vocabulary } from "../Vocabulary";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";

export const ROUTER_PHOTOS= {
  routes: [
    "/",
    "/vehiclesDashboard",
    "/vehiclesDashboard/vehiclePhotos/:id?",
    "*",
  ],
};

export const MAIN_SLIDER_PHOTOS = [{
  name: Vocabulary.logsName,
  path: "/logs",
  icon: <AssignmentIndIcon />,
}];

export const VEHICLE_OPTIONS_PHOTOS= [
  {
    name: Vocabulary.poze,
    path: "/vehiclesDashboard/vehiclePhotos/",
    icon: <PhotoLibraryIcon />,
    id: "vehiclePhotos",
    slug: "vehiclePhotos",
    orderNumber: 8,
  },
];

export const DINAMIC_TABS_PHOTOS = null;

export const MAIN_TABLE_PHOTOS = null;
