/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import VehicleSlider from "./VehicleSlider";
import MainTable from "./MainTable";
import style from "../Styles/mainDashboard.module.css";
import { getData } from "../Services/getData";
import { localUrlEnum, urlEnum } from "../Utils/urlEnum";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneIcon from "@mui/icons-material/Tune";
import { isMobile } from "../Utils/utils";
import { Typography } from "@mui/material";
import {
  ButtonsTable,
  componentNames,
  tipStatusVehicule,
  vehiclesTabs,
} from "../Utils/Constants";
import { VehiclesTab } from "../Utils/autocompleteEnum";
import { StatusModel } from "../Models/Models";

export type PillsProps = {
  status: string;
  statusVehicul: string;
  users: string;
  company: string;
  locations: string;
  vehicleTypes: string;
  purchaseType?: string;
  minPrice: number | null;
  maxPrice: number | null;
  visible: string | number;
  statusError: string;
  page: number;
  perPage: number;
  search: string | null;
  tvaRecovered: string | null;
  showIncompleteVehicles?: boolean;
  sortByCreatedAt?: string | boolean;
  showNegativeProfit?: string | boolean;
  category_id?: string | null;
  physicalVehicleStatus?: string;
};

export type StateProps = {
  vehicles: Array<any>;
  vehiclesNumber: number;
  physicalStatuses: Array<StatusModel>;
  sellingStatuses: Array<StatusModel>;
  acquisitionsSum: number;
};

export type AuthorizationStateProps = {
  authorizations: Array<any>;
  authorizationsNumber: number;
};

export default function MainDashboard(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [users, setUsers] = useState({
    vanzatori: [],
    useriAchizitie: [],
    useriReservari: [],
  });
  const [state, setState] = useState<StateProps>({
    vehicles: [],
    vehiclesNumber: 0,
    physicalStatuses: [],
    sellingStatuses: [],
    acquisitionsSum: 0,
  });
  const [pills, setPills] = useState<PillsProps>({
    status: vehiclesTabs[0].code,
    statusVehicul: "[]",
    users: "[]",
    company: "[]",
    locations: "[]",
    vehicleTypes: "[]",
    purchaseType: "[]",
    minPrice: null,
    maxPrice: null,
    visible: "null",
    statusError: "null",
    page: 0,
    perPage: 10,
    tvaRecovered: "null",
    search: null,
    showIncompleteVehicles: false,
    showNegativeProfit: false,
    sortByCreatedAt: false,
    category_id: null,
    physicalVehicleStatus: "[]",
  });

  const [openAccordion, setOpenAccordion] = useState(false);
  const [value, setValue] = useState(0);
  const [vehiclesCategories, setVehiclesCategories] = useState<any>(null);

  /**
   *
   */
  useEffect(() => {
    getData(urlEnum.userFilters).then((res: any) => {
      setUsers(res.data);
    });
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (vehiclesCategories?.length) getUrlVehicles(vehiclesCategories);
    else
      getData(
        `${urlEnum.status_id}/getByType/${tipStatusVehicule.vehicleCategory}`
      ).then((res: any) => {
        setVehiclesCategories(res.data?.data);
        getUrlVehicles(res.data?.data);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getUrlVehicles = (vehiclesCategories: any) => {
    const search = location.search.split("?");
    const newFilters = Object.assign({}, pills) as any;
    search.forEach((value) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "search")
          newFilters[values[0]] = decodeURIComponent(values[1]);
        if (values[0] === "visible") newFilters[values[0]] = values[1];
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        else
          newFilters[values[0]] = !isNaN(+values[1]) ? +values[1] : values[1];
        if (values[1] === "null") newFilters[values[0]] = null;
      }
    });

    if (vehiclesCategories?.length && newFilters.category_id) {
      const category = vehiclesCategories.findIndex(
        (category: any) => category.id === newFilters.category_id
      );
      if (category !== -1) {
        setValue(category);
      } else {
        setValue(0);
      }
    } else {
      setValue(0);
    }
    if (newFilters.showIncompleteVehicles)
      newFilters.showIncompleteVehicles =
        newFilters.showIncompleteVehicles == "true" ||
        newFilters.showIncompleteVehicles == true; //pentru ca valoarea venea ca string si nu functiona switch-ul cu string
    getVehicles(newFilters);

    setPills(newFilters);
  };

  /**
   *
   * @param newFilters
   */
  async function getVehicles(newFilters: any) {
    const url = `${urlEnum.vehicles}/${newFilters.status}/${newFilters.page}/${
      newFilters.perPage
    }/${
      JSON.parse(newFilters.statusVehicul)?.length
        ? JSON.parse(newFilters.statusVehicul)
        : null
    }/${
      JSON.parse(newFilters.users)?.length ? JSON.parse(newFilters.users) : null
    }/${
      JSON.parse(newFilters.company)?.length
        ? JSON.parse(newFilters.company)
        : null
    }/${
      JSON.parse(newFilters.locations)?.length
        ? JSON.parse(newFilters.locations)
        : null
    }/${
      JSON.parse(newFilters.vehicleTypes)?.length
        ? JSON.parse(newFilters.vehicleTypes)
        : null
    }/${newFilters.minPrice}/${newFilters.maxPrice}/${newFilters.visible}/${
      newFilters.statusError
    }/${newFilters.tvaRecovered}/${
      newFilters.search ? newFilters.search : null
    }/${newFilters.showIncompleteVehicles}/${
      JSON.parse(newFilters.purchaseType)?.length
        ? JSON.parse(newFilters.purchaseType)
        : null
    }/${newFilters.sortByCreatedAt}/${newFilters.showNegativeProfit}/${
      newFilters?.category_id
    }/${
      newFilters?.physicalVehicleStatus &&
      JSON.parse(newFilters?.physicalVehicleStatus)?.length
        ? JSON.parse(newFilters?.physicalVehicleStatus)
        : null
    }`;

    const res = await getData(url);
    setState(res.data);
  }

  // /**
  //  *
  //  * @param item
  //  * @returns
  //  */
  // const setPageOption = (item: any) => {
  //   switch (item) {
  //     case VehiclesTab.unsold:
  //       setValue(0);
  //       break;
  //     case VehiclesTab.sold:
  //       setValue(1);
  //       break;
  //     case VehiclesTab.reserved:
  //       setValue(2);
  //       break;
  //     case VehiclesTab.partialVehicles:
  //       setValue(3);
  //       break;
  //     case VehiclesTab.onDemandVehicles:
  //       setValue(4);
  //       break;
  //     default:
  //       setValue(0);
  //       break;
  //   }
  // };

  /**
   *
   * @param event
   * @param newValue
   */
  const handleSliderChange = (event: any, newValue: any) => {
    navigate(
      `${localUrlEnum.vehicles}?status=${pills.status}?page=${1}?perPage=${
        pills.perPage
      }?statusVehicul=${
        pills.statusVehicul ? pills.statusVehicul : null
      }?users=${pills.users ? pills.users : null}?company=${
        pills.company ? pills.company : null
      }?locations=${pills.locations ? pills.locations : null}?vehicleTypes=${
        pills.vehicleTypes ? pills.vehicleTypes : null
      }?minPrice=${newValue[0]}?maxPrice=${newValue[1]}?visible=${
        pills.visible
      }?statusError=${pills.statusError}?tvaRecovered=${pills.tvaRecovered}${
        pills?.search ? `?search=${pills?.search}` : ""
      }?showIncompleteVehicles=${pills.showIncompleteVehicles}?purchaseType=${
        pills.purchaseType ? pills.purchaseType : null
      }?sortByCreatedAt=${
        pills.sortByCreatedAt ? pills.sortByCreatedAt : null
      }?showNegativeProfit=${pills.showNegativeProfit}?category_id=${
        pills.category_id
      }?physicalVehicleStatus=${pills?.physicalVehicleStatus}`
    );
  };

  /**
   *
   * @param event
   */
  const handleMinInputChangeURL = (event: any) => {
    navigate(
      `${localUrlEnum.vehicles}?status=${pills.status}?page=${1}?perPage=${
        pills.perPage
      }?statusVehicul=${
        pills.statusVehicul ? pills.statusVehicul : null
      }?users=${pills.users ? pills.users : null}?company=${
        pills.company ? pills.company : null
      }?locations=${pills.locations ? pills.locations : null}?vehicleTypes=${
        pills.vehicleTypes ? pills.vehicleTypes : null
      }?minPrice=${
        event.target.value === "" ? "null" : event.target.value
      }?maxPrice=${pills.maxPrice}?visible=${pills.visible}?statusError=${
        pills.statusError
      }?tvaRecovered=${pills.tvaRecovered}${
        pills?.search ? `?search=${pills?.search}` : ""
      }?showIncompleteVehicles=${pills.showIncompleteVehicles}?purchaseType=${
        pills.purchaseType ? pills.purchaseType : null
      }?sortByCreatedAt=${
        pills.sortByCreatedAt ? pills.sortByCreatedAt : null
      }?showNegativeProfit=${pills.showNegativeProfit}?category_id=${
        pills.category_id
      }?physicalVehicleStatus=${pills?.physicalVehicleStatus}`
    );
  };

  /**
   *
   * @param event
   */
  const handleMaxInputChangeURL = (event: any) => {
    navigate(
      `${localUrlEnum.vehicles}?status=${pills.status}?page=${1}?perPage=${
        pills.perPage
      }?statusVehicul=${
        pills.statusVehicul ? pills.statusVehicul : null
      }?users=${pills.users ? pills.users : null}?company=${
        pills.company ? pills.company : null
      }?locations=${pills.locations ? pills.locations : null}?vehicleTypes=${
        pills.vehicleTypes ? pills.vehicleTypes : null
      }?minPrice=${pills.minPrice}?maxPrice=${
        event.target.value === "" ? "null" : event.target.value
      }?visible=${pills.visible}?statusError=${
        pills.statusError
      }?tvaRecovered=${pills.tvaRecovered}?search=${
        pills.search ? pills.search : null
      }?showIncompleteVehicles=${pills.showIncompleteVehicles}?purchaseType=${
        pills.purchaseType ? pills.purchaseType : null
      }?sortByCreatedAt=${
        pills.sortByCreatedAt ? pills.sortByCreatedAt : null
      }?showNegativeProfit=${pills.showNegativeProfit}?category_id=${
        pills.category_id
      }?physicalVehicleStatus=${pills?.physicalVehicleStatus}`
    );
  };

  /**
   *
   * @param event
   */
  const onChangeTextField = (event: any, newValue: any, name: string) => {
    switch (name) {
      case "statusVehicul":
        navigate(
          `${localUrlEnum.vehicles}?status=${pills.status}?page=${1}?perPage=${
            pills.perPage
          }?statusVehicul=${newValue ? JSON.stringify(newValue) : null}?users=${
            pills.users ? pills.users : null
          }?company=${pills.company ? pills.company : null}?locations=${
            pills.locations ? pills.locations : null
          }?vehicleTypes=${
            pills.vehicleTypes ? pills.vehicleTypes : null
          }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
            pills.visible
          }?statusError=${pills.statusError}?tvaRecovered=${
            pills.tvaRecovered
          }${
            pills?.search ? `?search=${pills?.search}` : ""
          }?showIncompleteVehicles=${
            pills.showIncompleteVehicles
          }?purchaseType=${
            pills.purchaseType ? pills.purchaseType : null
          }?sortByCreatedAt=${
            pills.sortByCreatedAt ? pills.sortByCreatedAt : null
          }?showNegativeProfit=${pills.showNegativeProfit}?category_id=${
            pills.category_id
          }?physicalVehicleStatus=${pills?.physicalVehicleStatus}`
        );
        break;
      case "physicalVehicleStatus":
        navigate(
          `${localUrlEnum.vehicles}?status=${pills.status}?page=${1}?perPage=${
            pills.perPage
          }?statusVehicul=${
            pills.statusVehicul ? pills.statusVehicul : null
          }?users=${pills.users ? pills.users : null}?company=${
            pills.company ? pills.company : null
          }?locations=${
            pills.locations ? pills.locations : null
          }?vehicleTypes=${
            pills.vehicleTypes ? pills.vehicleTypes : null
          }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
            pills.visible
          }?statusError=${pills.statusError}?tvaRecovered=${
            pills.tvaRecovered
          }${
            pills?.search ? `?search=${pills?.search}` : ""
          }?showIncompleteVehicles=${
            pills.showIncompleteVehicles
          }?purchaseType=${
            pills.purchaseType ? pills.purchaseType : null
          }?sortByCreatedAt=${
            pills.sortByCreatedAt ? pills.sortByCreatedAt : null
          }?showNegativeProfit=${pills.showNegativeProfit}?category_id=${
            pills.category_id
          }?physicalVehicleStatus=${newValue ? JSON.stringify(newValue) : null}`
        );
        break;
      case "purchaseType":
        navigate(
          `${localUrlEnum.vehicles}?status=${pills.status}?page=${1}?perPage=${
            pills.perPage
          }?statusVehicul=${
            pills.statusVehicul ? pills.statusVehicul : null
          }?users=${pills.users ? pills.users : null}?company=${
            pills.company ? pills.company : null
          }?locations=${
            pills.locations ? pills.locations : null
          }?vehicleTypes=${
            pills.vehicleTypes ? pills.vehicleTypes : null
          }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
            pills.visible
          }?statusError=${pills.statusError}?tvaRecovered=${
            pills.tvaRecovered
          }${
            pills?.search ? `?search=${pills?.search}` : ""
          }?showIncompleteVehicles=${
            pills.showIncompleteVehicles
          }?purchaseType=${
            newValue ? JSON.stringify(newValue) : null
          }?sortByCreatedAt=${
            pills.sortByCreatedAt ? pills.sortByCreatedAt : null
          }?showNegativeProfit=${pills.showNegativeProfit}?category_id=${
            pills.category_id
          }?physicalVehicleStatus=${pills?.physicalVehicleStatus}`
        );
        break;
      case "users":
        navigate(
          `${localUrlEnum.vehicles}?status=${pills.status}?page=${1}?perPage=${
            pills.perPage
          }?statusVehicul=${
            pills.statusVehicul ? pills.statusVehicul : null
          }?users=${newValue ? JSON.stringify(newValue) : null}?company=${
            pills.company ? pills.company : null
          }?locations=${
            pills.locations ? pills.locations : null
          }?vehicleTypes=${
            pills.vehicleTypes ? pills.vehicleTypes : null
          }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
            pills.visible
          }?statusError=${pills.statusError}?tvaRecovered=${
            pills.tvaRecovered
          }${
            pills?.search ? `?search=${pills?.search}` : ""
          }?showIncompleteVehicles=${
            pills.showIncompleteVehicles
          }?purchaseType=${
            pills.purchaseType ? pills.purchaseType : null
          }?sortByCreatedAt=${
            pills.sortByCreatedAt ? pills.sortByCreatedAt : null
          }?showNegativeProfit=${pills.showNegativeProfit}?category_id=${
            pills.category_id
          }?physicalVehicleStatus=${pills?.physicalVehicleStatus}`
        );
        break;
      case "company":
        navigate(
          `${localUrlEnum.vehicles}?status=${pills.status}?page=${1}?perPage=${
            pills.perPage
          }?statusVehicul=${
            pills.statusVehicul ? pills.statusVehicul : null
          }?users=${pills.users ? pills.users : null}?company=${
            newValue ? JSON.stringify(newValue) : null
          }?locations=${
            pills.locations ? pills.locations : null
          }?vehicleTypes=${
            pills.vehicleTypes ? pills.vehicleTypes : null
          }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
            pills.visible
          }?statusError=${pills.statusError}?tvaRecovered=${
            pills.tvaRecovered
          }${
            pills?.search ? `?search=${pills?.search}` : ""
          }?showIncompleteVehicles=${
            pills.showIncompleteVehicles
          }?purchaseType=${
            pills.purchaseType ? pills.purchaseType : null
          }?sortByCreatedAt=${
            pills.sortByCreatedAt ? pills.sortByCreatedAt : null
          }?showNegativeProfit=${pills.showNegativeProfit}?category_id=${
            pills.category_id
          }?physicalVehicleStatus=${pills?.physicalVehicleStatus}`
        );
        break;
      case "locations":
        navigate(
          `${localUrlEnum.vehicles}?status=${pills.status}?page=${1}?perPage=${
            pills.perPage
          }?statusVehicul=${
            pills.statusVehicul ? pills.statusVehicul : null
          }?users=${pills.users ? pills.users : null}?company=${
            pills.company ? pills.company : null
          }?locations=${
            newValue ? JSON.stringify(newValue) : null
          }?vehicleTypes=${
            pills.vehicleTypes ? pills.vehicleTypes : null
          }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
            pills.visible
          }?statusError=${pills.statusError}?tvaRecovered=${
            pills.tvaRecovered
          }${
            pills?.search ? `?search=${pills?.search}` : ""
          }?showIncompleteVehicles=${
            pills.showIncompleteVehicles
          }?purchaseType=${
            pills.purchaseType ? pills.purchaseType : null
          }?sortByCreatedAt=${
            pills.sortByCreatedAt ? pills.sortByCreatedAt : null
          }?showNegativeProfit=${pills.showNegativeProfit}?category_id=${
            pills.category_id
          }?physicalVehicleStatus=${pills?.physicalVehicleStatus}`
        );
        break;
      case "vehicleTypes":
        navigate(
          `${localUrlEnum.vehicles}?status=${pills.status}?page=${1}?perPage=${
            pills.perPage
          }?statusVehicul=${
            pills.statusVehicul ? pills.statusVehicul : null
          }?users=${pills.users ? pills.users : null}?company=${
            pills.company ? pills.company : null
          }?locations=${
            pills.locations ? pills.locations : null
          }?vehicleTypes=${
            newValue ? JSON.stringify(newValue) : null
          }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
            pills.visible
          }?statusError=${pills.statusError}?tvaRecovered=${
            pills.tvaRecovered
          }${
            pills?.search ? `?search=${pills?.search}` : ""
          }?showIncompleteVehicles=${
            pills.showIncompleteVehicles
          }?purchaseType=${
            pills.purchaseType ? pills.purchaseType : null
          }?sortByCreatedAt=${
            pills.sortByCreatedAt ? pills.sortByCreatedAt : null
          }?showNegativeProfit=${pills.showNegativeProfit}?category_id=${
            pills.category_id
          }?physicalVehicleStatus=${pills?.physicalVehicleStatus}`
        );
        break;

      case "showIncompleteVehicles":
        navigate(
          `${localUrlEnum.vehicles}?status=${pills.status}?page=${1}?perPage=${
            pills.perPage
          }?statusVehicul=${
            pills.statusVehicul ? pills.statusVehicul : null
          }?users=${pills.users ? pills.users : null}?company=${
            pills.company ? pills.company : null
          }?locations=${
            pills.locations ? pills.locations : null
          }?vehicleTypes=${newValue?.id ? newValue?.id : null}?minPrice=${
            pills.minPrice
          }?maxPrice=${pills.maxPrice}?visible=${pills.visible}?statusError=${
            pills.statusError
          }?tvaRecovered=${pills.tvaRecovered}${
            pills?.search ? `?search=${pills?.search}` : ""
          }?showIncompleteVehicles=${newValue}?purchaseType=${
            pills.purchaseType ? pills.purchaseType : null
          }?sortByCreatedAt=${
            pills.sortByCreatedAt ? pills.sortByCreatedAt : null
          }?showNegativeProfit=${pills.showNegativeProfit}?category_id=${
            pills.category_id
          }?physicalVehicleStatus=${pills?.physicalVehicleStatus}`
        );
        break;
      case "showNegativeProfit":
        navigate(
          `${localUrlEnum.vehicles}?status=${pills.status}?page=${1}?perPage=${
            pills.perPage
          }?statusVehicul=${
            pills.statusVehicul ? pills.statusVehicul : null
          }?users=${pills.users ? pills.users : null}?company=${
            pills.company ? pills.company : null
          }?locations=${
            pills.locations ? pills.locations : null
          }?vehicleTypes=${newValue?.id ? newValue?.id : null}?minPrice=${
            pills.minPrice
          }?maxPrice=${pills.maxPrice}?visible=${pills.visible}?statusError=${
            pills.statusError
          }?tvaRecovered=${pills.tvaRecovered}${
            props.pills?.search ? `?search=${props.pills?.search}` : ""
          }?showIncompleteVehicles=${
            pills.showIncompleteVehicles
          }?purchaseType=${
            pills.purchaseType ? pills.purchaseType : null
          }?sortByCreatedAt=${
            pills.sortByCreatedAt ? pills.sortByCreatedAt : null
          }?showNegativeProfit=${newValue}?category_id=${
            pills.category_id
          }?physicalVehicleStatus=${pills?.physicalVehicleStatus}`
        );
        break;
      case "sortByCreatedAt":
        navigate(
          `${localUrlEnum.vehicles}?status=${pills.status}?page=${1}?perPage=${
            pills.perPage
          }?statusVehicul=${
            pills.statusVehicul ? pills.statusVehicul : null
          }?users=${pills.users ? pills.users : null}?company=${
            pills.company ? pills.company : null
          }?locations=${
            pills.locations ? pills.locations : null
          }?vehicleTypes=${newValue?.id ? newValue?.id : null}?minPrice=${
            pills.minPrice
          }?maxPrice=${pills.maxPrice}?visible=${pills.visible}?statusError=${
            pills.statusError
          }?tvaRecovered=${pills.tvaRecovered}${
            pills?.search ? `?search=${pills?.search}` : ""
          }?showIncompleteVehicles=${
            pills.showIncompleteVehicles
          }?purchaseType=${
            pills.purchaseType ? pills.purchaseType : null
          }?sortByCreatedAt=${newValue}?showNegativeProfit=${
            pills.showNegativeProfit
          }?category_id=${pills.category_id}?physicalVehicleStatus=${
            pills?.physicalVehicleStatus
          }`
        );
        break;
      default:
        break;
    }
  };

  /**
   *
   * @param statusError
   */
  function changeStatusError(statusError: string) {
    navigate(
      `${localUrlEnum.vehicles}?status=${pills.status}?page=${1}?perPage=${
        pills.perPage
      }?statusVehicul=${
        pills.statusVehicul ? pills.statusVehicul : null
      }?users=${pills.users ? pills.users : null}?company=${
        pills.company ? pills.company : null
      }?locations=${pills.locations ? pills.locations : null}?vehicleTypes=${
        pills.vehicleTypes ? pills.vehicleTypes : null
      }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
        pills.visible
      }?statusError=${statusError}?tvaRecovered=${pills.tvaRecovered}${
        pills?.search ? `?search=${pills?.search}` : ""
      }?showIncompleteVehicles=${pills.showIncompleteVehicles}?purchaseType=${
        pills.purchaseType ? pills.purchaseType : null
      }?sortByCreatedAt=${
        pills.sortByCreatedAt ? pills.sortByCreatedAt : null
      }?showNegativeProfit=${pills.showNegativeProfit}?category_id=${
        pills.category_id
      }?physicalVehicleStatus=${pills?.physicalVehicleStatus}`
    );
  }

  /**
   *
   * @param tvaRecovered
   */
  function changeTvaRecovered(tvaRecovered: string) {
    navigate(
      `${localUrlEnum.vehicles}?status=${pills.status}?page=${1}?perPage=${
        pills.perPage
      }?statusVehicul=${
        pills.statusVehicul ? pills.statusVehicul : null
      }?users=${pills.users ? pills.users : null}?company=${
        pills.company ? pills.company : null
      }?locations=${pills.locations ? pills.locations : null}?vehicleTypes=${
        pills.vehicleTypes ? pills.vehicleTypes : null
      }?minPrice=${pills.minPrice}?maxPrice=${pills.maxPrice}?visible=${
        pills.visible
      }?visible=${pills.visible}?statusError=${
        pills.statusError
      }?tvaRecovered=${tvaRecovered}${
        pills?.search ? `?search=${pills?.search}` : ""
      }?showIncompleteVehicles=${pills.showIncompleteVehicles}?purchaseType=${
        pills.purchaseType ? pills.purchaseType : null
      }?sortByCreatedAt=${
        pills.sortByCreatedAt ? pills.sortByCreatedAt : null
      }?showNegativeProfit=${pills.showNegativeProfit}?category_id=${
        pills.category_id
      }?physicalVehicleStatus=${pills?.physicalVehicleStatus}`
    );
  }

  /**
   *
   * @param event
   * @param index
   */
  const handleChange = (event: any, index: number) => {
    deleteFilters();
    const categoryId = vehiclesCategories[index].id;
    navigate(
      `${localUrlEnum.vehicles}?status=${
        pills.status
      }?category_id=${categoryId}?page=${1}?perPage=${
        pills.perPage
      }?showIncompleteVehicles=${pills.showIncompleteVehicles}`
    );
  };

  /**
   *
   * @param newFilters
   */
  function handleAccordion(open: boolean) {
    setOpenAccordion(open);
  }

  /**
   *
   * @param newFilters
   */
  function setShowIncompleteVehicles(
    event: ChangeEvent<HTMLInputElement>,
    newValue: boolean
  ) {
    onChangeTextField(event, newValue, "showIncompleteVehicles");
    setPills({ ...pills, showIncompleteVehicles: newValue });
  }

  /**
   *
   */
  function deleteFilters() {
    setPills({
      ...pills,
      status: VehiclesTab.unsold,
      statusVehicul: "[]",
      users: "[]",
      company: "[]",
      locations: "[]",
      vehicleTypes: "[]",
      purchaseType: "[]",
      minPrice: null,
      maxPrice: null,
      visible: "null",
      statusError: "null",
      page: 0,
      perPage: 10,
      tvaRecovered: "null",
      search: null,
      sortByCreatedAt: false,
      showIncompleteVehicles: false,
      showNegativeProfit: false,
      category_id: null,
      physicalVehicleStatus: "[]",
    });
  }
  return (
    <div
      style={{ display: "flex", flexDirection: isMobile() ? "column" : "row" }}
    >
      {isMobile() ? (
        <Accordion
          expanded={openAccordion}
          onChange={() => handleAccordion(!openAccordion)}
          style={{ zIndex: 10 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <TuneIcon style={{ marginRight: 10 }}></TuneIcon>
            <Typography style={{ marginTop: 1 }}>Filtre </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <VehicleSlider
              key={new Date().getTime()}
              data={pills}
              users={users}
              handleSliderChange={handleSliderChange}
              handleMinInputChangeURL={handleMinInputChangeURL}
              handleMaxInputChangeURL={handleMaxInputChangeURL}
              onChangeTextField={onChangeTextField}
              changeStatusError={changeStatusError}
              changeTvaRecovered={changeTvaRecovered}
              handleAccordion={handleAccordion}
              setShowIncompleteVehicles={setShowIncompleteVehicles}
              statusesForFilters={state?.physicalStatuses}
              sellingStatuses={state?.sellingStatuses}
            />
          </AccordionDetails>
        </Accordion>
      ) : (
        <div className={style.vehicleSlider}>
          <VehicleSlider
            key={new Date().getTime()}
            users={users}
            data={pills}
            handleSliderChange={handleSliderChange}
            handleMinInputChangeURL={handleMinInputChangeURL}
            handleMaxInputChangeURL={handleMaxInputChangeURL}
            onChangeTextField={onChangeTextField}
            changeStatusError={changeStatusError}
            changeTvaRecovered={changeTvaRecovered}
            handleAccordion={handleAccordion}
            setShowIncompleteVehicles={setShowIncompleteVehicles}
            statusesForFilters={state?.physicalStatuses}
            sellingStatuses={state?.sellingStatuses}
          />
        </div>
      )}

      <div className={style.mainTable}>
        <MainTable
          type={localUrlEnum.vehicles}
          name={componentNames.MainTable}
          key={new Date().getTime()}
          info={ButtonsTable}
          pills={pills}
          state={state}
          value={value}
          handleChange={handleChange}
          getDataRefresh={getUrlVehicles}
          deleteFilters={deleteFilters}
        ></MainTable>
      </div>
    </div>
  );
}
