/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { Vocabulary } from "../../../Utils/Vocabulary";
import { ChangeEvent, useContext, useEffect, useMemo } from "react";
import { VehicleContext } from "../../../Context/vehicleContext";
import { ContractInformationModel } from "../../../Models/Models";
import { CacheContext } from "../../../Context/cacheContext";
import style from "../../../Styles/optionsVehicle.module.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { europeanDate, usDateFormatWithTime } from "../../../Utils/formatDate";
import moment from "moment";
import { UserModel } from "../../../Models/VehicleModel";

/**
 *
 */
type ContractFileProps = {
  contractInformation: ContractInformationModel;
  salesInformation: any;
  onChangeContractInformation: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: any
  ) => void;
  onChangeSaleDate: (event: any) => void;
  onChangeSaleUser: (event: any, value: any) => void;
  onChangeSalesInformation: (salesInformation: any) => void;
};

export default function ContractInformationForExistingContracts(
  props: ContractFileProps
) {
  const {
    contractInformation,
    salesInformation,
    onChangeContractInformation,
    onChangeSaleDate,
    onChangeSaleUser,
    onChangeSalesInformation,
  } = props;
  const { cache }: any = useContext(CacheContext);
  const vehicleContext: any = useContext(VehicleContext); //TODO
  const VATSetting = useMemo(
    () =>
      cache.settings.find(
        (item: { slug: string; value: any }) => item.slug === "procentTVA"
      ),
    [cache]
  );
  const VATPercent = +VATSetting?.value;

  useEffect(() => {
    if (
      vehicleContext.state.vehicle?.pret_vanzare_ron &&
      vehicleContext.state.vehicle?.pret_vanzare_ron !== 0 &&
      !contractInformation.pretVanzare
    ) {
      onChangeContractInformation(
        vehicleContext.state.vehicle?.pret_vanzare_ron
      );
    }
    const newSalesInformation = Object.assign({}, salesInformation);
    if (
      vehicleContext?.state?.vehicle?.data_vanzare &&
      vehicleContext?.state?.vehicle?.data_vanzare !== ""
    ) {
      newSalesInformation.data_vanzare = moment(
        vehicleContext?.state?.vehicle?.data_vanzare,
        usDateFormatWithTime
      );
    }
    if (vehicleContext?.state?.vehicle?.vanzator_id) {
      newSalesInformation.vanzator_id =
        vehicleContext?.state?.vehicle?.vanzator_id;
    }
    onChangeSalesInformation(newSalesInformation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const brutSellingPrice =
    vehicleContext?.state?.vehicle?.tva_id === 1
      ? Math.round(
          contractInformation.pretVanzare * (1 + VATPercent / 100) * 100
        ) / 100
      : contractInformation.pretVanzare;

  return (
    <div style={{ margin: 10 }}>
      <Grid container spacing={1}>
        <Grid item xs={6} md={6} xl={6}>
          <TextField
            fullWidth
            label={
              vehicleContext?.state?.vehicle?.tva_id === 1
                ? Vocabulary.sellPriceNet
                : Vocabulary.vehicleBrutPrice
            }
            variant="outlined"
            name="pretVanzare"
            size="small"
            type="number"
            value={contractInformation.pretVanzare}
            onChange={(event) => onChangeContractInformation(event)}
          />
        </Grid>
        <Grid item xs={6} md={6} xl={6}>
          <div style={{ margin: 7 }} className={style.textLines}>
            <Typography>
              <strong>{Vocabulary.sellPriceBrut}:</strong>&nbsp;&nbsp;
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography className={style.labels}>
                {brutSellingPrice && brutSellingPrice?.toFixed(3)}
              </Typography>
              <Typography>&nbsp;{Vocabulary.currencyLei}</Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} xl={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              className={style.textField}
              format={europeanDate}
              maxDate={moment()}
              value={salesInformation.data_vanzare}
              onChange={(event) => onChangeSaleDate(event)}
              label={Vocabulary.sellDate}
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <Autocomplete
            freeSolo={false}
            id="vanzator_id"
            disablePortal
            disableClearable
            className={style.textField}
            getOptionLabel={(option: any) => option.nume}
            size="small"
            value={cache?.users.find(
              (user: UserModel) => user.id === +salesInformation.vanzator_id
            )}
            options={cache?.users}
            onChange={(event: any, newValue: any) => {
              onChangeSaleUser(event, newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.seller}
                variant="outlined"
                size="small"
                name="vanzator_id"
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
