/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Button,
  Chip,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import MUIDataTable from "mui-datatables";
import GenericModal from "../../Modal/GenericModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { urlEnum } from "../../Utils/urlEnum";
import { getData } from "../../Services/getData";
import { Delete, Edit } from "@mui/icons-material";
import { multipleDelete } from "../../Services/multipleDelete";
import { isMobile } from "../../Utils/utils";
import Role from "./Role";
import { CacheContext } from "../../Context/cacheContext";
import { tableLayoutOnMobile } from "../../Utils/Constants";

type State = {
  roles: Array<RolesData>;
  rolesNumber: number;
  page: number;
  perPage: number;
  search: string | null;
  order: string;
  filter: string | null;
};
export type RolesData = {
  id?: number | undefined;
  name: string;
  culoare: string;
  tip_roles: string;
};
export default function Roles(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const cacheContext = useContext(CacheContext);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedIds, setDeletedIds] = useState<Array<number>>([]);

  const [editedRoles, setEditedRoles]: any = useState(null);
  const [state, setState] = useState<State>({
    roles: new Array<RolesData>(),
    rolesNumber: 0,
    page: 0,
    perPage: 10,
    search: null,
    order: "asc",
    filter: null,
  });

  const [state1, setState1] = useState({
    permissions: new Array<any>(),
    permissionsNumber: 0,
  });

  /**
   *
   * @param {*} event
   * @param {*} newValue
   */

  /**
   * Get roles
   */
  useEffect(() => {
    const search = location.search.split("?");
    const newState = {
      roles: new Array<RolesData>(),
      rolesNumber: 0,
      page: 0,
      perPage: 10,
      search: null,
      order: "asc",
      filter: null,
    } as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1] - 1;
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    getRoles(newState.page, newState.perPage, newState.search);
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  function getRoles(page: number, perPage: number, search?: string | null) {
    let url = `${urlEnum.roles}/${page}/${perPage}`;
    if (search) {
      url = `${urlEnum.roles}/${page}/${perPage}/${search}`;
    }
    getData(url)
      .then((res) => {
        setState({
          ...state,
          roles: res.data.roles,
          rolesNumber: res.data.rolesNumber,
          page: page,
          perPage: perPage,
          search: search ? search : null,
        });
      })
      .catch((err) => {
        //
      });
  }

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  function getPermissions() {
    const url = `${urlEnum.permissions_id}/${0}/${100}`;

    getData(url)
      .then((res) => {
        setState1({
          ...state1,
          permissions: res.data.permissions,
          permissionsNumber: res.data.permissionsNumber,
        });
      })
      .catch((err) => {
        //
      });
  }
  /**
   *
   */
  const rolesHeader = [
    {
      label: `${Vocabulary.name}`,
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.permissionsName}`,
      name: "permissions",
      options: {
        sort: false,
        customBodyRender: (rowIndex: any, index: any) => {
          return (
            <>
              {rowIndex.map((value: any) => (
                <Chip
                  key={value.id}
                  label={Vocabulary[value.name]}
                  style={{
                    margin: 5,
                    backgroundColor:
                      value.isDepartment === 1 ? "#f836" : "#4442",
                  }}
                />
              ))}
            </>
          );
        },
      },
    },

    {
      label: "",
      name: "",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (rowIndex: number, value: any) => {
          return (
            <>
              <div style={{ marginTop: -10, marginBottom: -10 }}>
                <ToggleButtonGroup exclusive aria-label="text alignment">
                  <Tooltip title={Vocabulary.edit}>
                    <ToggleButton
                      onClick={() => {
                        props.setOpenEditModal(true);
                        setEditedRoles(state.roles[rowIndex]);
                      }}
                      value="center"
                      aria-label="centered"
                    >
                      <Edit />
                    </ToggleButton>
                  </Tooltip>
                  {state.roles[rowIndex].name !== "Administrator" ? (
                    <Tooltip title={Vocabulary.delete}>
                      <ToggleButton
                        onClick={() => {
                          setDeletedIds([state.roles[rowIndex].id as number]);
                          setOpenDeleteModal(true);
                        }}
                        value="center"
                        aria-label="centered"
                      >
                        <Delete />
                      </ToggleButton>
                    </Tooltip>
                  ) : null}
                </ToggleButtonGroup>
              </div>
            </>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {

    return {
      responsive: tableLayoutOnMobile,
      viewColumns: false,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      fixedHeader: true,
      count: state.rolesNumber,
      rowsPerPage: state.perPage,
      page: state.page,
      search: false,
      serverSide: true,
      sort: true,
      textLabels: {
        // selectedRows: {
        //   text: "selectate",
        //   delete: "Sterge",
        //   deleteAria: "Sterge liniile selectate",
        // },
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: Vocabulary.pagination,
      },
      onRowsDelete: (rowsDeleted: any, newData: any) => {
        const ids = new Array<number>();
        rowsDeleted.data.forEach((row: any) => {
          ids.push(state.roles[row.dataIndex].id as number);
        });
        setDeletedIds(ids);
        setOpenDeleteModal(true);
      },
      onChangePage: (page: number) => {
        setState({ ...state, page: page });
        if (state.search)
          navigate(
            `?settings=roles?page=${page + 1}?perPage=${state.perPage}?search=${
              state.search
            }`
          );
        else
          navigate(`?settings=roles?page=${page + 1}?perPage=${state.perPage}`);
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        setState({ ...state, perPage: numberOfRows });
        if (state.search)
          navigate(
            `?settings=roles?page=${1}?perPage=${numberOfRows}?search=${
              state.search
            }`
          );
        else navigate(`?settings=roles?page=${1}?perPage=${numberOfRows}`);
        window.scrollTo(0, 0);
      },
    };
  }

  /**
   * Close delete modal
   */
  function onCloseDeleteModal() {
    setOpenDeleteModal(false);
    setDeletedIds([]);
  }

  /**
   * Delete roles
   */
  function deleteRoles() {
    const data = { data: { ids: deletedIds } };
    multipleDelete(urlEnum.roles, data)
      .then((response) => {
        onCloseDeleteModal();
        cacheContext.setCache({
          ...cacheContext.cache,
          roles: response.data.roles,
        });
        getRoles(state.page, state.perPage, state.search);
      })
      .catch((err) => {
        onCloseDeleteModal();
      });
  }
  return (
    <div>
      <div>
        <MUIDataTable
          title={""}
          data={state.roles}
          columns={rolesHeader}
          options={getTableOptions()}
        />
      </div>
      <Role
        open={props.openEditModal}
        onClose={(shouldRefetch) => {
          props.setOpenEditModal(false);
          setEditedRoles(null);
          if (shouldRefetch) {
            getRoles(state.page, state.perPage, state.search);
          }
        }}
        title={editedRoles ? Vocabulary.editRoles : Vocabulary.addNewRole}
        editedRoles={editedRoles}
        permissions={state1.permissions}
      />

      <GenericModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        title={Vocabulary.deleteRoles}
      >
        <p style={{ textAlign: "center", fontSize: 20 }}>
          {Vocabulary.deleteRolesMessage}
        </p>
        <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onCloseDeleteModal}
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={deleteRoles}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
    </div>
  );
}
