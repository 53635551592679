/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import GenericModal from "../../Modal/GenericModal";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditIcon from "@mui/icons-material/Edit";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import style from "../../Styles/mainHeader.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import { CacheContext } from "../../Context/cacheContext";
import { useContext, useEffect, useState } from "react";
import { CarOrderModel } from "./MainTableSalesOnDemand";
import { localUrlEnum, urlEnum } from "../../Utils/urlEnum";
import { postData } from "../../Services/postData";
import { updateData } from "../../Services/updateData";
import { CarOrderSource, RolesEnum } from "../../Utils/Constants";
import { ToastContainer, toast } from "react-toastify";
import { CarOrdersTab } from "../../Utils/autocompleteEnum";
import { VehicleModel } from "../../Models/VehicleModel";
import HistoryComponent from "../HistoryComponent";

const MIN_SEARCH_LEN = 3;

type CarOrderProps = {
  open: boolean;
  title: string;
  type: string;
  selectedCarOrder: CarOrderModel | null;
  onClose: (shouldRefetch?: boolean) => void;
};

const initialState: CarOrderModel = {
  nume_complet: "",
  localitate: "",
  email: "",
  telefon: "",
  brand_id: null,
  model_id: null,
  buget: "",
  detalii_extra: "",
  status_id: null,
  user_id: null,
  sursa_comanda: Object.values(CarOrderSource)[0],
  vehicle_name: "",
  vehicul_id: null,
  logs: [],
};
export default function CarOrder(props: CarOrderProps) {
  const { open, title, type, selectedCarOrder, onClose } = props;
  const roles = localStorage.getItem("roles")?.split(",");
  const notifyError = (message: string) => toast.error(message);
  const cacheContext = useContext(CacheContext);
  const [searchResult, setSearchResult]: any = useState(null);
  const [models, setModels] = useState<any[]>([]);
  const [data, setData] = useState<CarOrderModel>(initialState);
  const [openSearch, setOpenSearch] = useState(false);
  const [editVehicle, setEditVehicle] = useState(false);
  const [openSelectVehicle, setOpenSelectVehicle] = useState(false);
  const [vehicleName, setVehicleName] = useState<string>("");
  const [vehicleId, setVehicleId] = useState<number | null>(null);

  useEffect(() => {
    if (selectedCarOrder) {
      const brandModels = cacheContext.cache.models.filter(
        (model: any) =>
          selectedCarOrder &&
          selectedCarOrder.brand_id &&
          model.brand_id === selectedCarOrder.brand_id
      );
      setModels(brandModels);
      setData(selectedCarOrder);
    } else {
      setData(initialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCarOrder]);

  /**
   *
   * @param array
   * @param id
   * @returns
   */
  function getValueAutocomplete(array: Array<any>, id: number | null) {
    if (array.length !== 0) {
      const index = array.findIndex((item: any) => {
        if (id && +item.id === +id) return item;
        return null;
      });
      if (index < 0) return null;
      return {
        id: array[index].id,
        nume: array[index].nume,
      };
    }
    return null;
  }
  /**
   *
   * @param event
   */
  function handleChangeTextFields(event: any) {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  }

  /**
   *
   * @param event
   */
  function handleSearchVehicle(event: any) {
    const { value } = event.target;
    setVehicleName(value);
    if (value && value.length > MIN_SEARCH_LEN) {
      // fetch search data
      postData(`${urlEnum.searchVehicles}`, { search: value }).then(
        (response: any) => {
          setSearchResult(response.data);
        }
      );
    }
  }

  /**
   *
   * @param event
   * @param value
   */
  function handleChangeAutocomplete(event: any, value: any, name: string) {
    if (value === null) {
      if (name === "brand_id") {
        setModels([]);
        setData({ ...data, brand_id: null, model_id: null });
      } else setData({ ...data, [name]: null });
    } else {
      if (name === "brand_id") {
        const brandModels = cacheContext.cache.models.filter(
          (model: any) => model.brand_id === value.id
        );
        setModels(brandModels);
      }
      setData({ ...data, [name]: value.id });
    }
  }

  /**
   * Save car order
   */
  function saveCarOrder() {
    if (!data.brand_id) {
      notifyError(Vocabulary.selectBrand);
      return;
    }
    if (!data.model_id) {
      notifyError(Vocabulary.selectModel);
      return;
    }
    if (!data.status_id) {
      notifyError(Vocabulary.selectStatus);
      return;
    }
    if (data.id) {
      updateData(`${urlEnum.carOrders}/${data.id}`, data)
        .then(() => {
          onClose(true);
        })
        .catch((err) => {
          onClose(false);
        });
    } else {
      postData(urlEnum.carOrders, data)
        .then(() => {
          onClose(true);
        })
        .catch((err) => {
          onClose(false);
        });
    }
  }

  /**
   * Associated with vehicle
   */
  function associatedWithVehicle() {
    updateData(
      `${urlEnum.carOrders}/associatedWithVehicle/${data.id}/${vehicleId}`
    )
      .then((res) => {
        setData(res.data.carOrder);
        setOpenSelectVehicle(false);
      })
      .catch((err) => {
        setOpenSelectVehicle(false);
      });
  }

  return (
    <>
      <ToastContainer />
      {/** EDIT CAR ORDER */}
      <GenericModal
        open={open}
        onClose={() => {
          onClose(false);
          setEditVehicle(false);
        }}
        title={title}
        buttons={
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Button
                variant="contained"
                fullWidth
                color="secondary"
                onClick={() => onClose(false)}
              >
                {Vocabulary.cancel}
              </Button>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Button
                variant="contained"
                type="submit"
                form="carOrder-validateForm"
                fullWidth
              >
                {Vocabulary.save}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <ValidatorForm id="carOrder-validateForm" onSubmit={saveCarOrder}>
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={12} md={6} lg={6}>
              <TextValidator
                name="nume_complet"
                label={Vocabulary.name}
                fullWidth
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                value={data.nume_complet}
                onChange={handleChangeTextFields}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextValidator
                name="telefon"
                label={Vocabulary.phone}
                fullWidth
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                value={data.telefon}
                onChange={handleChangeTextFields}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextValidator
                name="email"
                label={Vocabulary.email}
                fullWidth
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                value={data.email}
                onChange={handleChangeTextFields}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextValidator
                name="localitate"
                label={Vocabulary.county}
                fullWidth
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                value={data.localitate}
                onChange={handleChangeTextFields}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextValidator
                name="buget"
                label={Vocabulary.budget}
                fullWidth
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                value={
                  isNaN(parseFloat(data.buget)) ? "" : parseFloat(data.buget)
                }
                onChange={handleChangeTextFields}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextValidator
                name="detalii_extra"
                label={Vocabulary.details}
                fullWidth
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                value={data.detalii_extra}
                onChange={handleChangeTextFields}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Autocomplete
                id="brands"
                freeSolo={false}
                disablePortal
                getOptionLabel={(option: any) => option.nume}
                size="medium"
                options={cacheContext.cache.brands}
                value={getValueAutocomplete(
                  cacheContext.cache.brands,
                  data.brand_id
                )}
                onChange={(event: any, newValue: any) =>
                  handleChangeAutocomplete(event, newValue, "brand_id")
                }
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="brand_id"
                    label={Vocabulary.brands}
                    variant="outlined"
                    size="medium"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Autocomplete
                id="models"
                freeSolo={false}
                disablePortal
                getOptionLabel={(option: any) => option.nume}
                size="medium"
                options={models}
                value={getValueAutocomplete(models, data.model_id)}
                onChange={(event: any, newValue: any) =>
                  handleChangeAutocomplete(event, newValue, "model_id")
                }
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={Vocabulary.models}
                    variant="outlined"
                    size="medium"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Autocomplete
                id="status"
                freeSolo={false}
                disablePortal
                getOptionLabel={(option: any) => option.nume}
                size="medium"
                options={cacheContext.cache.carOrdersStatus}
                value={getValueAutocomplete(
                  cacheContext.cache?.carOrdersStatus,
                  data.status_id
                )}
                onChange={(event: any, newValue: any) =>
                  handleChangeAutocomplete(event, newValue, "status_id")
                }
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={Vocabulary.status}
                    variant="outlined"
                    size="medium"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextValidator
                name="sursa_comanda"
                label={Vocabulary.commandSource}
                fullWidth
                disabled
                value={
                  CarOrderSource[
                    data?.sursa_comanda as keyof typeof CarOrderSource
                  ]
                    ? CarOrderSource[
                        data?.sursa_comanda as keyof typeof CarOrderSource
                      ]
                    : data?.sursa_comanda
                }
                onChange={handleChangeTextFields}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Autocomplete
                id="users"
                freeSolo={false}
                disablePortal={true}
                getOptionLabel={(option: any) => option.nume}
                size="medium"
                options={cacheContext.cache.users}
                value={getValueAutocomplete(
                  cacheContext.cache?.users,
                  data.user_id
                )}
                onChange={(event: any, newValue: any) =>
                  handleChangeAutocomplete(event, newValue, "user_id")
                }
                sx={{ width: "100%" }}
                ListboxProps={{ style: { maxHeight: 250 } }}
                disabled={
                  roles &&
                  !roles.includes(RolesEnum.admin) &&
                  !roles.includes(RolesEnum.saleDepartment) &&
                  !roles.includes(RolesEnum.financialDepartment) &&
                  !roles.includes(RolesEnum.logisticsDepartment) &&
                  !roles.includes(RolesEnum.acquisitionsDepartment) &&
                  type === CarOrdersTab.allocated
                    ? true
                    : false
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={Vocabulary.assignToAUser}
                    variant="outlined"
                    size="medium"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {data.vehicul_id && !editVehicle ? (
                <div style={{ display: "flex" }}>
                  <a
                    href={`${localUrlEnum.vehicles}/${localUrlEnum.vehicle}/${data.vehicul_id}`}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      style={{ color: "#1976d2", marginLeft: 4, fontSize: 17 }}
                    >
                      {Vocabulary.associatedVehicle}: {data.vehicle_name}
                    </Typography>
                  </a>
                  <EditIcon
                    style={{
                      marginLeft: 10,
                      cursor: "pointer",
                      color: "#1976d2",
                    }}
                    onClick={() => setEditVehicle(true)}
                  />
                </div>
              ) : selectedCarOrder ? (
                <div
                  onClick={() => setOpenSearch(true)}
                  style={{
                    marginLeft: 4,
                    color: "#1976d2",
                    fontSize: 17,
                    cursor: "pointer",
                  }}
                >
                  {Vocabulary.associateWithVehicle}
                </div>
              ) : null}
            </Grid>
          </Grid>
        </ValidatorForm>
        {data?.logs ? (
          Object.keys(data?.logs).length ? (
            <HistoryComponent
              logs={data?.logs}
              firstAllocMessage={Vocabulary.clientMessageWasAllocated}
              firstTimeStatusSetMessage={Vocabulary.carOrderSetStatus}
              statusChangedMessage={Vocabulary.carOrderStatusChanged}
              type={Vocabulary.carOrder}
              createdMessage={Vocabulary.createdCarOrder}
            />
          ) : null
        ) : null}
      </GenericModal>
      {/** SEARCH RESULTS */}
      <GenericModal
        open={openSearch}
        onClose={() => {
          setOpenSearch(false);
          setEditVehicle(false);
        }}
        title={""}
      >
        <div style={{ textAlign: "center" }}>
          <TextField
            autoFocus
            name="vehicleName"
            variant="standard"
            label={Vocabulary.associateWithVehicle}
            value={vehicleName ? vehicleName : ""}
            onChange={(event) => {
              handleSearchVehicle(event);
            }}
            style={{ margin: 10, width: "90%" }}
          />
        </div>
        <div className={style.searchWrapper}>
          <h3 style={{ fontWeight: 100 }}>{Vocabulary.searchResult}</h3>
        </div>
        <Divider />
        {searchResult && (
          <div
            className={style.searchWrapper}
            style={{ maxHeight: "700px", overflowY: "scroll" }}
          >
            <List sx={{ width: "90%", bgcolor: "background.paper" }}>
              {searchResult.map((vehicle: VehicleModel, index: number) => (
                <ListItem
                  alignItems="flex-start"
                  key={index}
                  className={style.searchListItem}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setVehicleId(vehicle.id);
                    setOpenSearch(false);
                    setEditVehicle(false);
                    setOpenSelectVehicle(true);
                  }}
                >
                  <ListItemAvatar
                    style={{ margin: "0px 20px 0px 0px", width: 76 }}
                  >
                    {vehicle.vehicule_files_r?.length ? (
                      <div
                        className={style.iconWrapper}
                        style={{
                          backgroundImage: `url("/api/${vehicle.vehicule_files_r[0].file}")`,
                        }}
                      ></div>
                    ) : (
                      <div className={style.iconWrapper}>
                        <DirectionsCarIcon />
                      </div>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${vehicle?.brand_r?.nume} ${vehicle?.model_r?.nume}`}
                    secondary={
                      <>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          VIN: {vehicle.vin.toUpperCase()}
                        </Typography>
                        <br />
                        {Vocabulary.status}: {vehicle?.status_vehicul_r?.nume}
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        )}
        {/** END SEARCH RESULTS */}
      </GenericModal>
      {/** SELECT VEHICLE */}
      <GenericModal
        open={openSelectVehicle}
        onClose={() => setOpenSelectVehicle(false)}
        title=""
      >
        <Grid container spacing={2}>
          <p
            style={{
              textAlign: "center",
              margin: "auto",
              marginTop: 10,
              marginBottom: 10,
              fontSize: 20,
            }}
          >
            {Vocabulary.associatedVehicleMessage}
          </p>
          <Grid item xs={12} md={6} lg={6}>
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              onClick={() => setOpenSelectVehicle(false)}
            >
              {Vocabulary.cancel}
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={associatedWithVehicle}
            >
              {Vocabulary.save}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
    </>
  );
}
