/* eslint-disable @typescript-eslint/no-explicit-any */
import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useEffect, useState } from "react";
import { Delete } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { getData } from "../../Services/getData";
import { localUrlEnum, urlEnum } from "../../Utils/urlEnum";
import Template from "./Template";
import {
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import GenericModal from "../../Modal/GenericModal";
import { isMobile } from "../../Utils/utils";
import { deleteData } from "../../Services/deleteData";
import FilePreview from "../../Utils/FilePreview";
import { StateProps, TemplatesProps } from "./GenericTemplates";
import { tableLayoutOnMobile } from "../../Utils/Constants";

export default function Templates(props: TemplatesProps) {
  const { openEditModal, setOpenEditModal } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState<number | null>(null);
  const [state, setState] = useState<StateProps>({
    templates: [],
    templatesNumber: 0,
    page: 0,
    perPage: 10,
  });

  /**
   * Get templates
   */
  useEffect(() => {
    const search = location.search.split("?");
    const newState = Object.assign({}, state) as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1] - 1;
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    getTemplates(newState.page, newState.perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   * @param page
   * @param perPage
   */
  function getTemplates(page: number, perPage: number) {
    const url = `${urlEnum.templates}/${page}/${perPage}`;
    getData(url).then((res) => {
      setState({
        ...state,
        templates: res.data.templates,
        templatesNumber: res.data.templatesNumber,
        page: page,
        perPage: perPage,
      });
    });
  }
  /**
   *
   */
  const templatesHeader = [
    {
      label: `${Vocabulary.name}`,
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.startNumberDocument}`,
      name: "startNumber",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.documente}`,
      name: "name",
      options: {
        sort: false,
        customBodyRender: (fileName: any) => {
          if (!fileName) return null;
          const path = `${urlEnum.templates}/getDoc/${fileName}`;
          return (
            <Button
              size="small"
              color="primary"
              variant="contained"
              data-file={path}
            >
              {Vocabulary.open}
            </Button>
          );
        },
      },
    },
    {
      label: "",
      name: "",
      options: {
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          align: "right",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (rowIndex: number) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10 }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.delete}>
                  <ToggleButton
                    onClick={() => {
                      setDeletedId(state.templates[rowIndex].id as number);
                      setOpenDeleteModal(true);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];
  /**
   *
   * @returns
   */
  function getTableOptions() {
    

    return {
      selectableRows: "none" as any,
      responsive: tableLayoutOnMobile,
      viewColumns: false,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      fixedHeader: true,
      count: state.templatesNumber,
      rowsPerPage: state.perPage,
      page: state.page,
      search: false,
      serverSide: true,
      sort: true,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: Vocabulary.pagination,
      },
      onChangePage: (page: number) => {
        navigate(
          `?templates=${localUrlEnum.templatesTab[1]}?page=${
            page + 1
          }?perPage=${state.perPage}`
        );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        navigate(
          `?templates=${
            localUrlEnum.templatesTab[1]
          }?page=${1}?perPage=${numberOfRows}`
        );
        window.scrollTo(0, 0);
      },
    };
  }

  /**
   * Close delete modal
   */
  function onCloseDeleteModal() {
    setOpenDeleteModal(false);
    setDeletedId(null);
  }

  /**
   * Delete templates
   */
  function deleteTemplates() {
    const url = `${urlEnum.templates}/${deletedId}`;
    deleteData(url)
      .then((res) => {
        onCloseDeleteModal();
        getTemplates(state.page, state.perPage);
      })
      .catch((err) => {
        onCloseDeleteModal();
      });
  }
  return (
    <div>
      <MUIDataTable
        title={""}
        data={state?.templates}
        columns={templatesHeader}
        options={getTableOptions()}
      />
      <Template
        open={openEditModal}
        onClose={(shouldRefetch: any) => {
          setOpenEditModal(false);
          if (shouldRefetch) {
            getTemplates(state.page, state.perPage);
          }
        }}
        title={Vocabulary.addTemplate}
      />
      <GenericModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        title={Vocabulary.deleteTemplate}
      >
        <p style={{ textAlign: "center", fontSize: 20 }}>
          {Vocabulary.deleteTemplateMessage}
        </p>
        <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onCloseDeleteModal}
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={deleteTemplates}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
      <FilePreview key={state?.templates.length} />
    </div>
  );
}
