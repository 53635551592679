/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Grid, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { CacheContext } from "../../Context/cacheContext";
import { Vocabulary } from "../../Utils/Vocabulary";
import { logsAction } from "../../Utils/autocompleteEnum";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { isMobile } from "../../Utils/utils";
import { europeanDate } from "../../Utils/formatDate";

type LogsFiltersProps = {
  filters: any;
  handleChangeAutocomplete: (event: any, newValue: any, name: string) => void;
  handleChangeStartDate: (event: any) => void;
  handleChangeEndDate: (event: any) => void;
  handleChangeTextFields: (event: any) => void;
  onBlurTextFields: () => void;
};
export default function LogsFilters(props: LogsFiltersProps) {
  const {
    filters,
    handleChangeAutocomplete,
    handleChangeStartDate,
    handleChangeEndDate,
    handleChangeTextFields,
    onBlurTextFields,
  } = props;
  const cacheContext = useContext(CacheContext) as any;
  const [users, setUsers] = useState<any[]>([]);
  const [localUser, setLocalUser] = useState<any>([]);
  const [localAction, setLocalAction] = useState<any>([]);

  const localUserConst = users?.find((user: any) => +filters.user === +user.id);
  const localActionConst: any = logsAction?.find(
    (action: any) => filters.action === action.id
  );

  useEffect(() => {
    if (cacheContext && cacheContext.cache && cacheContext.cache.length !== 0) {
      setUsers(cacheContext.cache.users);
    }
  }, [cacheContext]);
  useEffect(() => {
    setLocalUser(localUserConst);
    setLocalAction(localActionConst);
  }, [localUserConst, localActionConst]);

  return (
    <Grid container spacing={isMobile() ? 2 : 6} style={{ marginBottom: 10 }}>
      <Grid item xs={6} md={6} lg={3} style={{ maxWidth: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            format={europeanDate}
            label="Data de start"
            value={moment(filters.startDate)}
            maxDate={moment(filters.endDate)}
            onChange={handleChangeStartDate}
            slotProps={{ textField: { size: "small", fullWidth: true } }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            format={europeanDate}
            label="Data de stop"
            value={moment(filters.endDate)}
            minDate={moment(filters.startDate)}
            onChange={handleChangeEndDate}
            slotProps={{ textField: { size: "small", fullWidth: true } }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <Autocomplete
          options={cacheContext?.cache?.users}
          getOptionLabel={(option: any) => option.nume}
          value={localUser}
          freeSolo={false}
          selectOnFocus
          handleHomeEndKeys
          renderInput={(params) => (
            <TextField {...params} label={Vocabulary.usersName} size="small" />
          )}
          onChange={(event, newValue) =>
            handleChangeAutocomplete(event, newValue, "user")
          }
        />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <Autocomplete
          options={logsAction}
          getOptionLabel={(option: any) => option.nume}
          value={localAction}
          freeSolo={false}
          selectOnFocus
          handleHomeEndKeys
          renderInput={(params) => (
            <TextField {...params} label={Vocabulary.actions} size="small" />
          )}
          onChange={(event, newValue) =>
            handleChangeAutocomplete(event, newValue, "action")
          }
        />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <TextField
          name="model"
          size="small"
          label={Vocabulary.model}
          fullWidth
          value={filters.model}
          onChange={handleChangeTextFields}
          onBlur={onBlurTextFields}
        />
      </Grid>
    </Grid>
  );
}
