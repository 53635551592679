export interface tipIntrareIesireInterface {
    id: number;
    denumire: string;
  }
  
  export const tipIntrareIesire: tipIntrareIesireInterface[] = [
    {
      id: 1,
      denumire: "PTF (Traseu rutier incepe intr-un punct de frontiera)",
    },
    {
      id: 2,
      denumire: "ADR (Traseul rutier se finalizeaza pe teritoriul national)",
    },
  ];
  