export interface scopOperatiune {
  id: number;
  cod: string;
  denumire: string;
  idTipOperatiune: number;
}

export const scopOperatiuni: scopOperatiune[] = [
  {
    id: 100101,
    cod: "101",
    denumire: "Comercializare",
    idTipOperatiune: 10,
  },
  {
    id: 100201,
    cod: "201",
    denumire: "Producție",
    idTipOperatiune: 10,
  },
  {
    id: 100301,
    cod: "301",
    denumire: "Gratuități: mostre, produse promoționale, gratuități pt clienți",
    idTipOperatiune: 10,
  },
  {
    id: 100401,
    cod: "401",
    denumire: "Echipament comercial",
    idTipOperatiune: 10,
  },
  {
    id: 100501,
    cod: "501",
    denumire: "Mijloace fixe",
    idTipOperatiune: 10,
  },
  {
    id: 100601,
    cod: "601",
    denumire: "Consum propriu",
    idTipOperatiune: 10,
  },
  {
    id: 100703,
    cod: "703",
    denumire: "Operațiuni de livrare cu instalare",
    idTipOperatiune: 10,
  },
  {
    id: 100704,
    cod: "704",
    denumire: "Transfer între gestiuni",
    idTipOperatiune: 10,
  },
  {
    id: 100705,
    cod: "705",
    denumire: "Bunuri puse la dispoziția clientului",
    idTipOperatiune: 10,
  },
  {
    id: 100801,
    cod: "801",
    denumire: "Leasing financiar/operațional",
    idTipOperatiune: 10,
  },
  {
    id: 100802,
    cod: "802",
    denumire: "Bunuri în garanție",
    idTipOperatiune: 10,
  },
  {
    id: 109901,
    cod: "9901",
    denumire: "Altele",
    idTipOperatiune: 10,
  },
  {
    id: 100901,
    cod: "901",
    denumire: "Operațiuni scutite",
    idTipOperatiune: 10,
  },
  {
    id: 101001,
    cod: "101001",
    denumire: "Investiție in curs",
    idTipOperatiune: 10,
  },
  {
    id: 101101,
    cod: "1101",
    denumire: "Donații, ajutoare primite",
    idTipOperatiune: 10,
  },
];