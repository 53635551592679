import { Vocabulary } from "../Vocabulary";
//import StorefrontIcon from "@mui/icons-material/Storefront";
import DashboardIcon from "@mui/icons-material/Dashboard";
// import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
//import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import LoupeIcon from "@mui/icons-material/Loupe";

export const ROUTER_SALES_DEPARTMENT = {
  routes: [
    "/",
    "/sales",
    "/onDemand",
    "/sales",
    "/controlPanel",
    "/vehiclesDashboard/vehicleDashboard/:id?",
    "/tasksDashboard",
    "*",
  ],
};
// const subMenu = [
//   {
//     name: Vocabulary.inStock,
//     path: "/sales",
//     icon: <DomainVerificationIcon style={{ fontSize: 19 }} />,
//     orderId: 1,
//   },
//   {
//     name: Vocabulary.onDemand,
//     path: "/onDemand",
//     icon: <LoupeIcon style={{ fontSize: 19 }} />,
//     orderId: 2,
//   },
// ];

export const MAIN_SLIDER_SALES_DEPARTMENT = [
  {
    name: Vocabulary.carOnDemand,
    path: "/onDemand",
    icon: <LoupeIcon style={{ fontSize: 19 }} />,
    orderId: 12,
  },

  //DOAR DE SCOS COMENTARIILE SI APARARE PANOUL DE CONTROL
  // {
  //   name: Vocabulary.controlPanel,
  //   path: "/controlPanel",
  //   icon: <ViewCarouselIcon />,
  //   orderId: 0,
  // },
];

export const VEHICLE_OPTIONS_SALES_DEPARTMENT = [
  {
    name: Vocabulary.dashboard,
    path: `/vehiclesDashboard/vehicleDashboard/`,
    icon: <DashboardIcon />,
    id: "vehicleDashboard",
    orderNumber: 0,
  },
];

export const VEHICLES_SALES_DEPARTMENT = [3, 4];

export const DINAMIC_TABS_SALES_DEPARTMENT = null;

export const MAIN_TABLE_SALES_DEPARTMENT = { route: "vehicleDashboard" };

export const MAIN_HEADER_SALES = "vehicleDashboard";
