import { useEffect } from "react";
import { download } from "../../Utils/utils";
import { FileDataInfo } from "../../Utils/FilePreview";

type DownloadDocProps = {
  fileData: FileDataInfo | null;
};
export default function DownloadDoc(props: DownloadDocProps) {
  const { fileData } = props;

  useEffect(() => {
    downloadDoc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData?.path]);

  /**
   *
   */
  const downloadDoc = () => {
    const token = localStorage.getItem("access_token");
    if (fileData?.path)
      fetch(fileData?.path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.blob())
        .then((response) => {
          download(
            response,
            fileData?.name || "",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          );
        });
  };
  return <></>;
}
