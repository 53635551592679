/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  Fragment,
} from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Vocabulary } from "../../Utils/Vocabulary";

type SignatureProps = {
  width: number;
  height: number;
  doneCallback: (data: any) => void;
};

function Signature(props: SignatureProps) {
  const { width, height, doneCallback } = props;
  const canvasRef = useRef(null);
  const [isPainting, setIsPainting] = useState(false);
  const [mousePosition, setMousePosition] = useState(undefined);
  let p = false;
  let mp: any;

  /**
   *
   */
  const startPaint = useCallback((event: any) => {
    const coordinates: any = getCoordinates(event);
    if (coordinates) {
      setMousePosition(coordinates);
      setIsPainting(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      p = true;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      mp = coordinates;
    }
    /**
     *
     */
    const canvas: any = canvasRef.current;
    canvas.addEventListener("mousemove", paint);
    canvas.addEventListener("touchmove", paint);

    canvas.removeEventListener("mousedown", startPaint);
    canvas.removeEventListener("touchmove", startPaint);

    canvas.addEventListener("mouseup", exitPaint);
    canvas.addEventListener("mouseleave", exitPaint);

    canvas.addEventListener("touchend", exitPaint);
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: any = canvasRef.current;
    canvas.addEventListener("mousedown", startPaint);
    canvas.addEventListener("touchmove", startPaint);
    return () => {
      canvas.removeEventListener("mousedown", startPaint);
      canvas.removeEventListener("touchmove", startPaint);
    };
    // eslint-disable-next-line no-use-before-define
  }, [startPaint]);
  /**
   *
   */
  const paint = useCallback(
    (event: any) => {
      if (p) {
        const newMousePosition: any = getCoordinates(event);
        if (mp && newMousePosition) {
          drawLine(mp, newMousePosition);
          setMousePosition(newMousePosition);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          mp = newMousePosition;
        }
      }
      event.preventDefault();
    },
    [isPainting, mousePosition]
  );

  /**
   *
   */
  const exitPaint = useCallback(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    p = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    mp = undefined;
    const canvas: any = canvasRef.current;
    canvas.addEventListener("mousedown", startPaint);
    canvas.addEventListener("touchmove", startPaint);
    setIsPainting(false);
    setMousePosition(undefined);
  }, []);

  /**
   *
   * @param {Object} event
   */
  const getCoordinates = (event: any) => {
    if (!canvasRef.current) {
      return;
    }

    const canvas: any = canvasRef.current;

    if (event.touches) {
      const pos = {
        x: event.touches[0].pageX - canvas.offsetLeft,
        y: event.touches[0].pageY - canvas.offsetTop,
      };
      return pos;
    }

    return {
      x: event.pageX - canvas.offsetLeft,
      y: event.pageY - canvas.offsetTop,
    };
  };

  /**
   *
   */
  const clearSign = () => {
    const canvas: any = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  /**
   *
   */
  const saveSign = () => {
    const canvas: any = canvasRef.current;
    const signature = canvas.toDataURL();
    canvas.toBlob((blob: Blob) => {
      doneCallback({ dataImage: signature, blobSignature: blob });
    });
  };

  /**
   *
   * @param {*} originalMousePosition
   * @param {*} newMousePosition
   */
  const drawLine = (originalMousePosition: any, newMousePosition: any) => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: any = canvasRef.current;
    const context = canvas.getContext("2d");
    if (context) {
      context.strokeStyle = "black";
      context.lineJoin = "round";
      context.lineWidth = 2;
      context.beginPath();
      context.moveTo(originalMousePosition.x, originalMousePosition.y);
      context.lineTo(newMousePosition.x, newMousePosition.y);
      context.closePath();
      context.stroke();
    }
  };

  return (
    <Fragment>
      <canvas ref={canvasRef} height={height} width={width} />
      <Container
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid container direction="row" spacing={1} style={{ margin: 10 }}>
          <Grid item xs={12}>
            <Button
              fullWidth={true}
              onClick={saveSign}
              color="primary"
              variant="contained"
            >
              {Vocabulary.save}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth={true}
              onClick={clearSign}
              color="secondary"
              variant="contained"
              type="submit"
            >
              {Vocabulary.erase}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}
Signature.defaultProps = {
  width: 500,
  height: 300,
};
export default Signature;
