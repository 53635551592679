export const vehicleModel = {
  //First column vehicle
  vin: "",
  tip_vehicul_id: [],
  brand_id: [
    {
      id: "",
      nume: "",
    },
  ],
  model_id: [
    {
      id: "",
      nume: "",
    },
  ],
  tip_caroserie_id: [
    {
      id: "",
      nume: "",
    },
  ],
  an_fabricatie: "",
  data_inmatriculare: " ",
  kilometri: "",
  tara_origine_id: [
    {
      id: "",
      nume: "",
    },
  ],
  norma_poluare_id: [
    {
      id: "",
      nume: "",
    },
  ],

  //Second column vehicle
  culoare_id: [
    {
      id: "",
      nume: "",
    },
  ],
  optiuni_culoare_id: [
    {
      id: "",
      nume: "",
    },
  ],
  tractiune_id: [
    {
      id: "",
      nume: "",
    },
  ],
  combustibil_id: [
    {
      id: "",
      nume: "",
    },
  ],
  cutie_viteze_id: [
    {
      id: "",
      nume: "",
    },
  ],
  capacitate_cilindrica: " ",
  putere_cp: " ",
  nr_locuri: " ",
  numar_portiere: " ",

  //First column administrative
  vanzator_id: [
    {
      id: "",
      nume: "",
    },
  ],
  user_achizitie_id: [
    {
      id: "",
      nume: "",
    },
  ],
  user_logistica_id: [
    {
      id: "",
      nume: "",
    },
  ],
  tip_achizitie_id: [
    {
      id: "",
      nume: "",
    },
  ],
  firma_achizitie_id: [
    {
      id: "",
      nume: "",
    },
  ],
  furnizor_id: [
    {
      id: "",
      nume: "",
    },
  ],
  locatie_administrativa_id: [
    {
      id: "",
      nume: "",
    },
  ],
  tva_id: [
    {
      id: "",
      nume: "",
    },
  ],

  //Second column administrative
  curs_euro: "",
  pret_achizitie_euro: "",
  pret_site_vanzare: "",
  pret_minim_vanzare: " ",
  data_receptie: "",
  data_achizitie: "",
  vizibil_site:"0",
  data_vanzare: " ",
  carte_service:0,
};
