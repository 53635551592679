import { Checkbox, Grid, InputAdornment, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { europeanDate } from "../../Utils/formatDate";
import { Vocabulary } from "../../Utils/Vocabulary";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import styles from "../../Styles/progressAction.module.css";
import { useContext, useState } from "react";
import { VehicleContext } from "../../Context/vehicleContext";
import moment from "moment";

type ProgressInformation1Props = {
  disabled?: boolean;
  handleChange?: any;
};

export default function ProgressInformation1(props: ProgressInformation1Props) {
  const vehicleContext: any = useContext(VehicleContext);
  const [changePrice, setChangePrice] = useState(false);

  // const handleChangeInput =(e:any)=>{
  //     invoicesContext.handleChange(e)
  // }

  return (
    <div className={styles.informationContainer}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={props.disabled ? 4 : 12}
          xl={props.disabled ? 4 : 12}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <>
              <DesktopDatePicker
                format={europeanDate}
                label={Vocabulary.date}
                value={moment(vehicleContext?.state?.vehicle?.data_achizitie)}
                disabled={props.disabled}
                onChange={(e: any) =>
                  vehicleContext.onChangeVehicleAcquisitionDate(e)
                }
                slotProps={{ textField: { size: "small", fullWidth: true } }}
              />
              {props.disabled ? null : (
                <p
                  style={{
                    fontSize: 12,
                    marginTop: 5,
                    marginLeft: 10,
                    color: "#d32f2f",
                  }}
                >
                  {vehicleContext?.state?.vehicle?.data_achizitie
                    ? null
                    : Vocabulary.required}
                </p>
              )}
            </>
          </LocalizationProvider>
        </Grid>
        {props.disabled ? (
          <Grid
            item
            xs={12}
            md={props.disabled ? 4 : 12}
            xl={props.disabled ? 4 : 12}
          >
            <TextField
              name={"pret_achizitie_ron"}
              label={`${Vocabulary.sum} (${Vocabulary.lei})`}
              size="small"
              variant="outlined"
              type="number"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={vehicleContext?.state?.vehicle?.pret_achizitie_ron || null}
              disabled={props.disabled}
              error={
                props.disabled
                  ? false
                  : vehicleContext?.state?.vehicle?.pret_achizitie_ron
                      ?.length === 0
              }
              helperText={
                vehicleContext?.state?.vehicle?.pret_achizitie_ron?.length === 0
                  ? Vocabulary.compulsory
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {Vocabulary.lei}
                  </InputAdornment>
                ),
              }}
              onChange={(e: any) =>
                vehicleContext.onChangeVehiclePrices(e.target)
              }
            />
          </Grid>
        ) : null}

        <Grid
          item
          xs={12}
          md={props.disabled ? 4 : 12}
          xl={props.disabled ? 4 : 12}
        >
          <TextField
            name={changePrice ? "pret_achizitie_ron" : "pret_achizitie_euro"}
            label={
              changePrice
                ? `${Vocabulary.sum} (${Vocabulary.lei})`
                : `${Vocabulary.sum} (${Vocabulary.currency})`
            }
            size="small"
            variant="outlined"
            type="number"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={
              changePrice
                ? vehicleContext?.state?.vehicle?.pret_achizitie_ron
                : vehicleContext?.state?.vehicle?.pret_achizitie_euro
            }
            disabled={props.disabled}
            error={
              props.disabled
                ? false
                : changePrice
                ? !vehicleContext?.state?.vehicle?.pret_achizitie_ron
                : !vehicleContext?.state?.vehicle?.pret_achizitie_euro
            }
            helperText={
              changePrice
                ? !vehicleContext?.state?.vehicle?.pret_achizitie_ron
                : !vehicleContext?.state?.vehicle?.pret_achizitie_euro
                ? Vocabulary.compulsory
                : ""
            }
            InputProps={{
              endAdornment: !props.disabled ? (
                <InputAdornment position="end">
                  <Checkbox
                    onClick={() => setChangePrice(!changePrice)}
                    checked={changePrice}
                    disabled={props.disabled}
                  />

                  <span>
                    <div>{Vocabulary.leiPrice}</div>
                  </span>
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  {Vocabulary.currency}
                </InputAdornment>
              ),
            }}
            onChange={(e: any) => {
              vehicleContext.onChangeVehiclePrices(e.target);
              !changePrice
                ? vehicleContext.onChangeVehiclePrices({
                    name: "pret_achizitie_ron",
                    value: 0,
                  })
                : vehicleContext.onChangeVehiclePrices({
                    name: "pret_achizitie_euro",
                    value: 0,
                  });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
