import { Vocabulary } from "../Vocabulary";
import ArticleIcon from "@mui/icons-material/Article";

export const ROUTER_ACCES_TEMPLATES = {
  routes: ["/", "/templates", "*"],
};

export const MAIN_SLIDER_ACCES_TEMPLATES = [{
  name: Vocabulary.templatesName,
  path: "/templates",
  icon: <ArticleIcon />,
  orderId: 7,
}];

export const VEHICLE_OPTIONS_ACCES_TEMPLATES = [];

export const DINAMIC_TABS_ACCES_TEMPLATES = null;

export const MAIN_TABLE_ACCES_TEMPLATES = null;
