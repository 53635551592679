import GenericModal from "../../Modal/GenericModal";
import { Vocabulary } from "../../Utils/Vocabulary";
import GenericTemplate from "./GenericTemplate";
import { GenericTemplatesProps } from "./GenericTemplates";

type GenericTemplateModalProps = {
  open: boolean;
  template: GenericTemplatesProps | null;
  onClose: (shouldRefetch?: boolean) => void;
};
export default function GenericTemplateModal(props: GenericTemplateModalProps) {
  const { template, open, onClose } = props;

  /**
   *
   * @param shouldRefetch
   */

  return (
    <GenericModal
      open={open}
      onClose={() => onClose(false)}
      title={Vocabulary.addTemplate}
    >
      <GenericTemplate
        template={template}
        fromVehicle={false}
        onClose={onClose}
      />
    </GenericModal>
  );
}
