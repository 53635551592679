/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { localUrlEnum } from "../../Utils/urlEnum";
import style from "../../Styles/tableFilter.module.css";
import Templates from "./Templates";
import { TemplatesTab } from "../../Utils/autocompleteEnum";
import { getMuiTheme } from "../../Utils/globalStyleTables";
import { ThemeProvider } from "@emotion/react";
import DinamicTabs from "../DinamicTabs";
import { Vocabulary } from "../../Utils/Vocabulary";
import GenericTemplates from "./GenericTemplates";

export default function MainTemplates() {
  const [value, setValue]: any = useState(0);
  const navigate = useNavigate();
  const [
    openEditModalGenerateDocTemplates,
    setOpenEditModalGenerateDocTemplates,
  ] = useState(false);
  const [openEditModalGenericTemplates, setOpenEditModalGenericTemplates] =
    useState(false);
  const location = useLocation();

  useEffect(() => {
    const search = location.search.split("?");
    const newState = {
      templates: "",
    } as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1] - 1;
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    setValue(getPageOption(newState.templates));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   * @param event
   * @param newValue
   */
  const handleChange = (event: any, newValue: number) => {
    setOpenEditModalGenerateDocTemplates(false);
    setValue(newValue);
    navigate(`?templates=${localUrlEnum.templatesTab[newValue]}`);
  };

  /**
   *
   * @param item
   * @returns
   */
  const getTemplatesPage = (item: number) => {
    switch (item) {
      case 0:
        return (
          <GenericTemplates
            openEditModal={openEditModalGenericTemplates}
            setOpenEditModal={setOpenEditModalGenericTemplates}
          />
        );

      case 1:
        return (
          <Templates
            openEditModal={openEditModalGenerateDocTemplates}
            setOpenEditModal={setOpenEditModalGenerateDocTemplates}
          />
        );
      default:
        return (
          <GenericTemplates
            openEditModal={openEditModalGenericTemplates}
            setOpenEditModal={setOpenEditModalGenericTemplates}
          />
        );
    }
  };

  /**
   *
   * @param item
   * @returns
   */
  const getPageOption = (item: string) => {
    switch (item) {
      case TemplatesTab.templates:
        return 0;

      case TemplatesTab.generateDocuments:
        return 1;

      default:
        return 0;
    }
  };

  function openAddModal(item: number) {
    switch (item) {
      case 0:
        setOpenEditModalGenericTemplates(true);
        break;
      case 1:
        setOpenEditModalGenerateDocTemplates(true);
        break;
      default:
        break;
    }
  }

  return (
    <div className={style.containerTable}>
      <ThemeProvider theme={getMuiTheme()}>
        <DinamicTabs
          key={value + 2}
          hideAddNew={false}
          hideSave={false}
          value={value}
          handleChangeTab={handleChange}
          labels={[Vocabulary.generics, Vocabulary.generatedDocuments]}
          openModalWindow={() => openAddModal(value)}
          addNewLabel={Vocabulary.add}
        ></DinamicTabs>
        {getTemplatesPage(value)}
      </ThemeProvider>
    </div>
  );
}
