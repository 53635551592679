/* eslint-disable @typescript-eslint/no-explicit-any */
// import {  useState } from "react";

import { useContext, useEffect, useState } from "react";
import style from "../Styles/mainHeader.module.css";
import MainHeader from "./MainHeader";
import MainSlider from "./MainSlider";
import { useLocation } from "react-router-dom";
import { deleteData } from "../Services/deleteData";
import { urlEnum } from "../Utils/urlEnum";
import { VehicleContext } from "../Context/vehicleContext";
import GenericModal from "../Modal/GenericModal";
import { Button } from "@mui/material";
import { Vocabulary } from "../Utils/Vocabulary";
import { readCookie,isMobile} from "../Utils/utils";
import { componentNames } from "../Utils/Constants";

export default function HeaderAndSlider(props: any) {
  const [hideSlider, setHideSlider] = useState(isMobile() ? true : false);
  const vehicleContext: any = useContext(VehicleContext);
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);

  const pageUnload = async () => {
    await deleteCrtUserEditing();
  };

  const deleteCrtUserEditing = async () => {
    if (
      !location.pathname.includes("vehicleDetails") &&
      +vehicleContext?.state?.vehicle?.id !== 0
    ) {
      const userId = localStorage.getItem("useId");
      deleteData(
        `${urlEnum.vehicles}/vehiclesEditing/${vehicleContext?.state?.vehicle?.id}/${userId}`
      );
    } else if (
      location.pathname.includes("vehicleDetails") &&
      +vehicleContext?.state?.vehicle?.id === 0
    ) {
      const url = location.pathname.split("/");
      const id = url[url?.length - 1];
      const userId = localStorage.getItem("useId");
      deleteData(`${urlEnum.vehicles}/vehiclesEditing/${id}/${userId}`);
    } else {
      const id = (window as any).vehicleIdForDeletionFromVehiclesEditing;
      const userId = (window as any).authenticatedUserIdAutodel;
      deleteData(`${urlEnum.vehicles}/vehiclesEditing/${id}/${userId}`);
    }
  };

  /**
   *
   */
  useEffect(() => {
    window.removeEventListener("beforeunload", pageUnload);
    window.addEventListener("beforeunload", pageUnload);

    // notifications
    const ua = navigator.userAgent.toLowerCase();
    if (ua.includes("applewebkit") && ua.includes("mobile")) {
      //nu facem nimic
    } else {
      if (typeof Notification !== "undefined" && Notification?.permission !== "granted") {
        const notificationCookie = readCookie("accept_notification");
        if (!notificationCookie || notificationCookie === "false") {
          setOpenModal(true);
          document.cookie = "accept_notification =;";
        } else if (notificationCookie === "true") {
          Notification.requestPermission().then((permission) => {
            //nu facem nimic
          });
        }
      }
    }
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (vehicleContext?.state?.vehicle && vehicleContext?.state?.vehicle.id > 0)
      deleteCrtUserEditing();
  }, [location]);

  /**
   *
   */
  function setSlider() {
    setHideSlider(!hideSlider);
  }

  /**
   *
   */
  const handleToggleModal = () => {
    setOpenModal(!openModal);
  };

  /**
   *
   */
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const ua = navigator.userAgent.toLowerCase();
    if (ua.includes("applewebkit") && ua.includes("mobile")) {
      //nu facem nimic
    } else {
      document.cookie = "accept_notification = true;";
      Notification.requestPermission().then((permission) => {
        //nu facem nimic
      });
    }
    handleToggleModal();
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className={style.mainHeader} id="headerWrapper">
        <MainHeader
          key={hideSlider}
          handleFilter={setSlider}
          hideSlider={hideSlider}
          title={props.title}
          name={componentNames.MainHeader}
        ></MainHeader>
      </div>
      <div id="allContentWrapper">
        <div
          className={hideSlider ? style.mainSliderHide : style.mainSlider}
          id="sliderWrapper"
        >
          <MainSlider
            key={hideSlider}
            open={hideSlider}
            name={"MainSlider"}
            handleFilter={setSlider}
            info={[]}
          ></MainSlider>
        </div>

        <div id="contentWrapper">{props.component}</div>
      </div>
      {openModal && (
        <GenericModal
          maxWidth={400}
          open={openModal}
          onClose={handleToggleModal}
          title={Vocabulary.allowBrowserNotifications}
          children={
            <form onSubmit={handleSubmit}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  onClick={handleToggleModal}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  style={{ margin: 10 }}
                >
                  {Vocabulary.block}
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  style={{ color: "white", margin: 10 }}
                  type="submit"
                  color="primary"
                >
                  {Vocabulary.allow}
                </Button>
              </div>
            </form>
          }
        />
      )}
    </div>
  );
}
