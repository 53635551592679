export interface tipOperatiune {
  id: string;
  cod: string;
}

export const tipOperatiuni: tipOperatiune[] = [
  {
    id: "10",
    cod: "Achizitie intracomunitara",
  },
  {
    id: "20",
    cod: " Livrare intracomunitara",
  },
  {
    id: "30",
    cod: " Transport pe teritoriul national",
  },
  {
    id: "40",
    cod: "Import",
  },
  {
    id: "50",
    cod: "Export",
  },
  {
    id: "60",
    cod: "Tranzacţie intracomunitară - Intrare pentru depozitare/formare nou transport",
  },
  {
    id: "70",
    cod: "Tranzacţie intracomunitară - Ieşire după depozitare/formare nou transport",
  },
];
