import { Grid, Typography } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useContext } from "react";
import { VehicleContext } from "../../Context/vehicleContext";

export default function ProfitTab() {
  const vehicleContext: any = useContext(VehicleContext);
  const totalDuration =
    vehicleContext?.state?.vehicle?.durata_vanzare +
    vehicleContext?.state?.vehicle?.durata_receptie +
    vehicleContext?.state?.vehicle?.durata_pregatire_vanzare;
  return (
    <div style={{ marginBottom: 30 }}>
      <p
        style={{
          fontSize: "20px",
          paddingLeft: 10,
          marginBottom: 5,
          marginTop: 40,
        }}
      >
        {Vocabulary.salePeriodInformation}
      </p>
      <div style={{ padding: 10 }}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={3}>
            <Typography
              style={{
                color: "#4d5e80",
                verticalAlign: "middle",
              }}
            >
              {Vocabulary.receivedDuration}
              <span style={{ fontSize: 12, fontStyle: "italic" }}>
                (achizitie-receptie)
              </span>
              &nbsp;&nbsp;
              {vehicleContext?.state?.vehicle?.durata_receptie
                ? vehicleContext?.state?.vehicle?.durata_receptie
                : 0}{" "}
              Zile
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              style={{
                color: "#4d5e80",
                verticalAlign: "middle",
              }}
            >
              {Vocabulary.salePreparationDuration}
              <span style={{ fontSize: 12, fontStyle: "italic" }}>
                (receptie-pregatit vanzare)
              </span>
              &nbsp;&nbsp;
              {vehicleContext?.state?.vehicle?.durata_pregatire_vanzare
                ? vehicleContext?.state?.vehicle?.durata_pregatire_vanzare
                : 0}{" "}
              Zile
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              style={{
                color: "#4d5e80",
                verticalAlign: "middle",
              }}
            >
              {Vocabulary.saleDuration}
              <span style={{ fontSize: 12, fontStyle: "italic" }}>
                (pregatit vanzare-vandut)
              </span>
              &nbsp;&nbsp;
              {vehicleContext?.state?.vehicle?.durata_vanzare
                ? vehicleContext?.state?.vehicle?.durata_vanzare
                : 0}{" "}
              Zile
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography
              style={{
                fontWeight: 700,
                verticalAlign: "middle",
                color: "#4d5e80",
              }}
            >
              {Vocabulary.total}: &nbsp;
              {totalDuration} Zile
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
