import { useEffect, useState } from "react";
import GenericModal from "../../Modal/GenericModal";
import { urlEnum } from "../../Utils/urlEnum";
import { Button } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

type PreviewImageProps = {
  title: string | undefined;
  open: boolean;
  onClose: () => void;
  url: string | undefined;
  isVehicleIcon?: boolean;
  changeImage?: () => void;
};
export default function PreviewImage(props: PreviewImageProps) {
  const { title, open, onClose, url, isVehicleIcon, changeImage } = props;
  const [path, setPath] = useState<string | undefined | null>(null);

  useEffect(() => {
    const newPath = url?.replace(`${urlEnum.vehicles}`, "/api");
    setPath(newPath);
  }, [url]);

  return (
    <GenericModal
      open={open}
      onClose={onClose}
      title={title || ""}
      maxWidth={1000}
      fullScreenOnMobile={false}
      buttons={
        isVehicleIcon && (
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            startIcon={<AddPhotoAlternateIcon />}
            onClick={changeImage}
          >
            {Vocabulary?.changeVehicleIcon}
          </Button>
        )
      }
    >
      <div style={{ textAlign: "center" }}>
        <img style={{ width: "80%" }} src={`${path}`} alt="No file" />
      </div>
    </GenericModal>
  );
}
