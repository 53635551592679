import { Vocabulary } from "../Vocabulary";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

export const ROUTER_SALARY = {
  routes: ["/", "/salaries","/salaries/user/:id?/:month?/:year?", "*"],
};

export const MAIN_SLIDER_SALARY = [
  {
    name: Vocabulary.salaries,
    path: "/salaries",
    icon: <RequestQuoteIcon />,
    orderId: 19,
  },
];

export const VEHICLE_OPTIONS_SALARY = [];

export const DINAMIC_TABS_SALARY = {
  //  add: true
};

export const MAIN_TABLE_SALARY = null;
export const MAIN_SETTINGS_SALARY = [
  Vocabulary.salaries,
];

export const SALARY_COMPONENT={
  accesToBonuses:true,
}
