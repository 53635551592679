/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  IconButton,
  Paper,
} from "@mui/material";
import GenericModal from "../../Modal/GenericModal";
import { Vocabulary } from "../../Utils/Vocabulary";
import { CostData } from "./AdministrativeCostsDashboard";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { ToastContainer } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { postData } from "../../Services/postData";
import { urlEnum } from "../../Utils/urlEnum";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { DropzoneArea } from "material-ui-dropzone";
import style from "../../Styles/modalServices.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteData } from "../../Services/deleteData";
import { getData } from "../../Services/getData";
import ModeIcon from "@mui/icons-material/Mode";
import GenericAutocompleteModal from "../../Modal/GenericAutocompleteModal";
import { europeanDate } from "../../Utils/formatDate";
import { getIconByFileFileType,isMobile } from "../../Utils/utils";
import { AutocompleteSchema } from "../../Validators/vehicleValidators";
import { CacheContext } from "../../Context/cacheContext";
import {
  AutocompleteModel,
  AutocompleteModelForCosts,
  GenericAutocompleteModalModel,
} from "../../Models/Models";

type CostProps = {
  open: boolean;
  title: string;
  editedCost: any;
  onClose: (shouldRefetch?: boolean) => void;
  refetch: () => void;
  status?: any;
  tabStatus: any;
  isVisible: boolean;
};

const initialState: CostData = {
  id: "",
  descriere: "",
  data: moment().utcOffset("+05:30").format("YYYY-MM-DD hh:mm:ss a"),
  tip_cost_adm_id: null,
  valoare: "",
  file_id: "",
};

const initialGenericModalState = new GenericAutocompleteModalModel();

export default function Cost(props: CostProps) {
  const {
    open,
    title,
    editedCost,
    onClose,
    refetch,
    tabStatus,
    status,
    isVisible,
  } = props;
  const [dataCost, setData] = useState<CostData>(initialState);
  const cacheContext: any = useContext(CacheContext);
  const cacheData = cacheContext.cache;
  const [costFile, setCostFile] = useState([]);
  const [adminCostType, setAdminCostType] = useState<any>([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [costModel, setCostModel] = useState(new AutocompleteModelForCosts());
  const [genericAutocompleteModel, setGenericAutocompleteModel] = useState(
    initialGenericModalState
  );

  useEffect(() => {
    setCostModel({
      ...costModel,
      tip_cost_adm: cacheData?.costsType?.find((item: any) => {
        if (item.nume === "Administrative") return item;
        return null;
      }).id,
    });
    if (editedCost) {
      setData(editedCost);
    } else setData({ ...initialState, tip_cost_adm_id: status });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedCost, status]);

  useEffect(() => {
    getAdminTypeCost();
  }, []);

  /**
   * Get admin type cost
   */
  function getAdminTypeCost() {
    getData(urlEnum.costsAdminType).then((res: any) => {
      setAdminCostType(res.data.data);
    });
  }

  /**
   *
   * @param event
   */
  function handleChangeTextFields(event: any) {
    setData({ ...dataCost, [event.target.name]: event.target.value });
  }

  /**
   *
   * @param event
   */
  function handleChangeData(event: any, value: any) {
    setData({
      ...dataCost,
      data: moment(event._d).format("YYYY-MM-DD HH:mm:ss"),
    });
  }

  function handleChangeDropFile(files: any) {
    setCostFile(files);
  }

  /**
   * Save cost
   */
  function saveCost() {
    const formData: any = new FormData();
    formData.append("data", dataCost.data);
    formData.append("descriere", dataCost.descriere);
    formData.append(
      "tip_cost_adm_id",
      dataCost.tip_cost_adm_id ? dataCost.tip_cost_adm_id : status
    );
    formData.append("valoare", dataCost.valoare);
    if (dataCost.file) formData.append("file_id", dataCost.file_id);
    else if (costFile.length !== 0) {
      costFile.forEach((file: any) => {
        formData.append("file_id", file);
      });
    } else {
      formData.append("file_id", null);
    }
    let url = `${urlEnum.administrativeCosts}`;
    if (editedCost) {
      url = `${urlEnum.administrativeCosts}/${editedCost.id}`;
      formData.append("id", dataCost.id);
    }
    postData(url, formData)
      .then(() => {
        setData(initialState);
        onClose(true);
      })
      .catch(() => {
        setData(initialState);
        onClose(false);
      });
  }

  /**
   *
   * @param idFile
   */
  const submitDeletedCar = (idFile: any) => {
    deleteCosts(idFile);
  };

  /**
   *
   * @param data
   * @param title
   * @param id
   */
  const handleClickEditDrop = (data: any, title: any, id: any) => {
    setGenericAutocompleteModel({
      ...genericAutocompleteModel,
      open: true,
      title: title,
      data: data,
      id: id,
      columns: columns,
      validationSchema: AutocompleteSchema,
      hiddenFieldsArray: [
        "tip_cost_adm",
        "comision",
        "created_at",
        "id",
        "pret_recomandat",
        "tip_cost_adm",
        "updated_at",
      ],
      modelForFields: costModel,
    });
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "nume",
      label: "Nume",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  const handleEmptyDrop = (data: any, title: any, id: any) => {
    setGenericAutocompleteModel({
      ...genericAutocompleteModel,
      open: true,
      title: title,
      data: adminCostType,
      id: "tip_cost_adm_id",
      columns: columns,
      validationSchema: AutocompleteSchema,
      hiddenFieldsArray: [
        "tip_cost_adm",
        "comision",
        "created_at",
        "id",
        "pret_recomandat",
        "tip_cost_adm",
        "updated_at",
      ],
      modelForFields: costModel,
    });
  };

  /**
   *
   * @param param0
   * @returns
   */
  const DropDownButton = ({ children, ...other }: any) => (
    <Paper {...other}>
      <IconButton
        onMouseDown={(event) => {
          event.preventDefault();
          children[1] !== null || children[2] !== null
            ? children[1] === "null" || children[1] === null
              ? handleClickEditDrop(
                  children[2]?.props?.ownerState?.options,
                  children[2]?.props?.ownerState?.label,
                  children[2].props?.ownerState?.id
                )
              : handleClickEditDrop(
                  children[1]?.props?.ownerState?.options,
                  children[1]?.props?.ownerState?.label,
                  children[1].props?.ownerState?.id
                )
            : handleEmptyDrop("", "Tip Cost", 0);
        }}
        style={{
          position: "absolute",
          top: -40,
          right: -36,
          margin: 5,
          display: "flex",
          justifyContent: "end",
          backgroundColor: "#FF6633",
          color: "#fff",
          borderRadius: 7,
          zIndex: 100,
        }}
        size="small"
        aria-label="editDropdown"
      >
        <ModeIcon fontSize="inherit" />
      </IconButton>
      {children}
    </Paper>
  );

  /**
   *
   * @param idFile
   */
  async function deleteCosts(idFile: any) {
    const url = `${urlEnum.administrativeCosts}/docs/${dataCost.file.id}/${idFile}`;
    const res = (await deleteData(url)) as any;
    if (!res || res?.error) {
      return;
    }
    refetch();
    setData({ ...dataCost, file: null, file_id: null });
    setOpenModalDelete(false);
  }

  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeType(event: any, newValue: any) {
    const newData = Object.assign({}, dataCost);
    if (newValue) newData.tip_cost_adm_id = newValue.id;
    else newData.tip_cost_adm_id = null;
    setData(newData);
  }

  return (
    <>
      <ToastContainer />
      <GenericModal open={open} onClose={() => onClose(false)} title={title}>
        <ValidatorForm onSubmit={saveCost}>
          <Grid
            container
            spacing={isMobile() ? 1 : 2}
            style={{
              paddingBottom: 15,
              zIndex: 100,
              marginTop: 5,
              marginBottom: 10,
            }}
          >
            <Grid
              item
              md={+tabStatus === 2 ? (isMobile() ? 12 : 3) : 6}
              xs={+tabStatus === 2 ? (isMobile() ? 12 : 3) : 6}
            >
              <TextValidator
                fullWidth
                type="number"
                name="valoare"
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                label={Vocabulary.value}
                value={dataCost.valoare}
                disabled={isVisible}
                onChange={handleChangeTextFields}
              />
            </Grid>
            {+tabStatus === 2 ? (
              <Autocomplete
                id="tip_cost_adm_id"
                freeSolo={false}
                disablePortal
                disabled={isVisible}
                getOptionLabel={(option: any) => option.nume}
                value={
                  adminCostType.findIndex(
                    (admCost: any) =>
                      dataCost.tip_cost_adm_id &&
                      +dataCost.tip_cost_adm_id === +admCost.id
                  ) !== -1
                    ? {
                        id: adminCostType[
                          adminCostType.findIndex(
                            (admCost: any) =>
                              dataCost.tip_cost_adm_id &&
                              +dataCost.tip_cost_adm_id === +admCost.id
                          )
                        ].id,
                        nume: adminCostType[
                          adminCostType.findIndex(
                            (admCost: any) =>
                              dataCost.tip_cost_adm_id &&
                              +dataCost.tip_cost_adm_id === +admCost.id
                          )
                        ].nume,
                      }
                    : null
                }
                options={adminCostType}
                PaperComponent={DropDownButton}
                onChange={(event: any, newValue: any) =>
                  handleChangeType(event, newValue)
                }
                sx={{
                  width: isMobile() ? "100%" : "39%",
                  marginTop: isMobile() ? 1 : 2,
                  marginLeft: isMobile() ? 1 : 2,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled={isVisible}
                    label={Vocabulary.tip_cost_adm_id}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                )}
              />
            ) : null}

            <Grid
              item
              md={+tabStatus === 2 ? (isMobile() ? 12 : 4) : 6}
              xs={+tabStatus === 2 ? (isMobile() ? 12 : 4) : 6}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Data"
                  views={["month", "year"]}
                  minDate={moment().subtract(1, "months")}
                  maxDate={moment()}
                  slotProps={{ textField: { style: { width: "100%" } } }}
                  onChange={handleChangeData}
                  value={moment(dataCost.data)}
                  disabled={isVisible}
                  format={europeanDate}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextValidator
                fullWidth
                name="descriere"
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                label={Vocabulary.description}
                value={dataCost.descriere}
                onChange={handleChangeTextFields}
                disabled={isVisible}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              {dataCost?.file ? (
                <div style={{ display: "flex" }}>
                  {dataCost.file.mime === "application/pdf" ? (
                    <div style={{ marginTop: 10, marginLeft: 20 }}>
                      {getIconByFileFileType(
                        dataCost.file.file ? dataCost.file.file : "",
                        100
                      )}
                    </div>
                  ) : (
                    <div style={{ marginTop: 10, marginLeft: 20 }}>
                      {getIconByFileFileType(
                        dataCost.file.file ? dataCost.file.file : "",
                        100
                      )}
                    </div>
                  )}
                  {isVisible ? null : (
                    <DeleteIcon
                      onClick={() => setOpenModalDelete(true)}
                      style={{
                        marginTop: 20,
                        backgroundColor: "#ff6633",
                        color: "#fff",
                        padding: 5,
                        borderRadius: 7,
                        cursor: "pointer",
                      }}
                    ></DeleteIcon>
                  )}
                </div>
              ) : (
                <>
                  {isVisible ? null : (
                    <DropzoneArea
                      acceptedFiles={["image/*,application/*"]}
                      dropzoneClass={style.dropzoneArea}
                      maxFileSize={30000000} //30 MB
                      filesLimit={1}
                      showAlerts={false}
                      onChange={handleChangeDropFile}
                      onDelete={() => setCostFile([])}
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
          {isVisible ? null : (
            <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
              <Grid item md={6} xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onClose(false)}
                  fullWidth
                >
                  {Vocabulary.cancel}
                </Button>
              </Grid>
              <Grid item md={6} xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  style={{ color: "white" }}
                >
                  {Vocabulary.save}
                </Button>
              </Grid>
            </Grid>
          )}
        </ValidatorForm>
        <GenericModal
          open={openModalDelete}
          onClose={() => setOpenModalDelete(false)}
          title=""
        >
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: 20 }}>Sunteti sigur ca doriti sa stergeti?</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Grid
                container
                spacing={isMobile() ? 1 : 4}
                style={{ padding: 10 }}
              >
                <Grid item md={6} xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpenModalDelete(false)}
                    fullWidth
                  >
                    {Vocabulary.no}
                  </Button>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => submitDeletedCar(dataCost.id)}
                    style={{ color: "white" }}
                  >
                    {Vocabulary.yes}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </GenericModal>

        {genericAutocompleteModel.open && (
          <GenericAutocompleteModal
            open={genericAutocompleteModel.open}
            onClose={() =>
              setGenericAutocompleteModel({
                ...genericAutocompleteModel,
                open: false,
                modelForFields: new AutocompleteModel(),
              })
            }
            title={genericAutocompleteModel.title}
            data={genericAutocompleteModel.data}
            id={genericAutocompleteModel.id}
            columns={genericAutocompleteModel.columns}
            validationSchema={genericAutocompleteModel.validationSchema}
            hiddenFieldsArray={genericAutocompleteModel.hiddenFieldsArray}
            modelForFields={genericAutocompleteModel.modelForFields}
            onEdit={(value, tableMeta: any) => {
              setGenericAutocompleteModel({
                ...genericAutocompleteModel,
                modelForFields: tableMeta.tableData[tableMeta.rowIndex],
              });
            }}
            onSucces={getAdminTypeCost}
          >
            <></>
          </GenericAutocompleteModal>
        )}
      </GenericModal>
    </>
  );
}
