import { InputAdornment, TextField, Typography } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
//import { RolesEnum } from "../../Utils/Constants";
import style from "../../Styles/vehicleInfo.module.css";
import { NumericFormatCustom } from "../../Utils/NumericFormatComponent";

export type UserBonusesProps = {
  userData: any;
  setUserData: (userData: any) => void;
  saveUserBonuses: () => void;
  permission: any;
};
export default function UserBonuses(props: UserBonusesProps) {
  const { userData, setUserData, saveUserBonuses, permission } = props;

  return (
    <fieldset
      style={{
        borderRadius: 7,
        padding: "20px",
      }}
    >
      <legend>
        <Typography style={{ padding: 3 }}>{Vocabulary.bonuses}</Typography>
      </legend>
      <TextField
        type="number"
        name="bonusLeasing"
        className={style.textField}
        onBlur={saveUserBonuses}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">Lei</InputAdornment>,
        }}
        label={Vocabulary.bonusLeasing}
        fullWidth
        disabled={!permission}
        value={userData.leasingBonus ?? ""}
        onChange={(event) =>
          setUserData({
            ...userData,
            leasingBonus: event.target.value,
          })
        }
      />
      <TextField
        //type="number"
        name="otherBonuses"
        className={style.textField}
        onBlur={saveUserBonuses}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputComponent: NumericFormatCustom,
          endAdornment: <InputAdornment position="end">Lei</InputAdornment>,
        }}
        label={Vocabulary.otherBonuses}
        value={userData.otherBonuses ?? ""}
        disabled={!permission}
        fullWidth
        onChange={(event) =>
          setUserData({
            ...userData,
            otherBonuses: event.target.value,
          })
        }
      />
      <TextField
        type="number"
        name="mealTickets"
        onBlur={saveUserBonuses}
        className={style.textField}
        disabled={!permission}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">Lei</InputAdornment>,
        }}
        label={Vocabulary.mealTickets}
        fullWidth
        value={userData.mealTickets ?? ""}
        onChange={(event) =>
          setUserData({
            ...userData,
            mealTickets: event.target.value,
          })
        }
      />
    </fieldset>
  );
}
