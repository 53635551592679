/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "../../Styles/mainDashboard.module.css";
import { isMobile } from "../../Utils/utils";
//import { ClientMessagesTab } from "../../Utils/autocompleteEnum";

import ClientMessages from "./ClientMessages";
import { localUrlEnum } from "../../Utils/urlEnum";
import { RolesEnum, clientMessagesStatus } from "../../Utils/Constants";
import { CacheContext } from "../../Context/cacheContext";
import { Vocabulary } from "../../Utils/Vocabulary";
import withRole from "../../Utils/withRole";

export type StateProps = {
  vehicles: Array<any>;
  vehiclesNumber: number;
};

 function DashboardClientMessages(props: any) {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [status, setStatus] = useState("");

  const [unassignedMessagesCount, setUnassignedMessagesCount] = useState(0);
  const roles: any = window.localStorage.getItem("roles");
  const isAdmin = roles.includes(RolesEnum.admin);

  const cacheContext = useContext(CacheContext);
  const cacheValue = cacheContext.cache.settings.find(
    (user: any) => user.slug == "automatedClientMessages"
  )?.value;
  const isAutomatedMessages = cacheValue ? parseInt(cacheValue) : null;
  const tabsLabelsArray: any[] = [];
  console.log(props);

  
  tabsLabelsArray.push(
    {
      label: Vocabulary.allocated,
      badge: 0, //CA SA NU AFISEZE DELOC BADGE
      code: clientMessagesStatus.Alocate,
    },
    {
      label: Vocabulary.canceled,
      badge: 0, //CA SA NU AFISEZE DELOC BADGE
      code: clientMessagesStatus.Anulate,
    }
  );
  //select what tabs to include
  if (isAdmin||props.info)
    //show all messages only for admin
    tabsLabelsArray.push({
      label: Vocabulary.all,
      badge: 0, //CA SA NU AFISEZE DELOC BADGE
      code: clientMessagesStatus.Toate,
    });

  if (!isAutomatedMessages)
    //show unassigned messages only if automated allocation is off
    tabsLabelsArray.push({
      label: !isAutomatedMessages ? Vocabulary.unallocated : null,
      badge: unassignedMessagesCount,
      code: clientMessagesStatus.Nealocate,
    });

  /**
   *
   */
  useEffect(() => {
    getUrlVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getUrlVehicles = () => {
    const search = location.search.split("?");
    const newFilters = Object.assign({}) as any;
    search.forEach((value) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "search")
          newFilters[values[0]] = decodeURIComponent(values[1]);
        if (values[0] === "visible") newFilters[values[0]] = values[1];
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        else
          newFilters[values[0]] = !isNaN(+values[1]) ? +values[1] : values[1];
        if (values[1] === "null") newFilters[values[0]] = null;
      }
    });
    if (newFilters.status) setStatus(newFilters.status);
    else setStatus(tabsLabelsArray[0].code);
    setValue(newFilters.index ? newFilters.index : 0);
    
  };

  /**
   *
   * @param event
   * @param index
   */
  const handleChange = (event: any, index: number) => {
    const statusCode = tabsLabelsArray[index].code;
    setStatus(statusCode);
    setValue(index);
    navigate(
      `${localUrlEnum.clientMessages}?status=${statusCode}?index=${index}`
    );
  };

  return (
    <div
      style={{ display: "flex", flexDirection: isMobile() ? "column" : "row" }}
    >
      <div className={style.mainTable}>
        {
          <ClientMessages
            value={value}
            accesForAllMessages={props.info}
            getDataRefresh={getUrlVehicles}
            handleChange={handleChange}
            status={status}
            tabsLabelsArray={tabsLabelsArray}
            setUnassignedMessagesCount={setUnassignedMessagesCount}
            cacheContext={cacheContext}
            isAdmin={isAdmin}
          ></ClientMessages>
        }
      </div>
    </div>
  );
}

export default withRole(DashboardClientMessages);

