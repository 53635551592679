import { Grid, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import { urlEnum } from "../../Utils/urlEnum";
import { getData } from "../../Services/getData";
import { useContext, useEffect, useState } from "react";
import { europeanDate, formatNumber } from "../../Utils/formatDate";
import moment from "moment";
import { VehicleContext } from "../../Context/vehicleContext";
import { Location, useNavigate } from "react-router-dom";

export type VehiclesForMarketingTable = {
  historyData: any;
  numberFormat: Intl.NumberFormat;
  setTotalMarketingCommission(marketingCommission: any): void;
  location: Location;
};

export default function VehiclesForMarketingTable(
  props: VehiclesForMarketingTable
) {
  const { historyData, numberFormat, setTotalMarketingCommission, location } =
    props;
  const [state, setState] = useState({
    vehicles: [],
    vehiclesNumber: 0,
    page: 0,
    perPage: 10,
  });
  const vehicleContext: any = useContext(VehicleContext);
  const navigate = useNavigate();

  /**
   *
   */
  useEffect(() => {
    getVehiclesForMarketing();
  }, [location]);

  /**
   *
   */
  const getVehiclesForMarketing = async (
    page = state.page,
    perPage = state.perPage
  ) => {
    const url = `${urlEnum.vehicles}/marketing/${historyData.month}/${historyData.year}/${page}/${perPage}`;
    const res = await getData(url);
    if (res.data) {
      setState({
        ...state,
        vehicles: res.data?.vehicles,
        vehiclesNumber: res.data?.vehiclesNumber,
        page: page,
        perPage: perPage,
      });
      setTotalMarketingCommission(res.data?.totalCommission);
    }
  };

  /**
   *
   */
  const columns = [
    {
      name: "nume_complet",
      label: Vocabulary.name,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pret_vanzare_ron",
      label: Vocabulary.salePrice,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} </Typography>
        ),
      },
    },
    {
      name: "data_vanzare",
      label: Vocabulary.sellDate,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (date: string | null) => {
          const d = moment.utc(date);
          return <Typography>{d.local().format(europeanDate)}</Typography>;
        },
      },
    },
    {
      name: "profit_brut",
      label: Vocabulary.grossProfit,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} </Typography>
        ),
      },
    },
    {
      name: "tip_achizitie_r",
      label: Vocabulary.purchaseType,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{object?.nume} </Typography>
        ),
      },
    },

    {
      name: "commission",
      label: Vocabulary.comision,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{numberFormat.format(object)} </Typography>
        ),
      },
    },
  ];

  /**
   *
   */
  const options = {
    filter: false,
    download: false,
    viewColumns: false,
    responsive: "standard" as any,
    rowsPerPageOptions: [
      10, 50, 100,
      //state?.vehiclesNumber > 100 ? state?.vehiclesNumber : 1000,
    ],
    sort: false,
    textLabels: {
      viewColumns: {
        title: Vocabulary.viewColumns,
      },
      toolbar: {
        downloadCsv: Vocabulary.downloadExcel,
        viewColumns: Vocabulary.selectColumns,
      },
      pagination: Vocabulary.pagination,
    },
    fixedHeader: false,
    fixedSelectColumn: false,
    selectableRows: "none" as any,
    print: false,
    page: state.page,
    serverSide: true,
    rowsPerPage: state.perPage,
    search: false,
    count: state?.vehiclesNumber,
    rowHover: true,
    onChangePage: (page: number) => {
      getVehiclesForMarketing(page, state.perPage);
      window.scrollTo(0, 0);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      getVehiclesForMarketing(state.page, numberOfRows);
      window.scrollTo(0, 0);
    },
    onRowClick: (rowData: any, rowState: any) => {
      const vehicleId = Object.assign(
        state.vehicles[rowState.rowIndex]
      )?.vehicul_id;
      vehicleContext.updateVehicle({});
      navigate(`/vehiclesDashboard/vehicleDashboard/${vehicleId}`);
    },
    setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
      return {
        style: { cursor: "pointer" },
      };
    },
  };

  return (
    <>
      <Grid item xs={12} sm={12}>
        <MUIDataTable
          title={Vocabulary.selledCars}
          data={state.vehicles}
          columns={columns}
          options={options}
        />
      </Grid>
    </>
  );
}
