/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */

import { InputBase } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { getMuiTheme } from "../Utils/globalStyleTables";
import withRole from "../Utils/withRole";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import style from "../Styles/tableSearch.module.css";

function TableSearch(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchField, setSearchField]: any = useState("");

  useEffect(() => {
    const search = location.search.split("?");
    let localSearch = location.search;
    search.forEach((value: any) => {
      const values = value.split("=");
      if (values[0] === "search")
        {
        const formatedSearchField = values[1].replace(/%20/g, " ");
        localSearch = localSearch.substring(0, setSearchField(formatedSearchField));
        }
      if (value === "") {
        setSearchField("");
      }
    });
  }, [location.search]);

  function onChageSearch() {
    const search = location.search.split("?");

    let localSearch = location.search;

    search.forEach((value: any) => {
      const values = value.split("=");
      if (values[0] === "search")
        localSearch = localSearch.substring(
          0,
          localSearch.length - (values[1].length + 8)
        );
      if (values[0] === "page" && parseInt(values[1]) !== 1) {
        localSearch = localSearch.replace(`page=${values[1]}`, "page=1");
      }

      navigate(`${localSearch}?search=${searchField}`);

      if (searchField.length === 0) {
        navigate(`${localSearch}?search=${searchField}`);
      }
    });
  }

  return (
    <div style={{ display: "flex" }}>
      <ThemeProvider theme={getMuiTheme()}>
        <InputBase
          placeholder="Cautare"
          value={searchField}
          onBlur={(e: any) => onChageSearch()}
          onKeyUp={(e: any) => {
            if (e.key === "Enter") {
              onChageSearch();
            }
          }}
          inputProps={{ "aria-label": "Cauta" }}
          onChange={(event) => setSearchField(event.target.value)}
          className={style.containerSearch}
        />
      </ThemeProvider>
    </div>
  );
}
export default withRole(TableSearch);
