import { ClickAwayListener, Paper, Typography } from "@mui/material";
import styles from "../../Styles/progressAction.module.css";
import CircleIcon from "@mui/icons-material/Circle";
import { Vocabulary } from "../../Utils/Vocabulary";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { isBoolean } from "../../Utils/utils";

// import { Vocabulary } from "../../Utils/Vocabulary";

type ProgressActionWrapperProps = {
  title: string;
  children?: JSX.Element | JSX.Element[];
  info?: { invoices: object };
  disabled?: boolean;
  status?: boolean;
  modifyBy?: any;
};

export default function ProgressActionWrapper({
  title,
  children,
  info,
  disabled,
  status,
  modifyBy,
}: ProgressActionWrapperProps) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "none",
    },
  });

  return (
    <Paper className={styles.mainContainer}>
      <div
        className={
          status
            ? styles.tabHeaderCompleted
            : disabled
            ? styles.tabHeaderDisabled
            : styles.tabHeader
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p className={styles.title}>{title}</p>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div style={{ display: "flex" }}>
              {modifyBy?.length > 0 && (
                <NoMaxWidthTooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  arrow
                  onClose={handleTooltipClose}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  open={open}
                  title={
                    modifyBy?.length > 1 &&
                    modifyBy.map((item: any) => {
                      return (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Typography>{item?.userId}</Typography>
                          <Typography>{" - "} </Typography>
                          <Typography>{item?.date}</Typography>
                        </div>
                      );
                    })
                  }
                >
                  <div
                    onClick={handleTooltipOpen}
                    className={styles.modifyByContainer}
                  >
                    <Typography
                      color="#fff"
                      className={styles.modifyBy}
                      variant="body2"
                    >
                      {Vocabulary.modifyBy +
                        " " +
                        modifyBy[modifyBy.length - 1]?.userId +
                        " -" +
                        " "}
                    </Typography>
                    <Typography
                      color="#fff"
                      className={styles.modifyBy}
                      variant="body2"
                    >
                      {modifyBy[modifyBy.length - 1]?.date}
                    </Typography>
                  </div>
                </NoMaxWidthTooltip>
              )}
              {isBoolean(status) && (
                <div
                  style={{
                    fontSize: "13px",
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    display: "flex",
                    margin: "7px 20px 20px 10px",
                    cursor: "pointer",
                    verticalAlign: "middle",
                  }}
                  // onClick={() =>
                  //   invoicesContext.invoices.clientInvoice.length !== 0
                  //     ? null
                  //     : setOpen(true)
                  // }
                >
                  <CircleIcon
                    style={{
                      margin: 2,
                      color: status ? "green" : "red",
                      verticalAlign: "center",
                      marginRight: 5,
                      marginTop: 3,
                    }}
                  ></CircleIcon>

                  <p
                    style={{ marginBottom: -5, paddingTop: 7, marginRight: 7 }}
                  >
                    {status ? Vocabulary.finished : Vocabulary.incomplete}
                  </p>
                </div>
              )}
            </div>
          </ClickAwayListener>
        </div>
      </div>
      {children}
    </Paper>
  );
}
