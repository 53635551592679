import moment from "moment";

export const europeanDate = "DD/MM/YYYY";
export const anafFormatDate = "YYYY-MM-DD";
export const europeanDateWithTime = "DD/MM/YYYY hh:mm:ss";
export const shortEuropeanDate = "MM YYYY";

export const usDateFormat = "YYYY/MM/DD";
export const usDateFormatWithTime = "YYYY/MM/DD hh:mm:ss";

export default function formatDate(data: any, format?: string) {
  if (format) return moment(data).format(format);
  else return moment(data, europeanDate, true).format();
}

export function formatNumber(number: any) {
  return number?.toLocaleString("ro-Ro");
}

/**
 * Format a number from a string.
 * @param {string} number - The string to format
 * @returns {string} The formatted number as a string formatted with decimal separator
 */
export function formatNumberFromString(number: string) {
  if (number) return formatNumber(parseFloat(number.replace(/,/g, "")));
}
