import { Vocabulary } from "../Vocabulary";
// import InventoryIcon from "@mui/icons-material/Inventory";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NoCrashIcon from "@mui/icons-material/NoCrash";
// import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';

export const ROUTER_ACQUISITIONS_DEPARTMENT = {
  routes: [
    "/",
    "/acquisitions",
    "/acquisitions/:id?",
    "/controlPanel",
    "/vehiclesDashboard/vehicleDashboard/:id?",
    "/vehiclesDashboard/vehicleDetails/:id?",
    "*",
  ],
};

export const MAIN_SLIDER_ACQUISITIONS_DEPARTMENT = [
  //   {
  //   name: Vocabulary.acquisitionsName,
  //   path: "/acquisitions",
  //   icon: <InventoryIcon />,
  //   orderId: 14,
  // },
  //DOAR DE SCOS COMENTARIILE SI APARARE PANOUL DE CONTROL
  // {
  //   name: Vocabulary.controlPanel,
  //   path: "/controlPanel",
  //   icon: <ViewCarouselIcon />,
  //   orderId: 0,
  // }
];

export const VEHICLE_ACQUISITIONS_DEPARTMENT = [
  {
    name: Vocabulary.dashboard,
    path: `/vehiclesDashboard/vehicleDashboard/`,
    icon: <DashboardIcon />,
    id: "vehicleDashboard",
    orderNumber: 0,
  },
  {
    name: Vocabulary.detaliiVehicul,
    path: "/vehiclesDashboard/vehicleDetails/",
    icon: <NoCrashIcon />,
    id: "vehicleDetails",
    orderNumber: 1,
  },
];

export const DINAMIC_TABS_ACQUISITIONS_DEPARTMENT = {
  addVehicles: true,
};

export const VEHICLES_ACQUISITIONS_DEPARTMENT = [1, 2, 0];

export const LOGIN_PAGE_ACQUISITIONS_DEPARTMENT = "/acquisitions";

export const MAIN_HEADER_ACQUISITIONS_DEPARTMENT = "vehicleDashboard";
