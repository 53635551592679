/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Divider, Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { CacheContext } from "../../Context/cacheContext";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { localUrlEnum } from "../../Utils/urlEnum";
import {
  CarOrderSource,
  RolesEnum,
  clientMessagesStatus,
} from "../../Utils/Constants";
import { CarOrdersTab } from "../../Utils/autocompleteEnum";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { europeanDate } from "../../Utils/formatDate";
import moment from "moment";
import { StatusModel } from "../../Models/Models";
import LegendComponent from "../LegendComponent";

type OnDemandFiltersProps = {
  filters: any;
  setFilters: any;
  status: string | null;
  value: number | null;
};
export default function OnDemandFilters(props: OnDemandFiltersProps) {
  const { filters } = props;
  const navigate = useNavigate();
  const cacheContext = useContext(CacheContext);
  const [models, setModels] = useState<any[]>([]);
  const carOrderStatuses = cacheContext.cache.carOrdersStatus;

  useEffect(() => {
    if (filters.brand) {
      const brandModels = cacheContext.cache.models.filter(
        (model: any) =>
          filters && filters.brand && model.brand_id === filters.brand
      );
      setModels(brandModels);
    } else {
      setModels([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  /**
   *
   * @returns values for autocomplete from url
   */
  const getValuesForMultipleAutocomplete = () => {
    const array: StatusModel[] = [];
    if (filters.status)
      if (JSON.parse(filters.status)?.length) {
        const ids = JSON.parse(filters.status);
        if (ids?.length) {
          ids.forEach((id: number) => {
            const status: StatusModel = carOrderStatuses.find(
              (status: StatusModel) => {
                return status.id == id;
              }
            );
            if (status) array.push(status);
          });
        }
      }
    return array;
  };

  /**
   *
   * @param array
   * @param id
   * @returns
   */
  function getValueAutocomplete(array: Array<any>, id: number | null) {
    if (array.length !== 0) {
      const index = array.findIndex((item: any) => {
        if (id && +item.id === +id) return item;
        return null;
      });
      if (index < 0) return null;
      return {
        id: array[index].id,
        nume: array[index].nume,
      };
    }
    return null;
  }

  /**
   *
   * @param event
   * @param value
   * @param name
   */
  function handleChangeAutocomplete(event: any, value: any, name: string) {
    switch (name) {
      case "brand":
        navigate(
          `${localUrlEnum.onDemand}?type=${filters.type}?index=${
            props.value
          }?page=${1}?perPage=${filters.perPage}?brand=${
            value ? value.id : null
          }?model=${null}?status=${filters.status}?user=${
            filters.user
          }?startDate=${filters.startDate}?endDate=${filters.endDate}?search=${
            filters.search
          }`
        );
        break;
      case "model":
        navigate(
          `${localUrlEnum.onDemand}?type=${filters.type}?index=${
            props.value
          }?page=${1}?perPage=${filters.perPage}?brand=${filters.brand}?model=${
            value ? value.id : null
          }?status=${filters.status}?user=${filters.user}?startDate=${
            filters.startDate
          }?endDate=${filters.endDate}?search=${filters.search}`
        );
        break;
      case "status":
        navigate(
          `${localUrlEnum.onDemand}?type=${filters.type}?index=${
            props.value
          }?page=${1}?perPage=${filters.perPage}?brand=${filters.brand}?model=${
            filters.model
          }?status=${value ? JSON.stringify(value) : null}?user=${
            filters.user
          }?startDate=${filters.startDate}?endDate=${filters.endDate}?search=${
            filters.search
          }`
        );
        break;
      case "user":
        navigate(
          `${localUrlEnum.onDemand}?type=${filters.type}?index=${
            props.value
          }?page=${1}?perPage=${filters.perPage}?brand=${filters.brand}?model=${
            filters.model
          }?status=${filters.status}?user=${
            value ? value.id : null
          }?startDate=${filters.startDate}?endDate=${filters.endDate}?search=${
            filters.search
          }`
        );
        break;
      case "startDate":
        navigate(
          `${localUrlEnum.onDemand}?type=${filters.type}?index=${
            props.value
          }?page=${1}?perPage=${filters.perPage}?brand=${filters.brand}?model=${
            filters.model
          }?status=${filters.status}?user=${filters.user}?startDate=${moment(
            value?._d
          ).format("YYYY-MM-DD")}?endDate=${filters.endDate}?search=${
            filters.search
          }`
        );
        break;
      case "endDate":
        navigate(
          `${localUrlEnum.onDemand}?type=${filters.type}?index=${
            props.value
          }?page=${1}?perPage=${filters.perPage}?brand=${filters.brand}?model=${
            filters.model
          }?status=${filters.status}?user=${filters.user}?startDate=${
            filters.startDate
          }?endDate=${moment(value?._d).format("YYYY-MM-DD")}?search=${
            filters.search
          }`
        );
        break;
      default:
        break;
    }
  }

  /**
   * filters the carr orders based on sursa_comanda
   * @param value
   */
  function handleChangeCarOrderSource(value: string | null) {
    navigate(
      `${localUrlEnum.onDemand}?type=${filters.type}?index=${
        props.value
      }?page=${1}?perPage=${filters.perPage}?brand=${
        filters.brand ? filters.brand : null
      }?model=${null}?status=${filters.status}?user=${filters.user}?startDate=${
        filters.startDate
      }?endDate=${filters.endDate}?search=${filters.search}?source=${value}`
    );
  }

  /**
   *
   * @returns
   */
  function showUsers() {
    const roles = localStorage.getItem("roles")?.split(",");
    if (
      roles &&
      (roles.includes(RolesEnum.admin) ||
        roles.includes(RolesEnum.saleDepartment) ||
        roles.includes(RolesEnum.financialDepartment) ||
        roles.includes(RolesEnum.logisticsDepartment) ||
        roles.includes(RolesEnum.acquisitionsDepartment)) &&
      filters.type !== CarOrdersTab.unallocated &&
      filters.type !== CarOrdersTab.allocated
    )
      return (
        <Grid item xs={12} md={12} lg={12}>
          <Autocomplete
            id="users"
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) => option.nume}
            size="small"
            options={cacheContext.cache.users}
            value={getValueAutocomplete(
              cacheContext.cache?.users,
              filters.user
            )}
            onChange={(event: any, newValue: any) =>
              handleChangeAutocomplete(event, newValue, "user")
            }
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.user}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
      );
    return null;
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ padding: 10, marginTop: 5, maxWidth: 400 }}
      >
        <Grid item xs={12} md={12} lg={12}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label={Vocabulary.startDate}
              value={filters?.startDate && moment(filters.startDate)}
              maxDate={moment(filters?.endDate && moment(filters.endDate))}
              // onChange={handleChangeStartDate}
              onChange={(event: any, newValue: any) =>
                handleChangeAutocomplete(event, event, "startDate")
              }
              format={europeanDate}
              slotProps={{ textField: { size: "small", fullWidth: true } }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label={Vocabulary.endDate}
              value={filters?.endDate && moment(filters.endDate)}
              minDate={moment(filters?.startDate && moment(filters.startDate))}
              // onChange={handleChangeEndDate}
              onChange={(event: any, newValue: any) =>
                handleChangeAutocomplete(event, event, "endDate")
              }
              format={europeanDate}
              slotProps={{ textField: { size: "small", fullWidth: true } }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Autocomplete
            id="brands"
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) => option.nume}
            size="small"
            options={cacheContext.cache.brands}
            value={getValueAutocomplete(
              cacheContext.cache.brands,
              filters.brand
            )}
            onChange={(event: any, newValue: any) =>
              handleChangeAutocomplete(event, newValue, "brand")
            }
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.brands}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Autocomplete
            id="models"
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) => option.nume}
            size="small"
            options={models}
            value={getValueAutocomplete(models, filters.model)}
            onChange={(event: any, newValue: any) =>
              handleChangeAutocomplete(event, newValue, "model")
            }
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.models}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        {(props.status == clientMessagesStatus.Toate ||
          props.status == clientMessagesStatus.Alocate) && (
          <Grid item xs={12} md={12} lg={12}>
            <Autocomplete
              id="status"
              freeSolo={false}
              multiple
              disablePortal
              getOptionLabel={(option: any) => option.nume}
              size="small"
              options={carOrderStatuses}
              value={getValuesForMultipleAutocomplete()}
              onChange={(event: any, newValue: any) => {
                const statusIds: any[] = [];
                if (newValue) {
                  newValue?.forEach((status: StatusModel) => {
                    statusIds.push(status?.id);
                  });
                }
                handleChangeAutocomplete(event, statusIds, "status");
              }}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Vocabulary.status}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {showUsers()}
        <div
          style={{
            border: "1px solid #0000001f",
            borderRadius: 10,
            marginTop: 20,
            marginLeft: "16px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
              backgroundColor:
                filters.source == Object.keys(CarOrderSource)[0]
                  ? "#dbdbdb4d"
                  : "",
              width: "100%",
            }}
            onClick={() => {
              const source =
                filters.source != Object.keys(CarOrderSource)[0]
                  ? Object.keys(CarOrderSource)[0]
                  : null;
              handleChangeCarOrderSource(source);
            }}
          >
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  height: 30,
                  width: 30,
                  backgroundColor: "#7a7a7a63",
                  margin: 10,
                  borderRadius: 10,
                }}
              ></div>
              <p
                style={{
                  margin: 13,
                  verticalAlign: "center",
                  color: "#4d5e80",
                }}
              >
                {Object.values(CarOrderSource)[0]}
              </p>
            </div>
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
              backgroundColor:
                filters.source == Object.keys(CarOrderSource)[1]
                  ? "#dbdbdb4d"
                  : "",
            }}
            onClick={() => {
              const source =
                filters.source != Object.keys(CarOrderSource)[1]
                  ? Object.keys(CarOrderSource)[1]
                  : null;
              handleChangeCarOrderSource(source);
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  height: 30,
                  width: 30,
                  backgroundColor: "#49ab518c",
                  margin: 10,
                  borderRadius: 10,
                }}
              ></div>
              <p
                style={{
                  margin: 13,
                  verticalAlign: "center",
                  color: "#4d5e80",
                }}
              >
                {Object.values(CarOrderSource)[1]}
              </p>
            </div>
          </div>
        </div>
      </Grid>
      <LegendComponent title={Vocabulary.status} data={carOrderStatuses} style={{margin:'0px 10px'}}/>
    </>
  );
}
