import { createContext, useState } from "react";

/**
 * context for file preview in vehicle dashboard steps
 */
// since it loads each steps, in the first load, the previous steps files dont have click event attacheds
export const FilePreviewContext = createContext<any>({
  reoadFilePreview: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setReloadFilePreview: (value: any) => {},
});
export const FilePreviewContextProvider = ({ children }: any) => {
  const [reloadFilePreview, setReloadFilePreview] = useState(false);

  return (
    <FilePreviewContext.Provider
      value={{ reloadFilePreview, setReloadFilePreview }}
    >
      {children}
    </FilePreviewContext.Provider>
  );
};
