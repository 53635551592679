import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";

import { Vocabulary } from "../Vocabulary";

export const ROUTER_INTERVENTII_VOPSITORIE = {
  routes: [
    "/",
    "/vehiclesDashboard/vehiclePainting/:id?",
    "/vehiclesDashboard",
    "*",
  ],
};

export const MAIN_SLIDER_INTERVENTII_VOPSITORIE = [{
  name: Vocabulary.vehicles,
  path: "/vehiclesDashboard",
  icon: <DirectionsCarIcon />,
}];

export const VEHICLE_OPTIONS_INTERVENTII_VOPSITORIE = [
  {
    name: Vocabulary.vopsitorie,
    path: "/vehiclesDashboard/vehiclePainting/",
    icon: <FormatPaintIcon />,
    id: "vehiclePainting",
    slug: "interventii_vopsitorie",
    orderNumber:5
   
  },
];

export const DINAMIC_TABS_INTERVENTII_VOPSITORIE = {
  addVehicles: true,
};

