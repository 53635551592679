/* eslint-disable @typescript-eslint/no-explicit-any */
import VehicleDashboardTable from "./VehicleDashboardTable";
import { useContext, useState } from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { VehicleDashboardTableType } from "../Utils/autocompleteEnum";
import { Vocabulary } from "../Utils/Vocabulary";
import { urlEnum } from "../Utils/urlEnum";
import { VehicleContext } from "../Context/vehicleContext";
import moment from "moment";
import { europeanDate, usDateFormatWithTime } from "../Utils/formatDate";

export default function VehicleDetailing() {
  const vehicleContext: any = useContext(VehicleContext);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selected, setSelected]: any = useState(null);

  const emptyFields = () => {
    setSelected(null);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "data_intrare",
      label: "Data intrare detailing",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (date: any) => (
           <>
            {date
              ? moment(date, usDateFormatWithTime).format(europeanDate)
              : ""}
          </>
        ),
      },
    },
    {
      name: "data_iesire",
      label: "Data iesire detailing",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (date: any) => (
           <>
            {date
              ? moment(date, usDateFormatWithTime).format(europeanDate)
              : ""}
          </>
        ),
      },
    },
    {
      name: "mentiuni",
      label: "Mentiuni",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "cost",
      label: "Cost",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (cost: string) => <>{cost ? parseFloat(cost).toFixed(2) : 0} Lei</>,
      },
    },
    {
      name: "fileName",
      label: " ",
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (fileName: any) => {
          if (fileName === null) return null;
          const path = `${urlEnum.vehicles}/${fileName}`;
          return (
            <Button
              style={{ float: "right" }}
              size="small"
              color="primary"
              variant="contained"
              data-file={path}
            >
              {Vocabulary.open}
            </Button>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex: number) => (
          <div style={{ float: "right" }}>
            <EditIcon
              style={{
                marginRight: 15,
                height: 30,
                cursor: "pointer",
                color: "#4449",
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                setSelected(
                  vehicleContext?.state?.vehicle?.interventii_detailing[
                    rowIndex
                  ]
                );
                setOpenEditModal(true);
              }}
            ></EditIcon>
            <DeleteIcon
              style={{ height: 30, cursor: "pointer", color: "#4449" }}
              onClick={(event: any) => {
                event.stopPropagation();
                setSelected(
                  vehicleContext?.state?.vehicle?.interventii_detailing[
                    rowIndex
                  ]
                );
                setOpenModal(true);
              }}
            ></DeleteIcon>
          </div>
        ),
      },
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "100%",
            margin: 10,
            overflowY: "auto",
          }}
        >
          <VehicleDashboardTable
            title={Vocabulary.detailing}
            data={vehicleContext?.state?.vehicle?.interventii_detailing}
            columns={columns}
            type={VehicleDashboardTableType.detailing}
            editData={selected}
            openEditModal={openEditModal}
            closeModal={() => {
              setOpenEditModal(false);
              setSelected(null);
            }}
            emptyFields={emptyFields}
            openModalDelete={openModal}
            closeModalDelete={() => {
              setSelected(null);
              setOpenModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}
