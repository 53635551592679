/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Delete, Edit } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import GenericModal from "../../Modal/GenericModal";
import { isMobile } from "../../Utils/utils";
import { getData } from "../../Services/getData";
import { localUrlEnum, urlEnum } from "../../Utils/urlEnum";
import { deleteData } from "../../Services/deleteData";
import { EditorState } from "draft-js";
import GenericTemplateModal from "./GenericTemplateModal";
import { tableLayoutOnMobile } from "../../Utils/Constants";

export type GenericTemplatesProps = {
  id?: number;
  templateName: string;
  html: EditorState | undefined;
  subject: string;
  cc: string;
  type: string;
  status_id: number | null;
};

export type GenerateDocumentsTemplatesProps = {
  id?: number;
  name: string;
  filePath: string;
  type: string;
  startNumber: number;
  currentNumber: number;
};

export type StateProps = {
  templates: GenericTemplatesProps | GenerateDocumentsTemplatesProps | any;
  templatesNumber: number;
  page: number;
  perPage: number;
};

export type TemplatesProps = {
  openEditModal: boolean;
  setOpenEditModal: (openEditModal: boolean) => void;
};

export default function GenericTemplates(props: TemplatesProps) {
  const { openEditModal, setOpenEditModal } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [editedModel, setEditedModel] = useState<GenericTemplatesProps | null>(
    null
  );
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState<number | null>(null);

  const [state, setState] = useState<StateProps>({
    templates: [],
    templatesNumber: 0,
    page: 0,
    perPage: 10,
  });

  /**
   *
   */
  useEffect(() => {
    const search = location.search.split("?");
    const newState = Object.assign({}, state) as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1] - 1;
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    getGenericTemplates(newState.page, newState.perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  /**
   *
   * @param page
   * @param perPage
   */
  function getGenericTemplates(page: number, perPage: number) {
    const url = `${urlEnum.genericTemplates}/${page}/${perPage}`;
    getData(url).then((res) => {
      setState({
        ...state,
        templates: res.data.templates,
        templatesNumber: res.data.templatesNumber,
        page: page,
        perPage: perPage,
      });
    });
  }
  /**
   *
   */
  const templatesHeader = [
    {
      label: Vocabulary.templateName,
      name: "templateName",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.options,
      name: Vocabulary.options,
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        print: false,
        download: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div
              style={{ marginTop: -10, marginBottom: -10, textAlign: "center" }}
            >
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    onClick={() => {
                      setEditedModel(state.templates[rowIndex]);
                      setOpenEditModal(true);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setDeletedId(state.templates[rowIndex].id as number);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    
    return {
      selectableRows: "none" as any,
      viewColumns: false as any,
      responsive: tableLayoutOnMobile,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
      rowsPerPage: state.perPage,
      page: state.page,
      count: state.templatesNumber,
      search: true,
      serverSide: true,
      sort: true,
      onChangePage: (page: any) => {
        navigate(
          `?templates=${localUrlEnum.templatesTab[0]}?page=${
            page + 1
          }?perPage=${state.perPage}`
        );

        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        navigate(
          `?templates=${
            localUrlEnum.templatesTab[0]
          }?page=${1}?perPage=${numberOfRows}`
        );
        window.scrollTo(0, 0);
      },
    };
  }

  /**
   * Close delete modal
   */
  function onCloseDeleteModal() {
    setOpenDeleteModal(false);
    setDeletedId(null);
  }

  /**
   *
   */
  function deleteTemplate() {
    deleteData(`${urlEnum.genericTemplates}/${deletedId}`).then((res) => {
      onCloseDeleteModal();
      getGenericTemplates(state.page, state.perPage);
    });
  }

  return (
    <div>
      <MUIDataTable
        title={""}
        data={state?.templates}
        columns={templatesHeader}
        options={getTableOptions()}
      />
      <GenericTemplateModal
        open={openEditModal}
        template={editedModel}
        onClose={(shouldRefetch) => {
          setOpenEditModal(false);
          setEditedModel(null);
          if (shouldRefetch) {
            getGenericTemplates(state.page, state.perPage);
          }
        }}
      />
      <GenericModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        title={Vocabulary.deleteTemplate}
      >
        <p style={{ textAlign: "center", fontSize: 20 }}>
          {Vocabulary.deleteTemplateMessage}
        </p>
        <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onCloseDeleteModal}
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={deleteTemplate}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
    </div>
  );
}
