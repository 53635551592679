/* eslint-disable @typescript-eslint/no-explicit-any */
import style from "../Styles/mainSlider.module.css";
import { Link, useLocation } from "react-router-dom";
import withRole from "../Utils/withRole";
import { ClickAwayListener, IconButton, Typography } from "@mui/material";
import { isMobile } from "../Utils/utils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";

function MainSlider(
  props: {
    handleFilter?: any;
    info?: any;
    open?: boolean;
  },
  location: any
) {
  location = useLocation();
  const [openMenu, setOpenMenu] = useState(true);
  const shortLocation = location.pathname.split("/");
  const finalLocation = `/${shortLocation[1]}`;
  return (
    <div>
      {!props.open ? (
        <ClickAwayListener
          onClickAway={isMobile() ? props.handleFilter : () => null}
        >
          <div className={style.container}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: 190,
              }}
            >
              {props?.info
                ?.sort((a: any, b: any) => (a?.orderId > b?.orderId ? 1 : -1))
                .map((element: any, key: any) => {
                  return (
                    <div key={key}>
                      <Link
                        onClick={
                          isMobile() && !element?.subMenu
                            ? props.handleFilter
                            : () => null
                        }
                        key={`${element.orderId}-${element.name}`}
                        style={{ display: "flex", textDecoration: "none" }}
                        to={element?.subMenu ? "" : element.path}
                      >
                        <IconButton
                          id="1"
                          className={
                            location.pathname === element.path ||
                            finalLocation === element.path
                              ? style.selected
                              : style.nonSelected
                          }
                          onClick={
                            element?.subMenu
                              ? (e: any) => {
                                  e.preventDefault();
                                  setOpenMenu(!openMenu);
                                }
                              : () => {
                                  ("");
                                }
                          }
                        >
                          {element.icon}
                        </IconButton>
                        <Typography
                          className={
                            location.pathname === element.path ||
                            finalLocation === element.path
                              ? style.selectedText
                              : style.nonSelectedText
                          }
                          onClick={
                            element?.subMenu
                              ? (e: any) => {
                                  e.preventDefault();
                                  setOpenMenu(!openMenu);
                                }
                              : () => {
                                  ("");
                                }
                          }
                        >
                          {element.name}
                        </Typography>
                        {element?.subMenu ? (
                          <div
                            onClick={
                              element?.subMenu
                                ? (e: any) => {
                                    e.preventDefault();
                                    setOpenMenu(!openMenu);
                                  }
                                : () => {
                                    ("");
                                  }
                            }
                            style={{ paddingTop: 10, marginLeft: 20 }}
                          >
                            {openMenu ? (
                              <IconButton size="small">
                                <KeyboardArrowDownIcon
                                  style={{ height: 20, width: 20 }}
                                ></KeyboardArrowDownIcon>
                              </IconButton>
                            ) : (
                              <IconButton size="small">
                                <KeyboardArrowUpIcon
                                  style={{ height: 20, width: 20 }}
                                ></KeyboardArrowUpIcon>
                              </IconButton>
                            )}
                          </div>
                        ) : null}
                      </Link>
                      {element?.subMenu ? (
                        <div
                          className={
                            openMenu
                              ? style.selectedTextSubMenu
                              : style.nonSelectedTextSubMenu
                          }
                        >
                          {element?.subMenu?.map((el: any, key: any) => {
                            return (
                              <Link
                                onClick={
                                  isMobile() ? props.handleFilter : () => null
                                }
                                key={`${el.orderId}-${el.name}`}
                                style={{
                                  display: "flex",
                                  textDecoration: "none",
                                  minWidth: 200,
                                }}
                                to={el.path}
                              >
                                <IconButton
                                  id="1"
                                  className={
                                    location.pathname === el.path ||
                                    finalLocation === el.path
                                      ? style.selected
                                      : style.nonSelected
                                  }
                                  size="small"
                                >
                                  {el.icon}
                                </IconButton>
                                <Typography
                                  className={
                                    location.pathname === el.path ||
                                    finalLocation === el.path
                                      ? style.selectedTextMenu
                                      : style.nonSelectedTextMenu
                                  }
                                >
                                  {el.name}
                                </Typography>
                              </Link>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
            </div>
          </div>
        </ClickAwayListener>
      ) : null}
    </div>
  );
}

export default withRole(MainSlider);
