import { createTheme } from "@mui/material/styles";
import { isMobile } from "../Utils/utils";
import { portraitLayout } from "./Constants";

export const getMuiTheme = (openSearch?: any) =>
  createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            "& .Mui-selected": {
              overflow: "visible !important",
              backgroundColor: "#FF6633 !important",
              color: "#fff !important",
              fontWeight: 800,
              borderRadius: "10px 10px 0px 0px !important",
            },
            overflow: "visible !important",
            "& .MuiTabs-scroller": {
              overflow: "visible !important",
            },
          },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          root: {
            "& .Mui-selected": {
              backgroundColor: "#FF6633 !important",
              color: "#fff !important",
              fontWeight: 800,
              borderRadius: "10px 10px 0px 0px !important",
            },
          },
          paper: {
            borderRadius: "0px 0px 15px 15px !important",
            overflow: "hidden !important",
            boxShadow:
              "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 2px 0px rgba(0,0,0,0.14), 0px 0px 2px 0px rgba(0,0,0,0.12) !important",
            zIndex: 10,
            marginBottom: isMobile() ? 30 : 0,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: 800,
            backgroundColor: "#f7f8fa",
          },
          root: isMobile()
            ? {
                padding: 10,
                width: "100%",
                borderBottom: portraitLayout.matches ? "none" : "auto",
              }
            : {
                padding: 14,
              },
        },
      },

      MUIDataTablePagination: {
        styleOverrides: {
          tableCellContainer: isMobile()
            ? {
                backgroundColor: "#ededed !important",
              }
            : {
                backgroundColor: "#fff !important",
              },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          simpleCell: {
            display: "inline-block",
            width: isMobile() ? "47vw" : "100%",
            boxSizing: "border-box",
          },
        },
      },

      MuiTypography: {
        styleOverrides: {
          root: isMobile()
            ? {
                fontSize: 13,
                width: "40vw",
              }
            : {
                fontSize: 12,
              },
        },
      },

      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            backgroundColor: "#f7f8fa !important",
            "&.Mui-selected": {
              backgroundColor: "#FF6633 !important",
              color: "#fff",
            },
            maxWidth: "100% !important",
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            // backgroundColor: "#1976d2 !important",
            padding: "8px !important",
            // borderRadius:"10px !important",
            // color: "#fff",
          },
          containedSecondary: {
            backgroundColor: "#fff !important",
            padding: "8px !important",
            // borderRadius:"10px !important",
            border: " solid 1px #444 !important",
            color: "#444 !important",
          },
          // outlinedSecondary: {
          //   padding: "8px !important",
          //   // borderRadius:"10px !important",
          //   color: "#fff",
          // },
        },
      },
      MuiFormGroup: {
        styleOverrides: {
          root: {
            paddingLeft: 15,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          ".MuiBox-root": {
            padding: 50,
          },
        },
      },

      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: "#f7f8fa",
          },
        },
      },
      MUIDataTableToolbar: {
        // styleOverrides: {
        //   actions: {
        //     display: "flex",
        //     flexDirection: "column",
        //     position:"absolute",
        //     bottom:-35,
        //   },
        // },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            overflowY: "initial",
          },
        },
      },

      MuiTableRow: {
        styleOverrides: {
          hover: {
            backgroundColor: "blue !important",
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            "& .MuiToolbar-root": {
              backgroundColor: "#fff",
              marginTop: "2px !important",
              marginBottom: "1px !important",
              position: "inherit !important",
            },
          },
        },
      },
    },
  });
