/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import { localUrlEnum, urlEnum } from "../../Utils/urlEnum";
import { useLocation, useNavigate } from "react-router-dom";
import { Vocabulary } from "../../Utils/Vocabulary";
import AdministrativeCostsTable from "./AdministrativeCostsTable";
import { ThemeProvider } from "@emotion/react";
import { getMuiTheme } from "../../Utils/globalStyleTables";
import { CacheContext } from "../../Context/cacheContext";
import style from "../../Styles/tableFilter.module.css";
import DinamicTabs from "../DinamicTabs";
import { componentNames } from "../../Utils/Constants";

export type State = {
  costs: Array<CostData>;
  costsNumber: number;
  status: number;
  page: number;
  perPage: number;
  search: string | null;
  order: string;
  filter: string | null;
};

export type CostData = {
  id?: any;
  created_at?: string;
  data: any;
  descriere: string;
  tip_cost_adm_id: number | null;
  updated_at?: string;
  valoare?: any;
  file_id?: any;
  file?: any;
};

export default function AdministrativeCostsDashboard() {
  const cacheContext: any = useContext(CacheContext);
  const cacheData: any = Object.assign({}, cacheContext.cache);
  const [openEditModal, setOpenEditModal]: any = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [costAdminType, setCostAdminType] = useState<any[]>([]);
  const [state, setState] = useState<State>({
    costs: new Array<CostData>(),
    costsNumber: 0,
    status: 0,
    page: 0,
    perPage: 10,
    search: null,
    order: "asc",
    filter: null,
  });

  const tableLabels = [
    Vocabulary.marketing,
    Vocabulary.logisticsName,
    Vocabulary.administrative,
  ];
  useEffect(() => {
    if (cacheContext && cacheContext.cache && cacheContext.cache.costsType) {
      setCostAdminType(cacheContext.cache.costsType);
    }
  }, [cacheContext]);

  /**
   * Get costs
   */
  useEffect(() => {
    if (costAdminType.length !== 0) {
      const search = location.search.split("?");
      const newState = {
        costs: new Array<CostData>(),
        costsNumber: 0,
        status: 0,
        page: 0,
        perPage: 10,
        search: null,
        order: "asc",
        filter: null,
      } as any;
      search.forEach((value: any) => {
        if (value !== "") {
          const values = value.split("=");
          if (values[0] === "page") newState[values[0]] = +values[1] - 1;
          else if (values[0] === "status") newState[values[0]] = values[1];
          else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
        }
      });
      getCosts(
        newState.status,
        newState.page,
        newState.perPage,
        newState.search
      );
      setValue(+newState.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, costAdminType]);

  /**
   * @param status
   * @param page
   * @param perPage
   * @param search
   */
  function getCosts(
    status: any,
    page: number,
    perPage: number,
    search?: string | null
  ) {
    let url = `${urlEnum.administrativeCosts}/${costAdminType[status]?.id}/${page}/${perPage}`;
    if (search) {
      url = `${urlEnum.administrativeCosts}/${costAdminType[status]?.id}/${page}/${perPage}/${search}`;
    }
    getData(url).then((res) => {
      setState({
        ...state,
        costs: res.data.costs,
        costsNumber: res.data.costsNumber,
        status: status,
        page: page,
        perPage: perPage,
        search: search ? search : null,
      });
    });
  }

  /**
   *
   * @param {*} event
   * @param {*} newValue
   */
  const handleChange = (event: any, newValue: number) => {
    setValue(cacheData?.costsType[0]?.id);
    navigate(
      `${
        localUrlEnum.administrativeCosts
      }?status=${newValue}?page=${1}?perPage=${state.perPage}`
    );
  };

  const openModalWindow = (value: any) => {
    setOpenEditModal(value);
  };

  return (
    <div className={style.containerTable}>
      <ThemeProvider theme={getMuiTheme()}>
        <DinamicTabs
          name={componentNames.DinamicTabs}
          key={value}
          labels={tableLabels}
          handleChangeTab={handleChange}
          value={value}
          addNewLabel={Vocabulary.addNewCost}
          openModalWindow={openModalWindow}
        ></DinamicTabs>

        <AdministrativeCostsTable
          state={state}
          getCosts={getCosts}
          status={state.status}
          idCostType={costAdminType && costAdminType[state.status]?.id}
          openNewOption={openEditModal}
          openModalWindow={openModalWindow}
          tableLabels={tableLabels}
          costAdminType={costAdminType}
        />
      </ThemeProvider>
    </div>
  );
}
