import { Vocabulary } from "../Vocabulary";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import NoCrashIcon from "@mui/icons-material/NoCrash";

export const ROUTER_ANAF_DEPARTMENT = {
  routes: [
    "/",
    "/logistics",
    "/vehiclesDashboard/vehicleDetails/:id?",
    "/vehiclesDashboard/:id?",
    "/controlPanel",
    "*",
  ],
};

export const MAIN_SLIDER_ANAF_DEPARTMENT = [
  {
    name: Vocabulary.anafTitle,
    path: "/anaf/notifications",
    icon: <AccountBalanceIcon />,
    orderId: 15,
  },
];

export const VEHICLE_LOGISTICS_DEPARTMENT = [
  {
    name: Vocabulary.detaliiVehicul,
    path: "/vehiclesDashboard/vehicleDetails/",
    icon: <NoCrashIcon />,
    id: "vehicleDetails",
    orderNumber: 1,
  },
];
