/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Paper,
} from "@mui/material";
// import style from "../Styles/tableFilter.module.css";
import EditIcon from "@mui/icons-material/Edit";

import SaveIcon from "@mui/icons-material/Save";
import { isMobile } from "../Utils/utils";
import { Vocabulary } from "../Utils/Vocabulary";

export default function MobileNavigation(props: any) {
  return (
    <div>
      {isMobile() ? (
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 200}}
          elevation={5}
        >
          <BottomNavigation
            showLabels
            value={props.value}
            onChange={props.handleChangeTab}
          >
            {props?.labels?.map((tab: any, index: number) => (
              <BottomNavigationAction
                key={index}
                sx={{ fontWeight: 800 }}
                label={tab}
              />
            ))}
            {props.editButton ? (
              <Button
                onClick={() => props.handleEdit(false)}
                style={{
                  borderRadius: 0,
                  color: "#fff",
                  backgroundColor: "#1976d2",
                }}
                size="small"
              >
                <EditIcon style={{ marginRight: 5 }}></EditIcon>
                Editeaza
              </Button>
            ) : (
              <Button
                type="submit"
                form="formVehicleDetails"
                style={{
                  borderRadius: 0,
                  color: "#fff",
                  backgroundColor: "#FF6633",
                }}
                size="small"
              >
                <SaveIcon style={{ marginRight: 5 }}></SaveIcon>
                {Vocabulary.save}
              </Button>
            )}
          </BottomNavigation>
        </Paper>
      ) : (
        <></>
      )}
    </div>
  );
}
