/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ProgressActionWrapper from "../ProgressActionComponents/ProgressActionWrapper";
import ProgressAction from "../ProgressActionComponents/ProgressAction";
import UploadElement from "../ProgressActionComponents/UploadElement";
import { Vocabulary } from "../../Utils/Vocabulary";
import styles from "../../Styles/progressAction.module.css";

import {
  Divider,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import ContractInformation from "../VehicleDasboard/GenerateFilesComponents/ContractInformation";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { VehicleContext } from "../../Context/vehicleContext";
import { urlEnum } from "../../Utils/urlEnum";
import { postData } from "../../Services/postData";
import {
  VehicleFileType,
  VehiclesStatus,
  tipFisier,
  tipStatus,
} from "../../Utils/autocompleteEnum";
import GenericModal from "../../Modal/GenericModal";
import { ContractInformationModel } from "../../Models/Models";
import { toast } from "react-toastify";
import {
  activeStep,
  disableStep,
  verifySellTypeOrReserve,
} from "../../Utils/utils";
import { ShowDocumentsList } from "../ProgressActionComponents/ShowDocumentsList";
import { CacheContext } from "../../Context/cacheContext";
import { Nomenclator } from "../../Models/VehicleModel";
import moment from "moment";
import ContractInformationForExistingContracts from "../VehicleDasboard/GenerateFilesComponents/ContractInformationForExistingContracts";

export default function ProgressAction4() {
  const vehicleContext: any = useContext(VehicleContext);
  const [openFillContractData, setOpenContractData] = useState(false);
  const [openClientInvoice, setOpenClientInvoice] = useState(false);
  const [openContract, setOpenContract] = useState(false);
  const [openAmendment, setOpenAmendment] = useState(false);
  const [openContractModal, setOpenContractModal] = useState(false);
  const { cache }: any = useContext(CacheContext);
  const [contractInformation, setContractInformation] =
    useState<ContractInformationModel>(new ContractInformationModel());
  const [tvaType, setTvaType] = useState(0);
  const [salesInformation, setSalesInformation] = useState({
    data_vanzare: moment(),
    vanzator_id: localStorage.getItem("useId"),
  });

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newTva: number
  ) => {
    if (newTva !== null) {
      setTvaType(newTva);
    }
  };
  useEffect(() => {
    if (vehicleContext?.state?.vehicle?.contractData) {
      setContractInformation({
        ...contractInformation,
        ...vehicleContext?.state?.vehicle?.contractData,
      });
    }

    if (
      vehicleContext?.state?.vehicle &&
      vehicleContext?.state?.vehicle.indexes
    ) {
      if (!vehicleContext?.state?.vehicle.indexes.includes("4.1")) {
        if (
          vehicleContext?.state?.vehicle?.contractData &&
          vehicleContext?.state?.vehicle?.contractData.pretVanzare
        ) {
          setOpenContractData(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleContext?.state?.vehicle]);

  /**
   * change all information in the contract
   * @param event
   * @param newValue
   */
  function onChangeContractInformation(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | File,
    newValue?: any
  ) {
    if (event instanceof File) {
      const file = event; // manarie
      const { financingMethod } = newValue;
      const metodeFinantareClona = contractInformation.metodeFinantare.slice();
      const mf = metodeFinantareClona.find(
        (m) => m.name === financingMethod.name
      );
      if (mf) {
        mf.file = file;
        setContractInformation({
          ...contractInformation,
          metodeFinantare: metodeFinantareClona,
        });
      }
      return;
    }

    if (Number.isInteger(event)) {
      setContractInformation({
        ...contractInformation,
        pretVanzare: +event,
      });
      return;
    }

    if (event?.target?.name === "isBuyBack") {
      const crtTarget = event?.target as HTMLInputElement;
      setContractInformation({
        ...contractInformation,
        isBuyBack: crtTarget.checked,
      });
      return;
    }

    if (event?.target?.id.includes("paymentMethod")) {
      setContractInformation({
        ...contractInformation,
        metodaDeIncasare: newValue.nume,
      });
      return;
    }

    if (
      event?.target?.name &&
      event?.target?.name?.includes("financingMethod_value")
    ) {
      const mf = contractInformation.metodeFinantare.map((method) => {
        if (method.name === event?.target?.getAttribute("financingMethod"))
          method.value = parseFloat(event?.target?.value);
        return method;
      });

      setContractInformation({
        ...contractInformation,
        metodeFinantare: mf,
      });
      return;
    }

    if (event?.target?.id && event?.target?.id.includes("financingMethod")) {
      const mf = newValue.map((method: { nume: string }) => {
        const exists = contractInformation.metodeFinantare.find(
          (existingMethod) => method.nume === existingMethod.name
        );
        if (exists) return exists;
        return { name: method.nume, value: 0, file: null };
      });
      setContractInformation({
        ...contractInformation,
        metodeFinantare: mf,
      });
      return;
    }

    const cf = { ...contractInformation };
    cf[event?.target?.name] = isNaN(parseFloat(event?.target?.value))
      ? event?.target?.value
      : parseFloat(event?.target?.value);
    setContractInformation(cf);
  }

  /**
   *
   * @param financingMethodName
   */
  function onChangeFinancingMethodOpen(financingMethodName: string) {
    const mf = contractInformation.metodeFinantare.slice();
    const method = mf.find((method) => method.name === financingMethodName);
    if (method) {
      method.open = !method.open;
      setContractInformation({
        ...contractInformation,
        metodeFinantare: mf,
      });
    }
  }

  /**
   * saves the vehicle in the context
   */
  async function setIndexes(index: any, localVehicleData?: any) {
    try {
      setOpenContractData(false);
      await vehicleContext.saveWithoutFile(
        () => true,
        Array.isArray(index) ? index : [index],
        null,
        localVehicleData
      );
    } catch (e) {
      return false;
    }
  }

  /**
   *
   * @param event
   */
  function onChangeSaleDate(event: any) {
    setSalesInformation({
      ...salesInformation,
      data_vanzare: event,
    });
  }

  /**
   *
   * @param event
   */
  function onChangeSaleUser(event: any, value: any) {
    setSalesInformation({
      ...salesInformation,
      vanzator_id: value ? (value.id ? value.id : value) : null,
    });
  }

  /**
   *
   * @param newSalesInformation
   */
  function onChangeSalesInformation(newSalesInformation: any) {
    setSalesInformation(newSalesInformation);
  }

  /**
   * send contract information to server
   */
  async function completeContractData(contractData: ContractInformationModel) {
    // check if user can submit - check if admin
    const priceDifferenceInput = document.getElementById("priceDifference");
    if (!priceDifferenceInput || contractData.pretVanzare === 0) {
      toast.error(Vocabulary.contractDataNotCompleted);
      return;
    }
    const priceDifferences = parseFloat(
      priceDifferenceInput?.getAttribute("value") || "-1"
    );
    if (priceDifferences !== 0) {
      toast.error(Vocabulary.errorSettingPaymentMethods);
      return;
    }
    const newStatus = cache.vehicleStatus.find(
      (status: Nomenclator) => status.nume === VehiclesStatus.contractToSign
    );

    const formData: any = new FormData();
    contractData.metodeFinantare.forEach((metoda) => {
      if (metoda.file) {
        formData.append(metoda.name.trim().replace(" ", "_"), metoda.file);
      }
      if (!(typeof metoda.file === "string" || metoda.file instanceof String))
        metoda.file = null;
    });
    contractData.hasContract = false; //SETEZ CA NU ARE CONTRACT!

    formData.append("contractData", JSON.stringify(contractData));
    formData.append("userId", localStorage.getItem("useId"));
    formData.append("clientId", vehicleContext?.state?.vehicle?.client_id);

    postData(
      `${urlEnum.vehicles}/contractData/${vehicleContext?.state?.vehicle?.id}/${vehicleContext?.state?.vehicle?.vin}`,
      formData
    ).then((response) => {
      setIndexes("4.1", {
        ...response.data.data,
        status_id: newStatus?.id,
        vanzator_id: salesInformation.vanzator_id,
        data_vanzare: moment(salesInformation.data_vanzare).format(
          "YYYY-MM-DD"
        ),
        modifyBy: vehicleContext.updateModifyBy(
          [
            {
              index: "4.1",
              userId: localStorage.getItem("userName"),
              date: moment().format("DD-MM-YYYY HH:mm:ss"),
            },
          ]
        ),
      });
      setOpenContractData(false);
      setOpenContractModal(false);
    });
  }

  /**
   * send contract information to server
   */
  async function completeContractDataForExistingContracts(
    contractData: ContractInformationModel
  ) {
    const newStatus = cache.vehicleStatus.find(
      (status: Nomenclator) => status.nume === VehiclesStatus.contractToSign
    );
    contractData.hasContract = true; //SETEZ CA ARE CONTRACT!
    const formData: any = new FormData();

    formData.append("contractData", JSON.stringify(contractData));
    formData.append("userId", localStorage.getItem("useId"));
    formData.append("clientId", vehicleContext?.state?.vehicle?.client_id);

    postData(
      `${urlEnum.vehicles}/contractData/${vehicleContext?.state?.vehicle?.id}/${vehicleContext?.state?.vehicle?.vin}`,
      formData
    ).then((response) => {
      setIndexes(["4.1", "4.2"], {
        ...response.data.data,
        status_id: newStatus?.id,
        vanzator_id: salesInformation.vanzator_id,
        data_vanzare: moment(salesInformation.data_vanzare).format(
          "YYYY-MM-DD"
        ),
        modifyBy: vehicleContext.updateModifyBy(
          [
            {
              index: "4.2",
              userId: localStorage.getItem("userName"),
              date: moment().format("DD-MM-YYYY HH:mm:ss"),
            },
          ]
        ),
      });
      setOpenContractData(false);
      setOpenContractModal(false);
    });
  }

  /**
   *
   */
  function saveFile(type: string, index: string) {
    let localVehicleData = null;
    if (index && parseFloat(index) === 4.3) {
      const statusVandut = cache.vehicleStatus.find(
        (status: Nomenclator) => status.nume === VehiclesStatus.sold
      );
      if (statusVandut) {
        localVehicleData = {
          status_id: statusVandut.id,
        };
      }
    }
    vehicleContext.saveWithFile(
      "clientInvoice",
      tipFisier[0].nume,
      type,
      () => true,
      [index],
      null,
      localVehicleData
    );
    setOpenClientInvoice(false);
    setOpenContract(false);
  }

  /**
   *
   */
  function saveAmendmentFile() {
    const parentDocArray = vehicleContext?.state?.invoices.clientInvoice;
    const formData = new FormData();
    formData.append("vehicul_id", vehicleContext?.state?.vehicle?.id);
    formData.append("vin", vehicleContext?.state?.vehicle?.vin);
    formData.append("date", moment().format("YYYY-MM-DD"));
    formData.append(
      "descriere_financiar",
      ` ${parentDocArray[0] && parentDocArray[0]?.name}`
    );
    formData.append("suma_financiar", "0");
    formData.append("fileType", tipFisier[0].nume);
    formData.append("type", VehicleFileType.amendment);
    formData.append("status_financiar", tipStatus[1].nume);
    formData.append("file_id", parentDocArray && parentDocArray[0]);

    const url = parentDocArray[0]?.id
      ? `${urlEnum.files}/${parentDocArray[0]?.id}/${vehicleContext?.state?.vehicle.id}/${tipFisier[0].nume}`
      : `${urlEnum.files}`;
    postData(url, formData)
      .then((res) => {
        setOpenAmendment(false);
        vehicleContext.updateVehicleInvoices({
          clientInvoice: res.data.clientInvoice,
          providerInvoice: res.data.providerInvoice,
          OPInvoice: res.data.OPInvoice ? res.data.OPInvoice : [],
          modify:vehicleContext.updateModifyBy(
            [
              {
                index: "4.4",
                userId: localStorage.getItem("userName"),
                date: moment().format("DD-MM-YYYY HH:mm:ss"),
              },
            ]
          ),
          reservedDocuments: res.data.reservedDocuments
            ? res.data.reservedDocuments
            : [],
        });
        vehicleContext.updateVehicleDocuments(res?.data?.documents);
      })
      .catch((err) => {
        setOpenAmendment(false);
      });
  }


  const modifyByArray = [];
  const tempModifyBy = vehicleContext?.state?.vehicle?.modifyBy
    ? vehicleContext?.state?.vehicle?.modifyBy
    : [];
  for (const modification of tempModifyBy) {
    if (modification?.index?.includes("4.1")||modification?.index?.includes("4.2")||modification?.index?.includes("4.3")||modification?.index?.includes("4.4")) modifyByArray.push(modification);
  }

  return (
    <ProgressActionWrapper
      disabled={
        !activeStep(
          "4.1",
          vehicleContext?.state?.vehicle?.indexes,
          verifySellTypeOrReserve(vehicleContext?.state?.vehicle?.isLeasing)
        )
      }
      title={Vocabulary.clientInvoice}
      status={disableStep("4.3", vehicleContext?.state?.vehicle?.indexes)}
      modifyBy={modifyByArray}
    >
      <Stepper className={styles.stepper} orientation="vertical">
        <Step
          active={activeStep(
            "4.1",
            vehicleContext?.state?.vehicle?.indexes,
            verifySellTypeOrReserve(vehicleContext?.state?.vehicle?.isLeasing)
          )}
        >
          <StepLabel>
            <Typography className={styles.stepperLabel}>
              {Vocabulary.completeContractData}
            </Typography>
          </StepLabel>
          <StepContent>
            <ProgressAction index="4.1">
              <div
                className={
                  vehicleContext?.state?.vehicle?.indexes?.includes("4.1")
                    ? styles.container4_1Completed
                    : styles.container4_1
                }
              >
                <Typography>
                  {vehicleContext?.state?.vehicle?.indexes?.includes("4.1")
                    ? Vocabulary.contractDataCompleted
                    : Vocabulary.contractDataNotCompleted}
                </Typography>

                {/********* CONTRACT INFORMATION MODAL ******************/}
                {openFillContractData && (
                  <GenericModal
                    open={openFillContractData}
                    onClose={() => {
                      setOpenContractData(false);
                      setOpenContractModal(false);
                    }}
                    title={Vocabulary.generateContract}
                    buttons={
                      openContractModal ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            tvaType === 1
                              ? completeContractData(contractInformation)
                              : completeContractDataForExistingContracts(
                                  contractInformation
                                )
                          }
                        >
                          {Vocabulary.save}
                        </Button>
                      ) : null
                    }
                  >
                    {!openContractModal ? (
                      <ToggleButtonGroup
                        color="primary"
                        style={{
                          width: "100%",
                          marginTop: 20,
                          marginBottom: 20,
                          color: "#000",
                          height: 80,
                        }}
                        value={tvaType}
                        exclusive
                        onChange={(e: any, tvaNumber: any) => {
                          handleChange(e, tvaNumber);
                          setOpenContractModal(true);
                        }}
                        aria-label="Contract"
                      >
                        <ToggleButton style={{ width: "100%" }} value={1}>
                          {Vocabulary.withoutContract}
                        </ToggleButton>
                        <ToggleButton style={{ width: "100%" }} value={2}>
                          {Vocabulary.contractExist}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    ) : null}
                    {openContractModal && tvaType === 1 ? (
                      <ContractInformation
                        contractInformation={contractInformation}
                        salesInformation={salesInformation}
                        onChangeContractInformation={
                          onChangeContractInformation
                        }
                        onChangeFinancingMethodOpen={
                          onChangeFinancingMethodOpen
                        }
                        onChangeSaleDate={onChangeSaleDate}
                        onChangeSaleUser={onChangeSaleUser}
                        onChangeSalesInformation={onChangeSalesInformation}
                      />
                    ) : null}
                    {openContractModal && tvaType === 2 ? (
                      <ContractInformationForExistingContracts
                        contractInformation={contractInformation}
                        salesInformation={salesInformation}
                        onChangeContractInformation={
                          onChangeContractInformation
                        }
                        onChangeSaleDate={onChangeSaleDate}
                        onChangeSaleUser={onChangeSaleUser}
                        onChangeSalesInformation={onChangeSalesInformation}
                      />
                    ) : null}
                  </GenericModal>
                )}

                {/********* END CONTRACT INFORMATION MODAL ******************/}

                <div style={{ display: "flex" }}>
                  <Button
                    style={{ marginLeft: "auto", color: "#fff" }}
                    variant="contained"
                    onClick={() => setOpenContractData(true)}
                    disabled={disableStep(
                      "4.3",
                      vehicleContext?.state?.vehicle?.indexes
                    )}
                  >
                    {Vocabulary.contractData}
                  </Button>
                </div>
              </div>
            </ProgressAction>
            <Divider></Divider>
          </StepContent>
        </Step>
        <Step
          active={activeStep(
            "4.2",
            vehicleContext?.state?.vehicle?.indexes,
            verifySellTypeOrReserve(vehicleContext?.state?.vehicle?.isLeasing)
          )}
        >
          <StepLabel>
            <Typography className={styles.stepperLabel}>
              {Vocabulary.uploadClientInvoice}
            </Typography>
          </StepLabel>
          <StepContent>
            <ProgressAction index="4.2">
              <div style={{ marginBottom: 20 }}>
                <ShowDocumentsList
                  documents={vehicleContext?.state?.invoices?.clientInvoice}
                  types={[
                    VehicleFileType.clientInvoice,
                    VehicleFileType.contractDraft,
                  ]}
                />
                <UploadElement
                  openModal={openClientInvoice}
                  handleModal={() => setOpenClientInvoice(!openClientInvoice)}
                  handleSave={() =>
                    saveFile(VehicleFileType.clientInvoice, "4.2")
                  }
                  handleEditInfo={(event: any) =>
                    vehicleContext.updateVehicleInvoiceType({
                      name: "clientInvoice",
                      value: event,
                    })
                  }
                  title={Vocabulary.add}
                  disabled={disableStep(
                    "4.3",
                    vehicleContext?.state?.vehicle?.indexes
                  )}
                  fileIsRequired={true}
                />
              </div>
            </ProgressAction>
            <Divider></Divider>
          </StepContent>
        </Step>
        <Step
          active={activeStep(
            "4.3",
            vehicleContext?.state?.vehicle?.indexes,
            verifySellTypeOrReserve(vehicleContext?.state?.vehicle?.isLeasing)
          )}
        >
          <StepLabel>
            <Typography className={styles.stepperLabel}>
              {Vocabulary.uploadContract}
            </Typography>
          </StepLabel>
          <StepContent>
            <ProgressAction index="4.3">
              <>
                <UploadElement
                  openModal={openContract}
                  handleModal={() => setOpenContract(!openContract)}
                  handleSave={() => saveFile(VehicleFileType.contract, "4.3")}
                  handleEditInfo={(event: any) =>
                    vehicleContext.updateVehicleInvoiceType({
                      name: "clientInvoice",
                      value: event,
                    })
                  }
                  fileName={
                    vehicleContext?.state?.invoices?.clientInvoice &&
                    vehicleContext?.state?.invoices?.clientInvoice.find(
                      (item: any) => item.type === VehicleFileType.contract
                    )?.name
                  }
                  file={
                    vehicleContext?.state?.invoices?.clientInvoice &&
                    vehicleContext?.state?.invoices?.clientInvoice.find(
                      (item: any) => item.type === VehicleFileType.contract
                    )?.file
                  }
                  title={Vocabulary.add}
                  disabled={disableStep(
                    "4.3",
                    vehicleContext?.state?.vehicle?.indexes
                  )}
                  fileIsRequired={true}
                />
              </>
            </ProgressAction>
            <Divider></Divider>
          </StepContent>
        </Step>
        <Step
          active={activeStep(
            "4.4",
            vehicleContext?.state?.vehicle?.indexes,
            verifySellTypeOrReserve(vehicleContext?.state?.vehicle?.isLeasing)
          )}
        >
          <StepLabel>
            <Typography className={styles.stepperLabel}>
              {Vocabulary.uploadAdditionalPapers}
            </Typography>
          </StepLabel>
          <StepContent>
            <ProgressAction index="4.4">
              <div style={{ marginBottom: 20 }}>
                <ShowDocumentsList
                  documents={vehicleContext?.state?.invoices?.clientInvoice}
                  types={[VehicleFileType.amendment]}
                />
                <UploadElement
                  openModal={openAmendment}
                  handleModal={() => setOpenAmendment(!openAmendment)}
                  handleSave={saveAmendmentFile}
                  handleEditInfo={(event: any) =>
                    vehicleContext.updateVehicleInvoiceType({
                      name: "clientInvoice",
                      value: event,
                    })
                  }
                  title={Vocabulary.add}
                  fileIsRequired={true}
                />
              </div>
            </ProgressAction>
            <Divider></Divider>
          </StepContent>
        </Step>
      </Stepper>
     
    </ProgressActionWrapper>
  );
}
