/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { pdfjs, Document as PdfDocument, Page } from "react-pdf";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useLocation } from "react-router-dom";
import { urlEnum } from "../../Utils/urlEnum";
import useMediaQuery from "@mui/material/useMediaQuery";
import SignatureModal from "../Generic/SignatureModal";

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

export default function GDPRClient() {
  const contentRef: any = useRef(null);
  const matchesPhone = useMediaQuery("(max-width:600px)");
  const matchesIPod = useMediaQuery("(min-width:601px) and (max-width:850px)");
  const location = useLocation();
  const [openModalSignature, setOpenModalSignature] = useState(false);
  const [docId, setDocId] = useState<any>(null);
  const [displayDocSign, setDisplayDocSign] = useState<any>(false);
  const [isActive, setIsActive] = useState(true);
  const [state, setState] = useState<any>({
    numPages: 0,
    pdfPath: null,
    templateLoaded: 0,
    pageNumber: 1,
  });

  useEffect(() => {
    const items = location.pathname.split("/");
    const id: string = items[items.length - 1];
    getFileData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getFileData(id: string) {
    setDocId(id);
    const url = `${urlEnum.signGDPR}/${id}`;
    fetch(url, {
      method: "GET",
      mode: "no-cors",
    })
      .then((response: any) => {
        if (response.status === 405) {
          setIsActive(false);
          return "linkExpired";
        }
        if (response.status === 400) {
          setIsActive(false);
          return "error";
        }
        return response.blob();
      })
      .then((response): any => {
        if (response === "linkExpired" || response === "error") return;
        const file = new File([response], "GDPR_doc", {
          type: "application/pdf",
        });
        setState({
          ...state,
          pdfPath: file,
          templateLoaded: 1,
          pdfLink: URL.createObjectURL(file),
        });
      });
  }
  /**
   *
   * @param data
   */
  function onDocumentLoadSuccess(data: { numPages: any }) {
    const { numPages } = data;
    setState({ ...state, numPages });
  }
  /**
   * render pages
   */
  function renderPages() {
    const pages: Array<any> = [];
    for (let i = 0; i < state.numPages; i++) {
      pages.push(
        <>
          {matchesPhone ? (
            <Page
              renderTextLayer={false}
              renderAnnotationLayer={false}
              key={`page_${i + 1}`}
              scale={1.75}
              pageNumber={i + 1}
              width={200}
            />
          ) : (
            <>
              {matchesIPod ? (
                <Page
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  key={`page_${i + 1}`}
                  scale={1.75}
                  pageNumber={i + 1}
                  width={400}
                />
              ) : (
                <Page
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  key={`page_${i + 1}`}
                  scale={1.75}
                  pageNumber={i + 1}
                />
              )}
            </>
          )}
        </>
      );
    }
    return pages;
  }

  /**
   *
   * @param signature
   */
  function saveSignature(signature: any) {
    //enable loading
    let element = document.getElementById("loading");
    element?.classList.remove("loadingDisabled");
    element?.classList.add("loading");
    const formData = new FormData();
    formData.append("signature", signature.blobSignature);
    fetch(`${urlEnum.clients}/GDPR/saveSignature/${docId}`, {
      method: "POST",
      mode: "no-cors",
      body: formData,
    })
      .then((response: any) => response.blob())
      .then((response): any => {
        const file = new File([response], "GDPR_doc", {
          type: "application/pdf",
        });
        setState({
          ...state,
          pdfPath: file,
          templateLoaded: 1,
          pdfLink: URL.createObjectURL(file),
        });
        setIsActive(false);
        setOpenModalSignature(false);
        setDisplayDocSign(true);
        //disable loading
        element = document.getElementById("loading");
        element?.classList.remove("loading");
        element?.classList.add("loadingDisabled");
      })
      .catch(() => {
        setOpenModalSignature(false);
        //disable loading
        element = document.getElementById("loading");
        element?.classList.remove("loading");
        element?.classList.add("loadingDisabled");
      });
  }

  return (
    <>
      <div
        style={{
          width: "100vw",
          backgroundColor: "#ededed",
          boxShadow:
            "0px 2px 1px -1px rgb(255 255 255  / 20%), 0px 1px 1px 0px rgb(175 175 175  / 14%), 0px 1px 13px 5px rgb(0 0 0 / 12%)",
        }}
      >
        <img
          style={{ height: 70, marginLeft: 50 }}
          src="/icon.png"
          alt="Icon"
        />
      </div>
      {isActive ? (
        <div style={{ overflowY: "auto" }}>
          <p
            style={{
              textAlign: "center",
              marginTop: 30,
              marginBottom: 30,
              fontSize: matchesPhone ? 20 : 30,
            }}
          >
            {Vocabulary.signGDPR}
          </p>
          <div
            style={{
              height: "73vh",
              display: "flex",
              width: "100%",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <div
              ref={contentRef}
              style={{
                boxShadow:
                  "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 13px 5px rgb(0 0 0 / 12%)",
                overflowY: "scroll",
              }}
            >
              {state.pdfPath ? (
                <>
                  {!state.templateLoaded ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <CircularProgress color="secondary" />
                    </div>
                  ) : (
                    <PdfDocument
                      file={state.pdfPath}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {renderPages()}
                    </PdfDocument>
                  )}
                </>
              ) : (
                Vocabulary.loading
              )}
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white", fontSize: 15 }}
              onClick={() => setOpenModalSignature(!openModalSignature)}
            >
              {Vocabulary.signGDPR}
            </Button>
          </div>
          {/* )} */}
        </div>
      ) : (
        <>
          {displayDocSign ? (
            <div
              style={{
                textAlign: "center",
                marginTop: 200,
              }}
            >
              <p style={{ fontSize: 25 }}>Mulțumim pentru semnătura!</p>
              <p style={{ fontSize: 25 }}>
                Puteți descărca pdf-ul din link-ul de mai jos!{" "}
              </p>
              <a
                href={state.pdfLink}
                target="_blank"
                rel="noreferrer"
                style={{
                  fontSize: 25,
                  textDecoration: "none",
                  color: "blue",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <PictureAsPdfIcon style={{ color: "red", fontSize: 30 }} />{" "}
                &nbsp;&nbsp;
                <span style={{ verticalAlign: "middle" }}>
                  Apasă aici pentru a descărca ACORDUL PRIVIND PRELUCRAREA
                  DATELOR CU CARACTER PERSONAL.
                </span>
              </a>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ verticalAlign: "middle", marginTop: 200, padding: 15 }}
              >
                <p style={{ fontSize: 35 }}>Link-ul nu mai este activ!</p>
              </div>
            </div>
          )}
        </>
      )}
      <SignatureModal
        openModalSignature={openModalSignature}
        showSignature={() => setOpenModalSignature(!openModalSignature)}
        saveSignature={(signature) => saveSignature(signature)}
      />
    </>
  );
}
