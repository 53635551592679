/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { ThemeProvider } from "@emotion/react";
import { getMuiTheme } from "../../Utils/globalStyleTables";
import MUIDataTable from "mui-datatables";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { urlEnum } from "../../Utils/urlEnum";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Button, IconButton, Typography } from "@mui/material";
import AnafFilters from "./AnafFilters";
import moment from "moment";
import style from "../../Styles/tableFilter.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneIcon from "@mui/icons-material/Tune";
import { containsLetters, formatNumberToEuropean, isMobile } from "../../Utils/utils";
import { tableLayoutOnMobile } from "../../Utils/Constants";
import { postData } from "../../Services/postData";
import { getData } from "../../Services/getData";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { TransportData } from "../../Models/Models";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GenericModal from "../../Modal/GenericModal";
import AnafNotification from "./AnafNotification";
import formatDate, { anafFormatDate } from "../../Utils/formatDate";
import { updateData } from "../../Services/updateData";

type State = {
  transports: Array<any>;
  numberOfTransports: number;
};

export default function AnafTable() {
  const navigate = useNavigate();
  const location = useLocation();
  const [accordeonState, setAccordeonState] = useState<boolean>(true);
  const [companies, setCompanies] = useState([]);
  const [filters, setFilters] = useState<any>({
    perPage: 20,
    CUI: "",
    nrNotificari: "1",
    token: "",
  });
  const [state, setState] = useState<State>({
    transports: new Array<any>(),
    numberOfTransports: 0,
  });
  const [openAnafNotificationModal, setOpenAnafNotificationModal] = useState(
    false
  );
  const [notificationForDelete, setNotificationForDelete]: any = useState(null);
  const [
    openAnafNotificationUpdateModal,
    setOpenAnafNotificationUpdateModal,
  ] = useState(false);
  const [notificationForUpdate, setNotificationForUpdate]: any = useState(null);

  const { handleSubmit, control, watch, getValues, setValue } = useForm({
    resolver: yupResolver(TransportData),
  });

  const watchCUI = watch("CUI");
  const watchNumarZile = watch("numarZile");
  /**
   * Get transports
   */
  useEffect(() => {
    const filters = getValues();
    const token = (companies.find(
      (comp: any) => comp.CIF === filters.CUI
    ) as any)?.access_token;
    const CUI = containsLetters(filters.CUI) ? filters.CUI?.substring(2) : filters.CUI;
    if (CUI && token) getTransports(filters.numarZile, CUI, token);
  }, [watchCUI, watchNumarZile]);

  useEffect(() => {
    getAllCompanies();
  }, []);

  function getAllCompanies() {
    const url = `/${urlEnum.firma_achizitie_r}`;
    getData(url)
      .then((res) => {
        setCompanies(res.data.data);
        setValue("CUI", res.data.data[0].CIF);
      })
      .catch((err) => {
        //
      });
  }

  /**
   *
   * @param numarZile
   * @param CUI
   * @param token
   */
  function getTransports(numarZile: number, CUI: string, token: string) {
    const url = urlEnum.transports;
    const data = {
      numarZile: numarZile,
      CUI: CUI,
      token: token,
    };
    postData(url, data)
      .then((res) => {
        const transports = res.data.mesaje.map((mesaj: any) => {
          const segments = mesaj.uit.match(/.{1,4}/g);
          return { ...mesaj, uit: segments ? segments.join("-") : mesaj.uit };
        });
        const deletedNotifications = transports.filter(
          (item: any) => item.tip === "DEL"
        );

        const filteredNotificationsButWithErrors = transports.filter(
          (item: any) =>
            !deletedNotifications.some(
              (filteredObj: any) => filteredObj.uit === item.uit
            )
        );
        const filteredNotifications = filteredNotificationsButWithErrors.filter(
          (item: any) =>
            !(
              item.stare === "ERR" &&
              (item.tip === "COR" || item.tip === "NOT")
            )
        );

        filteredNotifications.sort((a: any, b: any) => (new Date(b.data_creare) as any) - (new Date(a.data_creare) as any));

        setState({
          ...state,
          transports: filteredNotifications,
          numberOfTransports: filteredNotifications.length,
        });
      })
      .catch((err) => {
        //
      });
  }

  const shouldButtonBeDisabled = (rowIndex: number) => {
    const data_transp = new Date(state.transports[rowIndex].data_transp);
    const today = new Date();
    // Strip time from both dates by setting the time to 00:00:00
    const strippedDataTransp = new Date(
      data_transp.getFullYear(),
      data_transp.getMonth(),
      data_transp.getDate()
    );
    const strippedToday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    if (strippedToday > strippedDataTransp) {
      return true;
    }
    return false;
  };

  /**
   *
   */
  const transportsHeader = [
    {
      label: `${Vocabulary.uit}`,
      name: "uit",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.vehicul}`,
      name: "ref_decl",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.trTara}`,
      name: "tr_tara",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.nrVehicul}`,
      name: "nr_veh",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.nrRemorca}`,
      name: "nr_rem1",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.greutateNeta}`,
      name: "gr_tot_neta",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.greutateBruta}`,
      name: "gr_tot_bruta",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.dataTransport}`,
      name: "data_transp",
      options: {
        sort: false,
        customBodyRender: (rowData: any, index: any) => {
          return <>{convertTimestampToDate(rowData)} </>;
        },
      },
    },
    {
      label: `${Vocabulary.denumirePartenerUE}`,
      name: "pc_den",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.denumireTransportor}`,
      name: "tr_den",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.dataCreare}`,
      name: "data_creare",
      options: {
        sort: false,
        customBodyRender: (rowData: any, index: any) => {
          return <>{convertTimestampToDate(rowData)} </>;
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex: number) => (
          <div style={{ float: "right", display: "flex" }}>
            {!shouldButtonBeDisabled(rowIndex) && (
              <IconButton disabled={shouldButtonBeDisabled(rowIndex)}>
                <EditIcon
                  style={{
                    marginRight: 15,
                    height: 30,
                    cursor: "pointer",
                    color: "#4449",
                  }}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    editAnafNotification(rowIndex);
                  }}
                ></EditIcon>
              </IconButton>
            )}
            <IconButton>
              <DeleteIcon
                style={{ height: 30, cursor: "pointer", color: "#4449" }}
                onClick={(event: any) => {
                  event.stopPropagation();
                  setNotificationForDelete(rowIndex);
                  setOpenAnafNotificationModal(true);
                }}
              ></DeleteIcon>
            </IconButton>
          </div>
        ),
      },
    },
  ];

  function editAnafNotification(rowIndex: number) {
    const selectedRow = state.transports[rowIndex];
    const selectedNotificationData: any = {
      dateTransport: {},
      bunuriTransportate: {},
      partenerComercial: {},
    };
    selectedNotificationData.CUI = watchCUI;
    selectedNotificationData.uit = selectedRow.uit;

    setNotificationForUpdate(selectedNotificationData);
    setOpenAnafNotificationUpdateModal(true);
  }

  function deleteNotification() {
    const selectedRow = state.transports[notificationForDelete];
    const filters = getValues();
    const data: any = {
      uit: selectedRow.uit.replace(/-/g, ""),
      CUI: containsLetters(filters.CUI) ? filters.CUI?.substring(2):filters.CUI,
    };
    if (data?.CUI) {
      const company: string | undefined = companies?.find(
        (company) => (company as any).CIF.substring(2) === data.CUI
      );
      if (company) {
        data.token = (company as any)?.access_token;
      }
    }
    // SEND TO ANAF
    postData(urlEnum.deleteNotificare, data)
      .then((res: any) => {
        console.log("ANAF notification deletion" + res);
        // refetch notifications
        getTransports(60, data.CUI, data.token);
        setOpenAnafNotificationModal(!openAnafNotificationModal);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  /**
   *
   * @returns
   */
  function getTableOptions() {
    return {
      selectableRows: "none" as any,
      responsive: tableLayoutOnMobile,
      viewColumns: false,
      rowsPerPageOptions: [20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      fixedHeader: true,
      count: state.numberOfTransports,
      rowsPerPage: filters.perPage,
      page: filters.page,
      search: false,
      sort: true,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: Vocabulary.pagination,
      },
    };
  }

  function handleSubmitGetNotifications(data: any) {
    console.log("Asta este in form: " + data);
  }

  function onError(data: any) {
    console.log("Asta este in form: " + data);
  }

  function convertTimestampToDate(timestamp: any) {
    // Ensure the timestamp is a string
    timestamp = String(timestamp);

    // Split the date and time components
    if (timestamp) {
      const [datePart, timePart] = timestamp.split("T");

      // Further split the date and time parts
      if (datePart && timePart) {
        const [year, month, day] = datePart
          .split("-")
          .map((part: any) => parseInt(part, 10));
        const [hour, minute, second] = timePart
          .split(":")
          .map((part: any) => parseInt(part, 10));

        // Adjust month (0-based)
        const correctedMonth = month - 1;

        // Use the values (e.g., to create a Date object)
        const newDate = new Date(
          year,
          correctedMonth,
          day,
          hour,
          minute,
          second
        );

        // Create and return the Date object
        // const newDate = new Date(year, month, day, hour, minute, second);
        return <Typography>{newDate.toLocaleString("ro-Ro")}</Typography>;
      }
    }
  }

  /**
   *
   * @param {*} event
   * @returns
   */
  function handleChangeStartDate(event: any) {
    let loc = location.search;
    let newLocation = loc.split("?");
    newLocation = newLocation.map((l: string) => {
      if (l.includes("startDate")) {
        return `startDate=${moment(event).format("YYYY-MM-DD")}`;
      }

      return l;
    });
    loc = newLocation.join("?");
    navigate(loc);
  }

  /**
   *
   * @param {*} event
   * @returns
   */
  function handleChangeEndDate(event: any) {
    let loc = location.search;
    let newLocation = loc.split("?");
    newLocation = newLocation.map((l: string) => {
      if (l.includes("endDate")) {
        return `endDate=${moment(event).format("YYYY-MM-DD")}`;
      }

      return l;
    });
    loc = newLocation.join("?");
    navigate(loc);
  }

  function handleChangeTextFields(event: any) {
    setFilters({ ...filters, model: event.target.value });
    const newData = Object.assign(filters, {
      numarZile: event.target.value,
    });
    setFilters(newData);
  }
  /**
   *
   * @param event
   */
  function onBlurTextFields() {
    let loc = location.search;
    let newLocation = loc.split("?");
    if (loc.includes("model")) {
      newLocation = newLocation.map((l: string) => {
        if (l.includes("model")) {
          if (filters.model === "") return "";
          return `model=${filters.model}`;
        }

        return l;
      });
      loc = newLocation.join("?");
    } else {
      loc += `?model=${filters.model}`;
    }
    navigate(loc);
  }

  /**
   * Handles the submission of a notification to ANAF.
   *
   * This function takes the provided data, formats it according to ANAF requirements,
   * sends the notification to ANAF, and then updates the vehicle status and shipping information.
   *
   * @param {any} data - The data to be sent to ANAF.
   * @return {void}
   */
  const handleSubmitNotificationToANAF = async (data: any) => {
    data.dateTransport.codOrgTransport = containsLetters(data.dateTransport.codOrgTransport) ? data.dateTransport.codOrgTransport?.substring(
      2
    ):data.dateTransport.codOrgTransport;
    if (data?.CUI) {
      const company: string | undefined = companies?.find(
        (company) => (company as any).CIF === data.CUI
      );
      if (company) {
        data.token = (company as any)?.access_token;
        data.CUI = containsLetters(data.CUI) ? data.CUI.substring(2):data.CUI;
      }
    }
    data.bunuriTransportate[0].valoareLeiFaraTva = formatNumberToEuropean(
      data.bunuriTransportate[0].valoareLeiFaraTva
    );
    data.dateTransport.dataTransport = formatDate(
      data.dateTransport.dataTransport,
      anafFormatDate
    );
    data.documenteTransport.forEach((doc: any, index: number) => {
      data.documenteTransport[index].dataDocument = formatDate(
        data.documenteTransport[index].dataDocument,
        anafFormatDate
      );
    });
    // ADD NEW SHIPPINGS
    const formatedShippings = {
      name: data.dateTransport.denumireOrgTransport,
      CIF: data.dateTransport.codOrgTransport,
      nrVehicul: data.dateTransport.nrVehicul,
      nrRemorca: data.dateTransport.nrRemorca,
    };
    const url = `${urlEnum.shipping}`;
    const newShipping: any = await postData(url, formatedShippings);
    // SEND TO ANAF
    postData(urlEnum.uploadNotification, data)
      .then((res: any) => {
        console.log("ANAF notification sent" + res);
        //-----------------------------------------Update Notification to my database-------------------------------------------
        const addNotification = `${urlEnum.anafNotifications}`;
        //data.uit = res.data.UIT;
        data.bunuriTransportate = JSON.stringify(data.bunuriTransportate);
        data.documenteTransport = JSON.stringify(data.documenteTransport);
        data.partenerComercial = JSON.stringify(data.partenerComercial);
        data.dateTransport = JSON.stringify(data.dateTransport);
        data.codPtf = JSON.stringify(data.codPtf);
        data.locatie = JSON.stringify(data.locatie);

        updateData(addNotification, data).then((response) => {
          if (response) console.log(response.data);
        });
        //-----------------------------------------End Update Notification to my database-------------------------------------------

        // we need to a
        setOpenAnafNotificationUpdateModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={style.containerTable}>
      <Accordion
        onChange={() => {
          setAccordeonState(!accordeonState);
        }}
        expanded={accordeonState}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TuneIcon style={{ marginRight: 10 }}></TuneIcon>
          <Typography style={{ marginTop: 1 }}>Filtre </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <form
            onSubmit={handleSubmit(handleSubmitGetNotifications, onError)}
            method="get"
            id="getNotifications"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <AnafFilters
              control={control}
              companies={companies}
              watchedCUI={watchCUI}
            />
          </form>
        </AccordionDetails>
      </Accordion>

      <GenericModal
        open={openAnafNotificationModal}
        onClose={() => setOpenAnafNotificationModal(false)}
        maxWidth={1200}
        title={`${Vocabulary.anafStergereNotificationTitle}`}
        buttons={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpenAnafNotificationModal(false);
              }}
            >
              {Vocabulary.no}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                deleteNotification();
              }}
            >
              {Vocabulary.yes}
            </Button>
          </div>
        }
      >
        {" "}
      </GenericModal>
      <AnafNotification
        openAnafNotificationModal={openAnafNotificationUpdateModal}
        setOpenAnafNotificationModal={() =>
          setOpenAnafNotificationUpdateModal(false)
        }
        handleSubmitNotificationToANAF={(data: any) => {
          handleSubmitNotificationToANAF(data);
        }}
        selectedData={notificationForUpdate}
      />
      <ThemeProvider theme={getMuiTheme()}>
        <div style={{ marginTop: isMobile() ? 10 : 20 }}>
          <MUIDataTable
            title={""}
            data={state.transports}
            columns={transportsHeader}
            options={getTableOptions()}
          />
        </div>
      </ThemeProvider>
    </div>
  );
}
