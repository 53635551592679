import { Vocabulary } from "../Vocabulary";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import FileCopyIcon from "@mui/icons-material/FileCopy";

export const ROUTER_DOCUMENTE_FINANCIARE = {
  routes: [
    "/",
    "/vehiclesDashboard",
    "/vehiclesDashboard/vehicleDocuments/:id?",

    "*",
  ],
};

export const MAIN_SLIDER_DOCUMENTE_FINANCIARE = [
  {
    name: Vocabulary.logsName,
    path: "/logs",
    icon: <AssignmentIndIcon />,
  },
];

export const VEHICLE_OPTIONS_DOCUMENTE_FINANCIARE = [
  {
    name: Vocabulary.documente,
    path: "/vehiclesDashboard/vehicleDocuments/",
    icon: <FileCopyIcon />,
    id: "vehicleDocuments",
    slug: "vehicleDocuments",
    orderNumber: 7,
  },
];

export const DINAMIC_TABS_DOCUMENTE_FINANCIARE = null;

export const MAIN_TABLE_DOCUMENTE_FINANCIARE = null;

export const VEHICLE_MAIN_INFO = {
  accesToDocuments: true,
};
