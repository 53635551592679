/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { ReportsTab, TemplatesTab } from "./autocompleteEnum";

export const urlEnum: any = {
  api: "/api",
  login: "/api/login",
  logout: "/api/logout",
  vehicles: "/api/vehicule",
  cancelTheSale: "/api/vehicule/cancelTheSale",
  cacheData: "/api/cache",
  soldVehicles: "/api/vehicule/sold",
  unsoldVehicles: "/api/vehicule/unsold",
  reservedVehicles: "/api/vehicule/reserved",
  files: "/api/vehicule/files",
  clientFiles: "/api/vehicule/clientFiles",
  multipleUpdate: "/api/vehicule/multipleUpdate/files",
  multipleDeletePhotos: "/api/vehicule/multipleDelete/files",
  data_intrare_detailing: "api/interventii-detailing",
  data_intrare_vopsitorie: "api/interventii-vopsitorie",
  data_intrare_service: "api/interventii-service",
  reserveVehicle: "/api/vehicule/reserveVehicle",
  reserveForVehicle: "/api/vehicule/shipping/reserveForVehicle",
  deleteReserveForShipping: "/api/vehicule/shipping/deleteReserveForShipping",
  carOrders: "/api/carOrders",
  costs: "api/costuri-aditionale",
  guarantees: "api/costuri-aditionale",
  //ENDPOINT pentru update cache
  tip_vehicul_r: "api/tipuri-vehicule",
  status_id: "api/statusuri-vehicule",
  brand_r: "api/branduri-vehicule",
  model_r: "api/modele-vehicule",
  locatie_administrativa_r: "api/locatii-administrative",
  tip_achizitie_r: "api/tipuri-achizitie",
  firma_achizitie_r: "api/firme",
  furnizor_r: "api/furnizori",
  tip_caroserie_r: "api/tipuri-caroserii",
  culoare_r: "api/culori-vehicule",
  optiune_culoare_r: "api/optiuni-culori",
  tara_origine_r: "api/tari-origine",
  financingTypes: "api/tipuri-finantare",
  norma_poluare_r: "api/norme-poluare",
  combustibil_r: "api/tipuri-combustibil",
  tip_tractiune_r: "api/tipuri-tractiuni",
  cutie_viteze_r: "api/cutii-viteze",
  dotari_id: "api/dotari",
  tip_tva_r: "api/tva",
  tip_cost_adm_id: "api/tip-costuri-adm",
  users: "/api/users",
  roles: "api/roles",
  templates: "/api/templates",
  genericTemplates: "/api/genericTemplates",
  permissions: "api/permissions",
  tip_costuri: "api/tip-costuri",
  tip_costuri_adm: "api/tip-costuri-adm",
  clients: "/api/clients",
  clientsDocuments: "/api/clientsDocuments",
  userFilters: "/api/users/vehicles/filters",
  administrativeCosts: "/api/costuri-administrative",
  costsAdminType: "/api/tip-costuri-adm",
  logs: "/api/logs",
  negotiation_id: "/api/user/lista-notificari",
  permissions_id: "/api/permissions",
  settings: "/api/settings",
  unreadMessages: "/api/integration/clientMessages/unreadMessages",
  reports: "/api/reports",
  TVARecovered: "api/vehicule/tvaRecovered",
  user: "/api/users",
  bnrExchangeRates: "/api/bnrExchangeRates",
  numberOfVehicleForFinancialDepartment:
    "api/vehicule/numberOfVehicleForFinancialDepartment",
  notifications: "/api/notifications",
  markNotificationAsRead: "/api/notifications/markAsRead",
  searchVehicles: "/api/vehicule/search",
  postOnSite: "/api/vehicule/postOnSite/",
  clientMessages: "/api/integration/clientMessages",
  shipping: "/api/shippings",
  transports: "/api/transports",
  drivers: "api/drivers",
  notes: "/api/notes",
  sendWhatsappTemplate: "/api/sendWhatsappTemplate",
  sendWhatsappText: "/api/sendWhatsappText",
  signGDPR: "/api/clients/GDPR/getPDF",
  profitEstimates: "/api/vehicule/estimatedCostsAndProfits",
  autovit: "/api/autovit",
  bestauto: "/api/bestauto",
  publi24: "/api/publi24",
  websitesIntegration: "/api/websitesIntegrations",
  getCountries: "/api/clients/countries",
  notificationsType: "/api/notifications/get/types",
  oauthEtransport: "/api/oauth",
  callbackEtransport: "/api/callback",
  uploadNotification: "/api/upload/notificare",
  deleteNotificare: "/api/delete/notificare",
  getEtransportNotifications: "/api/transports",
  getEtransportNotification: "/api/transport",
  getBunuriTransportate: "/api/bunuriTransportate",
  unitateMasura: "/api/unitateMasura",
  updateShipping: "/api/vehicule/shipping/updateShipping",
  soldVehiclesForUser: "/api/vehicule/soldForUser",
  userBonuses: "/api/userBonuses",
  removeShipping: "/api/shippings/remove",
  vehicleCost: "/api/vehicleCost",
  usersForSalaries: "/api/users/active/forSalaries",
  downloadFinancialSummary: "/api/salary/exportFinancialSumary",
  duplicateVehicle: "/api/vehicule/duplicate/vehicle/data/record",
  exportVehiclesTable: "/api/vehicule/export/table/data/as/excel",
  exportClientsTables: "/api/clients/export/table/data",
  anafNotifications: "/api/anaf/notifications",
  promoteAd: "/api/promote/ad",
};

export const localUrlEnum = {
  settings: [
    "settings",
    "salaryConfigurations",
    "statusVehicle",
    "roles",
    "permissions",
    "shipping",
    "companies",
    "websitesIntegration",
    "notifications",
  ],
  reportsTab: [
    ReportsTab.statistically,
    ReportsTab.vehicles,
    ReportsTab.brands,
    ReportsTab.models,
    ReportsTab.administrativeLocations,
    ReportsTab.shipping,
  ],
  templatesTab: [TemplatesTab.templates, TemplatesTab.generateDocuments],
  reports: "/reports",
  vehiclesTab: [
    "unsold",
    "sold",
    "reserved",
    "partialVehicles",
    "onDemandVehicles",
  ],
  messagesTab: ["unassigned", "assigned", "canceled", "all"],
  carOrdersTab: ["allocated", "unallocated", "canceled"],
  financialTab: [
    "unpaid",
    "paid",
    "advancePaid",
    "contractToSign",
    "contractSigned",
  ],
  vehicles: "/vehiclesDashboard",
  users: "/usersDashboard",
  administrativeCosts: "/administrativeCosts",
  financialDepartment: "/financialDepartment",
  acquisitions: "/acquisitions",
  sales: "/sales",
  onDemand: "/onDemand",
  logistics: "/logistics",
  vehicle: "vehicleDashboard",
  clientMessages: "/clientMessages",
  financingRequests: "/financingRequests",
  logs: "/logs",
  login: "/login",
};

export const documentsTypeEnum = {
  providerInvoice: "providerInvoice",
  paymentOrder: "paymentOrderToProvider",
  reserveVehicle: "reserveVehicle",
  clientInvoice: "clientInvoice",
};

export const sellTypeOrReserve = [
  //NU SCHIMBA ORDINEA! DACA SCHIMBI ORDINEA, VERIFICA UNDE FOLOSESTI ACEASTA VARIABILA
  {
    id: 0,
    nume: "Cu factura de avans existenta",
    value: "withInvoice",
  },
  {
    id: 1,
    nume: "Fara avans",
    value: "withoutAdvance",
  },
  {
    id: 2,
    nume: "Finatare totala",
    value: "totalFunding",
  },
  {
    id: 3,
    nume: "Cu avans",
    value: "totalIndexes",
  },
];

export const vehiclesExcelFields = {
  Marca: true,
  Model: true,
  "Serie Sasiu": true,
  Firma: true,
  Locatie: true,
  "Tip achizitie": true,
  "Pret achizitie EURO": true,
  "Pret achizitie RON": true,
  "Pret vanzare EURO": true,
  "Pret vanzare RON": true,
  "Pret vanzare site": true,
  Dobanda: true,
  "Profit Brut": true,
  "User achizitie": true,
  "User logistica": true,
  "User vanzare": true,
  "Data achizitie": true,
  "Data receptie": true,
  "Data vanzare": true,
  Transport: true,
  Service: true,
  Detailing: true,
  "Costuri aditionale": true,
  Vopsitorie: true,
  "Taxa licitație": true,
  Alimentare: true,
};
