/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Grid, TextField } from "@mui/material";
// import { useContext, useEffect, useState } from "react";
// import { CacheContext } from "../../Context/cacheContext";
import { Vocabulary } from "../../Utils/Vocabulary";
import { isMobile } from "../../Utils/utils";
import { Controller } from "react-hook-form";

type TransportsFiltersProps = {
  companies: any;
  control: any;
  watchedCUI:any;
};
export default function AnafFilters(props: TransportsFiltersProps) {
  const { control, companies, watchedCUI } = props;
  // const cacheContext = useContext(CacheContext) as any;

  return (
    <Grid container spacing={isMobile() ? 2 : 6} style={{ marginBottom: 10 }}>
      <Grid item xs={12} md={4} lg={3}>
      <Controller
        name="CUI"
        control={control}
        render={({
          field: { ref, onChange, value, ...field },
          fieldState: { error },
        }) => (
          <Autocomplete
            freeSolo={false}
            style={{
              width: isMobile() ? "100%" : 320,
              marginBottom: 15,
            }}
            {...field}
            size="small"
            disableClearable
            disablePortal
            getOptionLabel={(option) => option.nume_firma}
            renderOption={(props, option) => (
              <li {...props}>{option.nume_firma}</li>
            )}
            id="CUI"
            onChange={(event, value) => {
              onChange(value?.CIF || ""); // Update form value to the selected option's CIF
            }}
            value={
              companies.find((option:any) => option.CIF === watchedCUI) || null
            }
            options={companies}
            getOptionDisabled={(option) => option.access_token === null}
            renderInput={(params) => (
              <TextField
                error={!!error}
                helperText={error?.message}
                label="CUI"
                name="CUI"
                type="search"
                inputRef={ref}
                {...params}
              />
            )}
          />
        )}
      />
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <Controller
          name="numarZile"
          defaultValue={"60"}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              style={{
                width: isMobile() ? "100%" : 270,
                marginBottom: 15,
              }}
              value={value}
              error={!!error}
              helperText={error?.message}
              type="number"
              id="numarZile"
              label={Vocabulary.noDays}
              size="small"
              variant="outlined"
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
