import { CircularProgress } from "@mui/material";

type LoadingProps = {
  enabled?:boolean;
}
export default function Loading(props:LoadingProps) {
  return (
    <div id="loading" className={props.enabled ? "" : "loadingDisabled" }>
      <CircularProgress color="primary" />
    </div>
  );
}
