import { Button, Grid } from "@mui/material";
import { isMobile } from "../../Utils/utils";
import GenericModal from "../../Modal/GenericModal";
import { Vocabulary } from "../../Utils/Vocabulary";
import { urlEnum } from "../../Utils/urlEnum";
import { deleteData } from "../../Services/deleteData";

type CarOrderDeleteModalProps = {
  open: boolean;
  deletedCarOrderId: number | null;
  onClose: (shouldRefetch: boolean) => void;
};
export default function CarOrderDeleteModal(props: CarOrderDeleteModalProps) {
  const { open, deletedCarOrderId, onClose } = props;

  /**
   *
   */
  function deleteCarOrders() {
    deleteData(`${urlEnum.carOrders}/${deletedCarOrderId}`).then(() => {
      onClose(true);
    });
  }
  return (
    <GenericModal
      open={open}
      onClose={() => onClose(false)}
      title={Vocabulary.deleteCarOrder}
    >
      <div style={{ textAlign: "center" }}>
        <p style={{ fontSize: 20 }}>{Vocabulary.deleteCarOrderMessage}</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
            <Grid item md={6} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => onClose(false)}
                fullWidth
              >
                {Vocabulary.no}
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <Button
                variant="contained"
                fullWidth
                onClick={deleteCarOrders}
                style={{ color: "white" }}
              >
                {Vocabulary.yes}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </GenericModal>
  );
}
