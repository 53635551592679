import { Autocomplete, Divider, TextField } from "@mui/material";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import { Controller } from "react-hook-form";
import { tipIntrareIesire } from "../../../../Utils/Enums/PTForADR";
import PTF from "./PTF";
import ADR from "./ADR";

export default function LocDeStartFinalTraseu(props: any) {
  const { control, locatieIntrare, locatieIesire, onChangeADRJudet, ptfIntrareIesire} = props;

  const getDefaultTipIntrareIesire = (recievedValue: string) => {
    if (!tipIntrareIesire || !recievedValue) return "";
    const defaultValue = tipIntrareIesire?.find(
      (intrare: any) => intrare?.id.toString() === recievedValue
    );
    return defaultValue?.id || "";
  };

  return (
    <div>
      <fieldset
        style={{
          border: "1px solid #0000001f",
          borderRadius: 10,
          marginTop: 10,
          paddingTop: 15,
        }}
      >
        <legend
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "0rem 0.8rem",
          }}
        >
          {Vocabulary.locDescarcare}
        </legend>
        <Controller
          name="tipIntrare"
          control={control}
          defaultValue={getDefaultTipIntrareIesire(locatieIntrare)}
          render={({
            field: { ref, value, ...field },
            fieldState: { error },
          }) => (
            <Autocomplete
              value={value || " "}
              isOptionEqualToValue={(option: any, value: any) => {
                return option.id.toString() === value;
              }}
              freeSolo={false}
              style={{
                marginBottom: 15,
              }}
              size="small"
              disableClearable
              disablePortal
              getOptionLabel={(option: any) => {
                return (
                  tipIntrareIesire?.find(
                    (intrare: any) => intrare?.id.toString() === (option.id ? option.id.toString() : option.toString())
                  )?.denumire || ""
                );
              }}
              id="tipIntrare"
              onChange={(event, value: any) => field.onChange(value.id ? value.id : value)}
              options={tipIntrareIesire}
              renderInput={(params) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  label={Vocabulary.startTraseu}
                  name="tipIntrare"
                  {...params}
                />
              )}
            />
          )}
        />
        {(locatieIntrare === 1 || locatieIntrare === "1") && (
          <PTF control={control} cofPTFName={"codPtfIntrare"} ptfIntrareIesire={ptfIntrareIesire} />
        )}
        {(locatieIntrare === 2 || locatieIntrare === "2") && (
          <ADR
            control={control}
            tipLocatie={"locatieIntrare"}
            onChangeADRJudet={onChangeADRJudet}
          />
        )}
        <Divider style={{ marginBottom: 20 }} />
        <Controller
          name="tipIesire"
          control={control}
          defaultValue={getDefaultTipIntrareIesire(locatieIesire)}
          render={({ field: { ref, value, ...field }, fieldState: { error } }) => (
            <Autocomplete
              value={value || " "}
              isOptionEqualToValue={(option: any, value: any) => {
                return option.id.toString() === value;
              }}
              freeSolo={false}
              style={{
                marginBottom: 15,
              }}
              size="small"
              disableClearable
              disablePortal
              getOptionLabel={(option: any) => {
                return (
                  tipIntrareIesire?.find(
                    (intrare: any) => intrare?.id.toString() ===  (option.id ? option.id.toString() : option.toString())
                  )?.denumire || ""
                );
              }}
              id="tipIesire"
              onChange={(event, value: any) => field.onChange(value.id ? value.id : value)}
              options={tipIntrareIesire}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!error}
                  helperText={error?.message}
                  label={Vocabulary.sfarsitTraseu}
                  name="tipIesire"
                />
              )}
            />
          )}
        />
        {(locatieIesire === 1 || locatieIesire === "1") && (
          <PTF control={control} cofPTFName={"codPtfIesire"} ptfIntrareIesire={ptfIntrareIesire}/>
        )}
        {(locatieIesire === 2 || locatieIesire === "2") && (
          <ADR
            control={control}
            tipLocatie={"locatieIesire"}
            onChangeADRJudet={onChangeADRJudet}
          />
        )}
      </fieldset>
    </div>
  );
}
