/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Button,
  Dialog,
  DialogTitle,
  Switch,
  Paper,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../Styles/genericModal.module.css";
import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Controller, useForm } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { postData } from "../Services/postData";
import { DropzoneArea } from "material-ui-dropzone";
import style from "../Styles/modalServices.module.css";
import GenericModal from "../Modal/GenericModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { CacheContext } from "../Context/cacheContext";
import ModeIcon from "@mui/icons-material/Mode";


import moment from "moment";
import { deleteData } from "../Services/deleteData";
import { urlEnum } from "../Utils/urlEnum";
import { europeanDate } from "../Utils/formatDate";
import { VehicleDashboardTableType } from "../Utils/autocompleteEnum";
import { getIconByFileFileType, isMobile } from "../Utils/utils";
import { VehicleContext } from "../Context/vehicleContext";
import { AutocompleteModel, AutocompleteModelTipCost, GenericAutocompleteModalModel } from "../Models/Models";
import { AutocompleteSchema } from "../Validators/vehicleValidators";
import GenericAutocompleteModal from "../Modal/GenericAutocompleteModal";
import { Vocabulary } from "../Utils/Vocabulary";


const initialGenericModalState = new GenericAutocompleteModalModel();


function GenericServicesModal(props) {
  const cacheContext = useContext(CacheContext);
  const vehicleContext = useContext(VehicleContext);
  const { open, onClose, title, maxWidth } = props;
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [files, setFiles] = useState([]);
  const [deletedFile, setDeletedFile] = useState(false);
  const [openModalImg, setOpenModalImg] = useState(false);
  const [succesDelete, setSuccesDelete] = React.useState(
    props?.editData?.id ? false : true
  );
  let cacheData = Object.assign({}, cacheContext.cache);
  const userId = localStorage.getItem("useId");
  const [genericAutocompleteModel, setGenericAutocompleteModel] = useState(
    initialGenericModalState
  );

  const schema = Yup.object().shape({
    tip_costuri:
      props.type === VehicleDashboardTableType.costs
        ? Yup.object().required("Acest camp este obligatoriu!")
        : Yup.string(),
    data_intrare: Yup.string().required("Acest camp este obligatoriu"),
    data_iesire: Yup.string().required("Acest camp este obligatoriu"),
    mentiuni: Yup.string().required("Acest camp este obligatoriu"),
    cost: Yup.string().required("Acest camp este obligatoriu"),
    data_programare_service:
      props.type === VehicleDashboardTableType.service
        ? Yup.string().required("Acest camp este obligatoriu")
        : Yup.string(),
  });

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   *
   * @param {*} data
   */
  const onSubmit = (data) => {
    const formData = new FormData();
    if (
      props.type === VehicleDashboardTableType.costs ||
      props.type === VehicleDashboardTableType.guarantees
    ) {
      formData.append("vehicul_id", vehicleContext?.state?.vehicle?.id);
      formData.append("vin", vehicleContext?.state?.vehicle?.vin);
      formData.append(
        "data_start",
        moment(data.data_intrare).format("YYYY-MM-DD HH:mm:ss")
      );
      formData.append(
        "data_sfarsit",
        moment(data.data_iesire).format("YYYY-MM-DD HH:mm:ss")
      );
      formData.append("mentiuni", data.mentiuni);
      formData.append("cost", data.cost);
      if (props.type === VehicleDashboardTableType.guarantees) {
        formData.append("tip_cost_id", cacheData.costTypeGuarantee[0].id);
      } else {
        formData.append("tip_cost_id", data.tip_costuri.id);
      }
      formData.append("user_id", userId);
      formData.append("platit", data.platit);

      if (files && files.length !== 0)
        files.forEach((file) => {
          formData.append("file_id", file);
        });
      else formData.append("file_id", null);
      if (props.edit) {
        updateService(formData, props?.editData?.id);
      } else {
        createNewService(formData);
        resetAllFields();
      }
    } else {
      formData.append("vehicul_id", vehicleContext?.state?.vehicle?.id);
      formData.append("vin", vehicleContext?.state?.vehicle?.vin);
      formData.append(
        "data_intrare",
        moment(data.data_intrare).format("YYYY-MM-DD HH:mm:ss")
      );
      formData.append(
        "data_iesire",
        moment(data.data_iesire).format("YYYY-MM-DD HH:mm:ss")
      );
      if (props.type === VehicleDashboardTableType.service) {
        formData.append(
          "data_programare_service",
          moment(data.data_programare_service).format("YYYY-MM-DD HH:mm:ss")
        );
      }

      formData.append("mentiuni", data.mentiuni);
      formData.append("cost", data.cost);

      if (files && files.length !== 0)
        files.forEach((file) => {
          formData.append("file_id", file);
        });
      else
        formData.append(
          "file_id",
          deletedFile ? null : props?.editData?.file?.id
        );
      if (props.edit) {
        updateService(formData, props?.editData?.id);
      } else {
        createNewService(formData);
        resetAllFields();
      }
    }
  };

  /**
   *
   * @param {*} data
   */
  async function createNewService(data) {
    let url = `/${urlEnum[props.type]}`;
    const res = await postData(url, data);
    if (!res || res?.error) {
      return;
    }
    props.getNewData();
    onClose(false);
  }

  /**
   *
   * @param {*} param0
   * @returns
   */
  const DropDownButton = ({ children, ...other }) => (
    <Paper {...other}>
      <IconButton
        onMouseDown={(event) => {
          event.preventDefault();
          children[1] === "null" || children[1] === null
            ? handleClickEditDrop(
              children[2]?.props?.ownerState?.options,
              children[2]?.props?.ownerState?.label,
              children[2].props?.ownerState?.id
            )
            : handleClickEditDrop(
              children[1]?.props?.ownerState?.options,
              children[1]?.props?.ownerState?.label,
              children[1].props?.ownerState?.id
            );
        }}
        style={{
          position: "absolute",
          top: -40,
          right: -36,
          margin: 5,
          display: "flex",
          justifyContent: "end",
          backgroundColor: "#FF6633",
          color: "#fff",
          borderRadius: 7,
          zIndex: 100,
        }}
        size="small"
        aria-label="editDropdown"
      >
        <ModeIcon fontSize="inherit" />
      </IconButton>
      {children}
    </Paper>
  );
  /**
  *
  */
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "nume",
      label: "Nume",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "pret_recomandat",
      label: "Pret",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  /**
 *
 * @param {*} data
 * @param {*} title
 * @param {*} id
 */
  const handleClickEditDrop = (data, title, id) => {
    setGenericAutocompleteModel({
      ...genericAutocompleteModel,
      open: true,
      title: title,
      data: data,
      id: id,
      columns: columns,
      validationSchema: AutocompleteSchema,
      hiddenFieldsArray: ["brand_id", "id"],
      modelForFields: new AutocompleteModelTipCost(),
    });
  };



  /**
   *
   * @param {*} data
   * @param {*} id
   */
  async function updateService(data, id) {
    let url = `/${urlEnum[props.type]}/${id}`;
    const res = await postData(url, data);
    if (!res || res?.error) {
      return;
    }
    props.getNewData();
    onClose(false);
  }

  /**
   *
   */
  const resetAllFields = () => {
    onClose(false);
    if (!props.edit) {
      reset({
        data_intrare: null,
        data_iesire: null,
        mentiuni: "",
        data_programare_service:
          props.type === VehicleDashboardTableType.service ? null : "",
        cost: "",
      });
    }
  };

  /**
   *
   * @param {*} idService
   * @param {*} idFile
   */
  const submitDeletedCar = (idService, idFile) => {
    deleteServices(idService, idFile);
    setDeletedFile(true);
    setFiles();
    onClose(false);
  };

  /**
   *
   * @param {*} idService
   * @param {*} idFile
   */
  async function deleteServices(idService, idFile) {
    let url = `/${urlEnum[props.type]}/docs/${idFile}/${idService}`;
    const res = await deleteData(url);
    if (!res || res?.error) {
      return;
    }
    setSuccesDelete(true);
    props.getNewData();
    setOpenModalDelete(false);
  }

  /**
   *
   * @param {*} files
   */
  function handleChangeDropFile(files) {
    if (files.length !== 0) {
      setFiles(files);
      setDeletedFile(false);
    }
  }

  return (
    <Dialog
      key={succesDelete}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: maxWidth ? maxWidth : 750,
          },
        },
      }}
    >
      <DialogTitle className={styles.dialogTitle}>
        <p className={styles.title}>{title}</p>
        <p className={styles.close}>
          <CloseIcon
            onClick={() => resetAllFields()}
            className={styles.closeIcon}
          />
        </p>
      </DialogTitle>
      <form
        onSubmit={handleSubmit(onSubmit)}
        method="get"
        id="formAddNewServices"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            margin: 20,
            flexDirection: isMobile() ? "column" : "row",
            gap: 10,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            {props.type === VehicleDashboardTableType.costs ? (
              <Controller
                id="tip_costuri"
                name="tip_costuri"
                control={control}
                defaultValue={
                  props?.editData?.tip_cost_id
                    ? {
                      id: props?.editData?.tip_cost_id,
                      nume: props?.editData?.tipCost,
                    }
                    : null
                }
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    freeSolo={false}
                    style={{ width: isMobile() ? "100%" : 360, marginBottom: 15 }}
                    {...field}
                    size="small"
                    disableClearable
                    disablePortal
                    PaperComponent={
                      DropDownButton
                    }
                    getOptionLabel={(option) => option?.nume}
                    id="tip_costuri"
                    onChange={(event, value) => field.onChange(value)}
                    options={cacheData.costType}
                    renderInput={(params) => (
                      <TextField
                        error={!!error}
                        helperText={error?.message}
                        id="tip_costuri"
                        label="Tip cost"
                        name="tip_costuri"
                        type="search"
                        inputRef={ref}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            ) : null}
            <Controller
              name="data_intrare"
              defaultValue={
                props?.editData?.data_intrare
                  ? moment(props?.editData?.data_intrare)
                  : props?.editData?.data_start
                    ? moment(props?.editData?.data_start)
                    : null
              }
              control={control}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
              render={({ field: { ...field }, fieldState: { error } }) => (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <DatePicker
                    {...field}
                    format={europeanDate}
                    id="data_intrare"
                    defaultValue={
                      props?.editData?.data_intrare
                        ? moment(props?.editData?.data_intrare)
                        : props?.editData?.data_start
                          ? moment(props?.editData?.data_start)
                          : null
                    }
                    label={
                      props.type === VehicleDashboardTableType.costs
                        ? "Data Inceput"
                        : "Data Intrare"
                    }
                    variant="outlined"
                    slotProps={{
                      textField: { size: "small", style: {} },
                    }}
                  />
                  {
                    <p
                      style={{
                        fontSize: 12,

                        marginLeft: 10,
                        color: "#d32f2f",
                      }}
                    >
                      {error ? error?.message : null}
                    </p>
                  }
                </div>
              )}
            />
            {props.type === VehicleDashboardTableType.service ? (
              <Controller
                name="data_programare_service"
                defaultValue={
                  props?.editData?.data_programare_service
                    ? moment(props?.editData?.data_programare_service)
                    : null
                }
                control={control}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
                render={({ field: { ...field }, fieldState: { error } }) => (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <DatePicker
                      {...field}
                      format={europeanDate}
                      id="data_programare_service"
                      defaultValue={
                        props?.editData?.data_programare_service
                          ? moment(props?.editData?.data_programare_service)
                          : null
                      }
                      label="Data Programare"
                      variant="outlined"
                      slotProps={{
                        textField: {
                          size: "small",
                          style: {},
                        },
                      }}
                    />
                    {
                      <p
                        style={{
                          fontSize: 12,

                          marginLeft: 10,
                          color: "#d32f2f",
                        }}
                      >
                        {error ? error?.message : null}
                      </p>
                    }
                  </div>
                )}
              />
            ) : null}
            <Controller
              name="data_iesire"
              defaultValue={
                props?.editData?.data_iesire
                  ? moment(props?.editData?.data_iesire)
                  : props?.editData?.data_sfarsit
                    ? moment(props?.editData?.data_sfarsit)
                    : null
              }
              control={control}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
              render={({ field: { ...field }, fieldState: { error } }) => (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <DatePicker
                    {...field}
                    id="data_iesire"
                    format={europeanDate}
                    defaultValue={
                      props?.editData?.data_iesire
                        ? moment(props?.editData?.data_iesire)
                        : props?.editData?.data_sfarsit
                          ? moment(props?.editData?.data_sfarsit)
                          : null
                    }
                    label={
                      props.type === VehicleDashboardTableType.costs
                        ? "Data Sfarsit"
                        : "Data Iesire"
                    }
                    variant="outlined"
                    slotProps={{
                      textField: { size: "small", style: {} },
                    }}
                  />
                  {
                    <p
                      style={{
                        fontSize: 12,

                        marginLeft: 10,
                        color: "#d32f2f",
                      }}
                    >
                      {error ? error?.message : null}
                    </p>
                  }
                </div>
              )}
            />
          </LocalizationProvider>
          <Controller
            name="cost"
            defaultValue={props?.editData?.cost}
            control={control}
            style={{ width: "100%" }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                value={value}
                error={!!error}
                helperText={error?.message}
                id="cost"
                label="Cost (LEI)"
                size="small"
                variant="outlined"
                type="number"
              />
            )}
          />
          {props.type === VehicleDashboardTableType.costs ||
            props.type === VehicleDashboardTableType.guarantees ? (
            <div>
              <Typography
                style={{
                  marginTop: -10,
                  marginLeft: 15,
                  fontSize: 13,
                  color: "#444",
                }}
              >
                {" "}
                {Vocabulary.paid}
              </Typography>
              <Controller
                defaultValue={
                  props?.editData?.platit ? props?.editData?.platit : "0"
                }
                name="platit"
                control={control}
                render={({ field: { value, onChange, ref } }) => (
                  <Switch
                    defaultChecked={
                      props?.editData?.platit === "1" ? true : false
                    }
                    onChange={(e) => {
                      onChange(e.target.checked ? "1" : "0");
                    }}
                    value={value}
                    id="platit"
                    inputRef={ref}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <Controller
          name="mentiuni"
          defaultValue={props?.editData?.mentiuni}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              style={{ marginRight: 20, marginLeft: 20 }}
              onChange={onChange}
              value={value}
              error={!!error}
              helperText={error?.message}
              id="mentiuni"
              label="Mentiuni"
              size="small"
              variant="outlined"
              multiline
              rows={5}
            />
          )}
        />
        {props?.editData?.file_id ? (
          <div style={{ display: "flex" }}>
            <div style={{ marginTop: 10, marginLeft: 20 }}>
              {getIconByFileFileType(
                props?.editData?.fileName
                  ? props?.editData?.fileName
                  : props?.editData?.file
                    ? props?.editData?.file.file
                    : "",
                100
              )}
            </div>

            <DeleteIcon
              onClick={() => setOpenModalDelete(true)}
              style={{
                marginTop: 20,
                backgroundColor: "#ff6633",
                color: "#fff",
                padding: 5,
                borderRadius: 7,
                cursor: "pointer",
              }}
            ></DeleteIcon>
          </div>
        ) : (
          <div style={{ textDecoration: "none" }}>
            <DropzoneArea
              acceptedFiles={["image/*,application/*"]}
              dropzoneClass={style.dropzoneArea}
              maxFileSize={30000000} //30 MB
              filesLimit={1}
              showAlerts={false}
              onChange={handleChangeDropFile}
              onDelete={() => setFiles([])}
            />

          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: 20,
          }}
        >
          <Button
            onClick={() => resetAllFields()}
            variant="outlined"
            style={{
              color: "white",
              backgroundColor: "#444",
            }}
          >
            Anuleaza
          </Button>
          <Button
            variant="contained"
            style={{ color: "white" }}
            color="primary"
            type="submit"
            form="formAddNewServices"
          >
            Salveaza
          </Button>
        </div>
        <GenericModal
          open={openModalImg}
          onClose={() => setOpenModalImg(false)}
          title=""
        >
          <div style={{ marginTop: 10, marginLeft: 20 }}>
            {getIconByFileFileType(
              props?.editData?.fileName
                ? props?.editData?.fileName
                : props?.editData?.file
                  ? props?.editData?.file.file
                  : "",
              100
            )}
          </div>
        </GenericModal>
        <GenericModal
          open={openModalDelete}
          onClose={() => setOpenModalDelete(false)}
          title=""
        >
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: 20 }}>Sunteti sigur ca doriti sa stergeti?</p>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                fullWidth
                color="secondary"
                style={{ margin: 10 }}
                onClick={() => setOpenModalDelete(false)}
              >
                {Vocabulary.no}
              </Button>
              <Button
                onClick={() =>
                  submitDeletedCar(
                    props?.editData?.id,
                    props?.editData?.file_id
                  )
                }
                fullWidth
                variant="contained"
                style={{ color: "white", margin: 10 }}
              >
                {Vocabulary.yes}
              </Button>
            </div>
          </div>
        </GenericModal>
        {genericAutocompleteModel.open && (
          <GenericAutocompleteModal
            open={genericAutocompleteModel.open}
            onClose={() =>
              setGenericAutocompleteModel({
                ...genericAutocompleteModel,
                open: false,
                modelForFields: new AutocompleteModel(),
              })
            }
            title={genericAutocompleteModel.title}
            data={genericAutocompleteModel.data}
            id={genericAutocompleteModel.id}
            columns={genericAutocompleteModel.columns}
            validationSchema={genericAutocompleteModel.validationSchema}
            hiddenFieldsArray={genericAutocompleteModel.hiddenFieldsArray}
            modelForFields={genericAutocompleteModel.modelForFields}
            onEdit={(value, tableMeta) => {
              setGenericAutocompleteModel({
                ...genericAutocompleteModel,
                modelForFields: tableMeta.tableData[tableMeta.rowIndex],
              });
            }}
          // onSucces={() => {
          //   setModels(brandId.brand_id, cacheContext.cache?.models);
          //   setValue(genericAutocompleteModel.id, null);
          // }}
          ></GenericAutocompleteModal>
        )}
      </form>
    </Dialog>
  );
}

export default GenericServicesModal;
