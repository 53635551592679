/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { CacheContext } from "../../Context/cacheContext";
import { useContext } from "react";
import { GenericTemplatesType } from "../../Utils/utils";
import { GenericTemplatesProps } from "./GenericTemplates";

type GenericTemplateDetailsProps = {
  fromVehicle: boolean;
  genericTemplate: GenericTemplatesProps;
  handleChangeTemplateProps: (e: any) => void;
  handleChangeTemplateStatus: (e: any) => void;
  handleChangeTemplateType: (e: any) => void;
  handleChangeOnFocus: (value: string) => void;
};
export default function GenericTemplateDetails(
  props: GenericTemplateDetailsProps
) {
  const cacheContext: any = useContext(CacheContext);
  const {
    fromVehicle,
    genericTemplate,
    handleChangeTemplateProps,
    handleChangeTemplateStatus,
    handleChangeTemplateType,
    handleChangeOnFocus,
  } = props;

  return (
    <Grid container spacing={3}>
      {fromVehicle ? null : (
        <>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              type="text"
              label={Vocabulary.templateName}
              name="templateName"
              variant="standard"
              fullWidth
              required
              value={genericTemplate.templateName}
              onChange={(e: any) => handleChangeTemplateProps(e)}
              style={{ margin: "10px 0px" }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl
              variant="standard"
              fullWidth
              style={{ margin: "10px 0px" }}
            >
              <InputLabel>{Vocabulary.type}</InputLabel>
              <Select
                value={genericTemplate.type}
                name="type"
                onChange={(e) => {
                  handleChangeTemplateType(e);
                }}
              >
                {GenericTemplatesType.map((type: string) => (
                  <MenuItem value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl
              variant="standard"
              fullWidth
              style={{ margin: "10px 0px" }}
            >
              <InputLabel>
                {genericTemplate.status_id ? "" : Vocabulary.status}
              </InputLabel>
              <Select
                value={genericTemplate.status_id}
                name="template"
                onChange={(e) => {
                  handleChangeTemplateStatus(e);
                }}
              >
                {cacheContext.cache?.templatesStatus.map((status: any) => (
                  <MenuItem value={status.id}>{status.nume}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              type="text"
              label={Vocabulary.subject}
              name="subject"
              variant="standard"
              fullWidth
              value={genericTemplate.subject}
              onFocus={() => handleChangeOnFocus(Vocabulary.subject)}
              onChange={(e: any) => handleChangeTemplateProps(e)}
              style={{ margin: "10px 0px" }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              type="text"
              label={Vocabulary.cc}
              placeholder={Vocabulary.ccMessage}
              name="cc"
              variant="standard"
              fullWidth
              value={genericTemplate.cc}
              onChange={(e: any) => handleChangeTemplateProps(e)}
              style={{ margin: "10px 0px" }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
