import { Paper } from "@mui/material";
import styles from "../Styles/progressAction.module.css";

// import { Vocabulary } from "../../Utils/Vocabulary";

type ProgressActionWrapperProps = {
  title: string;
  children?: JSX.Element | JSX.Element[];
  info?: { invoices: object };
  disabled?: boolean;
  status?: boolean;
  modifyBy?: any;
};

export default function Wrapper({
  title,
  children,

  status,
}: ProgressActionWrapperProps) {
  return (
    <Paper className={styles.mainContainer}>
      <div className={styles.simpleHeader}>
        <div>
          <p className={styles.simpleWrapperTitle}>{title}</p>
        </div>
      </div>
      {children}
    </Paper>
  );
}
