/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import MUIDataTable from "mui-datatables";

import { useState } from "react";

import Permission from "./Permission";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

export type PermissionsData = {
  id?: number | undefined;
  nume: string;
  culoare: string;
  tip_permissions: string;
};
export default function PermisionsForEachRole(props: any) {
  const [editedPermissions, setEditedPermissions] = useState(null);

  /**
   *
   */
  const permissionsHeader = [
    {
      label: `Nr. crt`,
      name: "name",
      options: {
        sort: false,
        customBodyRenderLite: (rowIndex: number) => {
          return <div>{rowIndex + 1}</div>;
        },
      },
    },
    {
      label: `${Vocabulary.name}`,
      name: "name",
      options: {
        sort: false,
        customBodyRender: (rowIndex: any, index: any) => {
          return Vocabulary[rowIndex];
        },
      },
    },

    {
      label: "",
      name: "",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (rowIndex: number) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10 }}>
              {props?.data[rowIndex]?.isUsed &&
              props?.data[rowIndex]?.isDepartment === 0 ? (
                <ToggleButtonGroup exclusive aria-label="text alignment">
                  <Tooltip title={Vocabulary.add} placement="left">
                    <ToggleButton
                      onClick={() => {
                        props.handleChangePermissions(rowIndex);
                      }}
                      value="center"
                      aria-label="centered"
                      style={{ backgroundColor: "#73c08578" }}
                    >
                      <AddIcon />
                    </ToggleButton>
                  </Tooltip>
                </ToggleButtonGroup>
              ) : (
                <ToggleButtonGroup exclusive aria-label="text alignment">
                  {props?.data[rowIndex]?.isDepartment === 0 ? (
                    <Tooltip title={Vocabulary.exclude} placement="left">
                      <ToggleButton
                        onClick={() => {
                          props.handleChangePermissions(rowIndex);
                        }}
                        value="center"
                        aria-label="centered"
                        style={{ backgroundColor: "#c0737378" }}
                      >
                        <CloseIcon />
                      </ToggleButton>
                    </Tooltip>
                  ) : null}
                </ToggleButtonGroup>
              )}
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    const selectable: "multiple" | "single" | "none" | undefined = "none";

    return {
      responsive: responsive,
      viewColumns: false,

      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      selectableRows: selectable,
      download: false,
      fixedHeader: true,
      search: false,
      serverSide: true,
      sort: true,
      customFooter: () => {
        return <div></div>;
      },
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: Vocabulary.pagination,
      },
    };
  }
  return (
    <div>
      <Typography style={{ marginLeft: 10, fontSize: 13, color: "#737373" }}>
        {Vocabulary.permissionsName}
      </Typography>
      <MUIDataTable
        title={""}
        data={props?.data}
        columns={permissionsHeader}
        options={getTableOptions()}
      />

      <Permission
        open={props.openEditModal}
        onClose={(shouldRefetch) => {
          props.setOpenEditModal(false);
          setEditedPermissions(null);
        }}
        title={
          editedPermissions
            ? Vocabulary.editPermissions
            : Vocabulary.addNewPermission
        }
        editedPermissions={editedPermissions}
      />
    </div>
  );
}
