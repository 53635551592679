import { ContractInformationModel } from "./Models";

export class VehicleModel {
  id = 0;
  slug = "";
  tip_vehicul_id: number | null | undefined = null;
  brand_id: number | null | undefined = null;
  model_id: number | null | undefined = null;
  variant: string | null = "";
  client_id: number | null | undefined = null;
  shipping_id: number | null | undefined = null;
  data_achizitie: moment.Moment | Date | string | null = null;
  data_receptie: moment.Moment | Date | string | null = null;
  data_rezervare: moment.Moment | Date | string | null = "";
  pickupDate: moment.Moment | Date | string | null = "";
  estimatedArrivalDate: moment.Moment | Date | string | null = "";
  data_transport: moment.Moment | Date | string | null = "";
  data_pregatire_vanzare: moment.Moment | Date | string | null = "";
  data_inmatriculare: moment.Moment | Date | string | null = null;
  data_vanzare: moment.Moment | Date | string | null = "";
  sort_order = null;
  pret_achizitie_euro = 0;
  cursEuro = 1;
  pret_achizitie_ron = 0;
  pret_vanzare_euro = 0;
  pret_vanzare_ron = 0;
  pret_site_vanzare = 0;
  pret_vechi_vanzare: number | null = 0;
  pret_minim_vanzare = 0;
  status_id: number | null = null;
  tip_achizitie_id: number | null = null;
  locatie_administrativa_id: number | null = null;
  combustibil_id: number | null = null;
  kilometri: number | null = 0;
  an_fabricatie: number | null = 0;
  putere_cp: number | null = 0;
  cutie_viteze_id: number | null = null;
  nr_locuri: number | null = 5;
  tractiune_id: number | null = null;
  norma_poluare_id: number | null = null;
  vin = "";
  tva_id: number | null = null;
  TvaRecovered: boolean | null = false;
  capacitate_cilindrica: number | null = null;
  descriere = "";
  notite = "";
  culoare_id: number | null = null;
  optiuni_culoare_id: number | null = null;
  tip_caroserie_id: number | null = null;
  vizibil_site: boolean | null = false;
  retromobil: boolean | null = false;
  carte_service: boolean | null = false;
  tuning: boolean | null = false;
  numar_portiere = 5;
  tara_origine_id: number | null = null;
  user_id: number | null = null;
  vanzator_id: number | null = null;
  user_achizitie_id: number | null = null;
  user_logistica_id: number | null = null;
  user_intermediere_id?: number | null = null;
  dobanda_vanzare: number | null = null;
  dobanda: number | null = 0;
  blocat: boolean | null = false;
  fisa_receptie_id: number | null = null;
  factura_id: number | null = null;
  firma_achizitie: string | null = null;
  firma_achizitie_id: number | null = null;
  furnizor_id: number | null = null;
  contractData: ContractInformationModel | null =
    new ContractInformationModel();
  indexes: string[] = [];
  modifyBy: string | null = "[]";
  created_at: moment.Moment | Date | string | null = "";
  updated_at: moment.Moment | Date | string | null = "";
  volan: boolean | null = false;
  obsTVA = "";
  numeCompletVin = "";
  numeComplet = "";
  locatieAdministrativa = "";
  statusVehicul = "";
  total_interventii_detailing: number | null = 0;
  total_interventii_vopsitorie: number | null = 0;
  total_interventii_service: number | null = 0;
  suma_investitii_detailing: number | null = 0;
  suma_investitii_vopsitorie: number | null = 0;
  suma_investitii_service: number | null = 0;
  interventii_service: Array<InterventieModel> | null = [];
  interventii_detailing: Array<InterventieModel> | null = [];
  interventii_vopsitorie: Array<InterventieModel> | null = [];
  suma_costuri_aditionale: number | null = 0;
  costuri_aditionale: Array<CostAditionalModel> | null = [];
  suma_intrare_garantie: number | null = 0;
  intrare_garantie: Array<InterventieModel> | null = [];
  total_costuri_aditionale: number | null = 0;
  suma_costuri_logistica: number | null = 0;
  suma_costuri_marketing: number | null = 0;
  suma_costuri_administrative: number | null = 0;
  profit_net_vehicul: number | null = 0;
  profit_brut_vehicul: number | null = 0;
  suma_investita: number | null = 0;
  valoare_vehicul: number | null = 0;
  durata_vanzare: number | null = 0;
  durata_pregatire_vanzare: number | null = 0;
  vanzator: boolean | null = false;
  costuri_aditionale_baza: number | null = 0;
  profit_brut_vehicul_baza: number | null = 0;
  profit_net_vehicul_baza: number | null = 0;
  short_vin = "";
  factura = "";
  fisa_receptie = "";
  documente_de_plata: number | null = 0;
  documente_de_incasat: number | null = 0;
  documente_platite: number | null = 0;
  documente_neplatite: number | null = 0;
  durata_receptie: number | null = 0;
  estimare_comision_vanzare: number | null = 0;
  estimare_comision_volum: number | null = 0;
  estimare_comision_ca: number | null = 0;
  estimare_comision_profit: number | null = 0;
  estimare_comision_total: number | null = 0;
  valoare_comisioane: number | null = 0;
  cheltuieli: number | null = 0;
  statusError: number | null = 0;
  user_r: UserModel | null = null;
  tip_vehicul_r: Nomenclator | null = null;
  user_achizitie_r: UserModel | null = null;
  user_logistica_r: UserModel | null = null;
  tip_achizitie_r: Nomenclator | null = null;
  combustibil_r: Nomenclator | null = null;
  cutie_viteze_r: Nomenclator | null = null;
  tip_tractiune_r: Nomenclator | null = null;
  culoare_r: Nomenclator | null = null;
  norma_poluare_r: Nomenclator | null = null;
  optiune_culoare_r: Nomenclator | null = null;
  tip_caroserie_r: Nomenclator | null = null;
  tara_origine_r: Nomenclator | null = null;
  furnizor_r: Nomenclator | null = null;
  dotari: Array<any> = [];
  brand_r: Nomenclator | null = null;
  model_r: VehicleBrandModel_Model | null = null;
  locatie_administrativa_r: AdministrativeLocation | null = null;
  status_vehicul_r: VehicleStatusModel | null = null;
  physical_status: VehicleStatusModel | null = null;
  firma_achizitie_r: FirmaAchizitieModel | null = null;
  firma: VehicleStatusModel | null = null;
  tip_tva_r: TipTvaModel | null = null;
  images: Array<VehicleFilesModel> | null = null;
  documents: Array<VehicleFilesModel> | null = null;
  clientInvoice: Array<VehicleFilesModel> | null = null;
  providerInvoice: Array<VehicleFilesModel> | null = null;
  OPInvoice: Array<VehicleFilesModel> | null = null;
  reservedDocuments: Array<VehicleFilesModel> | null = null;
  vehicule_files_r: Array<VehicleFilesModel> | null = null;
  main_image: string | null = null;
  status_logs: [] = [];
}

export class InterventieModel {
  id: number | null = null;
  vehicul_id: number | null = null;
  data_programare_service: moment.Moment | Date | string | null = "";
  data_intrare: moment.Moment | Date | string | null = "";
  data_iesire: moment.Moment | Date | string | null = "";
  mentiuni = "";
  cost: number | null = 0;
  file_id: number | null = null;
  user_id: number | null = null;
  notificat = "";
  fileName: string | null = null;
}

export class CostAditionalModel {
  id: number | null = null;
  vehicul_id: number | null = null;
  tip_cost_id: number | null = null;
  cost: number | null = 0;
  mentiuni = "";
  file_id: number | null = null;
  data_start: moment.Moment | Date | string | null = "";
  data_sfarsit: moment.Moment | Date | string | null = "";
  user_id: moment.Moment | Date | string | null = "";
  platit: boolean | null = false;
  doc = "";
  tipCost = "";
  file: any = null;
}

export class UserModel {
  id: number | null = null;
  file_id: number | null = null;
  name = "";
  telefon = "";
  email = "";
  isActive = "";
  comision_user: number | null = 0;
  locatie_administrativa_id: number | null = null;
  email_verified_at: moment.Moment | Date | string | null = "";
}

export class Nomenclator {
  id: number | null = null;
  nume = "";
}

export class VehicleBrandModel_Model {
  id: number | null = null;
  nume = "";
  brand_id: number | null = null;
}

export class AdministrativeLocation {
  id: number | null = null;
  name = "";
  adresa = "";
  judet = "";
  telefon = "";
}

export class VehicleStatusModel {
  id: number | null = null;
  nume = "";
  culoare = "";
  tip_status = "";
}

export class FirmaAchizitieModel {
  id: number | null = null;
  nume_firma = "";
}

export class TipTvaModel {
  id: number | null = null;
  name = "";
  valoare: number | null = 19;
}

export class VehicleFilesModel {
  id: number | null = null;
  vehicul_id: number | null = null;
  sort_order: number | null = 0;
  file = "";
  name = "";
  tip_financiar = "";
  descriere_financiar = "";
  status_financiar = "";
  suma_financiar: number | null = 0;
  status_poza: boolean | null = false;
  tip_fisier = "";
  mime = "";
  size: number | null = 0;
  date: moment.Moment | Date | string | null = null;
  type: string | null = null;
}
