/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "../../Styles/mainDashboard.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneIcon from "@mui/icons-material/Tune";
import { isMobile } from "../../Utils/utils";
import {
  RolesEnum,
  clientMessagesStatus,
  componentNames,
} from "../../Utils/Constants";
import { getData } from "../../Services/getData";
import { localUrlEnum, urlEnum } from "../../Utils/urlEnum";
import { CarOrdersTab } from "../../Utils/autocompleteEnum";
import MainTableSalesOnDemand from "./MainTableSalesOnDemand";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import OnDemandFilters from "./OnDemandFilters";
import moment, { Moment } from "moment";
import { Vocabulary } from "../../Utils/Vocabulary";
import { CacheContext } from "../../Context/cacheContext";
import { StatusModel } from "../../Models/Models";
import withRole from "../../Utils/withRole";

export type StateProps = {
  carOrders: Array<any>;
  carOrdersNumber: number;
};

export type FiltersProps = {
  type: string;
  page: number;
  perPage: number;
  brand: number | null;
  model: number | null;
  status: StatusModel[] | null;
  user: number | null;
  startDate: Moment | string;
  endDate: Moment | string;
  search: string | null;
  source: string | null;
};

function DashboardSalesOnDemand(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [openAccordion, setOpenAccordion] = useState(false);
  const [state, setState] = useState<StateProps>({
    carOrders: [],
    carOrdersNumber: 0,
  });
  const [value, setValue] = useState(0);
  const [status, setStatus] = useState("");

  const [unassignedMessagesCount, setUnassignedMessagesCount] = useState(0);
  const [unreadCarOrdersCount, setUnreadCarOrderdsCount] = useState(null);
  const roles: any = window.localStorage.getItem("roles");
  const isAdmin = roles.includes(RolesEnum.admin);

  const cacheContext = useContext(CacheContext);
  const cacheValue = cacheContext.cache.settings.find(
    (user: any) => user.slug == "automatedClientMessages"
  )?.value;
  const isAutomatedMessages = cacheValue ? parseInt(cacheValue) : null;
  const tabsLabelsArray: any[] = [];

  tabsLabelsArray.push(
    {
      label: Vocabulary.allocated,
      badge: 0, //CA SA NU AFISEZE DELOC BADGE
      code: clientMessagesStatus.Alocate,
    },
    {
      label: Vocabulary.canceled,
      badge: 0, //CA SA NU AFISEZE DELOC BADGE
      code: clientMessagesStatus.Anulate,
    }
  );

  //select what tabs to include
  if (isAdmin || props.info)
    //show all messages only for admin
    tabsLabelsArray.push({
      label: Vocabulary.all,
      badge: 0, //CA SA NU AFISEZE DELOC BADGE
      code: clientMessagesStatus.Toate,
    });

  if (!isAutomatedMessages)
    //show unassigned messages only if automated allocation is off
    tabsLabelsArray.push({
      label: !isAutomatedMessages ? Vocabulary.unallocated : null,
      badge: unassignedMessagesCount,
      code: clientMessagesStatus.Nealocate,
    });
  const [filters, setFilters] = useState<FiltersProps>({
    type: tabsLabelsArray[0].code,
    page: 0,
    perPage: 10,
    brand: null,
    model: null,
    status: null,
    user: null,
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    search: null,
    source: null,
  });

  /**
   *
   */
  useEffect(() => {
    getUrlCarOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   */
  const getUrlCarOrders = () => {
    const search = location.search.split("?");
    const newFilters = Object.assign({}, filters) as any;
    search.forEach((value) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "search")
          newFilters[values[0]] = decodeURIComponent(values[1]);
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        else
          newFilters[values[0]] = !isNaN(+values[1]) ? +values[1] : values[1];
        if (values[1] === "null") newFilters[values[0]] = null;
      }
    });
    if (newFilters.type) setStatus(newFilters.type);
    else newFilters.type = tabsLabelsArray[0].code;
    setValue(newFilters.index ? newFilters.index : 0);
    setFilters(newFilters);
    getCarOrders(newFilters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  /**
   *
   * @param newFilters
   */
  async function getCarOrders(newFilters: any) {
    const url = `${urlEnum.carOrders}/${newFilters.type}/${newFilters.page}/${
      newFilters.perPage
    }/${newFilters.brand}/${newFilters.model}/${
      JSON.parse(newFilters.status)?.length
        ? JSON.parse(newFilters.status)
        : null
    }/${newFilters.user}/${moment(
      newFilters.startDate ? newFilters.startDate : null
    ).format("YYYY-MM-DD")}/${moment(
      newFilters.endDate ? newFilters.endDate : null
    ).format("YYYY-MM-DD")}/${newFilters.search ? newFilters.search : null}/${
      newFilters.source
    }`;

    const res = await getData(url);
    setState(res.data);
    setUnassignedMessagesCount(res.data?.unassignedMessagesCount);
    setUnreadCarOrderdsCount(res.data?.unreadCarOrderds);
  }

  /**
   *
   * @param event
   * @param index
   */
  const handleChange = (event: any, index: number) => {
    deleteFilters();
    const statusCode = tabsLabelsArray[index].code;
    setStatus(statusCode);
    setValue(index);
    navigate(
      `${
        localUrlEnum.onDemand
      }?type=${statusCode}?index=${index}?page=${1}?perPage=${filters.perPage}`
    );
  };

  /**
   *
   */
  function deleteFilters() {
    setFilters({
      type: CarOrdersTab.allocated,
      page: 0,
      perPage: 10,
      brand: null,
      model: null,
      status: null,
      user: null,
      startDate: filters.startDate,
      endDate: filters.endDate,
      search: null,
      source: null,
    });
  }

  /**
   *
   * @param newFilters
   */
  function handleAccordion(open: boolean) {
    setOpenAccordion(open);
  }

  return (
    <div
      style={{ display: "flex", flexDirection: isMobile() ? "column" : "row" }}
    >
      {isMobile() ? (
        <Accordion
          expanded={openAccordion}
          onChange={() => handleAccordion(!openAccordion)}
          style={{ zIndex: 10 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <TuneIcon style={{ marginRight: 10 }}></TuneIcon>
            <Typography style={{ marginTop: 1 }}>Filtre </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <OnDemandFilters
              setFilters={setFilters}
              status={status}
              filters={filters}
              value={value}
            />
          </AccordionDetails>
        </Accordion>
      ) : (
        <div className={style.vehicleSlider}>
          <OnDemandFilters
            setFilters={setFilters}
            status={status}
            filters={filters}
            value={value}
          />
        </div>
      )}

      <div className={style.mainTable}>
        {
          <MainTableSalesOnDemand
            name={componentNames.MainTable}
            key={new Date().getTime()}
            info={{ route: "onDemand" }}
            filters={filters}
            state={state}
            value={value}
            handleChange={handleChange}
            getCarOrders={getCarOrders}
            tabsLabelsArray={tabsLabelsArray}
            status={status}
            unassignedMessagesCount={unassignedMessagesCount}
            cacheContext={cacheContext}
            unreadCarOrdersCount={unreadCarOrdersCount}
          />
        }
      </div>
    </div>
  );
}
export default withRole(DashboardSalesOnDemand);
